import { datadogRum } from '@datadog/browser-rum';
import { useProvider } from 'hooks';
import { uniqBy } from 'lodash';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import moment from 'moment-timezone';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-big-calendar';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, usePrevious, useUpdateEffect } from 'react-use';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderCalendarRead } from '@headway/api/models/ProviderCalendarRead';
import { ProviderCalendarSyncStatus } from '@headway/api/models/ProviderCalendarSyncStatus';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { StateInsuranceCarrierRead } from '@headway/api/models/StateInsuranceCarrierRead';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { StateInsuranceCarrierApi } from '@headway/api/resources/StateInsuranceCarrierApi';
import { theme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';
import { SelectedEventContextProvider } from '@headway/shared/events/SelectedEventContext';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { useProviderUserFreezes } from '@headway/shared/hooks/useProviderUserFreezes';
import { useQuery, useQueryClient } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { Button, LogoLoader } from '@headway/ui';

import { Message, MessagesApi } from 'api/MessagesApi';
import { EmptyView } from 'components/EmptyView';
import {
  useGetProviderEventsForCalendar,
  useGetProviderEventsForCalendarCache,
} from 'hooks/useGetProviderEventsForCalendar';
import { PaddedPanelLayout } from 'layouts/PaddedPanelLayout';
import { useSyncExternalCalendarMutation } from 'mutations/externalCalendar';
import { useUiStore } from 'stores/UiStore';

import { FullHeightPanelLayout } from '../../layouts/FullHeightPanelLayout';
import {
  PatientsContext,
  PatientsProvider,
} from '../../providers/PatientsProvider';
import { Calendar } from './Calendar';
import { HWMAAlerts } from './components/HWMAAlerts';
import { getEarliestHour } from './events/util/events';
import { LiveDateCalculator, LiveDateData } from './LiveDateCalculator';
import { CALENDAR_VIEW_STORAGE_KEY } from './utils/constants';
import {
  getCalendarRangeQueryKeyArgs,
  getExpandedPatientBookedEventsQueryKeyArgs,
  getPastScheduledAppointmentsQueryKeyArgs,
  useProviderCalendarsQuery,
} from './utils/queries';

export interface InitialEventToDisplay {
  eventId: number;
  startDate: Date;
}

const localStorageWorkingHoursAlertTimestamp = () =>
  window.localStorage.getItem('last_working_hours_alert_timestamp');

const getInitialEventFromQueryParams = (
  searchParams: queryString.ParsedQuery<string>
): InitialEventToDisplay | undefined => {
  let eventId;
  let startDate;

  if (searchParams.event_id && !Array.isArray(searchParams.event_id)) {
    eventId = parseInt(searchParams.event_id);
  }

  if (searchParams.start_date && !Array.isArray(searchParams.start_date)) {
    startDate = moment.utc(searchParams.start_date).local().toDate();
  }

  if (!eventId || !startDate) {
    return undefined;
  }

  return {
    eventId,
    startDate,
  };
};

const getIsModalOpenOnLoadFromQueryParams = (
  searchParams: queryString.ParsedQuery<string>,
  paramName: string
): boolean => {
  return searchParams[paramName] === 'true';
};

function getCalendarStartTime(
  events: ProviderEventRead[],
  isMobile: boolean,
  initialEvent?: InitialEventToDisplay
) {
  if (initialEvent) {
    return moment(initialEvent.startDate).local().subtract('hours', 2).toDate();
  }

  // on mobile show the current point in time
  if (isMobile) {
    return moment().subtract('minutes', 30).startOf('minute').toDate();
  } else if (events.length === 0) {
    return moment().hours(9).startOf('hour').toDate();
  } else {
    // on desktop try to show all events on the calendar
    const earliestHour = getEarliestHour(events);
    return moment()
      .hours(Math.max(0, earliestHour - 1))
      .startOf('hour')
      .toDate();
  }
}

const DEFAULT_CALENDAR_VIEW = 'week';
const getStoredCalendarView = () =>
  (window.localStorage.getItem(CALENDAR_VIEW_STORAGE_KEY) as View) ||
  DEFAULT_CALENDAR_VIEW;
const initialDaysPerView: {
  [key in View]?: number;
} = {
  day: 1,
  week: 7,
  work_week: 7,
  month: 31,
};

const momentTimePerView: {
  [key in View]?: moment.unitOfTime.StartOf;
} = {
  day: 'day',
  week: 'week',
  work_week: 'week', // not quite, but close enough
  month: 'month',
};

type CalendarViewWithContextProps = {
  AuthStore?: any;
  UiStore?: any;
  initialEventToDisplay?: InitialEventToDisplay;
  initialIsConfirmDetailsOpen: boolean;
  initialIsProgressNoteOnly: boolean;
  initialIsAppointmentToIntakeCallOpen: boolean;
  initialIsCancelOpen: boolean;
  initialIsRescheduling: boolean;
  calendars?: ProviderCalendarRead[];
  stateInsuranceCarriers?: StateInsuranceCarrierRead[];
  carriersById: { [index: string]: FrontEndCarrierRead };
  freezeReasonsByUser: { [index: string]: UserFreezeReason[] };
} & LiveDateData;

const CalendarViewWithContext: React.FC<
  React.PropsWithChildren<CalendarViewWithContextProps>
> = (props) => {
  const getViewDateRangeStart = () =>
    moment(props?.initialEventToDisplay?.startDate ?? undefined)
      .startOf(
        momentTimePerView[getStoredCalendarView()] || DEFAULT_CALENDAR_VIEW
      )
      .toDate();
  const getViewDateRangeEnd = () =>
    moment(props?.initialEventToDisplay?.startDate ?? undefined)
      .endOf(
        momentTimePerView[getStoredCalendarView()] || DEFAULT_CALENDAR_VIEW
      )
      .toDate();

  const [isCalendarInitialized, setIsCalendarInitialized] =
    useState<boolean>(false);
  const [view, setView] = useState<View>(getStoredCalendarView());
  const [mobileView, setMobileView] = useState<boolean>(false);
  const [eventsDateRangeStart, setEventsDateRangeStart] = useState<Date>(
    moment(getViewDateRangeStart())
      .subtract(initialDaysPerView[getStoredCalendarView()], 'days')
      .startOf('day')
      .toDate()
  );
  const [eventsDateRangeEnd, setEventsDateRangeEnd] = useState<Date>(
    moment(getViewDateRangeEnd())
      .add(initialDaysPerView[getStoredCalendarView()], 'days')
      .endOf('day')
      .toDate()
  );
  const [viewDateRangeStart, setViewDateRangeStart] = useState<Date>(
    getViewDateRangeStart()
  );
  const [viewDateRangeEnd, setViewDateRangeEnd] = useState<Date>(
    getViewDateRangeEnd()
  );
  const [timeZone] = useState<string>(moment.tz.guess());
  const [scrollToTime, setScrollToTime] = useState<Date | undefined>(undefined);
  const [daysPerView, setDaysPerView] =
    useState<typeof initialDaysPerView>(initialDaysPerView);

  const provider = useProvider();

  const expandedEventsWithinCalendarRangeQueryKeyArgs =
    getCalendarRangeQueryKeyArgs(
      provider.id,
      viewDateRangeStart,
      viewDateRangeEnd
    );

  const expandedAppointmentEventsPastScheduledQueryKeyArgs =
    getPastScheduledAppointmentsQueryKeyArgs(provider.id);

  const expandedPatientBookedEventsQueryKeyArgs =
    getExpandedPatientBookedEventsQueryKeyArgs(provider.id);

  const getProviderEventsForCalendarCache =
    useGetProviderEventsForCalendarCache();

  const {
    data: expandedEventsWithinCalendarRangeResult,
    isLoading: isExpandedEventsWithinCalendarRangeLoading,
  } = useGetProviderEventsForCalendar(
    expandedEventsWithinCalendarRangeQueryKeyArgs,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {},
    }
  );
  const expandedEventsWithinCalendarRange = useMemo(
    () => expandedEventsWithinCalendarRangeResult?.data || [],
    [expandedEventsWithinCalendarRangeResult?.data]
  );
  const {
    data: expandedAppointmentEventsPastScheduledResult,
    isLoading: isExpandedAppointmentEventsPastScheduledLoading,
  } = useGetProviderEventsForCalendar(
    expandedAppointmentEventsPastScheduledQueryKeyArgs,
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );
  const expandedAppointmentEventsPastScheduled = useMemo(
    () => expandedAppointmentEventsPastScheduledResult?.data || [],
    [expandedAppointmentEventsPastScheduledResult?.data]
  );
  const {
    data: expandedPatientBookedEventsResult,
    isLoading: isExpandedPatientBookedEventsLoading,
  } = useGetProviderEventsForCalendar(expandedPatientBookedEventsQueryKeyArgs, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const expandedPatientBookedEvents = useMemo(
    () => expandedPatientBookedEventsResult?.data || [],
    [expandedPatientBookedEventsResult?.data]
  );

  const isInitialLoading =
    isExpandedEventsWithinCalendarRangeLoading ||
    isExpandedAppointmentEventsPastScheduledLoading ||
    isExpandedPatientBookedEventsLoading;

  const expandedEvents = useMemo(
    () =>
      uniqBy(
        [
          ...expandedEventsWithinCalendarRange,
          ...expandedAppointmentEventsPastScheduled,
          ...expandedPatientBookedEvents,
        ],
        (e) => e.virtualId
      ),
    [
      expandedEventsWithinCalendarRange,
      expandedAppointmentEventsPastScheduled,
      expandedPatientBookedEvents,
    ]
  );

  const patientBookedEventsIds = uniq(
    expandedPatientBookedEvents.filter((e) => !!e.id).map((e) => e.id)
  ).sort();

  const messagesParams = {
    provider_event_ids: patientBookedEventsIds,
    message_types: Message.Type.PATIENT_BOOKING,
  };
  const { data: patientBookingMessages } = useQuery(
    ['findMessages', messagesParams],
    () => MessagesApi.findMessages(messagesParams),
    { enabled: patientBookedEventsIds.length > 0 }
  );

  const patientBookingMessagesByEventId = keyBy(
    patientBookingMessages?.data || [],
    (m) => m.provider_event_id
  );

  useEffect(() => {
    datadogRum.startDurationVital('calendarLoadTime');
  }, []);

  const isBelowTabletView = useMediaQuery(theme.__futureMedia.below('tablet'));
  useEffect(() => {
    setMobileView(isBelowTabletView);
    setDaysPerView((prev) => {
      return { ...prev, week: isBelowTabletView ? 3 : 7 };
    });
  }, [isBelowTabletView]);

  useEffect(() => {
    if (!isInitialLoading) {
      setIsCalendarInitialized(true);
    }
  }, [isInitialLoading]);

  useEffect(() => {
    if (!isCalendarInitialized) {
      return;
    }
    setScrollToTime(
      getCalendarStartTime(
        expandedEventsWithinCalendarRange,
        mobileView,
        props.initialEventToDisplay
      )
    );
  }, [
    isCalendarInitialized,
    expandedEventsWithinCalendarRange,
    mobileView,
    props.initialEventToDisplay,
  ]);

  const prevInitialEventToDisplay = usePrevious(props.initialEventToDisplay);
  useUpdateEffect(() => {
    if (
      prevInitialEventToDisplay?.eventId !==
      props.initialEventToDisplay?.eventId
    ) {
      setEventsDateRangeStart(
        moment(getViewDateRangeStart())
          .subtract(initialDaysPerView[getStoredCalendarView()], 'days')
          .startOf('day')
          .toDate()
      );
      setEventsDateRangeEnd(
        moment(getViewDateRangeEnd())
          .add(initialDaysPerView[getStoredCalendarView()], 'days')
          .endOf('day')
          .toDate()
      );
      setViewDateRangeStart(getViewDateRangeStart());
      setViewDateRangeEnd(getViewDateRangeEnd());
    }
  }, [props.initialEventToDisplay, prevInitialEventToDisplay]);

  const prevCalendars = usePrevious(props.calendars);

  useUpdateEffect(() => {
    const doEffect = async () => {
      const prevHadPendingCalendars = prevCalendars?.some(
        (c) => c.syncStatus === ProviderCalendarSyncStatus.PENDING
      );
      const hasPendingCalendars = props.calendars?.some(
        (c) => c.syncStatus === ProviderCalendarSyncStatus.PENDING
      );

      if (prevHadPendingCalendars && !hasPendingCalendars) {
        getProviderEventsForCalendarCache.invalidate(
          expandedPatientBookedEventsQueryKeyArgs
        );
        getProviderEventsForCalendarCache.invalidate(
          expandedEventsWithinCalendarRangeQueryKeyArgs
        );
        prefetchPreviousAndNextPage();
      }
    };
    doEffect();
  }, [
    prevCalendars,
    props.calendars,
    getProviderEventsForCalendarCache.invalidate,
  ]);

  const prefetchPreviousAndNextPage = useCallback(() => {
    const prevPage = getCalendarRangeQueryKeyArgs(
      provider.id,
      moment(viewDateRangeStart)
        .subtract(daysPerView[view], 'days')
        .startOf('day')
        .toDate(),
      moment(viewDateRangeStart).subtract(1, 'second').endOf('day').toDate()
    );
    const nextPage = getCalendarRangeQueryKeyArgs(
      provider.id,
      moment(viewDateRangeEnd).add(1, 'second').startOf('day').toDate(),
      moment(viewDateRangeEnd)
        .add(daysPerView[view], 'days')
        .endOf('day')
        .toDate()
    );
    getProviderEventsForCalendarCache.prefetch(prevPage);
    getProviderEventsForCalendarCache.prefetch(nextPage);
  }, [
    provider.id,
    getProviderEventsForCalendarCache,
    viewDateRangeEnd,
    viewDateRangeStart,
    daysPerView,
    view,
  ]);

  useEffect(() => {
    prefetchPreviousAndNextPage();
  }, [prefetchPreviousAndNextPage]);

  const handleRangeChange = (range: { start: Date; end: Date } | Date[]) => {
    let start: Date, end: Date;

    if (Array.isArray(range)) {
      start = range[0];
      end =
        range.length === 1
          ? moment(start).add(2, 'days').toDate()
          : range[range.length - 1];
    } else {
      start = range.start;
      end = range.end;
    }

    setEventsDateRangeStart(
      moment(start).subtract(daysPerView[view], 'days').startOf('day').toDate()
    );
    setEventsDateRangeEnd(
      moment(end).add(daysPerView[view], 'days').endOf('day').toDate()
    );
    setViewDateRangeStart(start);
    setViewDateRangeEnd(moment(end).endOf('day').toDate());
  };

  const handleViewChange = (view: View) => {
    setView(view);
    trackEvent({
      name: 'Calendar view changed',
      properties: {
        view,
      },
    });
  };

  const hasCalendarAccess = () => {
    return (
      provider.earliestActiveLiveOn ||
      Object.keys(props.estimatedLiveDates).length
    );
  };

  const handleWorkingHoursAlertClose = () => {
    window.localStorage.setItem(
      'last_working_hours_alert_timestamp',
      moment().toISOString()
    );
  };

  if (!hasCalendarAccess()) {
    return (
      <PaddedPanelLayout>
        <EmptyView
          title="Calendar coming soon"
          description="Once you are live with Headway you will be able to add sessions here."
          action={
            <Button
              component={Link}
              to="/insurance-status"
              size="large"
              variant="contained"
              color="primary"
            >
              View credentialing status
            </Button>
          }
        />
      </PaddedPanelLayout>
    );
  }

  return (
    <PatientsProvider>
      <PatientsContext.Consumer>
        {(patientsContext: any) => (
          <SelectedEventContextProvider>
            <div>
              <FullHeightPanelLayout>
                {isInitialLoading ||
                !patientsContext.patients ||
                props.estimatedLiveDatesLoading ? (
                  <div
                    css={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <LogoLoader />
                  </div>
                ) : (
                  <>
                    <HWMAAlerts
                      lastClosed={localStorageWorkingHoursAlertTimestamp()}
                      onClose={handleWorkingHoursAlertClose}
                    />
                    <Calendar
                      calendars={props.calendars || []}
                      handleViewChange={handleViewChange}
                      handleRangeChange={handleRangeChange}
                      view={view}
                      eventsDateRangeStart={eventsDateRangeStart}
                      eventsDateRangeEnd={eventsDateRangeEnd}
                      viewDateRangeStart={viewDateRangeStart}
                      viewDateRangeEnd={viewDateRangeEnd}
                      mobileView={mobileView}
                      daysPerView={daysPerView}
                      expandedEvents={expandedEvents}
                      patientMessages={patientBookingMessagesByEventId}
                      scrollToTime={scrollToTime}
                      timezone={timeZone}
                      {...patientsContext}
                      providerFrontendCarriers={props.providerFrontendCarriers}
                      stateInsuranceCarriers={
                        props.stateInsuranceCarriers ?? []
                      }
                      estimatedLiveDates={props.estimatedLiveDates}
                      minEstimatedLiveDate={props.minEstimatedLiveDate}
                      maxEstimatedLiveDate={props.maxEstimatedLiveDate}
                      initialEventToDisplay={props.initialEventToDisplay}
                      initialIsConfirmDetailsOpen={
                        props.initialIsConfirmDetailsOpen
                      }
                      initialIsProgressNoteOnly={
                        props.initialIsProgressNoteOnly
                      }
                      initialIsAppointmentToIntakeCallOpen={
                        props.initialIsAppointmentToIntakeCallOpen
                      }
                      initialIsCancelOpen={props.initialIsCancelOpen}
                      initialIsRescheduling={props.initialIsRescheduling}
                      carriersById={props.carriersById}
                      freezeReasonsByUser={props.freezeReasonsByUser}
                    />
                  </>
                )}
              </FullHeightPanelLayout>
            </div>
          </SelectedEventContextProvider>
        )}
      </PatientsContext.Consumer>
    </PatientsProvider>
  );
};

interface CalendarViewProps {
  provider: ProviderRead;
}

export const CalendarView: React.FC<
  React.PropsWithChildren<CalendarViewProps>
> = ({ provider }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [initialEvent, setInitialEvent] = React.useState({});
  const [initialIsConfirmDetailsOpen, setInitialIsConfirmDetailsOpen] =
    React.useState<boolean>(false);
  const [initialIsProgressNoteOnly, setInitialIsProgressNoteOnly] =
    React.useState<boolean>(false);
  const [
    initialIsAppointmentToIntakeCallOpen,
    setInitialIsAppointmentToIntakeCallOpen,
  ] = React.useState<boolean>(false);
  const [initialIsCancelOpen, setInitialIsCancelOpen] =
    React.useState<boolean>(false);
  const [initialIsRescheduling, setInitialIsRescheduling] =
    React.useState<boolean>(false);

  const calendarsQuery = useProviderCalendarsQuery(provider.id, {
    enabled: true,
  });
  const queryClient = useQueryClient();

  const syncExternalCalendarMutation = useSyncExternalCalendarMutation();

  const syncsyncExternalCalendarMutationFn =
    syncExternalCalendarMutation.mutate;

  React.useEffect(() => {
    const anHourAgoDate = new Date();
    anHourAgoDate.setHours(anHourAgoDate.getHours() - 1);

    calendarsQuery.data?.forEach((calendar) => {
      if (calendar.syncStatus === ProviderCalendarSyncStatus.PENDING) {
        return;
      }

      // If the calendar is in a failure state, and it was last updated
      // more than an hour ago. We try again to get a sync.
      if (
        calendar.syncStatus === null ||
        moment(calendar.syncedAt).isBefore(
          moment(anHourAgoDate.toUTCString())
        ) ||
        (moment(calendar.updatedOn).isBefore(
          moment(anHourAgoDate.toUTCString())
        ) &&
          calendar.syncStatus === ProviderCalendarSyncStatus.FAILURE)
      ) {
        syncsyncExternalCalendarMutationFn({ calendarId: calendar.id });
      }
    });
  }, [
    calendarsQuery.data,
    provider.id,
    queryClient,
    syncsyncExternalCalendarMutationFn,
  ]);

  React.useEffect(() => {
    if (location.pathname?.indexOf('/calendar') === -1) {
      return;
    }

    if (location.search) {
      const params = queryString.parse(location?.search);
      const initialEventToDisplay = getInitialEventFromQueryParams(params);
      const {
        confirm_session,
        switch_to_intake_call,
        cancel,
        reschedule,
        progress_note_only,
        event_id,
        start_date,
        ...paramsToKeep
      } = params;

      if (initialEventToDisplay) {
        setInitialEvent({
          initialEventToDisplay,
        });
        setInitialIsConfirmDetailsOpen(
          getIsModalOpenOnLoadFromQueryParams(params, 'confirm_session')
        );
        setInitialIsAppointmentToIntakeCallOpen(
          getIsModalOpenOnLoadFromQueryParams(params, 'switch_to_intake_call')
        );
        setInitialIsCancelOpen(
          getIsModalOpenOnLoadFromQueryParams(params, 'cancel')
        );
        setInitialIsRescheduling(
          getIsModalOpenOnLoadFromQueryParams(params, 'reschedule')
        );
        setInitialIsProgressNoteOnly(
          getIsModalOpenOnLoadFromQueryParams(params, 'progress_note_only')
        );
      }
      navigate(`?${queryString.stringify(paramsToKeep)}`, { replace: true });
    }
  }, [navigate, location.search, location.pathname]);

  const providerStates = provider.activeProviderLicenseStates.map(
    (pls) => pls.state
  );
  const stateInsuranceCarriersQuery = useQuery(
    ['state-insurance-carriers', ...providerStates],
    () =>
      StateInsuranceCarrierApi.getStateInsuranceCarriers({
        states: providerStates,
      }),
    {
      staleTime: 1000 * 60 * 60, // 60 minutes
    }
  );

  const { carriersById } = useFrontEndCarriers();
  const { freezeReasonsByUser } = useProviderUserFreezes(provider.id);

  const uiStore = useUiStore();

  return (
    <LiveDateCalculator>
      {(liveDates) => (
        <CalendarViewWithContext
          calendars={calendarsQuery.data}
          stateInsuranceCarriers={stateInsuranceCarriersQuery.data}
          UiStore={uiStore}
          carriersById={carriersById}
          freezeReasonsByUser={freezeReasonsByUser}
          initialIsConfirmDetailsOpen={initialIsConfirmDetailsOpen}
          initialIsProgressNoteOnly={initialIsProgressNoteOnly}
          initialIsAppointmentToIntakeCallOpen={
            initialIsAppointmentToIntakeCallOpen
          }
          initialIsCancelOpen={initialIsCancelOpen}
          initialIsRescheduling={initialIsRescheduling}
          {...liveDates}
          {...initialEvent}
        />
      )}
    </LiveDateCalculator>
  );
};
