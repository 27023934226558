import { FieldArray, useField } from 'formik';
import { useMemo } from 'react';

import { ALL_ASSESSMENT_TYPES } from '@headway/shared/constants/patientAssessments';

import { MultiAssessmentScheduleInputAddButton } from './MultiAssessmentScheduleInputAddButton';
import { MultiAssessmentScheduleInputRow } from './MultiAssessmentScheduleInputRow';
import { MultiAssessmentScheduleValue } from './types';

type MultiAssessmentScheduleInputProps = {
  name: string;
  withPendingSchedules?: boolean;
};

export function MultiAssessmentScheduleInput({
  name,
  withPendingSchedules,
}: MultiAssessmentScheduleInputProps) {
  const [{ value: schedules }, { touched }] =
    useField<MultiAssessmentScheduleValue[]>(name);

  const availableAssessmentTypes = useMemo(
    () =>
      ALL_ASSESSMENT_TYPES.filter(
        (assessmentType) =>
          !schedules.find(
            (schedule) => assessmentType === schedule.assessmentType
          )
      ),
    [schedules]
  );

  return (
    <div className="flex flex-col gap-2">
      <FieldArray name={name}>
        {({ push }) => (
          <>
            {schedules.map((_, index) => {
              const rowName = `${name}.${index}`;

              return (
                <MultiAssessmentScheduleInputRow
                  key={rowName}
                  name={rowName}
                  availableAssessmentTypes={availableAssessmentTypes}
                  withPendingSchedules={withPendingSchedules}
                />
              );
            })}

            {availableAssessmentTypes.length > 0 && touched && (
              <MultiAssessmentScheduleInputAddButton
                addToForm={push}
                withPendingSchedules={withPendingSchedules}
              />
            )}
          </>
        )}
      </FieldArray>
    </div>
  );
}
