import { ProviderTreatmentPlanApi } from '@headway/api/resources/ProviderTreatmentPlanApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface useProviderTreatmentPlansQueryKeyArgs {
  providerPatientId: number | undefined;
}

export const getUseProviderTreatmentPlansQueryKey = ({
  providerPatientId,
}: useProviderTreatmentPlansQueryKeyArgs): [string, number | undefined] => [
  'find-provider-treatment-plans',
  providerPatientId,
];

const {
  singleQuery: providerTreatmentPlans,
  useSingleQuery: useProviderTreatmentPlans,
  useListQuery: useProviderTreatmentPlansList,
  useCachedQuery: useProviderTreatmentPlansCache,
} = createBasicApiHooks(
  getUseProviderTreatmentPlansQueryKey,
  ({ providerPatientId }) =>
    ProviderTreatmentPlanApi.findProviderTreatmentPlans({
      provider_patient_id: providerPatientId,
    }),
  ({ providerPatientId }) => !!providerPatientId,
  () => 'Failed to fetch provider treatment plans for provider patient'
);

export {
  providerTreatmentPlans,
  useProviderTreatmentPlans,
  useProviderTreatmentPlansList,
  useProviderTreatmentPlansCache,
};
