import { Formik } from 'formik';
import { useProvider } from 'hooks';
import { keyBy } from 'lodash';
import React, { useContext, useMemo } from 'react';
import * as Yup from 'yup';
import { getAssessmentScheduleValidationSchema } from '~/legacy/components/AssessmentScheduler/utils';

import { BillingType } from '@headway/api/models/BillingType';
import { PatientAssessmentRecurrenceCadence } from '@headway/api/models/PatientAssessmentRecurrenceCadence';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { PatientFormSchemaRead } from '@headway/api/models/PatientFormSchemaRead';
import { PatientFormSchemaType } from '@headway/api/models/PatientFormSchemaType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UserRead } from '@headway/api/models/UserRead';
import { PatientFormApi } from '@headway/api/resources/PatientFormApi';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { ContentText } from '@headway/helix/ContentText';
import { FormControl } from '@headway/helix/FormControl';
import { IconCheckCircle } from '@headway/helix/icons/CheckCircle';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { PageSection } from '@headway/helix/Page';
import { theme } from '@headway/helix/theme';
import { toasts } from '@headway/helix/Toast';
import { PROMS_UPSELLS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useMutation, useQuery } from '@headway/shared/react-query';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { logException } from '@headway/shared/utils/sentry';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { LogoLoader } from '@headway/ui/LogoLoader';

import { useGetIsPromsScheduleRequired } from 'hooks/useGetIsPromsScheduleRequired';
import { usePatientFormSchemas } from 'hooks/usePatientFormSchema';
import { useProviderHasSentAssessments } from 'hooks/useProviderHasSentAssessments';

import { AssessmentScheduleStartDateType } from '../../Clients/Assessments/components/AssessmentScheduleInput/constants';
import {
  createMultiAssessmentScheduleInitialValues,
  getAssessmentScheduleConfigFromValue,
  MultiAssessmentScheduleValue,
  shouldSubmitMultiAssessmentScheduleValue,
} from '../../Clients/Assessments/components/MultiAssessmentScheduleInput';
import { MaybePendingAssessmentScheduleConfig } from '../../Clients/Assessments/helpers/types';
import { useAllowPendingSchedules } from '../../Clients/Assessments/helpers/useAllowPendingSchedules';
import { formatFirstName } from '../utils/addPatientModalUtils';
import {
  AddPatientModalContext,
  AddPatientModalPage,
} from './AddPatientModalContext';
import { InviteClientPromsUpsell } from './InviteClientPromsUpsell';

type ClientIntakeFormValues = {
  [key: string]: boolean;
};

export type InviteClientSetUpFormValues = {
  headwayInviteEmail: boolean;
  sendAll: boolean;
  assessmentSchedules: MultiAssessmentScheduleValue[];
  clientIntakeForms: ClientIntakeFormValues;
};

export interface InviteClientSetupProps {
  client: UserRead;
  providerPatient: ProviderPatientRead;
  setInitialAssessmentSchedules: (
    schedules: MaybePendingAssessmentScheduleConfig[]
  ) => void;
}
const inviteClientSetupValidationSchema = (isPromsScheduleRequired?: boolean) =>
  Yup.object({
    assessmentSchedules: Yup.array()
      .of(
        Yup.lazy((schedule) =>
          ((schedule as MultiAssessmentScheduleValue).selected
            ? getAssessmentScheduleValidationSchema()
            : Yup.object()
          ).required()
        )
      )
      .test(
        'at-least-one-assessment-selected',
        'You must send at least one assessment',
        (value) => {
          if (!isPromsScheduleRequired) return true;
          const atLeastOneAssessmentSelected = value?.some(
            (assessment) => assessment.selected
          );
          return Array.isArray(value) && !!atLeastOneAssessmentSelected;
        }
      )
      .test(
        'at-least-one-recurring',
        'You must send at least one recurring assessment',
        (value) => {
          if (!isPromsScheduleRequired) return true;
          const anySelectedAssessmentRecurring = value?.some((item) => {
            return (
              item.selected &&
              item.cadence !== PatientAssessmentRecurrenceCadence.ONE_TIME
            );
          });

          return Array.isArray(value) && !!anySelectedAssessmentRecurring;
        }
      )
      // Some older versions of Yup fail on `.required()` validation when the
      // array is defined but empty, but we want to allow empty in this case, so
      // we use a custom test instead of `.required()`.
      // This is fixed in Yup v0.31.0.
      .test('required-allow-empty', 'Required', (value) => {
        return Array.isArray(value);
      }),
  });

export const InviteClientSetup = ({
  client,
  providerPatient,
  setInitialAssessmentSchedules,
}: InviteClientSetupProps) => {
  const provider = useProvider();

  const isPromsUpsellEnabled: boolean = useFlag(PROMS_UPSELLS, false);
  const { data: isPromsScheduleRequiredDetails } =
    useGetIsPromsScheduleRequired({
      providerPatientId: providerPatient.id,
    });

  const {
    pendingSchedulesAllowed,
    isLoading: isPendingSchedulesAllowedLoading,
  } = useAllowPendingSchedules(providerPatient);

  React.useEffect(() => {
    trackPageView({
      name: `Autopay Billing Model Enabled Banner Viewed`,
      properties: {
        providerId: provider.id,
        patientUserId: client.id,
        renderingLocation: 'Provider add client flow',
      },
    });
  }, [client.id, provider.id]);
  const { setCurrentStep } = useContext(AddPatientModalContext);

  React.useEffect(() => {
    trackEvent({
      name: 'Add Patient Step Viewed',
      properties: {
        screenName: 'Invite client to setup their Headway account',
        stepName: 'SEND_ACCOUNT_INVITE',
      },
    });
  }, []);

  const [didOpenAddPracticePolicyLink, setDidOpenAddPracticePolicyLink] =
    React.useState(false);
  const { data: patientFormSchemas, isLoading: isPatientFormSchemasLoading } =
    usePatientFormSchemas(
      { providerId: provider.id },
      {
        enabled: true,
        // if the user clicks the "Add your practice policies to Headway" button, we want to refetch the patient form schemas
        // at an interval of 5 seconds to check if the practice policies have been added.
        // We also refetch on window focus to check if the practice policies have been added some other way.
        refetchOnWindowFocus: true,
        refetchInterval: (data) => {
          const policy = data?.find(
            (schema) =>
              schema.formType === PatientFormSchemaType.PRACTICE_POLICIES
          );

          if (policy) {
            return false;
          }

          if (didOpenAddPracticePolicyLink) {
            return 5000;
          }

          return false;
        },
      }
    );

  const { data: providerHasSentAssessments } = useProviderHasSentAssessments(
    { providerId: provider.id },
    {
      refetchOnWindowFocus: false,
    }
  );

  const formSchemasByType = keyBy(patientFormSchemas, 'formType');

  const doesProviderHavePracticePolicy =
    !!formSchemasByType[PatientFormSchemaType.PRACTICE_POLICIES];

  const appointmentReadinessQuery = useQuery(
    ['appointment-readiness', client.id],
    () => UserApi.getUserAppointmentReadiness(client.id),
    {
      retry: false,
    }
  );

  const sendInvitationMutation = useMutation(
    async (sendIntakeForms: boolean) => {
      await ProviderPatientApi.sendProviderPatientAccountInvite(
        providerPatient.id,
        { sendIntakeForms: sendIntakeForms }
      );
    },
    {
      onSuccess: async () => {
        await UserApi.updateUser(client.id, { isInvited: true });
      },
    }
  );

  const getSelectedForms = (clientIntakeFormValues: ClientIntakeFormValues) => {
    const selectedPatientFormSchemas: PatientFormSchemaRead[] = [];
    const patientFormSchemaIds: number[] = [];
    for (const intakeForm in clientIntakeFormValues) {
      if (clientIntakeFormValues[intakeForm]) {
        const form = patientFormSchemas?.find(
          (schema: PatientFormSchemaRead) => {
            return schema.formType === intakeForm;
          }
        );

        if (form) {
          selectedPatientFormSchemas.push(form);
          patientFormSchemaIds.push(form.id);
        }
      }
    }

    return {
      selectedPatientFormSchemas,
      patientFormSchemaIds,
    };
  };

  // if provider has not used PROMS product, render a product announcement modal
  // else take provider directly to send assessments modal
  const setNextStepOfAddPatient = () => {
    return providerHasSentAssessments
      ? setCurrentStep(AddPatientModalPage.SEND_ASSESSMENTS)
      : setCurrentStep(AddPatientModalPage.ASSESSMENTS_PRODUCT_ANNOUNCEMENT);
  };

  const firstName = formatFirstName(client);

  const handleInviteClientIntakeForms = async (
    values: InviteClientSetUpFormValues
  ) => {
    setInitialAssessmentSchedules(
      values.assessmentSchedules
        .filter(shouldSubmitMultiAssessmentScheduleValue)
        .map(getAssessmentScheduleConfigFromValue)
    );

    const isAppointmentReady = appointmentReadinessQuery.data?.isReady;

    const message = !isAppointmentReady
      ? `${firstName} has been invited to set up their account`
      : `${firstName} has been added as a client`;

    const { selectedPatientFormSchemas, patientFormSchemaIds } =
      getSelectedForms(values.clientIntakeForms);

    const hasInformedConsentChecked = !!selectedPatientFormSchemas.find(
      (form) => {
        return form?.formType === PatientFormSchemaType.INFORMED_CONSENT;
      }
    );
    const hasBiopsychosocialChecked = !!selectedPatientFormSchemas.find(
      (form) => {
        return form?.formType === PatientFormSchemaType.BIOPSYCHOSOCIAL;
      }
    );

    const hasPracticePolicyChecked = !!selectedPatientFormSchemas.find(
      (form) => {
        return form?.formType === PatientFormSchemaType.PRACTICE_POLICIES;
      }
    );

    const sendIntake =
      values.sendAll ||
      hasInformedConsentChecked ||
      hasBiopsychosocialChecked ||
      hasPracticePolicyChecked;

    const sendOnlyInvitation = values.headwayInviteEmail && !sendIntake;
    const sendOnlyIntake = !values.headwayInviteEmail && sendIntake;
    const sendInvitationAndIntake = values.headwayInviteEmail && sendIntake;

    // create intake forms
    if (patientFormSchemaIds.length > 0) {
      await PatientFormApi.createPatientFormsBulk({
        providerPatientId: providerPatient.id,
        patientFormSchemaIds: patientFormSchemaIds,
      });
    }

    if (sendOnlyInvitation || sendInvitationAndIntake) {
      try {
        await sendInvitationMutation.mutateAsync(!!sendInvitationAndIntake);
        toasts.add(message);
      } catch (e) {
        toasts.add(`Failed to send invitation to ${firstName}`, {
          variant: 'negative',
        });
        logException(e);
      }
    } else if (sendOnlyIntake) {
      try {
        await PatientFormApi.sendIntakeFormEmailToPatient(providerPatient.id);
        toasts.add(`Intake tasks sent to ${firstName}`);
      } catch (e) {
        toasts.add(`Failed to send intake tasks to ${firstName}`, {
          variant: 'negative',
        });
        logException(e);
      }
    } else {
      const message = !isAppointmentReady
        ? `${firstName} has been added as a client. Some additional setup tasks are required.`
        : `${firstName} has been added as a client`;
      toasts.add(message);
    }

    trackEvent({
      name: 'Individual New Client Invite Email Done Button Clicked',
      properties: {
        sendEmailFlag: values.headwayInviteEmail === true,
      },
    });
    trackEvent({
      name: 'Send Intake Form Button Clicked',
      properties: {
        providerId: provider.id,
        providerPatientId: providerPatient.id,
      },
    });
    setNextStepOfAddPatient();
  };

  const onInviteClientSetupSkip = () => setNextStepOfAddPatient();

  const intakeFormInitialValues = useMemo((): ClientIntakeFormValues => {
    if (isPatientFormSchemasLoading) {
      return {};
    }

    const values = (patientFormSchemas ?? []).reduce(
      (accum: any, schema: PatientFormSchemaRead) => {
        accum[schema.formType] = false;
        return accum;
      },
      {} as ClientIntakeFormValues
    );

    values[PatientFormSchemaType.PRACTICE_POLICIES] =
      values[PatientFormSchemaType.PRACTICE_POLICIES] ?? false;

    return values;
  }, [isPatientFormSchemasLoading, patientFormSchemas]);

  const initialValues = useMemo((): InviteClientSetUpFormValues => {
    const startDateType = pendingSchedulesAllowed
      ? AssessmentScheduleStartDateType.BeforeFirstSession
      : AssessmentScheduleStartDateType.ExactDate;

    return {
      headwayInviteEmail: true,
      sendAll: false,
      clientIntakeForms: intakeFormInitialValues,
      assessmentSchedules: createMultiAssessmentScheduleInitialValues([
        { assessmentType: PatientAssessmentType.PHQ9, startDateType },
        { assessmentType: PatientAssessmentType.GAD7, startDateType },
      ]),
    };
  }, [intakeFormInitialValues, pendingSchedulesAllowed]);

  return (
    <Formik
      enableReinitialize
      validateOnMount
      onSubmit={handleInviteClientIntakeForms}
      initialValues={initialValues}
      validationSchema={inviteClientSetupValidationSchema(
        isPromsScheduleRequiredDetails?.isPromsScheduleRequired
      )}
    >
      {({
        isValid,
        isSubmitting,
        values,
        submitCount,
        setFieldValue,
        submitForm,
      }) => {
        const checkAllIntakeForm = (toggleValue: boolean) => {
          Object.keys(values.clientIntakeForms).forEach((key) => {
            setFieldValue(`clientIntakeForms.${key}`, toggleValue);
          });
        };

        const needsPrivacyPolicyAdded =
          !doesProviderHavePracticePolicy &&
          !!values.clientIntakeForms[PatientFormSchemaType.PRACTICE_POLICIES];

        return isPatientFormSchemasLoading ||
          isPendingSchedulesAllowedLoading ? (
          <div className="mt-8 flex flex-col items-center gap-8">
            <ContentText variant="section-title/medium">Saving...</ContentText>
            <div>
              <LogoLoader />
            </div>
          </div>
        ) : (
          <>
            <ModalContent>
              <SafeFormikForm>
                <PageSection>
                  <div className="flex flex-col gap-6">
                    {providerPatient.billingTypeDefault ===
                      BillingType.INSURANCE &&
                      client.activeUserInsurance?.latestEligibilityLookup
                        ?.isClaimReady &&
                      firstName && (
                        <div className="flex gap-1">
                          <IconCheckCircle color={theme.color.system.green} />
                          <strong>{`${firstName}'s insurance has been verified.`}</strong>
                        </div>
                      )}

                    <ContentText variant="section-title/medium">
                      Invite {firstName} to set up their Headway account
                    </ContentText>
                    <div className="flex flex-col gap-2">
                      <div>
                        <div className="flex gap-2">
                          <ContentText variant="body-large/medium">
                            Send {firstName} an invitation email
                          </ContentText>
                          <Badge variant="positive">Recommended</Badge>
                        </div>
                        <ContentText>
                          We'll email your client with an invitation to set up
                          their Headway account and complete any outstanding
                          tasks, and reach out with reminders until they're
                          complete.
                        </ContentText>
                      </div>
                      <FormControl
                        component={Checkbox}
                        disabled={false}
                        name="headwayInviteEmail"
                        aria-labelledby="invitation-email-opt-in"
                      >
                        <span>
                          <span id="invitation-email-opt-in">
                            <strong>Headway invitation email</strong>
                          </span>{' '}
                          <Link
                            href="https://help.headway.co/hc/en-us/articles/16763469418260-Explaining-Headway-to-your-clients"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-describedby="invitation-email-opt-in"
                          >
                            Learn more
                          </Link>
                        </span>
                      </FormControl>
                    </div>

                    {isPromsUpsellEnabled && (
                      <InviteClientPromsUpsell
                        providerId={provider.id}
                        isPromsScheduleRequiredDetails={
                          isPromsScheduleRequiredDetails
                        }
                        withPendingSchedules={pendingSchedulesAllowed}
                      />
                    )}

                    {patientFormSchemas && (
                      <div className="flex flex-col gap-2">
                        <div>
                          <div className="flex gap-2">
                            <ContentText variant="body-large/medium">
                              Send {firstName} intake forms
                            </ContentText>
                            <Badge variant="neutral">Optional</Badge>
                          </div>
                          <ContentText>
                            You may also send some common intake forms used by
                            most providers.
                          </ContentText>
                        </div>
                        <div className="flex flex-col gap-2 [&>:not(:first-child)]:pl-7">
                          <FormControl
                            component={Checkbox}
                            disabled={false}
                            name="sendAll"
                            onChange={checkAllIntakeForm}
                            checked={Object.values(
                              values.clientIntakeForms
                            ).every((isSelected) => isSelected)}
                          >
                            Send all intake forms below
                          </FormControl>

                          {patientFormSchemas.map((form) => {
                            return (
                              <FormControl
                                component={Checkbox}
                                name={`clientIntakeForms.${form.formType}`}
                                key={form.id}
                              >
                                <span>
                                  {form.name}{' '}
                                  <Link
                                    href={`/intake-forms/${form.id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Preview
                                  </Link>
                                </span>
                              </FormControl>
                            );
                          })}

                          {!doesProviderHavePracticePolicy && (
                            <FormControl
                              component={Checkbox}
                              name={`clientIntakeForms.${PatientFormSchemaType.PRACTICE_POLICIES}`}
                            >
                              Practice policies (Add your practice policies in
                              the next step)
                            </FormControl>
                          )}
                        </div>
                      </div>
                    )}

                    {needsPrivacyPolicyAdded && (
                      <>
                        <div className="flex flex-col">
                          <ContentText variant="body-large/medium">
                            Last step: Add your practice policies to Headway
                          </ContentText>
                          <ContentText>
                            Once you added your practice policies, return to
                            this page to continue to the next step.
                          </ContentText>
                        </div>
                        <div>
                          <LinkButton
                            href="/settings/practice-policies"
                            target="_blank"
                            variant="primary"
                            onClick={() => {
                              setDidOpenAddPracticePolicyLink(true);
                              trackEvent({
                                name: 'Add Practice Policy Button Clicked',
                                properties: {
                                  providerId: provider.id,
                                },
                              });
                            }}
                          >
                            Add policies to Headway
                          </LinkButton>
                        </div>
                      </>
                    )}
                  </div>
                </PageSection>
              </SafeFormikForm>
            </ModalContent>
            <ModalFooter>
              <Button variant="secondary" onPress={onInviteClientSetupSkip}>
                Skip
              </Button>
              <Button
                variant="primary"
                disabled={
                  needsPrivacyPolicyAdded ||
                  isSubmitting ||
                  (submitCount > 0 && !isValid) ||
                  !isValid
                }
                onPress={submitForm}
              >
                {isSubmitting ? 'Sending...' : 'Continue'}
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};
