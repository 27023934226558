import { useFormikContext } from 'formik';

import { IsPromsScheduleRequiredResponse } from '@headway/api/models/IsPromsScheduleRequiredResponse';
import { Badge } from '@headway/helix/Badge';
import { ContentText } from '@headway/helix/ContentText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';

import { useProviderIncentiveProgramEnrollment } from 'hooks/useGetIsEnrolledProviderIncentiveProgram';

import { MultiAssessmentScheduleInput } from '../../Clients/Assessments/components/MultiAssessmentScheduleInput';
import { INCENTIVE_BONUS_AMOUNT_DOLLARS } from '../../Incentives/constants';
import { InviteClientSetUpFormValues } from './InviteClientSetup';

type InviteClientPromsUpsellProps = {
  providerId: number;
  isPromsScheduleRequiredDetails?: IsPromsScheduleRequiredResponse;
  withPendingSchedules?: boolean;
};

export function InviteClientPromsUpsell({
  providerId,
  isPromsScheduleRequiredDetails,
  withPendingSchedules,
}: InviteClientPromsUpsellProps) {
  const { errors } = useFormikContext<InviteClientSetUpFormValues>();
  const { data: incentiveEnrollment, isLoading } =
    useProviderIncentiveProgramEnrollment({ providerId });

  if (isLoading) {
    return null;
  }

  // Formik makes this error an array if there are nested errors
  const errorsRelatedToIndividualAssessments = Array.isArray(
    errors.assessmentSchedules
  );

  const errorsRelatedToSelectingRecurringSchedules =
    !errorsRelatedToIndividualAssessments &&
    typeof errors.assessmentSchedules === 'string';

  const isPromsScheduleRequiredWithCarrierName =
    isPromsScheduleRequiredDetails &&
    isPromsScheduleRequiredDetails.isPromsScheduleRequired &&
    isPromsScheduleRequiredDetails.carrierName;

  return (
    <div className="flex flex-col gap-2">
      <div>
        <div className="flex gap-2">
          <ContentText variant="body-large/medium">
            Send assessments
          </ContentText>
          {isPromsScheduleRequiredWithCarrierName ? (
            <Badge variant="info">Required</Badge>
          ) : incentiveEnrollment?.isProviderEnrolled ? (
            <Badge variant="info">Rate boost qualified</Badge>
          ) : (
            <Badge variant="positive">Recommended</Badge>
          )}
        </div>
        <ContentText>
          {isPromsScheduleRequiredWithCarrierName ? (
            `New clients with ${isPromsScheduleRequiredDetails.carrierName} (age 18+) must receive at least one initial and follow-up assessment. Choose the assessment and cadence you'd like to use below. You can also change or customize assessments at any time from the Assessments tab.`
          ) : incentiveEnrollment?.isProviderEnrolled ? (
            <>
              Earn ${INCENTIVE_BONUS_AMOUNT_DOLLARS} extra for{' '}
              <strong>each and every session</strong> when your client completes
              assessments.
            </>
          ) : (
            'Assessments are clinical questionnaires that help both you and your client track progress over time.'
          )}{' '}
          {!isPromsScheduleRequiredWithCarrierName && (
            <>
              {' '}
              You can change or customize assessments at any time from the
              Assessments tab.{' '}
            </>
          )}
          {isPromsScheduleRequiredWithCarrierName && (
            <LinkButton
              variant="link"
              target="_blank"
              href="https://help.headway.co/hc/en-us/articles/31110875396628"
            >
              <ContentText color="foreground/secondary">Learn more</ContentText>
            </LinkButton>
          )}
        </ContentText>
        {errorsRelatedToSelectingRecurringSchedules && (
          <div className="mb-5 mt-5">
            <GuidanceCard variant="error">
              {errors.assessmentSchedules as string}
            </GuidanceCard>
          </div>
        )}
      </div>
      <MultiAssessmentScheduleInput
        name="assessmentSchedules"
        withPendingSchedules={withPendingSchedules}
      />
    </div>
  );
}
