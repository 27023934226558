import React from 'react';
import { useProviderEvent } from '~/legacy/hooks/useProviderEvent';

import { BodyText } from '@headway/helix/BodyText';
import { theme } from '@headway/helix/theme';
import { EXTERNAL_PLATFORM_NEAR_TERM_BOOKINGS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { usePatientAppointmentReadinessCutoffDate } from '@headway/shared/hooks/usePatientAppointmentReadinessCutoffDate';
import { useUser } from '@headway/shared/hooks/useUser';

import { HowReferralAppointmentsWorkVisual } from './HowReferralAppointmentsWorkVisual';

export interface PendingReferralDetailProps {
  clientId: number;
  eventId: number | string;
}

export const PendingReferralDetail = ({
  clientId,
  eventId,
}: PendingReferralDetailProps) => {
  const { data: client } = useUser({
    userId: clientId,
  });
  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventId,
  });
  const { cancellationDate, isLoading: isCancellationDateLoading } =
    usePatientAppointmentReadinessCutoffDate(eventId);

  const isNearTermBookingsFlagEnabled = useFlag(
    EXTERNAL_PLATFORM_NEAR_TERM_BOOKINGS,
    false
  );

  if (!event || !client) {
    return null;
  }

  let formattedAutoCancellationDate;
  if (event && cancellationDate) {
    const cancellationDateObj = new Date(cancellationDate);
    const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    formattedAutoCancellationDate = cancellationDateObj.toLocaleString(
      'en-US',
      {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        weekday: 'long',
        minute: 'numeric',
        hour12: true,
        timeZone: systemTimezone,
      }
    );
  }

  const clientFirstName = client?.firstName;

  return !isCancellationDateLoading ? (
    <>
      <div css={{ marginTop: theme.spacing.x3 }}>
        <p>
          <BodyText>
            We've already sent {clientFirstName} email and text reminders to add
            a payment method and review the consent forms before your{' '}
            {isNearTermBookingsFlagEnabled
              ? 'cancellation policy takes effect'
              : 'advance booking window'}
            .
          </BodyText>
        </p>
        <p>
          <BodyText>
            If this still isn't complete by{' '}
            <strong>{formattedAutoCancellationDate}</strong>, we'll remove this
            hold from your calendar and archive {clientFirstName} in your Client
            List. We'll invite {clientFirstName} to rebook with you.
          </BodyText>
        </p>
      </div>

      <HowReferralAppointmentsWorkVisual clientId={clientId} />
    </>
  ) : null;
};
