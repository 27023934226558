import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';

import { SchemaComponent } from '.';
import { FormControlRHF } from '../../../../FormControlRHF';
import { getOptionalityText } from '../utils';
import { OtherTextField } from './OtherTextField';

const MemoizedRadioGroup = React.memo(RadioGroup);
const MemoizedRadio = React.memo(Radio);

interface OtherRadioWithTextFieldProps {
  disabled: boolean;
  option: string;
  id: string;
  key: number;
}

const OtherRadioWithTextField = ({
  disabled,
  option,
  id,
  key,
}: OtherRadioWithTextFieldProps) => {
  const { control, setValue } = useFormContext();
  const value = useWatch({
    control,
    name: id,
  });

  const [otherText, setOtherText] = useState('');

  const isOtherSelected =
    option === 'Other' && value !== undefined && value.includes('Other'); //not sure about this

  const otherPrefix = 'Other-';
  const createOtherValue = (value: string) => `${otherPrefix}${value}`;

  const onOtherTextChange = (newOtherText: string) => {
    if (!value) {
      return;
    }

    setValue(id, newOtherText);
    const separatedByDash = newOtherText.split(/-(.*)/);
    if (separatedByDash && separatedByDash.length > 1) {
      setOtherText(separatedByDash[1]);
    } else setOtherText('');
  };

  useEffect(() => {
    if (isOtherSelected) {
      const separatedByDash = value.split(/-(.*)/);
      if (separatedByDash && separatedByDash.length > 1) {
        setOtherText(separatedByDash[1]); //dont include 'Other-'
      } else {
        onOtherTextChange(createOtherValue(''));
      }
    }

    if (!isOtherSelected && !!value) {
      setValue(id, value);
      setOtherText('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOtherSelected]);

  return (
    <div css={{ display: 'flex', gap: theme.spacing.x2, alignItems: 'center' }}>
      <MemoizedRadio
        key={`radio-other-${key}`}
        value={createOtherValue(otherText)}
        disabled={disabled}
      >
        {option}
      </MemoizedRadio>
      {isOtherSelected && (
        <TextField
          disabled={disabled}
          name="Other"
          value={otherText}
          onChange={(value) => {
            onOtherTextChange(createOtherValue(value));
          }}
        />
      )}
    </div>
  );
};

export const FormRadio = ({
  element,
  isOptional = false,
  template,
  disabled = false,
}: SchemaComponent) => {
  const { id, title, options, optionalityText } = element;

  const optionality = useMemo(
    () => getOptionalityText(isOptional, optionalityText),
    [isOptional, optionalityText]
  );

  return (
    <FormControlRHF
      name={id}
      disabled={disabled}
      component={MemoizedRadioGroup}
      label={title}
      key={id}
      optionalityText={optionality}
    >
      {options.map((option: string, i: number) => {
        return option.includes('Other') ? (
          <OtherRadioWithTextField
            key={i}
            option={option}
            disabled={disabled}
            id={id}
          />
        ) : (
          <MemoizedRadio key={i} value={option} disabled={disabled}>
            {option}
          </MemoizedRadio>
        );
      })}
    </FormControlRHF>
  );
};
