import moment from 'moment';
import { convertTimeFieldsToDateString } from '~/legacy/utils/date';
import { NullableKeys } from '~/legacy/utils/types';
import { SessionDetailsFormV2ValuesWithOptionalDate } from '~/legacy/views/Practice/utils/types';

import { BillingType } from '@headway/api/models/BillingType';
import { ProviderAppointmentUpdate } from '@headway/api/models/ProviderAppointmentUpdate';
import { ProviderEventUpdate } from '@headway/api/models/ProviderEventUpdate';
import {
  CPTCodeInfo,
  PRESCRIBER_ADD_ON_PSYCHOTHERAPY_CODES,
} from '@headway/shared/constants/cptCodes';
import { CptCodeOption } from '@headway/ui/CPTCodeInput';
import { DiagnosisCodeOption } from '@headway/ui/DiagnosisCodeInput';

import { SessionDetailsFormV2Values } from './SessionDetailsFormV2';

export const isBillingAddOnPsychotherapy = (cptCodes: string[]) =>
  cptCodes.some((code) => PRESCRIBER_ADD_ON_PSYCHOTHERAPY_CODES.includes(code));

export const getMatchingCodeOptionsInOrder = (
  codes: string[],
  options: DiagnosisCodeOption[] | CptCodeOption[]
) => {
  return codes.reduce((acc: DiagnosisCodeOption[] | CptCodeOption[], code) => {
    const matchingOption = options.find(
      (o: DiagnosisCodeOption | CptCodeOption) => o.value === code
    );
    if (matchingOption) {
      acc.push(matchingOption);
    }
    return acc;
  }, []);
};

export const isStringArray = (value: any) => {
  return (
    Array.isArray(value) && value.every((item) => typeof item === 'string')
  );
};

export const convertFormValuesToProviderEventUpdate = (
  rawValues:
    | SessionDetailsFormV2Values
    | SessionDetailsFormV2ValuesWithOptionalDate
) => {
  return {
    ...(rawValues.startDate && rawValues.duration
      ? {
          startDate: new Date(rawValues.startDate).toISOString(),
          endDate: moment(rawValues.startDate)
            .add(rawValues.duration, 'minutes')
            .toISOString(),
        }
      : {}),
    ...(rawValues.providerAddressId && rawValues.providerAddressId === -1
      ? {
          providerAddressId: null,
          telehealth: true,
        }
      : {
          providerAddressId: rawValues.providerAddressId
            ? rawValues.providerAddressId
            : null,
          telehealth: false,
        }),
    providerAppointment: {
      billingType: rawValues.billingType,
      patientResponsibilityAmount:
        rawValues.billingType === BillingType.SELF_PAY
          ? rawValues.patientResponsibilityAmount
          : undefined,
      cptCodes: rawValues?.cptCodes?.map((code: CPTCodeInfo) => code?.value),
      diagnosisCodes: rawValues?.diagnosisCodes?.map(
        (code: DiagnosisCodeOption) => code?.value
      ),
      exactStartTime:
        rawValues.exactStartTime && rawValues.startDate
          ? convertTimeFieldsToDateString(
              new Date(rawValues.startDate),
              rawValues.exactStartTime
            )
          : undefined,
      exactEndTime:
        rawValues.exactEndTime && rawValues.startDate
          ? convertTimeFieldsToDateString(
              new Date(rawValues.startDate),
              rawValues.exactEndTime
            )
          : undefined,
      // convert the form representation of address and telehealth
      ...(rawValues.providerAddressId && rawValues.providerAddressId === -1
        ? {
            providerAddressId: null,
            telehealth: true,
            appointmentLocationPatientAddressId:
              rawValues.appointmentLocationPatientAddressId,
            telehealthPlatform: rawValues.telehealthPlatform,
            telehealthProviderState: rawValues.telehealthProviderState,
            telehealthAttestation: rawValues.telehealthAttestation,
          }
        : {
            providerAddressId: rawValues.providerAddressId
              ? rawValues.providerAddressId
              : null,
            telehealth: false,
            appointmentLocationPatientAddressId: null,
            telehealthPlatform: null,
            telehealthProviderState: null,
            telehealthAttestation: null,
          }),
      ...(rawValues.didPrescribeControlledSubstance
        ? {
            controlledSubstanceAttestation:
              rawValues.controlledSubstanceAttestation,
          }
        : rawValues.didPrescribeControlledSubstance === false
        ? {
            controlledSubstanceAttestation: {
              prescribedSubstances: [],
              notes: null,
              exceedsRecommendedDosage: null,
            },
          }
        : {}),
      prescriberPsychotherapyStartTime:
        !rawValues.prescriberPsychotherapyStartTime || !rawValues.startDate
          ? undefined
          : isBillingAddOnPsychotherapy(
              rawValues.cptCodes?.map((code) => code.value) || []
            )
          ? convertTimeFieldsToDateString(
              new Date(rawValues.startDate),
              rawValues.prescriberPsychotherapyStartTime
            )
          : null,
      prescriberPsychotherapyEndTime:
        !rawValues.prescriberPsychotherapyEndTime || !rawValues.startDate
          ? undefined
          : isBillingAddOnPsychotherapy(
              rawValues.cptCodes?.map((code) => code.value) || []
            )
          ? convertTimeFieldsToDateString(
              new Date(rawValues.startDate),
              rawValues.prescriberPsychotherapyEndTime
            )
          : null,
    } as NullableKeys<ProviderAppointmentUpdate> as ProviderAppointmentUpdate,
  } as NullableKeys<ProviderEventUpdate> as ProviderEventUpdate;
};

export const commonTimes = [15, 30, 45, 60];

export const getAllTimes = () => {
  const commonTimes_ = [15, 30, 45, 60];
  const excludedTimes = new Set(commonTimes_);
  return Array.from({ length: 24 }, (_, idx) => (idx + 1) * 5).filter(
    (time) => !excludedTimes.has(time)
  );
};

export const getFiveMinuteIntervals = (eventDatePart: any) => {
  const baseDate = moment(eventDatePart).startOf('day');
  return Array.from({ length: 288 }, (_, index) =>
    baseDate
      .clone()
      .add(index * 5, 'minutes')
      .toDate()
  );
};

export const isSessionDetailsFormValueTelehealth = (
  providerAddressId?: number | null
) => providerAddressId === -1;

export const isFormValueTelehealthAndBilledWithInsuranceOrEAP = (
  providerAddressId?: number | null,
  billingType?: string
) =>
  isSessionDetailsFormValueTelehealth(providerAddressId) &&
  (billingType === BillingType.INSURANCE || billingType === BillingType.EAP);
