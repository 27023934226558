import * as Yup from 'yup';

import { License } from '@headway/api/models/License';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { hasPhysicianLicenseType } from '@headway/shared/utils/licenseHelper';

import { BscaSpecialties, SectionStepForBscaSpecialty } from './constants';
import { getAllSpecialties } from './helpers';

const getSchemaBasedOnSiblingSpecialties = (
  specialtyKey: string,
  values: ProviderQuestionnaireRawData
) => {
  const allSpecialties = getAllSpecialties(values);
  return allSpecialties.includes(specialtyKey)
    ? Yup.string().required('This question is required.')
    : Yup.string();
};

export const getBcsaModalitiesSchema = (isPhysician: boolean) => {
  if (!isPhysician) {
    return {};
  }

  const psychTestingSpecialtySchema = Yup.object().when(
    ['modalities'],
    (modalities) =>
      getSchemaBasedOnSiblingSpecialties(
        BscaSpecialties.NeuropsychologicalTesting,
        {
          modalities,
        }
      )
  );
  return {
    bcsaNeuropsychTestingBoardMember: psychTestingSpecialtySchema,
    bcsaNeuropsychTestingCompletedCourses: psychTestingSpecialtySchema,
    bcsaNeuropsychTestingCompletedInternshipOrFellowship:
      psychTestingSpecialtySchema,
    bcsaNeuropsychTestingCompletedSupervisedExperience:
      psychTestingSpecialtySchema,
  };
};

export const getBcsaCurrentClientsSchema = (isPhysician: boolean) => {
  if (!isPhysician) {
    return {};
  }

  const infantToddlerSpecialtySchema = Yup.object().when(
    ['practicePopulations'],
    (practicePopulations) =>
      getSchemaBasedOnSiblingSpecialties(BscaSpecialties.InfantsToddlers, {
        practicePopulations,
      })
  );
  const childrenSpecialtySchema = Yup.object().when(
    ['practicePopulations'],
    (practicePopulations) =>
      getSchemaBasedOnSiblingSpecialties(BscaSpecialties.Children, {
        practicePopulations,
      })
  );
  const adolescentSpecialtySchema = Yup.object().when(
    ['practicePopulations'],
    (practicePopulations) =>
      getSchemaBasedOnSiblingSpecialties(BscaSpecialties.Adolescents, {
        practicePopulations,
      })
  );
  const seniorSpecialtySchema = Yup.object().when(
    ['practicePopulations'],
    (practicePopulations) =>
      getSchemaBasedOnSiblingSpecialties(BscaSpecialties.Seniors, {
        practicePopulations,
      })
  );

  return {
    bcsaInfantsToddlersTraining: infantToddlerSpecialtySchema,
    bcsaInfantsToddlersContinuingEducation: infantToddlerSpecialtySchema,
    bcsaInfantsToddlersPracticeExperience: infantToddlerSpecialtySchema,

    bcsaChildrenTraining: childrenSpecialtySchema,
    bcsaChildrenContinuingEducation: childrenSpecialtySchema,
    bcsaChildrenPracticeExperience: childrenSpecialtySchema,

    bcsaAdolescentsTraining: adolescentSpecialtySchema,
    bcsaAdolescentsContinuingEducation: adolescentSpecialtySchema,
    bcsaAdolescentsPracticeExperience: adolescentSpecialtySchema,

    bcsaSeniorsTraining: seniorSpecialtySchema,
    bcsaSeniorsContinuingEducation: seniorSpecialtySchema,
    bcsaSeniorsPracticeExperience: seniorSpecialtySchema,
  };
};

const stepToSchemaMap: { [keyof in SectionStepForBscaSpecialty]: any } = {
  [SectionStepForBscaSpecialty.Modalities]: getBcsaModalitiesSchema,
};

export const getSchemaForStep = (
  step: SectionStepForBscaSpecialty,
  selectedLicenses?: { [key: string]: License }
) => {
  const isPhysician = hasPhysicianLicenseType(selectedLicenses, [
    UnitedStates.CALIFORNIA,
  ]);
  return stepToSchemaMap[step](isPhysician);
};
