import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, Resolver, useForm, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import { useSessionDetailsEditability } from '~/legacy/hooks/useSessionDetailsEditability';
import { useUpdateProviderEventMutation } from '~/legacy/mutations/providerEvent';
import { AddressModalV2 } from '~/legacy/views/AppointmentConfirmation/components/modals/AddressModalV2';
import { ProgressNoteDownloadAgreementModal } from '~/legacy/views/Clients/ProgressNotesDownloads/ProgressNoteDownloadAgreementModal';

import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { Divider } from '@headway/helix/Divider';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { useMediaQuery } from '@headway/helix/utils';
import { CPTCodeInfo } from '@headway/shared/constants/cptCodes';

import { isPast } from '../../../Calendar/events/util/events';
import { useInitialValues } from '../../hooks/useInitialValues';
import { useValidationSchema } from '../../hooks/useValidationSchema';
import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';
import { AppointmentConfirmationFooterV2 } from '../AppointmentConfirmationFooterV2';
import { AppointmentHasNotHappenedYetCard } from '../AppointmentHasNotHappenedYetCard';
import AutoSaveListener from '../AutoSaveListener';
import {
  AddendumsEditFormV2Values,
  AddendumsFormV2,
} from '../forms/AddendumsFormV2';
import {
  AppointmentAttachmentFormV2,
  AppointmentAttachmentFormV2Values,
} from '../forms/AppointmentAttachmentFormV2';
import { AppointmentAttestationFormV2 } from '../forms/AppointmentAttestationFormV2';
import { ProgressNoteHeaderV2 } from '../forms/ProgressNote/components/ProgressNoteHeaderV2';
import { ProgressNoteSignedTextV2 } from '../forms/ProgressNote/components/ProgressNoteSignedTextV2';
import {
  ProgressNoteFormV2,
  ProgressNoteFormV2Values,
} from '../forms/ProgressNote/ProgressNoteFormV2';
import { NonEditableSessionDetailsSectionV2 } from '../forms/SessionDetails/components/NonEditableSessionDetailsSectionV2';
import { SessionDetailsHeader } from '../forms/SessionDetails/components/SessionDetailsHeader';
import { SessionDetailsTopBanner } from '../forms/SessionDetails/components/SessionDetailsTopBanner';
import {
  SessionDetailsFormV2,
  SessionDetailsFormV2Values,
} from '../forms/SessionDetails/SessionDetailsFormV2';
import { convertFormValuesToProviderEventUpdate } from '../forms/SessionDetails/utils';
import { GroupPracticeAdminBanner } from '../GroupPracticeAdminBanner';
import {
  TreatmentPlanRequirementWarningBanner,
  TreatmentPlanRequirementWarningV2,
} from '../TreatmentPlanRequirementWarningV2';
import { ComplianceGuideModalV2 } from './ComplianceGuideModalV2';
import { RequiredNoteSubmissionModalV2 } from './RequiredNoteSubmissionModalV2';
import { SubmitAddendumWithoutChangesModalV2 } from './SubmitAddendumWithoutChangesModalV2';
import { SwoopUpsellModalV2 } from './SwoopUpsellModalV2';

export interface AppointmentConfirmationModalV2Props {
  open?: boolean | undefined;
  onClose: () => void;
  onOpenContactFormInsuranceIssues: () => void;
  onProgressNoteUpdate?: () => void;
}

export const defaultInitialValues = {
  addendumsEdit: {},
  attachments: [],
  attestation: false,
  progressNote: {},
  sessionDetails: {},
};
export interface AppointmentConfirmationModalFormV2Values {
  sessionDetails: SessionDetailsFormV2Values;
  progressNote: ProgressNoteFormV2Values;
  attachments: AppointmentAttachmentFormV2Values;
  addendumsEdit: AddendumsEditFormV2Values;
  attestation: boolean;
}

export const AppointmentConfirmationModalV2: React.FC<
  React.PropsWithChildren<AppointmentConfirmationModalV2Props>
> = ({ open, onOpenContactFormInsuranceIssues, onProgressNoteUpdate }) => {
  const {
    patient,
    provider,
    event,
    setEvent,
    isAutoSaving,
    setIsAutoSaving,
    progressNoteErrorsFromSubmitting,
    onCloseAppointmentConfirmationModal,
    isProgressNoteDownloadAgreementModalOpen,
    setIsProgressNoteDownloadAgreementModalOpen,
    isSubmitAddendumWithoutChangesModalOpen,
    setIsSubmitAddendumWithoutChangesModalOpen,
    isComplianceGuideModalOpen,
    setIsComplianceGuideModalOpen,
    isAddressModalOpen,
    setIsAddressModalOpen,
    isRequiredNoteSubmissionModalOpen,
    setIsRequiredNoteSubmissionModalOpen,
    isSwoopUpsellModalOpen,
    isGroupAdminImpersonating,
  } = useContext(AppointmentConfirmationContextV2);

  const { data: editabilityStatus } = useSessionDetailsEditability(
    event?.virtualId
  );
  const isSmallScreen = useMediaQuery('(max-width: 850px)');
  const updateEventMutation = useUpdateProviderEventMutation();

  const [progressNoteTemplateInfo, setProgressNoteTemplateInfo] = useState<
    string | undefined
  >(undefined);
  const [cptCodes, setCptCodes] = useState<CPTCodeInfo[] | undefined>(
    undefined
  );

  const areFormValuesInitializedRef = useRef(false);
  const { initialValues, isInitialized } = useInitialValues();
  const validationSchema: Yup.ObjectSchema<AppointmentConfirmationModalFormV2Values> =
    useValidationSchema(progressNoteTemplateInfo, cptCodes);

  const methods = useForm<AppointmentConfirmationModalFormV2Values>({
    defaultValues: useMemo(() => initialValues, [initialValues]),
    mode: 'onSubmit',
    resolver: yupResolver(
      validationSchema
    ) as Resolver<AppointmentConfirmationModalFormV2Values>,
  });

  // We have to watch those values this way so that we can then update the state
  // and re-trigger the call to useValidationSchema when those values change
  //const { reset, trigger, control } = methods;
  const watchedValues = useWatch<AppointmentConfirmationModalFormV2Values>({
    control: methods.control,
  });

  useEffect(() => {
    setProgressNoteTemplateInfo(watchedValues.progressNote?.template);
    setCptCodes(watchedValues.sessionDetails?.cptCodes as CPTCodeInfo[]);
  }, [
    watchedValues.progressNote?.template,
    watchedValues.sessionDetails?.cptCodes,
  ]);

  // Reset when initial values are fully fetched
  useEffect(() => {
    if (isInitialized && !areFormValuesInitializedRef.current) {
      areFormValuesInitializedRef.current = true;
      methods.reset(initialValues);

      /* 
        Here we need to add this updateEvent as it will create a new event's instance
        if we are working with a recurring event. It will then replace this
        newly createdEvent as the main event in the context. It happens only once
        on opening the modal.
      */
      const updateEvent = async () => {
        const newEvent = await updateEventMutation.mutateAsync({
          eventIdOrVirtualId: event?.virtualId!,
          update: convertFormValuesToProviderEventUpdate(
            initialValues.sessionDetails
          ),
        });
        setEvent(newEvent);
      };
      updateEvent();
    }
  }, [
    isInitialized,
    initialValues,
    methods,
    updateEventMutation,
    event?.virtualId,
    areFormValuesInitializedRef,
    setEvent,
  ]);

  // Trigger when validationSchema changes with errors from submitting
  useEffect(() => {
    if (progressNoteErrorsFromSubmitting) {
      methods.trigger();
    }
  }, [validationSchema, progressNoteErrorsFromSubmitting, methods]);

  const modalTitle = useMemo(() => {
    return event &&
      isPast(event) &&
      editabilityStatus === SessionDetailsEditabilityStatus.ALLOWED
      ? 'Confirm session details'
      : 'Session details';
  }, [event, editabilityStatus]);

  const canEditSessionDetails = useMemo(
    () => editabilityStatus === SessionDetailsEditabilityStatus.ALLOWED,
    [editabilityStatus]
  );

  return (
    <div>
      <FormProvider {...methods}>
        <form id="appointmentConfirmationModalForm">
          <Modal
            title={modalTitle}
            onDismiss={onCloseAppointmentConfirmationModal}
            isOpen={open}
            variant="fullscreen"
            layout={`${isSmallScreen ? 'contained' : 'full-bleed'}`}
            disableTrapFocus
          >
            <ModalContent css={{ justifyContent: 'center' }}>
              {isGroupAdminImpersonating && <GroupPracticeAdminBanner />}
              <TreatmentPlanRequirementWarningBanner />
              <div
                className={`${
                  isSmallScreen ? 'w-auto' : 'ml-auto mr-auto w-[850px]'
                }`}
              >
                <AutoSaveListener
                  isInitialized={isInitialized}
                  setIsAutoSaving={(value) => setIsAutoSaving(value)}
                />
                <SessionDetailsHeader />
                <SessionDetailsTopBanner />
                {canEditSessionDetails ? (
                  <SessionDetailsFormV2
                    onOpenContactFormInsuranceIssues={
                      onOpenContactFormInsuranceIssues
                    }
                  />
                ) : (
                  <NonEditableSessionDetailsSectionV2 />
                )}
                <Divider />
                <ProgressNoteHeaderV2 />
                <ProgressNoteFormV2 />
                <AppointmentAttachmentFormV2 />
                <AddendumsFormV2 />
                <AppointmentHasNotHappenedYetCard />
                <ProgressNoteSignedTextV2 />
                <AppointmentAttestationFormV2 />
                <TreatmentPlanRequirementWarningV2 />
              </div>
              <ModalFooter>
                <AppointmentConfirmationFooterV2 isAutoSaving={isAutoSaving} />
              </ModalFooter>
            </ModalContent>
          </Modal>
          <AddressModalV2
            open={isAddressModalOpen}
            onClose={() => setIsAddressModalOpen(false)}
          />
          <ComplianceGuideModalV2
            open={isComplianceGuideModalOpen}
            onClose={() => setIsComplianceGuideModalOpen(false)}
          />
          <SubmitAddendumWithoutChangesModalV2
            open={isSubmitAddendumWithoutChangesModalOpen}
            onClose={() => setIsSubmitAddendumWithoutChangesModalOpen(false)}
          />
          <ProgressNoteDownloadAgreementModal
            open={isProgressNoteDownloadAgreementModalOpen}
            onClose={async () =>
              setIsProgressNoteDownloadAgreementModalOpen(false)
            }
            providerId={provider?.id!}
            clientId={patient?.id!}
            providerAppointmentIds={[event?.providerAppointment?.id!]}
          />
          <RequiredNoteSubmissionModalV2
            open={isRequiredNoteSubmissionModalOpen}
            onClose={() => setIsRequiredNoteSubmissionModalOpen(false)}
          />
          <SwoopUpsellModalV2
            open={isSwoopUpsellModalOpen}
            onClose={onCloseAppointmentConfirmationModal}
          />
        </form>
      </FormProvider>
    </div>
  );
};
