import { ProviderEventReadForCalendar } from '@headway/api/models/ProviderEventReadForCalendar';

import { EventFilterData, filterEvents } from './filters';
import {
  mergeOverlappingWorkingHours,
  removeWorkingHoursContainedInBusyEvents,
  separateWorkingHoursAndEvents,
} from './hwma';

interface HWMAEvents {
  workingHours: ProviderEventReadForCalendar[];
  events: ProviderEventReadForCalendar[];
}

export const deriveHWMAEvents = (
  allEvents: ProviderEventReadForCalendar[],
  filterData?: EventFilterData
): HWMAEvents => {
  const filteredEvents = filterData
    ? filterEvents(allEvents, filterData)
    : allEvents;

  const { events, workingHoursByDate } =
    separateWorkingHoursAndEvents(filteredEvents);

  const mergedAndTrimmedWorkingHours = mergeOverlappingWorkingHours(
    removeWorkingHoursContainedInBusyEvents(workingHoursByDate, events)
  );

  return {
    events,
    workingHours: mergedAndTrimmedWorkingHours,
  };
};
