import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';

import { Rule } from '../Rule';

export class DocumentRequirementsPAPilot extends Rule<[boolean]> {
  addDataHooks() {
    return [async () => this.flags['documentationRequirementPilot'] || false];
  }

  isRuleActive(): boolean {
    const hookData = this.getHookData();

    if (!hookData || !hookData[0]) {
      return false;
    }
    return hookData[0];
  }

  get disabledProgressNoteTypes() {
    return [ProgressNoteType.NONE];
  }

  get banners() {
    return {};
  }

  isDocumentationRequired(): boolean {
    return true;
  }
}
