import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ProviderProgressNotesApi } from '@headway/api/resources/ProviderProgressNotesApi';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { Divider } from '@headway/helix/Divider';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Modal } from '@headway/helix/Modal';
import { TextArea } from '@headway/helix/TextArea';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';

import { SchemaComponent } from '.';
import { AppointmentConfirmationModalFormV2Values } from '../../../../modals/AppointmentConfirmationModalV2';
import { SessionDetailsFormV2Values } from '../../../SessionDetails/SessionDetailsFormV2';

const borderAnimation = keyframes`
  0% { border-color: red; }
  20% { border-color: orange; }
  40% { border-color: yellow; }
  60% { border-color: green; }
  80% { border-color: blue; }
  100% { border-color: red; }
`;

const TextBox = styled.textarea`
  outline: none;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #bebebe;
  border-radius: 4px;
  display: inline-block;
  min-height: 200px;
`;
const AnimatedTextBox = styled.textarea`
  outline: none;
  padding: 0.5rem;
  font-size: 1rem;
  border: 2px solid red;
  border-radius: 4px;
  animation: ${borderAnimation} 3s linear infinite;
  min-height: 200px;
  background-color: #e3e3e32a;
  backdrop-filter: blur(4px);
`;

export const AISoapNote = ({ element: { id }, disabled }: SchemaComponent) => {
  const { setValue, getValues } = useFormContext();
  const [showFeedbackSideSheet, setShowFeedbackSideSheet] = useState(false);
  const values = getValues();
  const [rawNote, setRawNote] = useState<string>(
    values['progressNote']['5461aabac-5ebb-4adb-922d-55f0a63719b7']
  );
  const [generatedResponse, setGeneratedResponse] = useState(undefined);
  const [generationError, setGenerationError] = useState('');
  const [isGenerated, setIsGenerated] = useState<boolean>(
    Boolean(values['progressNote']['ab4b06e7-8509-41b7-9b27-a06030f25a61'])
  );
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const { control } =
    useFormContext<AppointmentConfirmationModalFormV2Values>();
  const sessionDetailsValues: SessionDetailsFormV2Values =
    useWatch<AppointmentConfirmationModalFormV2Values>({
      control,
      name: 'sessionDetails',
    });

  const onRawNoteAdded = (rawNote: string) => {
    setRawNote(rawNote);
    setValue(id, rawNote);
  };

  const generateRawNote = async () => {
    try {
      setIsGenerating(true);
      const generatedSoap = await ProviderProgressNotesApi.generateSoap({
        raw_note: rawNote,
        cpt_codes:
          sessionDetailsValues.cptCodes?.map((value) => value.value) ?? [],
        diagnosis_codes:
          sessionDetailsValues.diagnosisCodes?.map((value) => value.value) ??
          [],
      });
      const parsedGeneratedSoap = JSON.parse(generatedSoap);
      setGeneratedResponse(parsedGeneratedSoap.response);
      Object.entries(parsedGeneratedSoap.response).forEach(([key, value]) => {
        setValue('progressNote.' + key, value);
      });
      setIsGenerated(true);
      setIsGenerating(false);
    } catch (e: any) {
      setGenerationError(e.message);
      setIsGenerated(false);
      setIsGenerating(false);
    }
  };

  const sendFeedback = async (component: string, feedback: string) => {
    try {
      ProviderProgressNotesApi.aiFeedback({
        component,
        feedback,
      });
    } catch (e) {
      console.error(
        'Error sending feedback to ai note internal slack workflow',
        e
      );
    }
  };

  return (
    <>
      <Divider />
      <Modal
        variant="sidesheet"
        title="AI Note Internal Feedback"
        isOpen={showFeedbackSideSheet}
        onDismiss={() => setShowFeedbackSideSheet(false)}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing.x4,
          }}
        >
          <Formik
            onSubmit={async (values) => {
              await sendFeedback(values.component, values.feedback);
              setShowFeedbackSideSheet(false);
            }}
            initialValues={{ component: '', feedback: '' }}
          >
            <Form>
              <GuidanceCard variant="compliance">
                Note: Do not include any PHI in your feedback!
              </GuidanceCard>
              <FormControl
                component={TextField}
                autoFocus={true}
                label="Template Question"
                name="component"
              />
              <FormControl
                component={TextArea}
                label="Feedback"
                name="feedback"
              />
              <Button type="submit">Send</Button>
            </Form>
          </Formik>
        </div>
      </Modal>
      {generationError && (
        <GuidanceCard variant="error">
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <ContentText>
              <strong>
                Something went wrong while generating the SOAP note. Please try
                again.
              </strong>
            </ContentText>
            <p>Error: {generationError}</p>
          </div>
        </GuidanceCard>
      )}
      {!disabled && (
        <GuidanceCard variant="info">
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            Paste your psychotherapy notes, then press Generate to have AI
            produce a compliant version.
          </div>
        </GuidanceCard>
      )}
      {disabled && rawNote && (
        <GuidanceCard variant="info">
          The following raw psychotherapy note you provided was used to generate
          the compliant SOAP progress note.
        </GuidanceCard>
      )}
      {isGenerating ? (
        <AnimatedTextBox value={rawNote} disabled={isGenerating} />
      ) : (
        <TextBox
          value={rawNote}
          disabled={isGenerating}
          onChange={(e) => onRawNoteAdded(e.target.value)}
          css={{ backgroundColor: disabled ? '#e3e3e32a' : 'white' }}
        />
      )}
      {!disabled && (
        <Button
          disabled={isGenerating}
          variant="brand"
          onPress={generateRawNote}
        >
          {isGenerating
            ? 'Generating'
            : isGenerated
            ? 'Regenerate'
            : 'Generate'}
        </Button>
      )}
      {generatedResponse && (
        <GuidanceCard
          variant="positive"
          css={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          AI Generation Successful!
        </GuidanceCard>
      )}
      <div css={{ position: 'fixed', right: '80px', top: '20px' }}>
        <Button variant="brand" onPress={() => setShowFeedbackSideSheet(true)}>
          Give Feedback
        </Button>
      </div>
      <Divider />
    </>
  );
};
