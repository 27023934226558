import { filter, minBy } from 'lodash';
import moment from 'moment';

import { BillingType } from '@headway/api/models/BillingType';
import { ClaimRead } from '@headway/api/models/ClaimRead';
import { ClaimSubmissionStatus } from '@headway/api/models/ClaimSubmissionStatus';
import { NestedPatientReadForCalendar } from '@headway/api/models/NestedPatientReadForCalendar';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { PatientMissingSchedulingInfoType } from '@headway/api/models/PatientMissingSchedulingInfoType';
import { PatientPaymentStatus } from '@headway/api/models/PatientPaymentStatus';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderAppointmentRead } from '@headway/api/models/ProviderAppointmentRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventChannel } from '@headway/api/models/ProviderEventChannel';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderPaymentStatus } from '@headway/api/models/ProviderPaymentStatus';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { StateInsuranceCarrierRead } from '@headway/api/models/StateInsuranceCarrierRead';
import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { UserClaimReadinessResponse } from '@headway/api/models/UserClaimReadinessResponse';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserRead } from '@headway/api/models/UserRead';
import { PROVIDER_CANCELLATION_STATUS_NOTES } from '@headway/shared/constants/providerAppointmentStatusNotes';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { toFloatingDateTime } from '@headway/shared/utils/dates';
import {
  getExternalPlatformChannelForEventChannel,
  getExternalPlatformDisplayName,
} from '@headway/shared/utils/externalPlatforms';
import { formatPatientName } from '@headway/shared/utils/patient';
import { formatPrice } from '@headway/shared/utils/payments';
import { joinWithOxfordComma } from '@headway/shared/utils/stringFormatting';

import {
  getCoordinationOfBenefitsFreezeMessage,
  getOtherFreezeMessage,
  getOutOfNetworkFreezeMessage,
  hasCoordinationOfBenefitsFreeze,
  hasOtherFreeze,
  hasOutOfNetworkFreeze,
} from 'utils/freeze';
import {
  EstimatedLiveDatesWithCarrierMap,
  EstimatedLiveDateWithCarrier,
} from 'views/Calendar/LiveDateCalculator';

import {
  getMissingPatientSchedulingInfo,
  isPatientMissingRequiredInfo,
} from '../../../../utils/patient';
import { RecurrenceType } from '../../utils/constants';
import {
  getBiWeeklyRRuleString,
  getDailyRRuleString,
  getEveryThreeMonthsRRuleString,
  getMonthlyRRuleString,
  getWeeklyRRuleString,
} from '../../utils/rrule';

export const CRISIS_CODES = ['90839', '90840'];

// The Provider Event Channels that are considered a 'Referral'
export const PROVIDER_EVENT_REFERRAL_CHANNELS = [
  ProviderEventChannel.ZOCDOC,
  ProviderEventChannel.DOCASAP,
  ProviderEventChannel.ADMIN_PORTAL,
];

export const doesAppointmentHaveCrisisCode = (selectedCptCodes: string[]) => {
  return selectedCptCodes.some((cptCode) => CRISIS_CODES.includes(cptCode));
};

export const insuranceStatusAllowedWithCrisisCodeException = [
  PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE,
  PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE,
  PatientInsuranceOrEAPStatus.OUT_OF_NETWORK,
  PatientInsuranceOrEAPStatus.IN_NETWORK,
];

export function isCanceled(event: ProviderEventRead | undefined) {
  return (
    event?.providerAppointment?.status === ProviderAppointmentStatus.CANCELED
  );
}

export function isDetailsConfirmed(event: ProviderEventRead | undefined) {
  return (
    event?.providerAppointment?.status ===
    ProviderAppointmentStatus.DETAILS_CONFIRMED
  );
}

export function isAppointmentDetailsConfirmed(
  providerAppointment?: ProviderAppointmentRead
) {
  return (
    providerAppointment?.status === ProviderAppointmentStatus.DETAILS_CONFIRMED
  );
}

export function isProgressNoteAdded(event: ProviderEventRead | undefined) {
  return (
    isDetailsConfirmed(event) &&
    (event?.providerAppointment?.progressNoteType !== 'NONE' ||
      event?.providerAppointment?.attachments)
  );
}

export function isPast(event: ProviderEventRead) {
  return moment(event.endDate).diff(moment()) < 0;
}

export function isUpcomingPatientBookedEvent(event: ProviderEventRead) {
  return (
    !isPast(event) &&
    (isAppointment(event) || isIntakeCall(event)) &&
    !isCanceled(event) &&
    isPatientBooked(event)
  );
}

export function eventHasPatientUserId(event: ProviderEventRead) {
  return !!event.patientUserId;
}

export function hasTask(event: ProviderEventRead) {
  return (
    isAppointment(event) &&
    isPast(event) &&
    !isDetailsConfirmed(event) &&
    !isCanceled(event)
  );
}

export function isAppointmentDateValidForPFEC(
  providerFrontEndCarrier: ProviderFrontEndCarrierRead,
  appointmentDate?: string
) {
  return (
    !!providerFrontEndCarrier.appointmentReadyDate &&
    !!appointmentDate &&
    moment(appointmentDate).isSameOrAfter(
      providerFrontEndCarrier.appointmentReadyDate
    )
  );
}

export const isAutobooked = (event: ProviderEventRead | undefined) =>
  event?.channel === ProviderEventChannel.AUTOBOOK_HC_REFERRAL;

export const isHealthcareReferral = (event: ProviderEventRead | undefined) =>
  event?.channel === ProviderEventChannel.HEALTHCARE_REFERRAL;

export const isReferral = (event: ProviderEventRead) => {
  return PROVIDER_EVENT_REFERRAL_CHANNELS.includes(event.channel);
};

export const isReferralAppointment = (event: ProviderEventRead) => {
  return event.type === ProviderEventType.APPOINTMENT && isReferral(event);
};

// Checks if the event is a referral appointment that has not been confirmed
// and the patient has not become appointment ready. Used to determine
// calendar event styling and whether a cancelled event should be hidden.
export const isUnreadyReferralAppointment = (event: ProviderEventRead) => {
  return (
    isReferralAppointment(event) &&
    !isDetailsConfirmed(event) &&
    !patientBecameReadyForReferredAppointment(event)
  );
};

export const isReferralIntakeCall = (event: ProviderEventRead) => {
  return event.type === ProviderEventType.INTAKE_CALL && isReferral(event);
};

export const patientBecameReadyForReferredAppointment = (
  event: ProviderEventRead
) => {
  return !!event.providerAppointment
    ?.referredPatientBecameReadyForAppointmentOn;
};

export const appointmentWasCancelledByProvider = (event: ProviderEventRead) => {
  return (
    event.providerAppointment?.status === ProviderAppointmentStatus.CANCELED &&
    !!event.providerAppointment?.statusNotes &&
    PROVIDER_CANCELLATION_STATUS_NOTES.includes(
      event.providerAppointment?.statusNotes
    )
  );
};

export const isPendingReferralAppointment = (event: ProviderEventRead) => {
  return (
    isUnreadyReferralAppointment(event) &&
    event.providerAppointment?.status === ProviderAppointmentStatus.SCHEDULED
  );
};

export const isCancelledReferralAppointment = (event: ProviderEventRead) => {
  return (
    isUnreadyReferralAppointment(event) &&
    event.providerAppointment?.status === ProviderAppointmentStatus.CANCELED
  );
};

export const isProviderCancelledReferralAppointment = (
  event: ProviderEventRead
) => {
  return (
    isCancelledReferralAppointment(event) &&
    appointmentWasCancelledByProvider(event)
  );
};

export const isPatientOrAutoCancelledReferralAppointment = (
  event: ProviderEventRead
) => {
  return (
    isCancelledReferralAppointment(event) &&
    !appointmentWasCancelledByProvider(event)
  );
};

export function isPatientBooked(event: ProviderEventRead) {
  return [
    ProviderEventChannel.ADMIN_PORTAL,
    ProviderEventChannel.AUTOBOOK_HC_REFERRAL,
    ProviderEventChannel.HEALTHCARE_REFERRAL,
    ProviderEventChannel.PATIENT_PORTAL,
    ProviderEventChannel.ZOCDOC,
    ProviderEventChannel.DOCASAP,
  ].includes(event.channel);
}

export function getTaskSummary(event: ProviderEventRead | undefined) {
  // in the future this will include more logic
  return 'Please confirm details for this session';
}

export const isSelfPayAppt = (event: ProviderEventRead | undefined) => {
  return event?.providerAppointment?.billingType === BillingType.SELF_PAY;
};

export function isPaid(event: ProviderEventRead | undefined) {
  const providerPaymentDate = event?.providerAppointment?.providerPaymentDate;
  if (!!providerPaymentDate) {
    return moment(providerPaymentDate).diff(moment()) < 0;
  }
  return false;
}

export function isPaymentExpected(event: ProviderEventRead | undefined) {
  return !!event?.providerAppointment?.providerPaymentDate;
}

export function paymentAttemptFailed(event: ProviderEventRead | undefined) {
  return (
    event?.providerAppointment?.patientPaymentStatus ===
    PatientPaymentStatus.PAYMENT_FAILED
  );
}

export function getPaymentAmount(event: ProviderEventRead | undefined) {
  return event?.providerAppointment?.providerPaymentAmount;
}

export function getPaymentSummary(event: ProviderEventRead) {
  if (!event.providerAppointment) {
    return '';
  }
  if (!event.providerAppointment.providerPaymentAmount) {
    return 'Details confirmed';
  }
  if (
    event.providerAppointment.providerPaymentStatusEnum ===
    ProviderPaymentStatus.UNDER_REVIEW
  ) {
    return `${formatPrice(
      event.providerAppointment.providerPaymentAmount
    )} on hold`;
  }
  if (
    event.providerAppointment.providerPaymentStatusEnum ===
    ProviderPaymentStatus.DENIED
  ) {
    return `${formatPrice(
      event.providerAppointment.providerPaymentAmount
    )} denied`;
  }

  return `${formatPrice(event.providerAppointment.providerPaymentAmount)} ${
    isPaid(event) ? 'paid' : 'expected'
  } on ${moment(event.providerAppointment.providerPaymentDate).format(
    'M/DD/YY'
  )}`;
}

export function getCancelPaymentSummary(event: ProviderEventRead) {
  return (isPaid(event) || isPaymentExpected(event)) &&
    (getPaymentAmount(event) || 0) > 0
    ? getPaymentSummary(event)
    : 'No cancellation fee';
}

export function getBookingWindowStart(provider: ProviderRead) {
  return moment().add(provider.soonestBookingCutoffHours, 'hours').toDate();
}

export function getBookingWindowEnd() {
  return moment().add(2, 'weeks').toDate();
}

export function getBookingNotificationEnd() {
  return moment().add(3, 'weeks').toDate();
}

export function isWithinBookingWindowStart(
  event: ProviderEventRead,
  provider: ProviderRead
) {
  // past the provider's soonest booking cutoff
  return moment(event.startDate).diff(getBookingWindowStart(provider)) > 0;
}

export function isWithinBookingWindowEnd(event: ProviderEventRead) {
  // less than two weeks in the future
  return moment(event.startDate).diff(getBookingWindowEnd()) < 0;
}

export function isWithinBookingWindow(
  event: ProviderEventRead,
  provider: ProviderRead
) {
  return (
    isWithinBookingWindowStart(event, provider) &&
    isWithinBookingWindowEnd(event)
  );
}

export function isAppointment(event?: ProviderEventRead) {
  return event?.type === ProviderEventType.APPOINTMENT;
}

export function isInsuranceAppointment(event?: ProviderEventRead) {
  const isInsurance =
    event?.providerAppointment?.billingType === BillingType.INSURANCE;
  return isAppointment(event) && isInsurance;
}

export function isAvailability(event: ProviderEventRead) {
  return event.type === ProviderEventType.AVAILABILITY;
}

export function isWithinBookableWindow(
  event: ProviderEventRead,
  provider: ProviderRead
) {
  return !isPast(event) && isWithinBookingWindow(event, provider);
}

export function isUnavailability(event?: ProviderEventRead) {
  return event?.type === ProviderEventType.BUSY;
}

export function isExternalEvent(event: ProviderEventRead) {
  return event?.channel === ProviderEventChannel.EXTERNAL_CALENDAR;
}

export function isIntakeCall(event?: ProviderEventRead) {
  return event?.type === ProviderEventType.INTAKE_CALL;
}

function sameDay(dt1: Date, dt2: Date) {
  return (
    dt1.getDate() === dt2.getDate() &&
    dt1.getMonth() === dt2.getMonth() &&
    dt1.getFullYear() === dt2.getFullYear()
  );
}

export function isDuplicateAppointment(
  existingEvents: ProviderEventRead[],
  event: ProviderEventRead
) {
  if (event.type !== ProviderEventType.APPOINTMENT) {
    return false;
  }

  const selectedDateTime = toFloatingDateTime(event.startDate);
  const otherEvents = filter(existingEvents, (e) => {
    if (e.type !== ProviderEventType.APPOINTMENT) {
      return false;
    }

    return (
      e.patientUserId === event.patientUserId &&
      !isCanceled(e) &&
      e.id !== event.id &&
      sameDay(toFloatingDateTime(e.startDate), selectedDateTime)
    );
  });

  return !!otherEvents.length;
}

export function getEarliestHour(events: ProviderEventRead[]) {
  let earliestTime = moment().subtract('minutes', 30).hour();

  events.forEach((event) => {
    if (
      event.type === ProviderEventType.APPOINTMENT ||
      event.type === ProviderEventType.INTAKE_CALL
    ) {
      const startTime = moment(event.startDate).hour();
      if (startTime < earliestTime) {
        earliestTime = startTime;
      }
    }
  });
  return earliestTime;
}

export function getDurationMinutes(event: ProviderEventRead) {
  return moment(event.endDate).diff(event.startDate, 'minute');
}

const getMissingInfoWarningMessage = (
  patient: NestedPatientReadForCalendar | UserRead,
  missingInfo: PatientMissingSchedulingInfoType[]
): string => {
  const mapping = {
    [PatientMissingSchedulingInfoType.INSURANCE]: 'provide their insurance',
    [PatientMissingSchedulingInfoType.BILLING]: 'add payment information',
    [PatientMissingSchedulingInfoType.FORMS]: 'acknowledge our standard forms',
    [PatientMissingSchedulingInfoType.ADDRESS]: '',
  };
  const fragments = missingInfo
    .map((info) => mapping[info])
    .filter((info) => !!info);
  const patientName = formatPatientName(patient, {
    firstNameOnly: true,
  });
  const joinedFragments = joinWithOxfordComma(fragments);
  return `${patientName} needs to ${joinedFragments} before you can confirm sessions with them.`;
};

const getMissingInfoWarningHeader = (
  patient: NestedPatientReadForCalendar | UserRead,
  missingInfo: PatientMissingSchedulingInfoType[]
): string | undefined => {
  let requiredTasksIncompleteCount = 0;
  if (missingInfo.includes(PatientMissingSchedulingInfoType.INSURANCE)) {
    requiredTasksIncompleteCount += 1;
  }
  if (missingInfo.includes(PatientMissingSchedulingInfoType.BILLING)) {
    requiredTasksIncompleteCount += 1;
  }
  if (missingInfo.includes(PatientMissingSchedulingInfoType.FORMS)) {
    requiredTasksIncompleteCount += 1;
  }
  if (requiredTasksIncompleteCount === 0) {
    return undefined;
  }
  const pluralize = requiredTasksIncompleteCount > 1;
  const taskOrTasks = pluralize ? 'tasks' : 'task';
  return `${requiredTasksIncompleteCount} required ${taskOrTasks} incomplete`;
};

interface AppointmentDetailWarning {
  warningHeader: string | undefined;
  warningMessage: string;
}

const getAppointmentDetailWarning = (
  warningMessage: string,
  warningHeader?: string | undefined
): AppointmentDetailWarning => {
  return {
    warningHeader: warningHeader,
    warningMessage: warningMessage,
  };
};

export function useWarningMessage({
  event,
  patient,
  acceptsPatientInsurance,
  claimReadiness,
  freezeReasons,
}: {
  event: ProviderEventRead;
  patient: NestedPatientReadForCalendar | UserRead | undefined;
  acceptsPatientInsurance: boolean;
  claimReadiness?: UserClaimReadinessResponse;
  freezeReasons?: UserFreezeReason[];
}): AppointmentDetailWarning | undefined {
  const isStandardPatientFormsEnabled = useFlag('standardPatientForms', true);
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);

  if (isDetailsConfirmed(event) || isCanceled(event) || isIntakeCall(event)) {
    return undefined;
  }

  if (!patient) {
    return undefined;
  }

  const patientName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const freezeCTACopy =
    "You can see more information on their profile in your Clients tab. We've reached out to them to resolve the issue.";

  const hasCobFreezeFromClaimReadiness =
    claimReadiness &&
    (claimReadiness.requirements || []).includes(
      UserClaimReadinessCheck.FROZEN_FOR_COB
    );

  const hasOonFreezeFromClaimReadiness =
    claimReadiness &&
    (claimReadiness.requirements || []).includes(
      UserClaimReadinessCheck.FROZEN_FOR_OON
    );

  if (
    hasCoordinationOfBenefitsFreeze(freezeReasons) ||
    hasCobFreezeFromClaimReadiness
  ) {
    const message = getCoordinationOfBenefitsFreezeMessage(patient);
    return getAppointmentDetailWarning(`${message} ${freezeCTACopy}`);
  }

  if (
    (hasOutOfNetworkFreeze(freezeReasons) || hasOonFreezeFromClaimReadiness) &&
    !isSelfPayAppt(event)
  ) {
    const message = getOutOfNetworkFreezeMessage(patient);
    return getAppointmentDetailWarning(`${message} ${freezeCTACopy}`);
  }

  const hasOtherFreezeFromClaimReadiness =
    claimReadiness &&
    (claimReadiness.requirements || []).includes(
      UserClaimReadinessCheck.FROZEN_FOR_OTHER_REASON
    );

  if (hasOtherFreeze(freezeReasons) || hasOtherFreezeFromClaimReadiness) {
    const message = getOtherFreezeMessage(patient);
    return getAppointmentDetailWarning(`${message} ${freezeCTACopy}`);
  }

  function handleInsurance() {
    if (isStandardPatientFormsEnabled) {
      if (
        isPatientMissingRequiredInfo(
          patient!,
          BillingType.INSURANCE,
          claimReadiness
        )
      ) {
        const missingInfo = getMissingPatientSchedulingInfo(
          patient!,
          BillingType.INSURANCE,
          claimReadiness
        );
        const message = getMissingInfoWarningMessage(patient!, missingInfo);
        const header = getMissingInfoWarningHeader(patient!, missingInfo);
        return getAppointmentDetailWarning(message, header);
      }
    } else {
      if (
        !patient!.defaultUserPaymentMethodId &&
        !patient!.activeUserInsurance
      ) {
        const message = `${patientName} has not provided us their insurance and payment information yet. You should not see them and you will not be able to confirm session details until we verify their coverage and payment information.`;
        return getAppointmentDetailWarning(message);
      }

      if (!patient!.defaultUserPaymentMethodId) {
        const message = `${patientName} has not provided us their payment information yet. You should not see them and you will not be able to confirm session details until we verify their payment information.`;
        return getAppointmentDetailWarning(message);
      }

      if (!patient!.activeUserInsurance) {
        const message = `${patientName} has not provided us their insurance
        information yet. You should not see them and you will not be able
        to confirm session details until we verify their coverage.`;
        return getAppointmentDetailWarning(message);
      }
    }

    if (!patient!.activeUserInsurance?.latestEligibilityLookup?.isClaimReady) {
      const message = `${patientName}'s insurance must be verified before you can confirm session details. You can request to manually verify ${patientName}'s benefits by visiting their insurance page.`;
      return getAppointmentDetailWarning(message);
    }

    if (
      !isMSCEnabled &&
      patient!.activeUserInsurance &&
      claimReadiness &&
      claimReadiness.requirements?.includes(
        UserClaimReadinessCheck.PATIENT_ADDRESS
      )
    ) {
      const message = `We’re missing ${patientName}’s home address, which we need to process their insurance. You can add ${patientName}’s address in your Client tab, or ask them to add it on their side. We won’t be able to process sessions or claims until this is done, so you may want to hold off.`;
      return getAppointmentDetailWarning(message);
    }

    const appointmentCptCodes = event.providerAppointment?.cptCodes || [];
    const hasCrisisCodes = doesAppointmentHaveCrisisCode(appointmentCptCodes);
    if (!acceptsPatientInsurance && !hasCrisisCodes) {
      const message = `You are not credentialed to take ${patientName}'s ${
        patient!.activeUserInsurance.frontEndCarrierName
      } insurance plan. You should not see this client through Headway as you will not be able to be reimbursed.`;
      return getAppointmentDetailWarning(message);
    }
  }

  function handleSelfPay() {
    if (isStandardPatientFormsEnabled) {
      if (
        isPatientMissingRequiredInfo(
          patient!,
          BillingType.SELF_PAY,
          claimReadiness
        )
      ) {
        const message = `${patientName} hasn’t acknowledged our standard authorization forms or provided their payment details.
        You will not be able to confirm sessions with ${patientName} until these are completed.`;
        return getAppointmentDetailWarning(message);
      }
    } else {
      if (!patient!.defaultUserPaymentMethodId) {
        const message = `${patientName} has not provided us their payment information yet. You should not see them and you will not be able to confirm session details until we verify their payment information.`;
        return getAppointmentDetailWarning(message);
      }
    }
  }

  function handleEAP() {
    if (isStandardPatientFormsEnabled) {
      const missingInfo = getMissingPatientSchedulingInfo(
        patient!,
        BillingType.EAP,
        claimReadiness
      );
      if (
        missingInfo.some((m) => m === PatientMissingSchedulingInfoType.FORMS)
      ) {
        const message = `${patientName} hasn’t acknowledged our standard authorization forms.
          You will not be able to confirm sessions with ${patientName} until these are completed.`;
        return getAppointmentDetailWarning(message);
      }
    } else {
      return null;
    }
  }

  switch (event.providerAppointment?.billingType) {
    case BillingType.INSURANCE:
      const insuranceWarning = handleInsurance();

      if (insuranceWarning) {
        return insuranceWarning;
      }
      break;
    case BillingType.SELF_PAY:
      const selfPayWarning = handleSelfPay();

      if (selfPayWarning) {
        return selfPayWarning;
      }
      break;
    case BillingType.EAP:
      const eapWarning = handleEAP();
      if (eapWarning) {
        return eapWarning;
      }
      break;
    default:
      return undefined;
  }

  if (
    !patient.privacyPracticesAcknowledgementDate ||
    !patient.assignmentOfBenefitsDate
  ) {
    const message = `You can see ${patientName} but we wanted to give you
    a heads up that they have not acknowledged our standard practice
    forms yet.`;
    return getAppointmentDetailWarning(message);
  }

  return undefined;
}

export function getEventIdentifierClass(event: ProviderEventRead) {
  // remove non-numeric characters as they are not necessary and can be invalid css
  return `rbc-event-${event.virtualId.toString().replace(/[^0-9]/g, '')}`;
}

export function getRecurrenceString(
  recurrenceType: RecurrenceType,
  startDate: string,
  tzid: string
) {
  if (recurrenceType === RecurrenceType.WEEKLY) {
    return getWeeklyRRuleString(startDate, tzid);
  }

  if (recurrenceType === RecurrenceType.BIWEEKLY) {
    return getBiWeeklyRRuleString(startDate, tzid);
  }

  if (recurrenceType === RecurrenceType.DAILY) {
    return getDailyRRuleString(startDate, tzid);
  }

  if (recurrenceType === RecurrenceType.MONTHLY) {
    return getMonthlyRRuleString(startDate, tzid, undefined);
  }

  if (recurrenceType === RecurrenceType.EVERY_THREE_MONTHS) {
    return getEveryThreeMonthsRRuleString(startDate, tzid, undefined);
  }

  if (recurrenceType === RecurrenceType.MONTHLY_LAST) {
    return getMonthlyRRuleString(startDate, tzid, -1);
  }

  if (recurrenceType === RecurrenceType.EVERY_THREE_MONTHS_LAST) {
    return getEveryThreeMonthsRRuleString(startDate, tzid, -1);
  }

  return undefined;
}

function getPaymentDateWithBuffer(event: ProviderEventRead) {
  if (!event?.providerAppointment?.providerPaymentDate) {
    return undefined;
  }

  const statedProviderPaymentDate = moment(
    event.providerAppointment.providerPaymentDate
  );
  const statedProviderPaymentDay = statedProviderPaymentDate.day();
  const providerReceivePaymentByDate = statedProviderPaymentDate.subtract(
    statedProviderPaymentDay > 1 && statedProviderPaymentDay < 6 ? 2 : 3,
    'days'
  );
  const providerReceivePaymentByDateWithBuffer =
    providerReceivePaymentByDate.subtract(1, 'day');
  return providerReceivePaymentByDateWithBuffer;
}

export function getLastDateToUpdateCancellationDetails(
  event: ProviderEventRead
) {
  return getPaymentDateWithBuffer(event);
}

export function getLastDateToUpdateSessionDetails(
  event: ProviderEventRead,
  latestClaim?: ClaimRead
) {
  if (
    event.providerAppointment?.billingType === BillingType.INSURANCE &&
    latestClaim &&
    latestClaim.submissionStatus !== ClaimSubmissionStatus.PENDING
  ) {
    return undefined;
  }
  if (event.providerAppointment?.billingType === BillingType.SELF_PAY) {
    return getPaymentDateWithBuffer(event);
  }

  // claim is filed 48 hours after session_details_confirmed_on
  // we also allow 48 hours for self-pay appointments for consistency
  const claimFilingDate = moment(
    event.providerAppointment?.sessionDetailsConfirmedOn
  ).add(48, 'hours');

  return claimFilingDate.startOf('hour');
}

function getEstimatedLiveDateForInsurancePatient(
  patient: UserRead,
  matchingPatientProviderFrontEndCarrier: ProviderFrontEndCarrierRead | null,
  stateInsuranceCarriers: StateInsuranceCarrierRead[],
  estimatedLiveDates: EstimatedLiveDatesWithCarrierMap
) {
  const patientCarrierId = matchingPatientProviderFrontEndCarrier
    ? matchingPatientProviderFrontEndCarrier.frontEndCarrierId
    : patient.activeUserInsurance?.billingFrontEndCarrierId;
  const patientStates = matchingPatientProviderFrontEndCarrier
    ? [matchingPatientProviderFrontEndCarrier.providerLicenseState.state]
    : patient.patientStates ?? [];

  const matchingDelegatedStateInsuranceCarriers = stateInsuranceCarriers.filter(
    (sic) =>
      sic.insuranceCarrierId === patientCarrierId &&
      sic.isDelegated &&
      (!patientStates.length || patientStates.includes(sic.state))
  );
  const estimatedLiveDatesForDelegatedSICs: EstimatedLiveDateWithCarrier[] =
    matchingDelegatedStateInsuranceCarriers
      .map(
        (sic) =>
          estimatedLiveDates?.[sic.insuranceCarrierId.toString()]?.find(
            (e) =>
              e.providerFrontEndCarrier.providerLicenseState.state === sic.state
          )
      )
      // e is EstimatedLiveDateWithCarrier allows typescript to understand that this filters out undefined from the list
      .filter((e): e is EstimatedLiveDateWithCarrier => !!e);

  // get the earliest in case there are multiple matches in different states. returns undefined if the list is empty
  return minBy(estimatedLiveDatesForDelegatedSICs, (e) =>
    e.estimatedLiveDate.getTime()
  )?.estimatedLiveDate;
}

const getMinScheduleDateForInsurancePatient = (
  patient: UserRead | undefined,
  provider: ProviderRead,
  stateInsuranceCarriers: StateInsuranceCarrierRead[],
  estimatedLiveDates: EstimatedLiveDatesWithCarrierMap,
  minEstimatedLiveDate: EstimatedLiveDateWithCarrier,
  matchingPatientProviderFrontEndCarrier: ProviderFrontEndCarrierRead | null
): Date | undefined => {
  const providerLicenseStatesMatchingPatientStates =
    provider?.activeProviderLicenseStates?.filter(
      (pls) => patient?.patientStates?.includes(pls.state)
    );
  const minProviderLiveOnInPatientStates =
    minBy(providerLicenseStatesMatchingPatientStates, (pls) => pls.liveOn)
      ?.liveOn ?? provider.earliestActiveLiveOn;

  if (!patient?.activeUserInsurance) {
    return getMinScheduledDateForProvider({
      providerLiveOnInPatientState: minProviderLiveOnInPatientStates,
      minEstimatedLiveDate,
    });
  }

  const appointmentReadyDate =
    matchingPatientProviderFrontEndCarrier?.appointmentReadyDate;
  if (
    !!minProviderLiveOnInPatientStates &&
    new Date(minProviderLiveOnInPatientStates).getTime() <= Date.now() &&
    appointmentReadyDate &&
    moment(appointmentReadyDate).toDate().getTime() <= Date.now()
  ) {
    return moment(appointmentReadyDate).toDate();
  }

  return getEstimatedLiveDateForInsurancePatient(
    patient,
    matchingPatientProviderFrontEndCarrier,
    stateInsuranceCarriers,
    estimatedLiveDates
  );
};

export const getMinScheduledDateForProvider = ({
  providerLiveOnInPatientState,
  minEstimatedLiveDate,
}: {
  providerLiveOnInPatientState?: string | null;
  minEstimatedLiveDate: EstimatedLiveDateWithCarrier;
}) => {
  const providerMinScheduleDate = providerLiveOnInPatientState;

  return providerMinScheduleDate
    ? moment(providerMinScheduleDate).startOf('day').toDate()
    : moment(minEstimatedLiveDate.estimatedLiveDate).startOf('day').toDate();
};

export const getMinScheduleDate = (
  eventType: ProviderEventType,
  patient: UserRead | undefined,
  provider: ProviderRead,
  stateInsuranceCarriers: StateInsuranceCarrierRead[],
  estimatedLiveDates: EstimatedLiveDatesWithCarrierMap,
  minEstimatedLiveDate: EstimatedLiveDateWithCarrier,
  matchingPatientProviderFrontEndCarrier: ProviderFrontEndCarrierRead | null
): Date | undefined => {
  if (eventType === ProviderEventType.INTAKE_CALL) {
    // scheduling calls more than 4 months ago is probably a mistake
    return moment().subtract(120, 'day').toDate();
  } else if (eventType === ProviderEventType.APPOINTMENT) {
    if (
      patient?.providerPatients?.find(
        (providerPatient) => providerPatient.providerId === provider?.id
      )?.billingTypeDefault === BillingType.SELF_PAY
    ) {
      // timely filing period for insurance companies, don't want appointments added further back
      return moment().subtract(120, 'days').toDate();
    }
    return getMinScheduleDateForInsurancePatient(
      patient,
      provider,
      stateInsuranceCarriers,
      estimatedLiveDates,
      minEstimatedLiveDate,
      matchingPatientProviderFrontEndCarrier
    );
  }

  // if no patient with insurance or not appointment, return min date for provider
  return getMinScheduledDateForProvider({
    providerLiveOnInPatientState: provider.earliestActiveLiveOn,
    minEstimatedLiveDate,
  });
};

export const dateToUSFormat = (date: string | Date) => {
  return moment(date).format('M/D/YYYY');
};

export const getDefaultProviderAddressIdForEvent = (
  event: ProviderEventRead,
  providerAddresses: ProviderAddressRead[]
) => {
  if (isIntakeCall(event)) {
    return null;
  }
  if (event.telehealth) {
    return -1;
  }
  if (event.providerAddressId) {
    return event.providerAddressId;
  }
  if (event.providerLicenseStateId) {
    return providerAddresses.filter(
      (address) =>
        address.providerLicenseStateId === event.providerLicenseStateId &&
        address.isActive
    )[0]?.id;
  }
  return providerAddresses.filter((address) => address.isActive)[0]?.id;
};

export const getConflictedOpenings = (events: ProviderEventRead[]) => {
  const openings: ProviderEventRead[] = [];
  const all: ProviderEventRead[] = [];

  events.forEach((event) => {
    if (event.type === ProviderEventType.AVAILABILITY) {
      openings.push(event);
    } else {
      all.push(event);
    }
  });

  all.sort(
    (a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf()
  );
  const conflictedOpenings = new Set();

  openings.forEach((opening) => {
    const openingStartDate = moment(opening.startDate);
    const openingEndDate = moment(opening.endDate);

    all.every((event) => {
      const eventStartDate = moment(event.startDate);
      const eventEndDate = moment(event.endDate);

      if (
        event.providerAppointment &&
        event.providerAppointment.status === ProviderAppointmentStatus.CANCELED
      ) {
        return true;
      }

      if (
        openingStartDate.isBefore(eventStartDate) &&
        openingEndDate.isBefore(eventStartDate)
      ) {
        return false;
      }

      if (
        (openingStartDate.isAfter(eventStartDate) &&
          openingStartDate.isBefore(eventEndDate)) ||
        (openingEndDate.isAfter(eventStartDate) &&
          openingEndDate.isBefore(eventEndDate)) ||
        openingStartDate.isSame(eventStartDate) ||
        openingEndDate.isSame(eventEndDate) ||
        (openingStartDate.isBefore(eventStartDate) &&
          openingEndDate.isAfter(eventEndDate))
      ) {
        conflictedOpenings.add(opening.id);
        return false;
      }

      return true;
    });
  });

  return conflictedOpenings;
};

/**
 * Checks whether event a overlaps with the start/end boundaries of
 * event b.
 */
export const eventsHaveOverlap = (
  a: ProviderEventRead,
  b: ProviderEventRead,
  startInclusivity: '[]' | '()' | '[)' | '(]' = '[]',
  endInclusivity: '[]' | '()' | '[)' | '(]' = '[]'
) => {
  const endsDuring = moment(a.endDate).isBetween(
    b.startDate,
    b.endDate,
    null,
    endInclusivity
  );
  const startsDuring = moment(a.startDate).isBetween(
    b.startDate,
    b.endDate,
    null,
    startInclusivity
  );

  return startsDuring || endsDuring;
};

/**
 * Checks if event b contains event a
 */
export const eventContainsEvent = (
  a: ProviderEventRead,
  b: ProviderEventRead
) => {
  const endsDuring = moment(a.endDate).isBetween(
    b.startDate,
    b.endDate,
    null,
    '[]'
  );
  const startsDuring = moment(a.startDate).isBetween(
    b.startDate,
    b.endDate,
    null,
    '[]'
  );

  return startsDuring && endsDuring;
};

export const withoutContainedEvents = (
  events: ProviderEventRead[],
  e: ProviderEventRead
) => events.filter((wh) => !eventContainsEvent(wh, e));

export const isMultiDayEvent = (event: ProviderEventRead) => {
  const startDateDate = moment(event.startDate).date();
  const endDateDate = moment(event.endDate).date();

  const startDateMonth = moment(event.startDate).month();
  const endDateMonth = moment(event.endDate).month();

  const isStartDateDifferentDay =
    startDateDate !== endDateDate ||
    (startDateDate === endDateDate && startDateMonth !== endDateMonth);
  return isStartDateDifferentDay;
};

export const getReferralEventChannelDisplayName = (
  event?: ProviderEventRead
) => {
  if (!event || !isReferral(event)) {
    return null;
  }
  const externalPlatformReferralChannel =
    getExternalPlatformChannelForEventChannel(event.channel);

  if (externalPlatformReferralChannel) {
    return `on ${getExternalPlatformDisplayName(
      externalPlatformReferralChannel
    )}`;
  } else if (event.channel === ProviderEventChannel.ADMIN_PORTAL) {
    return 'over the phone';
  } else {
    return null;
  }
};
