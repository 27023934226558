import React from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ContentText } from '@headway/helix/ContentText';

import { BulkConfirmEventsData, SubmissionStatusMap } from '../../utils/types';
import { SessionDetailsTable } from '../components/SessionDetailsTable';

interface DataEntryStepProps {
  provider: ProviderRead;
  bulkConfirmEventsData: BulkConfirmEventsData;
  submissionStatusByEventVirtualId: SubmissionStatusMap;
  onDismiss: () => void;
}

export const DataEntryStep = ({
  provider,
  bulkConfirmEventsData,
  submissionStatusByEventVirtualId,
  onDismiss,
}: DataEntryStepProps) => {
  return (
    <div>
      <div className="pb-5 pl-5 pt-10 ">
        <ContentText variant="page-title">Sessions</ContentText>
      </div>
      <SessionDetailsTable
        provider={provider}
        bulkConfirmEventsData={bulkConfirmEventsData}
        submissionStatusByEventVirtualId={submissionStatusByEventVirtualId}
        onDismiss={onDismiss}
      />
    </div>
  );
};
