import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { isProviderInState } from '@headway/shared/utils/ProviderLicenseStatesHelper';

import { BscaSpecialties, SectionStepForBscaSpecialty } from './constants';
import { getAllSpecialties } from './helpers';

export const getModalitiesInitialValues = (allSpecialties: string[]) => {
  return {
    ...(allSpecialties.includes(BscaSpecialties.NeuropsychologicalTesting) && {
      bcsaNeuropsychTestingBoardMember: '',
      bcsaNeuropsychTestingCompletedCourses: '',
      bcsaNeuropsychTestingCompletedInternshipOrFellowship: '',
      bcsaNeuropsychTestingCompletedSupervisedExperience: '',
    }),
  };
};

const stepToInitialValuesMap = {
  [SectionStepForBscaSpecialty.Modalities]: getModalitiesInitialValues,
};

export const getInitialValuesForStep = (
  provider: ProviderRead,
  step: SectionStepForBscaSpecialty,
  values: ProviderQuestionnaireRawData
) => {
  if (!isProviderInState(provider, UnitedStates.CALIFORNIA)) return {};

  const allSpecialties = getAllSpecialties(values);
  return stepToInitialValuesMap[step](allSpecialties);
};
