import { FormikProps } from 'formik';
import { useProvider } from 'hooks';
import moment from 'moment';
import React, {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { DocumentRemediationStatus } from '@headway/api/models/DocumentRemediationStatus';
import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderAppointmentAddendumRead } from '@headway/api/models/ProviderAppointmentAddendumRead';
import { ProviderDocumentRemediationWithAuditResults } from '@headway/api/models/ProviderDocumentRemediationWithAuditResults';
import { ProviderProgressNoteLateEntryReason } from '@headway/api/models/ProviderProgressNoteLateEntryReason';
import { ProviderProgressNoteRead } from '@headway/api/models/ProviderProgressNoteRead';
import { ProviderProgressNoteWithErrors } from '@headway/api/models/ProviderProgressNoteWithErrors';
import { TemplateError } from '@headway/api/models/TemplateError';
import { TemplateErrorMessages } from '@headway/api/models/TemplateErrorMessages';
import { ProviderAppointmentApi } from '@headway/api/resources/ProviderAppointmentApi';
import { ProviderProgressNotesApi } from '@headway/api/resources/ProviderProgressNotesApi';
import { useMutation } from '@headway/shared/react-query';

import {
  useProviderAppointmentAddendums,
  useProviderAppointmentAddendumsCache,
} from 'hooks/useProviderAppointmentAddendums';
import {
  useProviderDocumentRemediations,
  useProviderDocumentRemediationsCache,
} from 'hooks/useProviderDocumentRemediations';
import { useProviderEvent } from 'hooks/useProviderEvent';
import { Attachment } from 'views/Patients/AttachmentsList';

import { isDetailsConfirmed } from '../../Calendar/events/util/events';
import { AddendumFormValues } from '../components/forms/AddendumsForm';
import { TemplateErrorConfig } from '../components/forms/ProgressNote/Template/errors';
import {
  GenericTemplate,
  NoteJson,
  TemplateInfo,
} from '../components/forms/ProgressNote/Template/Renderer/types';
import {
  createComponentErrorMap,
  getTemplates,
} from '../components/forms/ProgressNote/Template/utils';
import { validateAndSubmit } from '../components/forms/utils';

export enum ProgressNoteState {
  EDITING,
  SAVED_FOR_LATER,
  SIGN_REQUESTED,
  SIGNED,
  ADDENDUM_EDITING,
  ADDENDUM_EDITING_FREE_TEXT,
}

/**
 * We assume that the user has full access. Once the API calls
 * are made and we have more data, we update the notePermissions
 * in the context accordingly
 */
export enum ProgressNotePermissions {
  READ,
  WRITE,
}

export const NUM_ALLOWED_ADDENDUM = 2;

export interface MetadataInfo {
  noteJsonPrefilledFrom: number | undefined;
}

export const createNoteJsonObject = (
  template: GenericTemplate<unknown>,
  response: any
) => ({
  templateInfo: template.templateInfo,
  response,
});

export const addendumAllowedCount = (
  addendums: ProviderAppointmentAddendumRead[],
  documentRemediation?: ProviderDocumentRemediationWithAuditResults
): number => {
  if (documentRemediation) {
    // if the document is under review, and we already have 2 addendums,
    // confirm that they happened before the review and allow another one.
    if (addendums.length === NUM_ALLOWED_ADDENDUM) {
      const documentRequestDate = moment(documentRemediation.createdOn);

      const addendumHappenedAfterRequest = addendums.some((addendum) => {
        const addendumAttestedOnDate = moment(addendum.attestedOn);
        return addendumAttestedOnDate.isAfter(documentRequestDate);
      });

      if (addendumHappenedAfterRequest) {
        return 0;
      } else {
        return 1;
      }
    }
  }

  return NUM_ALLOWED_ADDENDUM - addendums.length;
};

export const additionalAddendumAllowed = (
  addendums: ProviderAppointmentAddendumRead[],
  documentRemediation?: ProviderDocumentRemediationWithAuditResults
): boolean => {
  if (documentRemediation) {
    // If the document was under review and has been either submitted or not
    // submitted, we don't allow any more addendums
    if (documentRemediation.status !== DocumentRemediationStatus.NEEDS_REVIEW) {
      return false;
    }

    // If the document is under review, and we already have 2 addendums,
    // see if they happened before the review and allow another one.
    if (addendums.length === NUM_ALLOWED_ADDENDUM) {
      const documentRequestDate = moment(documentRemediation.createdOn);

      const addendumHappenedAfterRequest = addendums.some((addendum) => {
        const addendumAttestedOnDate = moment(addendum.attestedOn);
        return addendumAttestedOnDate.isAfter(documentRequestDate);
      });

      if (addendumHappenedAfterRequest) {
        return false;
      } else {
        return true;
      }
    }
  }

  return addendums.length < NUM_ALLOWED_ADDENDUM;
};

export type ProgressNoteStore = {
  // State for saving DB records
  progressNote?: ProviderProgressNoteRead;
  previousProgressNotes?: ProviderProgressNoteRead[];
  addendums: ProviderAppointmentAddendumRead[];
  isProgressNoteLoading: boolean;

  // Maintains the current state of the things for the progress note
  progressNoteState: ProgressNoteState;
  progressNoteErrors: any;
  setProgressNoteErrors: (value: any) => void;
  updateProgressNoteState: (state: ProgressNoteState) => Promise<boolean>;

  // Addendum information
  addendumFormRef: MutableRefObject<
    FormikProps<AddendumFormValues> | undefined
  >;
  addendumContainerRef: MutableRefObject<HTMLDivElement | undefined>;
  addendumWasAdded: boolean;

  // Inferred information about the Progress Note
  notePermissions: ProgressNotePermissions[];
  selectedTemplate: TemplateInfo | undefined;
  setSelectedTemplate: (templateInfo: TemplateInfo) => void;
  progressNoteType: ProgressNoteType;
  setProgressNoteType: (value: ProgressNoteType) => void;
  metadataInfo: MetadataInfo;
  setMetadataInfo: (metadata: MetadataInfo) => void;
  documentRemediation?: ProviderDocumentRemediationWithAuditResults;
  allowedUploadTypes?: string;
  setAllowedUploadTypes: (types: string) => void;

  // API Calls
  getProgressNote: (
    appointmentId: number
  ) => Promise<ProviderProgressNoteRead | undefined>;
  getPreviousProgressNotes: (
    templateId?: number,
    patientId?: number,
    providerId?: number
  ) => Promise<void>;
  createNote: (values: {
    providerId: number;
    patientId: number;
    providerAppointmentId: number;
    noteJson: object;
  }) => Promise<ProviderProgressNoteWithErrors | undefined>;
  updateNoteToEmptyIfNeeded: () => void;
  updateNote: (values: {
    noteJson: object;
    lateEntryReason?: ProviderProgressNoteLateEntryReason;
    lateEntryOtherReason?: string;
  }) => void;
  signNote: () => Promise<boolean>;
  signAddendumNote: () => void;
  onProgressNoteUpdate?: () => void;
};

export const ProgressNoteContext = createContext<ProgressNoteStore>({
  isProgressNoteLoading: false,
  progressNoteErrors: [],
  setProgressNoteErrors: () => {},
  getProgressNote: async () => undefined,
  getPreviousProgressNotes: async () => {},
  addendumWasAdded: false,
  notePermissions: [
    ProgressNotePermissions.READ,
    ProgressNotePermissions.WRITE,
  ],
  progressNoteState: ProgressNoteState.EDITING,
  addendums: [],
  addendumFormRef: { current: undefined },
  addendumContainerRef: { current: undefined },
  updateProgressNoteState: () => new Promise(() => true),
  createNote: async () => new Promise(() => true),
  updateNoteToEmptyIfNeeded: () => {},
  updateNote: () => {},
  signNote: () => new Promise(() => true),
  signAddendumNote: () => new Promise(() => true),
  selectedTemplate: undefined,
  setSelectedTemplate: () => {},
  progressNoteType: ProgressNoteType.TEMPLATE,
  setProgressNoteType: () => {},
  metadataInfo: { noteJsonPrefilledFrom: undefined },
  setMetadataInfo: () => {},
  documentRemediation: undefined,
  onProgressNoteUpdate: () => {},
  allowedUploadTypes: undefined,
  setAllowedUploadTypes: () => {},
});

export interface ProgressNoteContextProviderProps {
  children: ReactNode;
  progressNoteRef: any;
  attachmentRef: MutableRefObject<
    FormikProps<{ attachments: Attachment<string>[] }> | undefined
  >;
  eventVirtualId: string;
  onProgressNoteUpdate?: () => void;
}

export const ProgressNoteContextProvider = ({
  children,
  progressNoteRef,
  attachmentRef,
  eventVirtualId,
  onProgressNoteUpdate,
}: ProgressNoteContextProviderProps) => {
  const provider = useProvider();
  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventVirtualId,
  });
  const providerAppointment = event?.providerAppointment;
  const [progressNote, setProgressNote] = useState<ProviderProgressNoteRead>();
  const [previousProgressNotes, setPreviousProgressNotes] =
    useState<ProviderProgressNoteRead[]>();
  const [progressNoteErrors, setProgressNoteErrors] = useState<any>([]);
  const [notePermissions, setNotePermissions] = React.useState<
    ProgressNotePermissions[]
  >([ProgressNotePermissions.READ, ProgressNotePermissions.WRITE]);
  const [progressNoteState, setProgressNoteState] = useState(
    ProgressNoteState.EDITING
  );
  const [selectedTemplate, setSelectedTemplate] = useState<
    TemplateInfo | undefined
  >();
  const [progressNoteType, setProgressNoteType] = useState(
    ProgressNoteType.TEMPLATE
  );

  const [metadataInfo, setMetadataInfo] = useState<MetadataInfo>({
    noteJsonPrefilledFrom: 0,
  });

  const [allowedUploadTypes, setAllowedUploadTypes] = useState<string>();

  const [addendumWasAdded, setAddendumWasAdded] = useState(false);
  const documentRemediationsQuery = useProviderDocumentRemediations({
    providerAppointmentId: providerAppointment?.id,
  });
  const documentRemediation = (documentRemediationsQuery.data || [])[0];
  const documentRemediationsCache = useProviderDocumentRemediationsCache();

  const appointmentAddendumsQuery = useProviderAppointmentAddendums({
    providerAppointmentId: providerAppointment?.id,
  });
  const appointmentAddendumsCache = useProviderAppointmentAddendumsCache();
  const addendums = appointmentAddendumsQuery.data || [];
  const createAddendumMutation = useMutation(
    async ({
      addendumHtml,
      attachments,
    }: {
      addendumHtml: string;
      attachments: any;
    }) => {
      const providerAppointmentId = providerAppointment?.id;
      if (!providerAppointmentId) {
        throw new Error('Missing provider appointment id');
      }

      return ProviderAppointmentApi.createProviderAppointmentAddendum(
        providerAppointmentId,
        {
          providerAppointmentId,
          providerProgressNoteId: progressNote?.id ?? undefined,
          addendumHtml,
          attachments,
          attestedOn: new Date().toISOString(),
        }
      );
    },
    {
      onSuccess: (newAddendum) => {
        const providerAppointmentId = providerAppointment?.id;
        if (!providerAppointmentId) {
          return;
        }

        appointmentAddendumsCache.setAndInvalidate(
          { providerAppointmentId },
          (addendums) =>
            addendums ? [...addendums, newAddendum] : [newAddendum]
        );
        [
          {
            providerAppointmentId,
          },
          { providerId: provider.id },
        ].forEach((queryKeyArgs) =>
          documentRemediationsCache.setAndInvalidate(
            queryKeyArgs,
            (remediations) => {
              if (!remediations) {
                return undefined;
              }
              return remediations.map((remediation) =>
                remediation.providerAppointmentId === providerAppointmentId
                  ? {
                      ...remediation,
                      status: DocumentRemediationStatus.SUBMITTED_WITH_ADDENDUM,
                    }
                  : remediation
              );
            }
          )
        );
      },
      onError: (err: any) => {
        throw new Error('Error in creating progress note addendum');
      },
    }
  );

  const addendumFormRef = useRef<FormikProps<AddendumFormValues>>();
  const addendumContainerRef = useRef<HTMLDivElement>();

  const [isProgressNoteLoading, setIsProgressNoteLoading] = useState(true);

  const getProgressNote = async (appointmentId: number | undefined) => {
    try {
      if (!appointmentId) {
        return;
      }
      setIsProgressNoteLoading(true);
      let progressNotesFromApi;
      try {
        if (!notePermissions.includes(ProgressNotePermissions.READ)) {
          return;
        }
        progressNotesFromApi =
          await ProviderProgressNotesApi.findProviderProgressNotes({
            appointment_id: appointmentId,
          });
      } catch (e: any) {
        if (e.response.status === 403) {
          setNotePermissions((notePermissions) =>
            notePermissions.filter(
              (notePermission) =>
                notePermission !== ProgressNotePermissions.READ
            )
          );
        }
      }

      const progressNoteType = providerAppointment?.progressNoteType;
      let noteType = progressNoteType ?? ProgressNoteType.TEMPLATE;
      if (
        !isDetailsConfirmed(event) &&
        progressNoteType === ProgressNoteType.NONE
      ) {
        noteType = ProgressNoteType.TEMPLATE;
      }
      setProgressNoteType(noteType);

      if (progressNotesFromApi && progressNotesFromApi.length > 0) {
        const progressNote = progressNotesFromApi[0];
        setProgressNote(progressNote);
        if (progressNote.attestedOn) {
          setProgressNoteState(ProgressNoteState.SIGNED);
        }

        // If the provider switches from a template to either upload or
        // note saved elsewhere we update the noteJson to an empty object.
        // We do a check here in case the progress note type on the provider_appointment
        // model isn't up to date in the current session
        if (Object.keys(progressNote.noteJson ?? {}).length !== 0) {
          setProgressNoteType(ProgressNoteType.TEMPLATE);
        } else {
          if (providerAppointment?.attachments) {
            setProgressNoteType(ProgressNoteType.UPLOAD);
          } else {
            setProgressNoteType(ProgressNoteType.NONE);
            setProgressNoteState(ProgressNoteState.EDITING);
          }
        }
        return progressNote;
      } else {
        setProgressNote(undefined);

        // If there is no provider progress note row, and the appointment
        // was confirmed we know that they either selected upload or
        // note saved elsewhere, and we consider them in a "signed" state
        if (isDetailsConfirmed(event)) {
          if (progressNoteType === ProgressNoteType.NONE) {
            setProgressNoteState(ProgressNoteState.EDITING);
          } else {
            setProgressNoteState(ProgressNoteState.SIGNED);
          }
        } else {
          setProgressNoteState(ProgressNoteState.EDITING);
        }
        return undefined;
      }
    } finally {
      setIsProgressNoteLoading(false);
    }
  };

  useEffect(() => {
    const fetchProgressNote = async () => {
      await getProgressNote(providerAppointment?.id);
    };
    fetchProgressNote().then(() => {
      if (
        documentRemediation?.status === DocumentRemediationStatus.NEEDS_REVIEW
      ) {
        setProgressNoteState(ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerAppointment?.id, documentRemediation]);

  useEffect(() => {
    if (attachmentRef.current) {
      attachmentRef.current.resetForm();
    }
  }, [progressNoteType, attachmentRef]);

  const getPreviousProgressNotes = async (
    templateId?: number,
    patientId?: number,
    providerId?: number
  ) => {
    if (templateId && patientId && providerId) {
      const progressNotesFromApi =
        await ProviderProgressNotesApi.findProviderProgressNotes({
          template_id: templateId,
          patient_id: patientId,
          provider_id: providerId,
        });

      if (progressNotesFromApi) {
        setPreviousProgressNotes(progressNotesFromApi);
      }
    } else {
      // if template doesn't exist, reset previous notes
      setPreviousProgressNotes(undefined);
    }
  };

  const createNote = async ({
    providerId,
    patientId,
    providerAppointmentId,
    noteJson,
    shouldAttestNote = false,
  }: {
    providerId: number;
    patientId: number;
    providerAppointmentId: number;
    noteJson: object;
    shouldAttestNote?: boolean;
  }) => {
    try {
      if (!notePermissions.includes(ProgressNotePermissions.WRITE)) {
        return;
      }

      const createdNote =
        await ProviderProgressNotesApi.createProviderProgressNote({
          provider_progress_note_data: {
            providerId,
            patientId,
            providerAppointmentId,
            lastSavedOn: new Date().toISOString(),
            attestedOn: shouldAttestNote ? new Date().toISOString() : undefined,
            noteJson,
          },
          note_json_prefilled_from: metadataInfo.noteJsonPrefilledFrom,
        });
      if (createdNote.provider_progress_note) {
        setProgressNote(createdNote.provider_progress_note);
      }

      return createdNote;
    } catch (e: any) {
      if (e.response.status === 403) {
        setNotePermissions((notePermissions) =>
          notePermissions.filter(
            (notePermission) => notePermission !== ProgressNotePermissions.WRITE
          )
        );
      }
    }
  };

  const updateNote = async ({
    noteJson,
    lateEntryReason,
    lateEntryOtherReason,
    shouldAttest = false,
  }: {
    noteJson: object;
    lateEntryReason?: ProviderProgressNoteLateEntryReason;
    lateEntryOtherReason?: string;
    shouldAttest?: boolean;
  }) => {
    if (!progressNote) {
      return;
    }

    if (progressNoteState === ProgressNoteState.ADDENDUM_EDITING) {
      return;
    }

    try {
      if (!notePermissions.includes(ProgressNotePermissions.WRITE)) {
        return;
      }

      const updatedNote =
        await ProviderProgressNotesApi.updateProviderProgressNote(
          progressNote.id,
          {
            provider_progress_note_data: {
              noteJson,
              attestedOn: shouldAttest ? new Date().toISOString() : undefined,
              lateEntryReason,
              lateEntryOtherReason,
            },
            note_json_prefilled_from: metadataInfo.noteJsonPrefilledFrom,
          }
        );

      if (updatedNote.provider_progress_note) {
        setProgressNote(updatedNote.provider_progress_note);
      }
    } catch (e: any) {
      if (e.response.status === 403) {
        setNotePermissions((notePermissions) =>
          notePermissions.filter(
            (notePermission) => notePermission !== ProgressNotePermissions.WRITE
          )
        );
      }
    }
  };

  // After signing a progress note template, autoscroll to attestation
  const setScrollToAttestation = () => {
    document.getElementById('attestation')?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
      inline: 'nearest',
    });
  };

  // Autoscrolls to the addendum component
  const setScrollToAddendums = () => {
    if (addendumContainerRef.current) {
      addendumContainerRef.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
        inline: 'nearest',
      });
    }
  };

  /**
   *
   * Handles logic for the Progress Note State change.
   * The caller can send the state they want to be in, and depending on the
   * current progressNoteState it triggers reactions.
   *
   * @param requestedState: ProgressNoteState
   * @returns boolean: Whether or not the requested state change was successful
   */
  const updateProgressNoteState = async (
    requestedState: ProgressNoteState
  ): Promise<boolean> => {
    if (onProgressNoteUpdate) onProgressNoteUpdate();
    switch (requestedState) {
      case ProgressNoteState.SAVED_FOR_LATER:
        const noteJson = await getCurrentNoteJson();
        if (noteJson) {
          const { lateEntryReason, lateEntryOtherReason } =
            getCurrentLateEntryReason();
          await updateNote({ noteJson, lateEntryReason, lateEntryOtherReason });
        }

        setProgressNoteErrors([]);
        progressNoteRef?.current.setErrors({});
        setProgressNoteState(ProgressNoteState.SAVED_FOR_LATER);
        return true;

      case ProgressNoteState.EDITING:
        if (
          progressNote?.attestedOn &&
          progressNoteType === ProgressNoteType.TEMPLATE
        ) {
          setProgressNoteState(ProgressNoteState.ADDENDUM_EDITING);
        } else {
          setProgressNoteState(ProgressNoteState.EDITING);
        }
        return true;

      case ProgressNoteState.ADDENDUM_EDITING:
        setProgressNoteState(ProgressNoteState.ADDENDUM_EDITING);
        return true;

      case ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT:
        //Guard against allowing them to addendum more than twice
        if (!additionalAddendumAllowed(addendums, documentRemediation)) {
          setProgressNoteState(ProgressNoteState.SIGNED);
          return false;
        }

        setProgressNoteState(ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT);
        setTimeout(setScrollToAddendums, 200);
        return true;

      case ProgressNoteState.SIGN_REQUESTED:
        if (progressNoteState === ProgressNoteState.ADDENDUM_EDITING) {
          const isAddendumSigned = await signAddendumNote();
          if (isAddendumSigned) {
            setProgressNoteState(ProgressNoteState.SIGNED);
            setAddendumWasAdded(true);
            return true;
          } else {
            setProgressNoteState(ProgressNoteState.ADDENDUM_EDITING);
            return false;
          }
        } else if (
          progressNoteState === ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT
        ) {
          const addendumForm = await validateAndSubmit(addendumFormRef);

          if (!addendumForm?.success) {
            return false;
          }

          const {
            addendumHtml,
            attachments,
          }: { addendumHtml: string; attachments: Attachment<string>[] } =
            addendumForm.values;

          const isAddendumSigned = await createAddendumMutation.mutateAsync({
            addendumHtml,
            attachments: attachments.map((attachment) => ({
              link: attachment.link,
              name: attachment.name,
            })),
          });

          if (isAddendumSigned) {
            setProgressNoteState(ProgressNoteState.SIGNED);
            setAddendumWasAdded(true);
            return true;
          } else {
            setProgressNoteState(ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT);
            return false;
          }
        } else {
          const progressNoteForm = await validateAndSubmit(progressNoteRef);

          if (!progressNoteForm?.success) {
            return false;
          }

          const isNoteSigned = await signNote();

          if (isNoteSigned) {
            setProgressNoteState(ProgressNoteState.SIGNED);
            setScrollToAttestation();
            return true;
          } else {
            setProgressNoteState(ProgressNoteState.EDITING);
            return false;
          }
        }

      case ProgressNoteState.SIGNED:
        if (progressNoteState === ProgressNoteState.ADDENDUM_EDITING) {
          setProgressNoteState(ProgressNoteState.SIGNED);
          if (progressNoteRef.current) {
            progressNoteRef.current.resetForm();
          }
        } else if (
          progressNoteState === ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT
        ) {
          setProgressNoteState(ProgressNoteState.SIGNED);
        }
        return true;
      default:
        return true;
    }
  };

  /**
   * Handles progress note errors by updating context state and
   * sending the errors to the progressNoteForm
   *
   * @param errors
   * @param isPrefilledNote
   * @returns True if there are errors and False if not
   */
  const handleProgressNoteErrors = async (
    errors: TemplateError[] | undefined,
    isPrefilledNote: boolean
  ): Promise<boolean> => {
    if (!progressNote) {
      return false;
    }

    if (!errors || errors.length === 0) {
      setProgressNoteErrors([]);
      return true;
    }

    if (!isPrefilledNote) {
      errors = errors.filter((error) => {
        if (error.message === TemplateErrorMessages.RESPONSE_NOT_UNIQUE) {
          return false;
        }
        return true;
      });
    }

    if (errors.length === 0) {
      setProgressNoteErrors([]);
      return true;
    }
    setProgressNoteErrors(errors);
    const requestedTemplateId = (progressNote.noteJson as NoteJson).templateInfo
      .id;
    const requestedTemplateVersion = (progressNote.noteJson as NoteJson)
      .templateInfo.version;
    const template = (await getTemplates()).find((t) => {
      return (
        t.templateInfo.id === requestedTemplateId &&
        t.templateInfo.version === requestedTemplateVersion
      );
    });

    const componentErrorMap = createComponentErrorMap(errors as any, template!);
    Object.keys(componentErrorMap).forEach((key) => {
      if (componentErrorMap[key]?.validations)
        componentErrorMap[key] = (
          componentErrorMap[key] as TemplateErrorConfig
        ).validations[0].params[0];
    });

    progressNoteRef?.current.setErrors(componentErrorMap);

    return false;
  };

  /**
   * According to the current template being uses and the
   * form values creates the note_json object
   *
   * @returns a note_json object that contains the response and template_info
   */
  const getCurrentNoteJson = async () => {
    const {
      progressNoteType,
      template,
      previousNote,
      lateEntryReason,
      lateEntryOtherReason,
      ...response
    } = progressNoteRef.current.values;
    const fullTemplate = (await getTemplates()).find((t) => {
      return (
        t.templateInfo.id === selectedTemplate?.id &&
        t.templateInfo.version === selectedTemplate?.version
      );
    });

    if (!fullTemplate) {
      return;
    }

    return createNoteJsonObject(fullTemplate, response);
  };

  const getCurrentLateEntryReason = () => {
    const { lateEntryReason, lateEntryOtherReason } =
      progressNoteRef.current.values;
    return { lateEntryReason, lateEntryOtherReason };
  };

  const signNote = async (): Promise<boolean> => {
    if (!progressNote) {
      return false;
    }

    let currentNoteJson = await getCurrentNoteJson();
    const { lateEntryReason, lateEntryOtherReason } =
      getCurrentLateEntryReason();
    if (progressNote.noteJson && currentNoteJson) {
      await updateNote({
        noteJson: currentNoteJson,
        lateEntryReason,
        lateEntryOtherReason,
      });
    } else {
      currentNoteJson = progressNote.noteJson as any;
    }

    const attestationRequest =
      await ProviderProgressNotesApi.updateProviderProgressNote(
        progressNote.id,
        {
          provider_progress_note_data: {
            noteJson: currentNoteJson,
            attestedOn: new Date().toISOString(),
            lateEntryReason,
            lateEntryOtherReason,
          },
          note_json_prefilled_from: metadataInfo.noteJsonPrefilledFrom,
        }
      );

    setProgressNote(attestationRequest.provider_progress_note);

    return await handleProgressNoteErrors(
      attestationRequest?.errors,
      progressNoteRef?.current.values.previousNote !== null
    );
  };

  const signAddendumNote = async (): Promise<boolean> => {
    if (!progressNote) {
      return false;
    }

    // The addendum doesn't submit the form ever, since
    // autosaving is off. We manually submit the form
    // here in order for the helix components to error states to
    // properly plug in (we need submitForm > 0)
    //
    // Submitting here is a noop since we are in addendum mode
    await progressNoteRef.current.submitForm();

    const currentNoteJson = await getCurrentNoteJson();

    if (!currentNoteJson) {
      return false;
    }

    const noteCreationRequest = await createNote({
      providerId: progressNote?.providerId,
      patientId: progressNote?.patientId,
      providerAppointmentId: progressNote?.providerAppointmentId,
      noteJson: currentNoteJson,
      shouldAttestNote: true,
    });

    return await handleProgressNoteErrors(noteCreationRequest?.errors, false);
  };

  /**
   * Updates the current note to an empty JSON.
   * This is only needed in the following situations:
   *
   * - The provider started filling out a note (new record created),
   * but then switched to uploading a note
   * - The provider started filling out a note (new record created),
   * but then switched to selecting "Note saved elsewhere"
   *
   * In both situations there is originally a provider prog note record
   * created, so we have to set it to {} in order for the ProgressNoteType
   * to fill in correctly
   */
  const updateNoteToEmptyIfNeeded = async (): Promise<boolean> => {
    if (!providerAppointment) {
      return false;
    }

    if (progressNote) {
      await updateNote({
        noteJson: {},
        shouldAttest: true,
      });
    }

    return true;
  };

  const store: ProgressNoteStore = {
    // State for saving DB records
    progressNote,
    isProgressNoteLoading,
    previousProgressNotes,
    addendums,

    // Maintains the current state of the things for the progress note
    progressNoteState,
    updateProgressNoteState,
    progressNoteErrors,
    setProgressNoteErrors,
    addendumFormRef,
    addendumContainerRef,
    onProgressNoteUpdate,

    // Inferred information about the Progress Note
    addendumWasAdded,
    notePermissions,
    selectedTemplate,
    setSelectedTemplate,
    progressNoteType,
    setProgressNoteType,
    metadataInfo,
    setMetadataInfo,
    documentRemediation,
    allowedUploadTypes,
    setAllowedUploadTypes,

    // API Calls
    getProgressNote: useCallback(getProgressNote, []), // eslint-disable-line react-hooks/exhaustive-deps
    getPreviousProgressNotes,
    createNote,
    updateNoteToEmptyIfNeeded,
    updateNote,
    signNote,
    signAddendumNote,
  };

  return (
    <ProgressNoteContext.Provider value={store}>
      {children}
    </ProgressNoteContext.Provider>
  );
};
