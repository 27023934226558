import { getLocalTimeZone, today } from '@internationalized/date';

import { PatientAssessmentRecurrenceCadence } from '@headway/api/models/PatientAssessmentRecurrenceCadence';
import { dateValueToMoment } from '@headway/helix/date';
import { SHORT_ASSESSMENT_NAMES } from '@headway/shared/constants/patientAssessments';
import { getOrdinal } from '@headway/ui/utils/numbers';

import { MaybePendingAssessmentScheduleConfig } from '../../helpers/types';
import { CADENCE_DISPLAY_NAMES } from '../../helpers/utils';
import { AssessmentScheduleStartDateType } from '../AssessmentScheduleInput/constants';
import {
  MultiAssessmentScheduleValue,
  ValidMultiAssessmentScheduleValue,
} from './types';

const DEFAULT_CADENCE = PatientAssessmentRecurrenceCadence.EVERY_30_DAYS;

type InitialMultiAssessmentScheduleValue =
  Partial<MultiAssessmentScheduleValue>;

export const getAssessmentScheduleConfigWithDefaults = ({
  assessmentType,
  cadence = DEFAULT_CADENCE,
  startDate = today(getLocalTimeZone()),
  startDateType = AssessmentScheduleStartDateType.ExactDate,
  selected = true,
  editing = false,
}: InitialMultiAssessmentScheduleValue): MultiAssessmentScheduleValue => ({
  assessmentType,
  cadence,
  startDate,
  selected,
  editing,
  startDateType,
});

export const getAssessmentScheduleConfigFromValue = ({
  selected,
  editing,
  ...config
}: ValidMultiAssessmentScheduleValue): MaybePendingAssessmentScheduleConfig =>
  config;

export const isValidMultiAssessmentScheduleValue = (
  schedule: MultiAssessmentScheduleValue
): schedule is ValidMultiAssessmentScheduleValue =>
  !!schedule.assessmentType &&
  !!schedule.cadence &&
  !!schedule.startDate &&
  !!schedule.startDateType;

export const shouldSubmitMultiAssessmentScheduleValue = (
  schedule: MultiAssessmentScheduleValue
): schedule is ValidMultiAssessmentScheduleValue =>
  isValidMultiAssessmentScheduleValue(schedule) && schedule.selected;

export const createMultiAssessmentScheduleInitialValues = (
  initialValues: InitialMultiAssessmentScheduleValue[]
) => initialValues.map(getAssessmentScheduleConfigWithDefaults);

export const getAssessmentScheduleDisplayText = (
  schedule: MultiAssessmentScheduleValue
) => {
  if (!isValidMultiAssessmentScheduleValue(schedule)) {
    return undefined;
  }

  const typeText = SHORT_ASSESSMENT_NAMES[schedule.assessmentType];
  const cadenceText = CADENCE_DISPLAY_NAMES[schedule.cadence].toLowerCase();
  const startDate = dateValueToMoment(schedule.startDate);
  const startDateText =
    schedule.startDateType ===
    AssessmentScheduleStartDateType.BeforeFirstSession
      ? '2 days before the first session'
      : `on ${startDate.format('MMMM D')}${getOrdinal(startDate.date())}`;

  return `${typeText} assessment ${cadenceText} starting ${startDateText}`;
};
