/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum ProviderTaskType {
    CERTIFICATE_OF_INSURANCE = 'CERTIFICATE_OF_INSURANCE',
    CAQH = 'CAQH',
    REQUIRES_SUPERVISING_PHYSICIAN = 'REQUIRES_SUPERVISING_PHYSICIAN',
    LOGIN_CREDENTIALS_COLLECTION_CAQH = 'LOGIN_CREDENTIALS_COLLECTION_CAQH',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AK = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AK',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AL = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AL',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AR = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AR',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AZ = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_AZ',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CA = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CA',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CO = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CO',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CT = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_CT',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_DC = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_DC',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_DE = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_DE',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_FL = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_FL',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_GA = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_GA',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_HI = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_HI',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IA = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IA',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ID = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ID',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IL = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IL',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IN = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_IN',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_KS = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_KS',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_KY = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_KY',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_LA = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_LA',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MA = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MA',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MD = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MD',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ME = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ME',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MI = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MI',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MN = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MN',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MO = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MO',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MS = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MS',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MT = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_MT',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NC = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NC',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ND = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_ND',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NE = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NE',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NH = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NH',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NJ = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NJ',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NM = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NM',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NV = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NV',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NY = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_NY',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OH = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OH',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OK = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OK',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OR = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_OR',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_PA = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_PA',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_PR = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_PR',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_RI = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_RI',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_SC = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_SC',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_SD = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_SD',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_TN = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_TN',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_TX = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_TX',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_UT = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_UT',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VA = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VA',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VI = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VI',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VT = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_VT',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WA = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WA',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WI = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WI',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WV = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WV',
    LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WY = 'LOGIN_CREDENTIALS_COLLECTION_MEDICAID_WY'
}
