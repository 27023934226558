import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ContentText } from '@headway/helix/ContentText';
import { formatPrice } from '@headway/shared/utils/payments';
import { pluralize } from '@headway/shared/utils/stringFormatting';

export const TotalEarnings = ({
  confirmedEvents,
}: {
  confirmedEvents: ProviderEventRead[];
}) => {
  const totalEarnings = confirmedEvents.reduce((acc, event) => {
    return acc + (event.providerAppointment?.providerPaymentAmount || 0);
  }, 0);
  return (
    <div className="flex rounded border border-solid border-border-primary bg-background-secondary p-5">
      <span className="w-[148px]">
        <ContentText variant="body/medium">Total earnings</ContentText>
      </span>
      <ContentText variant="body">
        {formatPrice(totalEarnings)} for {confirmedEvents.length}{' '}
        {pluralize('session', confirmedEvents.length)}
      </ContentText>
    </div>
  );
};
