import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import partition from 'lodash/partition';
import React, { useState } from 'react';

import { AuthorizationType } from '@headway/api/models/AuthorizationType';
import { InsuranceAuthorizationRead } from '@headway/api/models/InsuranceAuthorizationRead';
import { UserEmployeeAssistanceProgramRead } from '@headway/api/models/UserEmployeeAssistanceProgramRead';
import { UserEmployeeAssistanceProgramApi } from '@headway/api/resources/UserEmployeeAssistanceProgramApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { useShouldShowAnthemEAPExperience } from '@headway/shared/hooks/useShouldShowAnthemEAPExperience';
import { useUser } from '@headway/shared/hooks/useUser';
import { useQuery } from '@headway/shared/react-query';
import { formatPatientName } from '@headway/shared/utils/patient';

import { useInsuranceAuthorizations } from 'hooks/useInsuranceAuthorizations';
import { determineActiveEAPStatus } from 'views/Patients/utils/patientInsuranceStatus';

import { EmployeeAssistanceProgramDetail } from './EmployeeAssistanceProgramDetail';

export type EAP = (
  | InsuranceAuthorizationRead
  | UserEmployeeAssistanceProgramRead
) & {
  memberReferenceNumber?: string;
  authorizationNumber?: string;
};

interface EmployeeAssistanceProgramsProps {
  clientId: number;
}

export const EmployeeAssistancePrograms = ({
  clientId,
}: EmployeeAssistanceProgramsProps) => {
  const [isShowingInactiveAuthorizations, setIsShowingInactiveAuthorizations] =
    useState<boolean>(false);
  const { data: client } = useUser({ userId: clientId });
  const insurance = client?.activeUserInsurance;

  // Legacy EAP objects, pre-Anthem EAP. We still fetch these to support the legacy EAP experience, ex. Cigna EAP.
  const { data: eapAuthorizations, isLoading: areEAPAuthorizationsLoading } =
    useInsuranceAuthorizations({
      query: {
        user_id: clientId,
        authorization_type: AuthorizationType.EAP,
        has_unused_sessions: true,
      },
    });

  // New EAP objects, post-Anthem EAP
  const { data: eaps, isLoading: areEAPsLoading } = useQuery(
    ['find-user-employee-assistance-programs'],
    () => {
      return UserEmployeeAssistanceProgramApi.findUserEmployeeAssistancePrograms(
        {
          user_id: clientId,
        }
      );
    }
  );

  const {
    data: shouldShowAnthemEAPExperience,
    isLoading: shouldShowAnthemEAPExperienceLoading,
  } = useShouldShowAnthemEAPExperience(clientId, undefined, undefined);

  const eapsToUse: EAP[] =
    (shouldShowAnthemEAPExperience ? eaps : eapAuthorizations) || [];

  const [activeEAPs, inactiveEAPs] = partition(eapsToUse, (auth) =>
    determineActiveEAPStatus(auth)
  );

  const carrierId =
    activeEAPs[0]?.frontEndCarrierId || insurance?.frontEndCarrierId;

  if (
    !carrierId ||
    areEAPAuthorizationsLoading ||
    areEAPsLoading ||
    shouldShowAnthemEAPExperienceLoading
  ) {
    return null;
  }

  return (
    <PageSection css={eapCss.container}>
      <h2>
        <SectionHeader>Employee Assistance Programs (EAP)</SectionHeader>
      </h2>
      {eapsToUse ? (
        <>
          {activeEAPs.length > 0 && (
            <div css={eapCss.detailList}>
              {activeEAPs.map((eap) => (
                <EmployeeAssistanceProgramDetail
                  eap={eap}
                  frontEndCarrierId={carrierId}
                  key={eap.id}
                />
              ))}
            </div>
          )}
          {inactiveEAPs.length > 0 && (
            <div css={eapCss.button}>
              <Button
                variant="link"
                onPress={() =>
                  setIsShowingInactiveAuthorizations((current) => !current)
                }
              >
                {isShowingInactiveAuthorizations ? 'Hide' : 'Show'} inactive
                programs
              </Button>
            </div>
          )}
          {inactiveEAPs.length > 0 && isShowingInactiveAuthorizations && (
            <div
              css={eapCss.detailList}
              data-testid="inactive-programs-wrapper"
            >
              {inactiveEAPs.map((eap) => (
                <EmployeeAssistanceProgramDetail
                  eap={eap}
                  frontEndCarrierId={carrierId}
                  key={eap.id}
                />
              ))}
            </div>
          )}
          {activeEAPs.length === 0 && (
            <div>
              <BodyText>
                {formatPatientName(client, {
                  firstNameOnly: true,
                })}{' '}
                doesn't have active EAP benefits on file.
              </BodyText>{' '}
              <Link
                href="https://findheadway.zendesk.com/hc/en-us/articles/7473561009428-Seeing-a-client-with-an-Employee-Assistance-Program-EAP-"
                target="_blank"
                rel="noreferrer"
              >
                Learn how to add EAP benefits
              </Link>
            </div>
          )}
        </>
      ) : (
        <Skeleton variant="rectangular" height={64} />
      )}
    </PageSection>
  );
};

const eapCss = {
  container: css({
    gap: theme.spacing.x3,
  }),
  detailList: css({
    margin: `0 -${theme.spacing.x4}`,
  }),
  button: css({
    justifySelf: 'center',
  }),
};
