import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseGetIsPromsScheduleRequiredQueryKeyArgs {
  providerPatientId?: number;
}

export const getUseIsPromsScheduleRequiredQueryKey = ({
  providerPatientId,
}: UseGetIsPromsScheduleRequiredQueryKeyArgs) =>
  ['get-use-is-proms-schedule-required', providerPatientId] as const;

const {
  useSingleQuery: useGetIsPromsScheduleRequired,
  useListQuery: useGetIsPromsScheduleRequiredList,
  useCachedQuery: useGetIsPromsScheduleRequiredCache,
} = createBasicApiHooks(
  getUseIsPromsScheduleRequiredQueryKey,
  ({ providerPatientId }) =>
    ProviderPatientApi.getIsPromsScheduleRequired(providerPatientId as number),
  ({ providerPatientId }) => !!providerPatientId,
  () => 'Failed to fetch provider patient proms schedule requirement'
);

export {
  useGetIsPromsScheduleRequired,
  useGetIsPromsScheduleRequiredList,
  useGetIsPromsScheduleRequiredCache,
};
