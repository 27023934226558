import { getLocalTimeZone } from '@internationalized/date';
import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import uniqueId from 'lodash/uniqueId';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { GetSessionsByProviderSessionSummary } from '@headway/api/models/GetSessionsByProviderSessionSummary';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { SessionDetailsConfirmabilityResponse } from '@headway/api/models/SessionDetailsConfirmabilityResponse';
import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { IconButton } from '@headway/helix/IconButton';
import { IconCaretDown } from '@headway/helix/icons/CaretDown';
import { IconCaretRight } from '@headway/helix/icons/CaretRight';
import { IconCheck } from '@headway/helix/icons/Check';
import { IconDotsThree } from '@headway/helix/icons/DotsThree';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { Menu, MenuItem, MenuTrigger } from '@headway/helix/Menu';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import { VisuallyHidden } from '@headway/helix/utils';
import { BULK_SESSION_CONFIRMATION } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { getProviderDisplayFirstAndLastWithPrenomial } from '@headway/shared/utils/providers';

import { useSessionDetailsEditability } from 'hooks/useSessionDetailsEditability';

import { BULK_CONFIRM_EVENT_URL_SEARCH_KEY } from '../utils/constants';
import { ProviderNameData } from '../utils/types';
import { SessionAction } from './SessionActionModal';

interface SessionDisclosureProps {
  provider: ProviderNameData;
  sessions: GetSessionsByProviderSessionSummary[];
  /** Confirmability is assumed to be loading if undefined. */
  confirmabilityMap:
    | { [virtualId: string]: SessionDetailsConfirmabilityResponse }
    | undefined;
  onSessionAction: (
    action: SessionAction,
    session: GetSessionsByProviderSessionSummary
  ) => void;
}

const SHOW_MORE_LIMIT = 3;

const timeFormatter = Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
  timeZone: getLocalTimeZone(),
});

export const SessionDisclosure = ({
  provider,
  sessions,
  confirmabilityMap,
  onSessionAction,
}: SessionDisclosureProps) => {
  const isBulkConfirmationEnabled = useFlag(BULK_SESSION_CONFIRMATION, false);
  const [contentId] = useState(uniqueId('session-disclosure-'));
  const [isShowingSessions, setIsShowingSessions] = useState(true);
  const [isShowingMore, setIsShowingMore] = useState(false);

  const showMoreButtonVisible = sessions.length > SHOW_MORE_LIMIT;
  const confirmableSessionVirtualIds = sessions
    .filter(
      (s) =>
        s.providerAppointmentStatus === ProviderAppointmentStatus.SCHEDULED &&
        new Date(s.startDate) < new Date() &&
        confirmabilityMap &&
        confirmabilityMap[s.providerEventVirtualId]?.isSessionConfirmable
    )
    .map((s) => s.providerEventVirtualId);
  const numConfirmableSessions = confirmableSessionVirtualIds.length;

  const params = new URLSearchParams(
    confirmableSessionVirtualIds.map((id) => [
      BULK_CONFIRM_EVENT_URL_SEARCH_KEY,
      id,
    ])
  );
  const bulkConfirmUrl = `/practice/billing/confirm?${params.toString()}`;

  return (
    <section className="mx-auto rounded border border-solid border-border-primary">
      <div className="flex h-12 items-center gap-2 whitespace-nowrap bg-background-secondary px-5 py-2">
        <button
          aria-expanded={isShowingSessions}
          aria-controls={contentId}
          onClick={() =>
            setIsShowingSessions((isShowingSessions) => !isShowingSessions)
          }
          className="-mx-1 flex cursor-pointer items-center gap-2 rounded border-0 bg-background-secondary p-0 px-1 ring-border-focus focus:ring-2"
        >
          {isShowingSessions ? <IconCaretDown /> : <IconCaretRight />}
          <h2>
            <ContentText variant="body-large/medium">
              {getProviderDisplayFirstAndLastWithPrenomial(provider)}
            </ContentText>
          </h2>
        </button>
        <ContentText variant="body-small">
          {sessions.length} session{sessions.length === 1 ? '' : 's'}
        </ContentText>
        <div className="ml-auto">
          {sessions.every(
            (s) =>
              s.providerAppointmentStatus ===
              ProviderAppointmentStatus.DETAILS_CONFIRMED
          ) ? (
            <ContentText
              variant="body-small/medium"
              color="foreground/compliance"
            >
              <span className="flex items-end gap-1">
                <IconCheck />
                All scheduled sessions confirmed
              </span>
            </ContentText>
          ) : (
            numConfirmableSessions > 0 &&
            isBulkConfirmationEnabled && (
              <LinkButton
                elementType="a"
                variant="secondary"
                component={RouterLink}
                to={bulkConfirmUrl}
              >
                Confirm scheduled sessions ({numConfirmableSessions})
              </LinkButton>
            )
          )}
        </div>
      </div>
      {isShowingSessions && (
        <div
          id={contentId}
          className={clsx(
            'flex flex-col',
            'border-0 border-t border-solid border-border-primary',
            !showMoreButtonVisible && '-mb-[1px]'
          )}
        >
          <Table
            aria-label={`Sessions for ${getProviderDisplayFirstAndLastWithPrenomial(
              provider
            )}`}
          >
            <TableHeader>
              <Column width={300}>Sessions</Column>
              <Column width={134}>Time</Column>
              <Column>Status</Column>
              <Column align="right">
                <VisuallyHidden>Actions</VisuallyHidden>
              </Column>
            </TableHeader>
            <TableBody>
              {sessions
                .slice(0, isShowingMore ? undefined : 3)
                .map((session) => {
                  const confirmability =
                    confirmabilityMap &&
                    confirmabilityMap[session.providerEventVirtualId];
                  return (
                    <Row key={session.providerEventVirtualId}>
                      <Cell>
                        Session with{' '}
                        <Link
                          target="_blank"
                          href={`/clients/${session.patientUserId}?provider_id=${session.providerId}`}
                        >
                          {session.patientFullName}
                        </Link>
                      </Cell>
                      <Cell>
                        {timeFormatter.format(new Date(session.startDate))}
                      </Cell>
                      <Cell>
                        {confirmability === undefined ? (
                          <Skeleton
                            variant="rectangular"
                            width={50}
                            height={20}
                          />
                        ) : (
                          <StatusBadge
                            confirmability={confirmability}
                            session={session}
                          />
                        )}
                      </Cell>
                      <Cell>
                        <div className="flex flex-row-reverse">
                          <div className="flex gap-2">
                            {confirmability !== undefined &&
                              !confirmability.isSessionConfirmable &&
                              session.providerAppointmentStatus !==
                                ProviderAppointmentStatus.DETAILS_CONFIRMED && (
                                <LinkButton
                                  variant="link"
                                  target="_blank"
                                  href={`/calendar?event_id=${
                                    session.providerEventId
                                  }&start_date=${moment(
                                    session.startDate
                                  ).toISOString()}&provider_id=${
                                    session.providerId
                                  }`}
                                >
                                  See billing issue
                                </LinkButton>
                              )}
                            <div className="-my-2">
                              <MenuTrigger>
                                <IconButton
                                  variant="transparent"
                                  aria-label="More actions"
                                >
                                  <IconDotsThree />
                                </IconButton>
                                <ContextMenu
                                  session={session}
                                  onSessionAction={onSessionAction}
                                  confirmability={confirmability}
                                />
                              </MenuTrigger>
                            </div>
                          </div>
                        </div>
                      </Cell>
                    </Row>
                  );
                })}
            </TableBody>
          </Table>
          {showMoreButtonVisible && (
            <div className="grid h-[46px]">
              <Button
                variant="link"
                onPress={() =>
                  setIsShowingMore((isShowingMore) => !isShowingMore)
                }
              >
                {isShowingMore ? 'Show less' : 'Show more'}
              </Button>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

interface ContextMenuProps {
  session: GetSessionsByProviderSessionSummary;
  onSessionAction: (
    action: SessionAction,
    session: GetSessionsByProviderSessionSummary
  ) => void;
  confirmability: SessionDetailsConfirmabilityResponse | undefined;
}
const ContextMenu = ({
  session,
  onSessionAction,
  confirmability,
}: ContextMenuProps) => {
  const items: React.ReactElement[] = [];

  const { data: editability, isLoading: isEditabilityLoading } =
    useSessionDetailsEditability(session.providerEventVirtualId, {
      enabled:
        session.providerAppointmentStatus ===
        ProviderAppointmentStatus.DETAILS_CONFIRMED,
    });

  if (
    session.providerAppointmentStatus === ProviderAppointmentStatus.SCHEDULED
  ) {
    if (
      confirmability?.isSessionConfirmable &&
      new Date(session.startDate) < new Date()
    ) {
      items.push(
        <MenuItem key={SessionAction.CONFIRM}>Confirm session</MenuItem>
      );
    }
    items.push(<MenuItem key={SessionAction.CANCEL}>Cancel session</MenuItem>);
  }
  if (
    session.providerAppointmentStatus ===
    ProviderAppointmentStatus.DETAILS_CONFIRMED
  ) {
    if (editability === SessionDetailsEditabilityStatus.ALLOWED) {
      items.push(
        <MenuItem key={SessionAction.MODIFY}>Modify session details</MenuItem>
      );
      items.push(
        <MenuItem key={SessionAction.UNDO_CONFIRMATION}>
          Undo session confirmation
        </MenuItem>
      );
    } else {
      items.push(
        <MenuItem key={SessionAction.VIEW} textValue="View session details">
          {isEditabilityLoading ? (
            <Skeleton variant="rectangular" height={22} width={150} />
          ) : (
            'View session details'
          )}
        </MenuItem>
      );
    }
  }

  return (
    <Menu onAction={(key) => onSessionAction(key as SessionAction, session)}>
      {items}
    </Menu>
  );
};

export const StatusBadge = ({
  confirmability,
  session,
}: {
  confirmability: SessionDetailsConfirmabilityResponse;
  session: GetSessionsByProviderSessionSummary;
}) => {
  if (
    session.providerAppointmentStatus ===
    ProviderAppointmentStatus.DETAILS_CONFIRMED
  ) {
    return <Badge variant="positive">Confirmed</Badge>;
  }
  if (!confirmability.isSessionConfirmable) {
    return <Badge variant="warning">Not billable</Badge>;
  }
  if (new Date(session.startDate) > new Date()) {
    return <Badge variant="neutral">Upcoming</Badge>;
  }
  return <Badge variant="neutral">Ready to confirm</Badge>;
};
