import React, { useContext, useState } from 'react';

import { FrontEndCarrierNested } from '@headway/api/models/FrontEndCarrierNested';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ModalContent } from '@headway/helix/Modal';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { getUseUserQueryKey, useUser } from '@headway/shared/hooks/useUser';
import { useQueryClient } from '@headway/shared/react-query';
import { formatPatientName } from '@headway/shared/utils/patient';
import { Modal } from '@headway/ui/Modal';
import { ProviderFrontEndCarrierContext } from '@headway/ui/providers/ProviderFrontEndCarrierProvider';

import {
  getUseEligibilityLookupQueryKey,
  useEligibilityLookup,
} from 'hooks/useEligibilityLookup';
import { getUseUserInsuranceQueryKey } from 'hooks/useUserInsurance';
import {
  getAuthorizationInstructionsModalTitle,
  InsuranceAuthorizationInstructionsModalContent,
} from 'views/Patients/InsuranceAuthorizationInstructionsModalContent';
import {
  InsuranceFormUpdate,
  PatientInsuranceForm,
} from 'views/Patients/PatientInsuranceForm';

import { InsuranceVerifyMatch } from './InsuranceVerifyMatch';

interface InsuranceModalProps {
  clientId: number;
  open: boolean;
  onClose: () => void;
}

export const InsuranceModal = ({
  clientId,
  open,
  onClose,
}: InsuranceModalProps) => {
  const { data: client } = useUser({ userId: clientId });
  const {
    data: latestEligibilityLookup,
    isLoading: isLatestEligibilityLookupLoading,
  } = useEligibilityLookup({
    eligibilityLookupId:
      client?.activeUserInsurance?.latestEligibilityLookup?.id,
  });
  const providerFrontEndCarrierContext = useContext(
    ProviderFrontEndCarrierContext
  );
  const [
    carrierToShowAuthzInstructionsFor,
    setCarrierToShowAuthzInstructionsFor,
  ] = useState<FrontEndCarrierRead | FrontEndCarrierNested>();
  const [isInsuranceVerifyMatch, setIsInsuranceVerifyMatch] =
    useState<boolean>(false);

  const queryClient = useQueryClient();

  const onUpdateSuccess = (update: InsuranceFormUpdate) => {
    const useUserQueryKey = getUseUserQueryKey({ userId: clientId });
    queryClient.setQueryData(useUserQueryKey, update.userRead);
    queryClient.invalidateQueries(useUserQueryKey);

    const useUserInsuranceQueryKey = getUseUserInsuranceQueryKey({
      userInsuranceId: update.userInsurance?.id,
    });
    queryClient.setQueryData(useUserInsuranceQueryKey, update.userInsurance);
    queryClient.invalidateQueries(useUserInsuranceQueryKey);

    const useEligibilityLookupQueryKey = getUseEligibilityLookupQueryKey({
      eligibilityLookupId: update.eligibilityLookup?.id,
    });
    queryClient.setQueryData(
      useEligibilityLookupQueryKey,
      update.eligibilityLookup
    );
    queryClient.invalidateQueries(useEligibilityLookupQueryKey);
    if (update.eligibilityLookup?.isFuzzyMatched) {
      setIsInsuranceVerifyMatch(true);
      return;
    }
    onClose();
  };

  if (!client || isLatestEligibilityLookupLoading) {
    return null;
  }

  const patientNameFormatted = formatPatientName(client, {
    firstNameOnly: true,
  });

  const title = carrierToShowAuthzInstructionsFor
    ? getAuthorizationInstructionsModalTitle(patientNameFormatted)
    : isInsuranceVerifyMatch
    ? 'Did you mean this?'
    : `${patientNameFormatted}'s Insurance`;

  return (
    <Modal title={title} open={open} onClose={onClose}>
      {carrierToShowAuthzInstructionsFor ? (
        <InsuranceAuthorizationInstructionsModalContent
          carrier={carrierToShowAuthzInstructionsFor}
          clientDisplayName={patientNameFormatted}
          closeModal={onClose}
        />
      ) : isInsuranceVerifyMatch ? (
        <InsuranceVerifyMatch
          patient={client}
          onEdit={() => {
            setIsInsuranceVerifyMatch(false);
          }}
          onSuccess={onClose}
        />
      ) : (
        <PatientInsuranceForm
          providerFrontEndCarrierContext={providerFrontEndCarrierContext}
          onUpdateSuccess={onUpdateSuccess}
          showAuthorizationInstructionsModal={
            setCarrierToShowAuthzInstructionsFor
          }
          onCancel={onClose}
          patient={client}
          currentEligibility={latestEligibilityLookup}
        />
      )}
    </Modal>
  );
};
