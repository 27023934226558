import { useProviderPatient, useSuggestedDiagnosisCodes } from 'hooks';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFindPastSessionsQueryKeyArgs } from '~/legacy/hooks/useFindPastSessionsQueryKeyArgs';
import { useFindProviderEvents } from '~/legacy/hooks/useFindProviderEvents';
import { convertDateToTimeFields } from '~/legacy/utils/date';

import { BillingType } from '@headway/api/models/BillingType';
import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { InsuranceAuthorizationApi } from '@headway/api/resources/InsuranceAuthorizationApi';
import { CIGNA_EAP_CODE } from '@headway/shared/constants/cptCodes';
import { CONTROLLED_SUBSTANCE_DATA_COLLECTION } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { logException } from '@headway/shared/utils/sentry';
import {
  CptCodeOption,
  getCptOptionsForProvider,
} from '@headway/ui/CPTCodeInput';
import { diagnosisOptions } from '@headway/ui/DiagnosisCodeInput';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';

import { SessionDetailsFormV2Values } from '../../components/forms/SessionDetails/SessionDetailsFormV2';
import { getMatchingCodeOptionsInOrder } from '../../components/forms/SessionDetails/utils';

export interface useSessionDetailsInitialValuesProps {
  event: ProviderEventRead | undefined;
  isEventLoading: boolean | undefined;
  patient: UserRead | undefined;
  provider: ProviderRead;
  updateAppointmentAddressState?: (
    providerAddressId?: number | undefined | null,
    appointmentLocationPatientAddressId?: number | undefined | null
  ) => void;
}

export const useSessionDetailsInitialValues = ({
  event,
  isEventLoading,
  patient,
  provider,
  updateAppointmentAddressState,
}: useSessionDetailsInitialValuesProps) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const isControlledSubstanceDataCollectionEnabled = useFlag(
    CONTROLLED_SUBSTANCE_DATA_COLLECTION,
    false
  );
  const [patientHasCignaEapAuthorization, setPatientHasCignaEapAuthorization] =
    useState<boolean>(false);

  const { data: providerPatient, isLoading: isProviderPatientLoading } =
    useProviderPatient({
      providerId: provider.id,
      patientId: patient?.id,
    });
  const { providerAddresses, isLoading: areAddressesLoading } = useContext(
    ProviderAddressContext
  );
  const pastSessionsQueryKeyArgs = useFindPastSessionsQueryKeyArgs({
    providerId: provider.id,
    clientId: patient?.id!,
    limit: 10,
  });
  const {
    data: lastConfirmedTelehealthAppointment,
    isLoading: isLoadingLastConfirmedTelehealthAppointment,
  } = useFindProviderEvents(pastSessionsQueryKeyArgs, {
    select: ({ data }) =>
      data?.find((appointment) => appointment.telehealth === true)
        ?.providerAppointment,
    enabled:
      !event?.providerAppointment?.appointmentLocationPatientAddressId &&
      event?.providerAppointment?.status ===
        ProviderAppointmentStatus.SCHEDULED,
    refetchOnWindowFocus: false,
    retry: false,
    onError: (error) => {
      logException(error);
    },
  });
  const {
    data: lastConfirmedAppointment,
    isLoading: isLoadingLastConfirmedAppointment,
  } = useFindProviderEvents(pastSessionsQueryKeyArgs, {
    select: ({ data }) => data[0],
    enabled:
      provider.isPrescriber && isControlledSubstanceDataCollectionEnabled,
    refetchOnWindowFocus: false,
    retry: false,
    onError: (error) => {
      logException(error);
    },
  });
  const {
    data: suggestedDiagnosisCodes,
    isLoading: isLoadingSuggestedDiagnosisCodes,
  } = useSuggestedDiagnosisCodes(provider, patient!);
  const initialDiagnosisCodes = event?.providerAppointment?.diagnosisCodes
    ?.length
    ? event?.providerAppointment.diagnosisCodes
    : suggestedDiagnosisCodes;

  const checkEapAuthorization = useCallback(
    async (dateOfService: string) => {
      // We do not need special logic if the patient does not have Cigna, or the provider is a prescriber
      if (
        patient?.activeUserInsurance?.frontEndCarrierId !==
          FrontEndCarrierIdentifier.CIGNA ||
        !!provider.isPrescriber
      ) {
        setPatientHasCignaEapAuthorization(false);
      } else {
        try {
          const authorizations =
            await InsuranceAuthorizationApi.findInsuranceAuthorizations({
              user_insurance_id: patient.activeUserInsuranceId!,
              date_of_service: dateOfService,
              has_unused_sessions: true,
            });

          const res =
            authorizations &&
            authorizations.some((auth) => auth.isEapAuthorization);
          setPatientHasCignaEapAuthorization(res);
        } catch (err) {
          logException(err);
        }
      }
    },
    [
      patient?.activeUserInsurance?.frontEndCarrierId,
      patient?.activeUserInsuranceId,
      provider?.isPrescriber,
    ]
  );

  useEffect(() => {
    if (event?.startDate) {
      checkEapAuthorization(new Date(event?.startDate).toISOString());
    }
  }, [checkEapAuthorization, event?.startDate]);

  const isLoading =
    isEventLoading ||
    areAddressesLoading ||
    isLoadingLastConfirmedTelehealthAppointment ||
    isLoadingLastConfirmedAppointment ||
    isProviderPatientLoading ||
    isLoadingSuggestedDiagnosisCodes;

  const {
    initialProviderAddressId,
    initialAppointmentLocationPatientAddressId,
  } = useMemo(() => {
    const locationFieldValue = () => {
      return event?.telehealth
        ? -1
        : event?.providerAddressId ||
            providerAddresses.filter(
              (address: ProviderAddressRead) =>
                address.isActive && address.providerId === provider.id
            )[0]?.id;
    };

    const initialProviderAddressId = event?.telehealth
      ? -1
      : locationFieldValue();

    const initialAppointmentLocationPatientAddressId =
      event?.providerAppointment?.status === ProviderAppointmentStatus.SCHEDULED
        ? event?.providerAppointment?.appointmentLocationPatientAddressId ??
          lastConfirmedTelehealthAppointment?.appointmentLocationPatientAddressId
        : locationFieldValue() === -1
        ? event?.providerAppointment?.appointmentLocationPatientAddressId
        : undefined;

    return {
      initialProviderAddressId,
      initialAppointmentLocationPatientAddressId,
    };
  }, [
    event,
    providerAddresses,
    lastConfirmedTelehealthAppointment,
    provider.id,
  ]);

  useEffect(() => {
    if (updateAppointmentAddressState) {
      updateAppointmentAddressState(
        initialProviderAddressId,
        initialAppointmentLocationPatientAddressId
      );
    }
  }, [
    initialProviderAddressId,
    initialAppointmentLocationPatientAddressId,
    updateAppointmentAddressState,
  ]);

  const initialValues: SessionDetailsFormV2Values = useMemo(() => {
    const cptOptions = getCptOptionsForProvider(provider);
    const providerAppointment = event?.providerAppointment;

    const lastControlledSubstanceAttestation =
      lastConfirmedAppointment?.providerAppointment
        ?.controlledSubstanceAttestation;
    const initialControlledSubstanceAttestationValues =
      providerAppointment?.controlledSubstanceAttestation
        ? {
            didPrescribeControlledSubstance:
              providerAppointment.controlledSubstanceAttestation
                .prescribedSubstances.length > 0,
            controlledSubstanceAttestation: {
              prescribedSubstances:
                providerAppointment.controlledSubstanceAttestation
                  .prescribedSubstances || [],
              notes: providerAppointment.controlledSubstanceAttestation.notes,
              exceedsRecommendedDosage:
                providerAppointment.controlledSubstanceAttestation
                  .exceedsRecommendedDosage,
            },
          }
        : lastControlledSubstanceAttestation
        ? {
            didPrescribeControlledSubstance:
              lastControlledSubstanceAttestation.prescribedSubstances.length >
              0,
            controlledSubstanceAttestation: {
              prescribedSubstances:
                lastControlledSubstanceAttestation.prescribedSubstances || [],
              notes: lastControlledSubstanceAttestation.notes,
              exceedsRecommendedDosage:
                lastControlledSubstanceAttestation.exceedsRecommendedDosage,
            },
          }
        : {
            didPrescribeControlledSubstance: undefined,
            controlledSubstanceAttestation: {
              prescribedSubstances: [],
              notes: undefined,
              exceedsRecommendedDosage: undefined,
            },
          };

    const initialStartDate = event?.startDate
      ? moment(event?.startDate).toISOString()
      : moment().toISOString();

    return {
      providerAddressId: initialProviderAddressId,
      appointmentLocationPatientAddressId:
        initialAppointmentLocationPatientAddressId,
      startDate: initialStartDate,
      startDateTime: initialStartDate,
      endDate: event?.endDate
        ? moment(event?.endDate).toISOString()
        : moment().toISOString(),
      duration:
        event?.startDate && event?.endDate
          ? moment
              .duration(moment(event.endDate).diff(event.startDate))
              .asMinutes()
              .toString()
          : undefined,
      exactStartTime: providerAppointment?.exactStartTime
        ? convertDateToTimeFields(new Date(providerAppointment?.exactStartTime))
        : undefined,
      exactEndTime: providerAppointment?.exactEndTime
        ? convertDateToTimeFields(new Date(providerAppointment?.exactEndTime))
        : undefined,
      billingType: providerAppointment?.billingType,
      patientResponsibilityAmount:
        providerAppointment?.billingType === BillingType.SELF_PAY
          ? providerAppointment?.patientResponsibilityAmount ||
            providerPatient?.selfPayRateDefault
          : // We only allow editing patient_responsibility for self-pay
            // If billing switches from insurance to self-pay, show self-pay default
            providerPatient?.selfPayRateDefault,
      cptCodes: patientHasCignaEapAuthorization
        ? cptOptions.filter((o: CptCodeOption) => o.value === CIGNA_EAP_CODE)
        : providerAppointment?.cptCodes
        ? getMatchingCodeOptionsInOrder(
            providerAppointment.cptCodes,
            cptOptions
          )
        : [],
      diagnosisCodes: initialDiagnosisCodes
        ? getMatchingCodeOptionsInOrder(
            initialDiagnosisCodes,
            diagnosisOptions(true)
          )
        : [],
      telehealth: event?.telehealth,
      telehealthPlatform:
        // If current appointment has telehealthPlatform, use that
        providerAppointment?.telehealthPlatform ??
        // Otherwise prefill with last confirmed telehealth appointment
        lastConfirmedTelehealthAppointment?.telehealthPlatform,
      telehealthProviderState:
        // If current appointment has telehealthProviderState, use that
        providerAppointment?.telehealthProviderState ??
        // Otherwise prefill with last confirmed telehealth appointment
        lastConfirmedTelehealthAppointment?.telehealthProviderState ??
        // Or provider's home state
        provider.stateOfResidence,
      telehealthAttestation:
        // If current appointment has the attestion, use that
        // We don't prefill with the previous appointment's attestation, since it's a new session
        providerAppointment?.telehealthAttestation,
      ...initialControlledSubstanceAttestationValues,
      prescriberPsychotherapyStartTime:
        providerAppointment?.prescriberPsychotherapyStartTime
          ? convertDateToTimeFields(
              new Date(providerAppointment?.prescriberPsychotherapyStartTime)
            )
          : undefined,
      prescriberPsychotherapyEndTime:
        providerAppointment?.prescriberPsychotherapyEndTime
          ? convertDateToTimeFields(
              new Date(providerAppointment?.prescriberPsychotherapyEndTime)
            )
          : undefined,
    };
  }, [
    event,
    lastConfirmedAppointment?.providerAppointment
      ?.controlledSubstanceAttestation,
    lastConfirmedTelehealthAppointment?.telehealthPlatform,
    lastConfirmedTelehealthAppointment?.telehealthProviderState,
    provider,
    providerPatient?.selfPayRateDefault,
    patientHasCignaEapAuthorization,
    initialDiagnosisCodes,
    initialAppointmentLocationPatientAddressId,
    initialProviderAddressId,
  ]);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
    }
  }, [isLoading, initialValues, isInitialized]);

  return { isInitialized: isInitialized, initialValues: initialValues };
};
