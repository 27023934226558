import { ReactNode } from 'react';

import { NestedPatientReadForCalendar } from '@headway/api/models/NestedPatientReadForCalendar';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserRead } from '@headway/api/models/UserRead';
import { Link } from '@headway/helix/Link';
import { theme } from '@headway/helix/theme';
import { formatPatientName } from '@headway/shared/utils/patient';

/**
 * Generates a helpful message to show the provider about a frozen patient.
 * @param  {Array<UserFreezeReason>} freezeReasons An array of reasons for freezing this patient.
 * @param  {string} patientName? An optional patient name to make the resulting message more personal.
 * @returns string A helpful message to display to a provider about a frozen client
 * @throws Error when freezeReasons has no recognizable reason to freeze the patient.
 */
export const getFreezeMessage = (
  freezeReasons: Array<UserFreezeReason>,
  patient?: NestedPatientReadForCalendar | UserRead
): ReactNode => {
  const clientName =
    formatPatientName(patient, {
      firstNameOnly: true,
    }) || 'This client';
  const freezeReasonsSet = new Set(freezeReasons);

  if (
    freezeReasonsSet.has(
      UserFreezeReason.COORDINATION_OF_BENEFITS_DEPRECATED
    ) ||
    freezeReasonsSet.has(UserFreezeReason.COB_NEEDS_PAYER_CONFIRMATION)
  ) {
    return `${clientName}'s session details can't be confirmed because of an issue with their coordination of benefits.`;
  } else if (freezeReasonsSet.has(UserFreezeReason.AWAITING_COB_USER_ACTION)) {
    return `${
      patient?.activeUserInsurance?.frontEndCarrierName ||
      'This client’s insurer'
    } let us know that this is a secondary insurance.  Currently, we can only submit claims to primary insurance so please ask your client to update their info. If you have new insurance details, you can also update it on your own.`;
  } else if (freezeReasonsSet.has(UserFreezeReason.TERMED_PLAN)) {
    return (
      <>
        It looks like this insurance is no longer active. Please make sure{' '}
        {clientName} adds active insurance or{' '}
        <Link
          component="a"
          color="inherit"
          href="https://help.headway.co/hc/en-us/articles/9565199998484-Private-Pay"
          target="_blank"
          css={{
            color: `inherit !important`,
            '&:hover': {
              color: `${theme.color.system.disabledGray} !important`,
            },
          }}
        >
          switches to private pay
        </Link>
        . For appointment held on or after June 1, they will be automatically
        charged the full cost of any sessions held under expired insurance.
      </>
    );
  } else if (
    freezeReasonsSet.has(UserFreezeReason.AWAITING_AUTOPAY_CX_ACTION)
  ) {
    return 'Client will not be charged for any session cost corrections while Headway is investigating an issue.';
  } else if (freezeReasonsSet.has(UserFreezeReason.OON_PLAN)) {
    return `${clientName}'s Headway account is paused because their plan is out of network.`;
  } else if (
    freezeReasonsSet.has(UserFreezeReason.OTHER) ||
    freezeReasonsSet.has(UserFreezeReason.PATIENT_ELIGIBILITY_NOT_FOUND)
  ) {
    return `${clientName}'s Headway account is paused because there's an issue verifying their insurance plan coverage.`;
  } else if (freezeReasonsSet.has(UserFreezeReason.WAIVED_SESSION_MAX_HIT)) {
    return `Your client has used all their waived sessions and future sessions will no longer be covered.  If they’d like to continue attending, ask them if they’d consider using private pay.`;
  } else {
    throw Error(
      `Given ${freezeReasons} did not include a freeze reason with a corresponding message.`
    );
  }
};

export const getCoordinationOfBenefitsFreezeMessage = (
  patient?: NestedPatientReadForCalendar | UserRead
): ReactNode =>
  getFreezeMessage([UserFreezeReason.COB_NEEDS_PAYER_CONFIRMATION], patient);

export const getOutOfNetworkFreezeMessage = (
  patient?: NestedPatientReadForCalendar | UserRead
): ReactNode => getFreezeMessage([UserFreezeReason.OON_PLAN], patient);

export const getCoordinationOfBenefitsFreezeAwaitingUserMessage = (
  patient?: NestedPatientReadForCalendar | UserRead
): ReactNode => {
  return getFreezeMessage([UserFreezeReason.AWAITING_COB_USER_ACTION], patient);
};

export const getTermedPlanFreezeMessage = (
  patient?: NestedPatientReadForCalendar | UserRead
): ReactNode => getFreezeMessage([UserFreezeReason.TERMED_PLAN], patient);

export const getOtherFreezeMessage = (
  patient?: NestedPatientReadForCalendar | UserRead
): ReactNode => getFreezeMessage([UserFreezeReason.OTHER], patient);

export const getWaivedSessionMaxHitMessage = (
  patient?: NestedPatientReadForCalendar | UserRead
): ReactNode => {
  return getFreezeMessage([UserFreezeReason.WAIVED_SESSION_MAX_HIT], patient);
};

export const hasCoordinationOfBenefitsFreeze = (
  freezeReasons?: Array<UserFreezeReason>
): boolean =>
  freezeReasons !== undefined &&
  (freezeReasons.includes(
    UserFreezeReason.COORDINATION_OF_BENEFITS_DEPRECATED
  ) ||
    freezeReasons.includes(UserFreezeReason.COB_NEEDS_PAYER_CONFIRMATION));

export const hasCoordinationOfBenefitsAwaitingUserFreeze = (
  freezeReasons?: Array<UserFreezeReason>
): boolean =>
  freezeReasons !== undefined &&
  freezeReasons.includes(UserFreezeReason.AWAITING_COB_USER_ACTION);

export const hasOutOfNetworkFreeze = (
  freezeReasons?: Array<UserFreezeReason>
): boolean =>
  freezeReasons !== undefined &&
  freezeReasons.includes(UserFreezeReason.OON_PLAN);

export const hasTermedPlanFreeze = (
  freezeReasons?: Array<UserFreezeReason>
): boolean =>
  freezeReasons !== undefined &&
  freezeReasons.includes(UserFreezeReason.TERMED_PLAN);

export const hasOtherFreeze = (
  freezeReasons?: Array<UserFreezeReason>
): boolean =>
  freezeReasons !== undefined &&
  (freezeReasons.includes(UserFreezeReason.OTHER) ||
    freezeReasons.includes(UserFreezeReason.PATIENT_ELIGIBILITY_NOT_FOUND));

export const hasWaivedSessionMaxHitFreeze = (
  freezeReasons?: Array<UserFreezeReason>
): boolean =>
  freezeReasons !== undefined &&
  freezeReasons.includes(UserFreezeReason.WAIVED_SESSION_MAX_HIT);

export const isEveryFreezeOON = (
  freezeReasons?: Array<UserFreezeReason>
): boolean => {
  return (
    freezeReasons !== undefined &&
    freezeReasons !== null &&
    freezeReasons.every(
      (freezeReason) => freezeReason === UserFreezeReason.OON_PLAN
    )
  );
};
