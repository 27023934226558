import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { Link } from '@headway/helix/Link';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { formatPatientName } from '@headway/shared/utils/patient';

import CollagingDesk from 'assets/img/collaging-desk.png';

interface PromsScheduleRequireModalProps {
  client: UserRead;
  promsScheduleRequiredModalOpen: boolean;
  setPromsScheduleRequiredModalOpen: (open: boolean) => void;
  carrierName: string;
}

export const PromsScheduleRequireModal = ({
  client,
  promsScheduleRequiredModalOpen,
  setPromsScheduleRequiredModalOpen,
  carrierName,
}: PromsScheduleRequireModalProps) => {
  const clientName = formatPatientName(client, { firstNameOnly: true });
  return (
    <Modal
      title={`Assessments will be automatically sent to ${clientName}`}
      isOpen={promsScheduleRequiredModalOpen}
      onDismiss={() => setPromsScheduleRequiredModalOpen(false)}
    >
      <ModalContent>
        <div className="gap-3">
          <img src={CollagingDesk} alt="Collaging desk" />
          <div className="mt-5 flex flex-col gap-5">
            <ContentText variant="body">
              Because {clientName} is on the {carrierName} plan, they must
              receive at least one initial and follow-up assessment. By default,{' '}
              <strong>they will receive PHQ-9 and GAD-7</strong> every 30 days
              starting 2 days before their first session. You can choose
              different assessments and send cadences from{' '}
              <Link
                href={`/clients/${client.id}/assessments`}
                target="_blank"
                rel="noopener noreferrer"
              >
                their assessments tab.
              </Link>
            </ContentText>
            <ContentText variant="body/medium">
              Why are assessments sent for this plan?
            </ContentText>
            <ContentText>
              {' '}
              Headway constantly advocates to get the best possible rates and
              agreements for our entire network, in recognition of the quality
              care and impact you deliver. While assessments do not
              independently demonstrate clinical effectiveness, they help plans
              like Horizon understand member engagement and can offer you and
              your clients an additional way to track progress.
            </ContentText>
            <ContentText>
              For more details on the program,{' '}
              <Link
                href="https://help.headway.co/hc/en-us/articles/31110875396628"
                target="_blank"
                rel="noopener noreferrer"
              >
                visit our Help Center
              </Link>
              .
            </ContentText>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          variant="primary"
          onPress={() => setPromsScheduleRequiredModalOpen(false)}
        >
          Got it
        </Button>
      </ModalFooter>
    </Modal>
  );
};
