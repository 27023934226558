import { useCallback, useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useUpdateProviderProgressNoteMutation } from '~/legacy/mutations/providerProgressNote';

import { ProgressNoteFormV2Values } from '../../components/forms/ProgressNote/ProgressNoteFormV2';
import {
  createNoteJsonObject,
  getTemplate,
  getTemplateIdAndVersionFromFormValue,
} from '../../components/forms/ProgressNote/utils';
import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

export const useSaveProgressNoteAsDraft = () => {
  const {
    progressNote,
    switchToNoteSavedAsDraft,
    templates,
    trackButtonClicked,
  } = useContext(AppointmentConfirmationContextV2);
  const { clearErrors } = useFormContext();
  const progressNoteValues: ProgressNoteFormV2Values = useWatch({
    name: 'progressNote',
  });
  const updateProviderProgressNoteMutation =
    useUpdateProviderProgressNoteMutation();

  const saveProgressNoteAsDraft = useCallback(async () => {
    if (!progressNote) return;

    const selectedTemplate = getTemplate(
      templates,
      ...getTemplateIdAndVersionFromFormValue(progressNoteValues.template)
    );

    if (!selectedTemplate) return;

    const {
      template,
      progressNoteType,
      previousNote,
      lateEntryReason,
      lateEntryOtherReason,
      ...response
    } = progressNoteValues;
    const noteJson = createNoteJsonObject(selectedTemplate, response);

    await updateProviderProgressNoteMutation.mutateAsync({
      progressNoteId: progressNote.id,
      update: {
        provider_progress_note_data: {
          noteJson: noteJson,
          lateEntryReason: lateEntryReason,
          lateEntryOtherReason: lateEntryOtherReason,
        },
        note_json_prefilled_from: progressNoteValues.previousNoteId,
      },
    });

    trackButtonClicked('Save and Complete Later Button Clicked');
    clearErrors();
    switchToNoteSavedAsDraft();
  }, [
    progressNote,
    updateProviderProgressNoteMutation,
    progressNoteValues,
    switchToNoteSavedAsDraft,
    templates,
    clearErrors,
    trackButtonClicked,
  ]);

  return { saveProgressNoteAsDraft };
};
