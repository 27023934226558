import { DateValue, getLocalTimeZone, today } from '@internationalized/date';
import { Formik } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useReplacePatientAssessmentRecurrenceSchedulesMutation } from '~/legacy/mutations/assessments';
import { AssessmentScheduleInput } from '~/legacy/views/Clients/Assessments/components/AssessmentScheduleInput';
import {
  getRelevantAssessmentTypeBasedOnDXCodes,
  useReplacePatientAssessmentRecurrenceSchedulesSideEffects,
} from '~/legacy/views/Clients/Assessments/helpers/utils';

import { PatientAssessmentRecurrenceCadence } from '@headway/api/models/PatientAssessmentRecurrenceCadence';
import { PatientAssessmentRecurrenceScheduleCreateRequest } from '@headway/api/models/PatientAssessmentRecurrenceScheduleCreateRequest';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { momentToDateValue } from '@headway/helix/date';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { IconCheck } from '@headway/helix/icons/Check';
import { IconCheckCircle } from '@headway/helix/icons/CheckCircle';
import { Link } from '@headway/helix/Link';
import { ListRow } from '@headway/helix/List';
import { Modal, ModalContent } from '@headway/helix/Modal';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { toasts } from '@headway/helix/Toast';
import { PROMS_ONLY_RATE_BOOST } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';

import TherapistImage from 'assets/img/therapist-study.png';
import { ManageAssessmentsModal } from 'views/Clients/Assessments/components/ManageAssessmentsModal';
import {
  INCENTIVE_BONUS_AMOUNT_DOLLARS,
  RateBoostBannerCopyVariant,
} from 'views/Incentives/constants';

import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

export interface ScheduleConfig {
  cadence: PatientAssessmentRecurrenceCadence;
  startDate: DateValue;
}

export type AssessmentTypeAndConfig = {
  assessmentType: PatientAssessmentType | 'other';
  scheduleConfig?: ScheduleConfig;
};

export type SendAssessmentsModalFormValues = {
  assessmentTypeAndConfig: AssessmentTypeAndConfig;
};
interface SwoopUpsellModalV2Props {
  open: boolean;
  onClose: () => void;
}

function UncheckedDashedCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <circle
        cx="10"
        cy="10"
        r="8.5"
        stroke="currentColor"
        stroke-dasharray="2,2"
      />
    </svg>
  );
}

export const SwoopUpsellModalV2 = ({
  open,
  onClose,
}: SwoopUpsellModalV2Props) => {
  const { patient, event, providerPatient } = useContext(
    AppointmentConfirmationContextV2
  );
  const [isManageAssementsModalOpened, setIsManageAssessmentsModalOpen] =
    useState(false);

  const promsOnlyRateBoostEnabled = useFlag(PROMS_ONLY_RATE_BOOST, false);

  const replacePatientAssessmentRecurrenceSchedulesMutation =
    useReplacePatientAssessmentRecurrenceSchedulesMutation({
      sideEffects: useReplacePatientAssessmentRecurrenceSchedulesSideEffects(),
    });

  const relevantAssessmentTypeBasedOnDXCodes =
    getRelevantAssessmentTypeBasedOnDXCodes(
      event?.providerAppointment?.diagnosisCodes
    );

  useEffect(() => {
    if (open) {
      trackEvent({
        name: 'Rate Boost Banner Viewed',
        properties: {
          copyVariant: RateBoostBannerCopyVariant.FULL_PAGE,
        },
      });
    }
  }, [open]);

  const initialValues: SendAssessmentsModalFormValues = useMemo(() => {
    const now = momentToDateValue(moment());
    return {
      assessmentTypeAndConfig: {
        assessmentType: relevantAssessmentTypeBasedOnDXCodes,
        scheduleConfig: {
          cadence: PatientAssessmentRecurrenceCadence.EVERY_30_DAYS,
          startDate: now,
        },
      },
    };
  }, [relevantAssessmentTypeBasedOnDXCodes]);

  const handleSubmit = async (values: SendAssessmentsModalFormValues) => {
    if (values.assessmentTypeAndConfig.assessmentType === 'other') {
      // The provider wants to choose another assessment, we open the manage assessments modal
      setIsManageAssessmentsModalOpen(true);
    } else {
      // The provider decided to send an assessment right there and then with the one
      // suggested based on the dx codes
      if (providerPatient && values.assessmentTypeAndConfig.scheduleConfig) {
        const schedules: PatientAssessmentRecurrenceScheduleCreateRequest[] = [
          {
            assessmentType: values.assessmentTypeAndConfig.assessmentType,
            cadence: values.assessmentTypeAndConfig.scheduleConfig.cadence,
            startDate:
              values.assessmentTypeAndConfig.scheduleConfig.startDate.toString(),
          },
        ];
        await replacePatientAssessmentRecurrenceSchedulesMutation.mutateAsync({
          providerPatientId: providerPatient.id,
          body: {
            currentLocalDate: today(getLocalTimeZone()).toString(),
            schedules,
          },
        });
        toasts.add('Assessments saved', { variant: 'positive' });
        trackEvent({
          name: 'Save and Send Client Assessments Button Clicked',
          properties: {
            patientUserId: providerPatient.userId,
            providerId: providerPatient.providerId,
            assessmentTypeList: schedules.map(
              (schedule) => schedule.assessmentType
            ),
          },
        });
        onClose();
      }
    }
  };

  return promsOnlyRateBoostEnabled ? (
    <div>
      <Formik<SendAssessmentsModalFormValues>
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, submitForm, isSubmitting }) => {
          return (
            <Form name="send-assessments-form-for-given-patient">
              <Modal
                title={
                  <div className="flex items-center gap-2">
                    <IconCheckCircle
                      className="w-20"
                      css={{
                        color: theme.color.system.green,
                      }}
                    />
                    <SectionHeader>
                      <strong>
                        Your session with {formatPatientName(patient)} is
                        confirmed
                      </strong>
                    </SectionHeader>
                  </div>
                }
                onDismiss={onClose}
                isOpen={open}
                variant="fullscreen"
                layout="contained"
              >
                <ModalContent>
                  <div className="mx-auto flex w-[920px] flex-col gap-[28px]">
                    <div className="mt-6 flex">
                      <div
                        className="flex h-[128px] flex-1 flex-col rounded-l-[6px] p-5"
                        css={{ backgroundColor: theme.color.hue.lightBlue }}
                      >
                        <BodyText>
                          <strong>
                            Earn a ${INCENTIVE_BONUS_AMOUNT_DOLLARS} rate boost
                            for this session — and all your sessions going
                            forward
                          </strong>
                        </BodyText>
                        <BodyText>
                          Have your client complete an assessment once every 30
                          days and earn ${INCENTIVE_BONUS_AMOUNT_DOLLARS} for{' '}
                          <strong>each and every session</strong> with that
                          client — on top of your standard rate.
                        </BodyText>
                      </div>
                      <img
                        className="h-[128px] w-[180px] rounded-r-[6px] object-cover object-left-top"
                        src={TherapistImage}
                        alt="therapist"
                      />
                    </div>
                    <div
                      className="flex flex-1 flex-col"
                      css={{
                        '.hlx-radio-root': {
                          gridTemplateColumns: 'auto !important',
                        },
                      }}
                    >
                      <div className="mb-2">
                        <BodyText>
                          <strong>
                            Assessments help you tailor treatment and give
                            clients a better sense of progress:
                          </strong>
                        </BodyText>
                      </div>
                      <FormControl
                        aria-labelledby="send-assessments-radio-group-label"
                        name="assessmentTypeAndConfig"
                        component={RadioGroup}
                        value={
                          values.assessmentTypeAndConfig.assessmentType ===
                          'other'
                            ? 'other'
                            : 'selectedAssessment'
                        }
                        onChange={(value: string) => {
                          if (value === 'other') {
                            setFieldValue('assessmentTypeAndConfig', {
                              assessmentType: 'other',
                              scheduleConfig: {
                                cadence:
                                  values.assessmentTypeAndConfig.scheduleConfig
                                    ?.cadence,
                                startDate:
                                  values.assessmentTypeAndConfig.scheduleConfig
                                    ?.startDate,
                              },
                            });
                          } else {
                            setFieldValue('assessmentTypeAndConfig', {
                              assessmentType:
                                relevantAssessmentTypeBasedOnDXCodes,
                              scheduleConfig: {
                                cadence:
                                  values.assessmentTypeAndConfig.scheduleConfig
                                    ?.cadence,
                                startDate:
                                  values.assessmentTypeAndConfig.scheduleConfig
                                    ?.startDate,
                              },
                            });
                          }
                        }}
                      >
                        <Radio value="selectedAssessment">
                          <div className="flex flex-col gap-2 pb-3">
                            <div>
                              <BodyText>
                                Send [
                                {relevantAssessmentTypeBasedOnDXCodes.valueOf()}
                                ] (suggested assessment based on{' '}
                                {formatPatientName(patient)} diagnosis)
                              </BodyText>
                            </div>
                            <AssessmentScheduleInput name="assessmentTypeAndConfig.scheduleConfig" />
                          </div>
                        </Radio>
                        <Radio value="other">
                          <BodyText>
                            Choose another assessment to send to{' '}
                            {formatPatientName(patient)}
                          </BodyText>
                        </Radio>
                      </FormControl>
                    </div>
                    <div
                      css={{
                        display: 'flex',
                        gap: theme.spacing.x3,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        variant="secondary"
                        onPress={onClose}
                        disabled={isSubmitting}
                      >
                        Skip
                      </Button>
                      <Button
                        type="submit"
                        onPress={submitForm}
                        disabled={isSubmitting}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </ModalContent>
              </Modal>
            </Form>
          );
        }}
      </Formik>
      {patient && (
        <ManageAssessmentsModal
          isOpen={isManageAssementsModalOpened}
          onDismiss={onClose}
          clientId={patient.id}
          isIntakeFlow={false}
        />
      )}
    </div>
  ) : (
    <div>
      <Modal
        title="Rate boost opportunity"
        onDismiss={onClose}
        isOpen={open}
        variant="fullscreen"
        layout="contained"
      >
        <ModalContent>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing.x2,
              marginTop: theme.spacing.x5,
            }}
          >
            <IconCheckCircle
              css={{
                color: theme.color.system.green,
              }}
              width={20}
            />
            <BodyText>
              <strong>
                Your session with {formatPatientName(patient)} is confirmed
              </strong>
            </BodyText>
          </div>
          <div css={{ display: 'flex', marginTop: theme.spacing.x4 }}>
            <div
              css={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                height: 128,
                backgroundColor: theme.color.hue.lightBlue,
                padding: theme.spacing.x5,
                borderTopLeftRadius: 6,
                borderBottomLeftRadius: 6,
              }}
            >
              <BodyText>
                <strong>
                  You’ve met 1 of 2 criteria to earn a rate boost for clients in
                  Michigan
                </strong>
              </BodyText>
              <BodyText>
                Have your client complete at least one assessment every 30 days
                to unlock a ${INCENTIVE_BONUS_AMOUNT_DOLLARS} rate boost per
                session.{' '}
                <Link href={'/incentives/rate-boost'} target="_blank">
                  Learn more
                </Link>
              </BodyText>
            </div>
            <img
              width={180}
              height={128}
              src={TherapistImage}
              alt="therapist"
              css={{
                objectFit: 'cover',
                objectPosition: '100% 0',
                borderTopRightRadius: 6,
                borderBottomRightRadius: 6,
              }}
            />
          </div>
          <ul css={{ paddingInlineStart: 0 }}>
            <ListRow
              children={
                <>
                  <div
                    css={{
                      marginLeft: theme.spacing.x7,
                      marginBottom: theme.spacing.x1,
                    }}
                  >
                    <Badge variant="info">You met this criteria</Badge>
                  </div>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: theme.spacing.x2,
                    }}
                  >
                    <IconCheck
                      css={{ color: theme.color.primary.blue }}
                      width={20}
                    />
                    <BodyText>
                      Upload a copy of your notes OR use Headway templates for
                      session notes
                    </BodyText>
                  </div>
                </>
              }
            />
            <ListRow
              children={
                <>
                  <div
                    css={{
                      marginLeft: theme.spacing.x7,
                      marginBottom: theme.spacing.x1,
                    }}
                  >
                    <Badge variant="neutral">
                      Remaining rate boost criteria
                    </Badge>
                  </div>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: theme.spacing.x2,
                    }}
                  >
                    <UncheckedDashedCircle
                      css={{ color: theme.color.system.gray }}
                    />
                    <BodyText>
                      Your client completes any Headway assessments 30 days
                      before or after a session.*
                    </BodyText>
                  </div>
                </>
              }
            />
          </ul>
          <div
            css={{
              display: 'flex',
              gap: theme.spacing.x3,
              justifyContent: 'flex-end',
            }}
          >
            <Button variant="secondary" onPress={onClose}>
              Skip
            </Button>
            <Button
              type="submit"
              onPress={() => {
                setIsManageAssessmentsModalOpen(true);
              }}
            >
              Send assessments to {formatPatientName(patient)}
            </Button>
          </div>
        </ModalContent>
      </Modal>
      {patient && (
        <ManageAssessmentsModal
          isOpen={isManageAssementsModalOpened}
          onDismiss={onClose}
          clientId={patient.id}
          isIntakeFlow={false}
        />
      )}
    </div>
  );
};
