/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';
import { stringify } from 'qs';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { ProviderProgressNoteCreateWithMetadataInfo } from '../models/ProviderProgressNoteCreateWithMetadataInfo';
import { ProviderProgressNoteRead } from '../models/ProviderProgressNoteRead';
import { ProviderProgressNoteUpdateWithMetadataInfo } from '../models/ProviderProgressNoteUpdateWithMetadataInfo';
import { ProviderProgressNoteWithErrors } from '../models/ProviderProgressNoteWithErrors';
import { SOAPGenerationRequest } from '../models/SOAPGenerationRequest';
import { TemplateError } from '../models/TemplateError';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderProgressNotesApiResource {

    /**
     * Ai Feedback
     * 
     * @param component 
     * @param feedback 
     
     */
    public aiFeedback(query?: { component: string, feedback: string }, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-progress-notes/ai-feedback';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Provider Progress Note
     * 
     * @param providerProgressNoteCreateWithMetadataInfo 
     
     */
    public createProviderProgressNote(body: ProviderProgressNoteCreateWithMetadataInfo, axiosConfig?: AxiosRequestConfig): Promise<ProviderProgressNoteWithErrors> {
        const apiRoute = '/provider-progress-notes';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Find Provider Progress Notes
     * 
     * @param templateId 
     * @param templateVersion 
     * @param providerId 
     * @param patientId 
     * @param appointmentId 
     
     */
    public findProviderProgressNotes(query?: { template_id?: number, template_version?: number, provider_id?: number, patient_id?: number, appointment_id?: number }, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderProgressNoteRead>> {
        const apiRoute = '/provider-progress-notes';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Generate Soap
     * 
     * @param sOAPGenerationRequest 
     
     */
    public generateSoap(body: SOAPGenerationRequest, axiosConfig?: AxiosRequestConfig): Promise<string> {
        const apiRoute = '/provider-progress-notes/generate_soap';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Progress Note
     * 
     * @param id 
     
     */
    public getProviderProgressNote(id: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderProgressNoteRead> {
        const apiRoute = '/provider-progress-notes/{id}';
        const reqPath = apiRoute
                    .replace('{' + 'id}', String(id));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Progress Note Errors
     * 
     * @param providerAppointmentId 
     * @param noteJson 
     * @param noteJsonPrefilledFrom 
     
     */
    public getProviderProgressNoteErrors(query?: { provider_appointment_id: number, note_json: string, note_json_prefilled_from?: number }, axiosConfig?: AxiosRequestConfig): Promise<Array<TemplateError>> {
        const apiRoute = '/provider-progress-notes/errors';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Valid Provider Progress Note
     * 
     * @param providerAppointmentId 
     
     */
    public getValidProviderProgressNote(providerAppointmentId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderProgressNoteRead> {
        const apiRoute = '/provider-progress-notes/valid/{provider_appointment_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_appointment_id}', String(providerAppointmentId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Retrieve List Of Empty Free Text Template
     * 
     * @param file 
     * @param minimumCharactersCount 
     
     */
    public retrieveListOfEmptyFreeTextTemplate(file: any, query?: { minimum_characters_count?: number }, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-progress-notes/retrieve-list-of-empty-free-text-template';
        const reqPath = apiRoute;
        let reqFormParams = {
            file: file
        };
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            data: stringify(reqFormParams),

        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Progress Note
     * 
     * @param id 
     * @param providerProgressNoteUpdateWithMetadataInfo 
     
     */
    public updateProviderProgressNote(id: number, body: ProviderProgressNoteUpdateWithMetadataInfo, axiosConfig?: AxiosRequestConfig): Promise<ProviderProgressNoteWithErrors> {
        const apiRoute = '/provider-progress-notes/{id}';
        const reqPath = apiRoute
                    .replace('{' + 'id}', String(id));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderProgressNotesApi = new ProviderProgressNotesApiResource();
