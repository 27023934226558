import type { Dictionary } from 'lodash';
import isMatch from 'lodash/isMatch';
import moment from 'moment';
import { useContext } from 'react';

import { CalendarEventType } from '@headway/api/models/CalendarEventType';
import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { GetPaginatedProviderEventForCalendarResponse } from '@headway/api/models/GetPaginatedProviderEventForCalendarResponse';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderCalendarRead } from '@headway/api/models/ProviderCalendarRead';
import { ProviderCalendarSyncStatus } from '@headway/api/models/ProviderCalendarSyncStatus';
import { ProviderCalendarType } from '@headway/api/models/ProviderCalendarType';
import { ProviderEventChannel } from '@headway/api/models/ProviderEventChannel';
import { ProviderEventCreate } from '@headway/api/models/ProviderEventCreate';
import { ProviderEventReadForCalendar } from '@headway/api/models/ProviderEventReadForCalendar';
import { ProviderEventRecurrenceUpdateResponse } from '@headway/api/models/ProviderEventRecurrenceUpdateResponse';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderCalendarApi } from '@headway/api/resources/ProviderCalendarApi';
import { Query, useQuery, useQueryClient } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';

import { UseFindConcreteProviderEventsQueryKeyArgs } from 'hooks/useFindProviderEvents';
import {
  isGetProviderEventsForCalendarQueryKey,
  useGetProviderEventsForCalendarCache,
  UseGetProviderEventsForCalendarQueryKeyArgs,
} from 'hooks/useGetProviderEventsForCalendar';
import { useProvider } from 'hooks/useProvider';
import {
  UpdateProviderEventMutationArgs,
  UpdateRecurringInstanceMutationArgs,
} from 'mutations/providerEvent';
import { SideEffectsBuilder } from 'mutations/utils';
import { PatientsContext } from 'providers/PatientsProvider';

export const taskDateRangeStart = moment().subtract(90, 'day').toDate();
export const taskDateRangeEnd = new Date();
export const getPastScheduledAppointmentsQueryKeyArgs = (
  providerId: number
): UseGetProviderEventsForCalendarQueryKeyArgs => ({
  providerId,
  date_range_start: taskDateRangeStart.toISOString(),
  date_range_end: taskDateRangeEnd.toISOString(),
  event_types: [ProviderEventType.APPOINTMENT],
  appointment_statuses: [ProviderAppointmentStatus.SCHEDULED],
});

export const patientBookedEventDateRangeStart = moment().toDate();
export const patientBookedEventDateRangeEnd = moment().add(2, 'weeks').toDate();
const PATIENT_BOOKED_EVENT_CHANNELS = [
  ProviderEventChannel.PATIENT_PORTAL,
  ProviderEventChannel.ADMIN_PORTAL,
  ProviderEventChannel.AUTOBOOK_HC_REFERRAL,
  ProviderEventChannel.HEALTHCARE_REFERRAL,
];
export const getExpandedPatientBookedEventsQueryKeyArgs = (
  providerId: number
): UseGetProviderEventsForCalendarQueryKeyArgs => ({
  providerId,
  date_range_start: patientBookedEventDateRangeStart.toISOString(),
  date_range_end: patientBookedEventDateRangeEnd.toISOString(),
  event_types: [ProviderEventType.APPOINTMENT, ProviderEventType.INTAKE_CALL],
  channels: PATIENT_BOOKED_EVENT_CHANNELS,
  appointment_statuses: [ProviderAppointmentStatus.SCHEDULED],
});

export const SYNC_IN_PROGRESS_REFETCH_INTERVAL = 1000 * 20;

export const getExternalCalendarsQueryKey = (providerId: number) => [
  'provider-calendars',
  providerId,
  { type: ProviderCalendarType.EXTERNAL, is_active: true },
];

export const useProviderCalendarsQuery = (
  providerId: number,
  { enabled } = { enabled: true }
) => {
  const queryKey = getExternalCalendarsQueryKey(providerId);
  const queryClient = useQueryClient();

  // If any of the calendars we loaded had a syncStatus
  // of PENDING then we want to enable polling until we no longer
  // have a PENDING sync.
  const latestCalendarData: ProviderCalendarRead[] =
    queryClient.getQueryData(queryKey) ?? [];

  const query = useQuery(
    queryKey,
    () => {
      return ProviderCalendarApi.getProviderCalendars({
        provider_id: providerId,
        type: ProviderCalendarType.EXTERNAL,
        is_active: true,
      });
    },
    {
      enabled,
      refetchInterval: latestCalendarData.some(
        (c) => c.syncStatus === ProviderCalendarSyncStatus.PENDING
      )
        ? SYNC_IN_PROGRESS_REFETCH_INTERVAL
        : false,
    }
  );

  return query;
};

export const getWorkingHoursQueryKeyArgs = (
  providerId: number
): UseFindConcreteProviderEventsQueryKeyArgs => {
  return {
    event_types: [ProviderEventType.AVAILABILITY],
    provider_id: providerId,
  };
};

/**
 * Returns a partial query key that matches all calendar range queries for a provider, across all
 * date ranges.
 */
export const getPartialCalendarRangeQueryKeyArgs = (
  providerId: number
): Omit<
  UseGetProviderEventsForCalendarQueryKeyArgs,
  'date_range_start' | 'date_range_end' // these are required args so we need to omit them for the type to work
> => {
  return {
    providerId,
    channels: [
      ProviderEventChannel.PROVIDER_PORTAL,
      ProviderEventChannel.PATIENT_PORTAL,
      ProviderEventChannel.ADMIN_PORTAL,
      ProviderEventChannel.AUTOBOOK_HC_REFERRAL,
      ProviderEventChannel.ZOCDOC,
      ProviderEventChannel.HEALTHCARE_REFERRAL,
      ProviderEventChannel.EXTERNAL_CALENDAR,
      ProviderEventChannel.DOCASAP,
    ],
  };
};

const createProviderEventReadForCalendarfromConcreteProviderEventRead = (
  event: ConcreteProviderEventRead,
  patientsById: Dictionary<UserRead> | null
): ProviderEventReadForCalendar => {
  return {
    ...event,
    // not sure why ConcreteProviderEventRead doesn't have start/end date guaranteed, but they should exist
    // in the create event response. this is just to satisfy type checking
    startDate: event.startDate || '',
    endDate: event.endDate || '',
    // add patient from already loaded patientsById -- from PatientsContext, should be available already if we're able
    // to create/update events
    patient: event.patientUserId
      ? patientsById?.[event.patientUserId]
      : undefined,
  };
};

/**
 * Returns a query key that is used for fetching all events that should be displayed on the calendar
 * for a given time range.
 */
export const getCalendarRangeQueryKeyArgs = (
  providerId: number,
  dateRangeStart: Date,
  dateRangeEnd: Date
): UseGetProviderEventsForCalendarQueryKeyArgs => ({
  ...getPartialCalendarRangeQueryKeyArgs(providerId),
  date_range_start: moment(dateRangeStart).toISOString() ?? undefined,
  date_range_end: moment(dateRangeEnd).toISOString() ?? undefined,
});

/**
 * Side effects for useCreateProviderEventMutation. Updates the queries used on the calendar to reflect
 * the new event.
 */
export const useCreateProviderEventSideEffectsForCalendar =
  (): SideEffectsBuilder<
    ConcreteProviderEventRead,
    unknown,
    ProviderEventCreate
  > => {
    const provider = useProvider();
    const queryClient = useQueryClient();
    const getProviderEventsForCalendarCache =
      useGetProviderEventsForCalendarCache();
    const { patientsById } = useContext(PatientsContext);

    return new SideEffectsBuilder<
      ConcreteProviderEventRead,
      unknown,
      ProviderEventCreate
    >().add({
      onSuccess: (event) => {
        const eventDate = new Date(event.startDate!);
        if (event.calendarEventType !== CalendarEventType.RECURRING_EVENT) {
          // Add the event to queries with a date range that overlaps this event's start date.
          queryClient.setQueriesData<
            GetPaginatedProviderEventForCalendarResponse | undefined
          >(
            {
              predicate: (query: Query) => {
                const queryKey = query.queryKey;
                if (
                  !isGetProviderEventsForCalendarQueryKey(queryKey) ||
                  !isMatch(
                    queryKey[1],
                    getPartialCalendarRangeQueryKeyArgs(provider.id)
                  )
                ) {
                  return false;
                }
                const { date_range_start, date_range_end } = queryKey[1];
                return date_range_start && date_range_end
                  ? new Date(date_range_start) <= eventDate &&
                      new Date(date_range_end) >= eventDate
                  : true;
              },
            },
            (old: GetPaginatedProviderEventForCalendarResponse | undefined) => {
              return (
                old && {
                  ...old,
                  totalCount: old.totalCount + 1,
                  data: [
                    ...old.data,
                    createProviderEventReadForCalendarfromConcreteProviderEventRead(
                      event,
                      patientsById
                    ),
                  ],
                }
              );
            }
          );
        }
        // Invalidate all calendar queries
        queryClient.invalidateQueries({
          predicate: (query: Query) => {
            const queryKey = query.queryKey;
            return (
              isGetProviderEventsForCalendarQueryKey(queryKey) &&
              isMatch(
                queryKey[1],
                getPartialCalendarRangeQueryKeyArgs(provider.id)
              )
            );
          },
        });

        // Invalidate query for tasks list
        if (
          eventDate <= taskDateRangeEnd &&
          event.type === ProviderEventType.APPOINTMENT
        ) {
          getProviderEventsForCalendarCache.invalidate(
            getPastScheduledAppointmentsQueryKeyArgs(provider.id)
          );
        }
      },
    });
  };

/**
 * Side effects for useUpdateProviderEventMutation. Updates the queries used on the calendar to
 * reflect the new event.
 */
export const useUpdateProviderEventSideEffectsForCalendar =
  (): SideEffectsBuilder<
    ConcreteProviderEventRead,
    unknown,
    UpdateProviderEventMutationArgs
  > => {
    const provider = useProvider();
    const queryClient = useQueryClient();
    const getProviderEventsForCalendarCache =
      useGetProviderEventsForCalendarCache();
    const { patientsById } = useContext(PatientsContext);

    return new SideEffectsBuilder<
      ConcreteProviderEventRead,
      unknown,
      UpdateProviderEventMutationArgs
    >()
      .add({
        onSuccess: (updatedEvent, update) => {
          const updater = (
            old: GetPaginatedProviderEventForCalendarResponse | undefined
          ) => {
            if (!old) {
              return old;
            }
            return {
              ...old,
              data: old.data.map((event) => {
                const isMatching =
                  event.calendarEventType === CalendarEventType.SINGLE_EVENT
                    ? event.id === updatedEvent.id
                    : updatedEvent.virtualId === event.virtualId;
                return isMatching
                  ? createProviderEventReadForCalendarfromConcreteProviderEventRead(
                      updatedEvent,
                      patientsById
                    )
                  : event;
              }),
            };
          };
          queryClient.setQueriesData<
            GetPaginatedProviderEventForCalendarResponse | undefined
          >(
            {
              predicate: (query: Query) => {
                const queryKey = query.queryKey;
                return (
                  isGetProviderEventsForCalendarQueryKey(queryKey) &&
                  isMatch(
                    queryKey[1],
                    getPartialCalendarRangeQueryKeyArgs(provider.id)
                  )
                );
              },
            },
            updater
          );
          getProviderEventsForCalendarCache.setAndInvalidate(
            getPastScheduledAppointmentsQueryKeyArgs(provider.id),
            updater
          );
          if (PATIENT_BOOKED_EVENT_CHANNELS.includes(updatedEvent.channel)) {
            getProviderEventsForCalendarCache.setAndInvalidate(
              getExpandedPatientBookedEventsQueryKeyArgs(provider.id),
              updater
            );
          }
        },
      })
      .add({
        // TODO(sc-266959): Remove this logging once issue is resolved.
        onSuccess: (updated, { update, eventIdOrVirtualId }) => {
          if (
            updated.calendarEventType === CalendarEventType.RECURRING_EVENT &&
            update.providerAppointment?.status ===
              ProviderAppointmentStatus.DETAILS_CONFIRMED
          ) {
            logException(
              new Error(
                'Attempted to confirm details on a base recurring event [UpdateProviderEvent]'
              ),
              {
                extra: { updatedId: eventIdOrVirtualId },
              }
            );
          }
        },
      });
  };

const useRecurringInstanceMutationSideEffectsForCalendar =
  (): SideEffectsBuilder<
    ProviderEventRecurrenceUpdateResponse,
    unknown,
    UpdateRecurringInstanceMutationArgs
  > => {
    const provider = useProvider();
    const queryClient = useQueryClient();
    const getProviderEventsForCalendarCache =
      useGetProviderEventsForCalendarCache();

    return new SideEffectsBuilder<
      ProviderEventRecurrenceUpdateResponse,
      unknown,
      UpdateRecurringInstanceMutationArgs
    >()
      .add({
        onSuccess: ({ updatedInstance }) => {
          // Since the recurrence change impacts several events, just invalidate rather than trying
          // to update the cache in-place.
          queryClient.invalidateQueries({
            predicate: (query: Query) => {
              const queryKey = query.queryKey;
              return (
                isGetProviderEventsForCalendarQueryKey(queryKey) &&
                isMatch(
                  queryKey[1],
                  getPartialCalendarRangeQueryKeyArgs(provider.id)
                )
              );
            },
          });
          getProviderEventsForCalendarCache.invalidate(
            getPastScheduledAppointmentsQueryKeyArgs(provider.id)
          );
          if (PATIENT_BOOKED_EVENT_CHANNELS.includes(updatedInstance.channel)) {
            getProviderEventsForCalendarCache.invalidate(
              getExpandedPatientBookedEventsQueryKeyArgs(provider.id)
            );
          }
        },
      })
      .add({
        // TODO(sc-266959): Remove this logging once issue is resolved.
        onSuccess: (updated, { update, virtualId }) => {
          if (
            updated.updatedBaseEvent.providerAppointment?.status ===
              ProviderAppointmentStatus.DETAILS_CONFIRMED &&
            update.providerAppointment?.status ===
              ProviderAppointmentStatus.DETAILS_CONFIRMED
          ) {
            logException(
              new Error(
                'Attempted to confirm details on a base recurring event [UpdateRecurringInstance]'
              ),
              {
                extra: { updatedId: virtualId },
              }
            );
          }
        },
      });
  };

export const useUpdateRecurringInstanceAndAllFollowingMutationSideEffectsForCalendar =
  () => {
    return useRecurringInstanceMutationSideEffectsForCalendar();
  };

export const useUpdateRecurringInstanceAndEndRecurrenceMutationSideEffectsForCalendar =
  () => {
    return useRecurringInstanceMutationSideEffectsForCalendar();
  };

export const useDeleteProviderEventMutationSideEffectsForCalendar =
  (): SideEffectsBuilder<{}, unknown, string | number> => {
    const provider = useProvider();
    const queryClient = useQueryClient();

    return new SideEffectsBuilder<{}, unknown, string | number>().add({
      onSuccess: (empty, eventIdOrVirtualId) => {
        const updater = (
          old: GetPaginatedProviderEventForCalendarResponse | undefined
        ) => {
          if (!old) {
            return old;
          }
          const newData = old.data.filter((event) =>
            typeof eventIdOrVirtualId === 'string'
              ? event.virtualId !== eventIdOrVirtualId
              : event.id !== eventIdOrVirtualId
          );
          return {
            ...old,
            totalCount:
              newData.length < old.data.length
                ? old.totalCount - 1
                : old.totalCount,
            data: newData,
          };
        };
        queryClient.setQueriesData<
          GetPaginatedProviderEventForCalendarResponse | undefined
        >(
          {
            predicate: (query: Query) => {
              const queryKey = query.queryKey;
              return (
                isGetProviderEventsForCalendarQueryKey(queryKey) &&
                isMatch(
                  queryKey[1],
                  getPartialCalendarRangeQueryKeyArgs(provider.id)
                )
              );
            },
          },
          updater
        );
      },
    });
  };

export const useDeleteRecurringInstanceAndAllFollowingMutationSideEffectsForCalendar =
  (): SideEffectsBuilder<ConcreteProviderEventRead, unknown, string> => {
    const provider = useProvider();
    const queryClient = useQueryClient();

    return new SideEffectsBuilder<
      ConcreteProviderEventRead,
      unknown,
      string
    >().add({
      onSuccess: () => {
        // Since the recurrence change impacts several events, just invalidate rather than trying
        // to update the cache in-place.
        queryClient.invalidateQueries({
          predicate: (query: Query) => {
            const queryKey = query.queryKey;
            return (
              isGetProviderEventsForCalendarQueryKey(queryKey) &&
              isMatch(
                queryKey[1],
                getPartialCalendarRangeQueryKeyArgs(provider.id)
              )
            );
          },
        });
      },
    });
  };
