/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { ProviderSupervisingPhysicianCreate } from '../models/ProviderSupervisingPhysicianCreate';
import { ProviderSupervisingPhysicianRead } from '../models/ProviderSupervisingPhysicianRead';
import { ProviderSupervisingPhysicianUpdate } from '../models/ProviderSupervisingPhysicianUpdate';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderSupervisingPhysicianApiResource {

    /**
     * Batch Create Provider Supervising Physicians
     * 
     * @param providerId 
     * @param providerSupervisingPhysicianCreate 
     
     */
    public batchCreateProviderSupervisingPhysicians(providerId: number, body: Array<ProviderSupervisingPhysicianCreate>, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderSupervisingPhysicianRead>> {
        const apiRoute = '/provider/{provider_id}/batch-create-provider-supervising-physicians';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Batch Update Provider Supervising Physicians
     * 
     * @param providerId 
     * @param providerSupervisingPhysicianUpdate 
     
     */
    public batchUpdateProviderSupervisingPhysicians(providerId: number, body: Array<ProviderSupervisingPhysicianUpdate>, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderSupervisingPhysicianRead>> {
        const apiRoute = '/provider/{provider_id}/batch-update-provider-supervising-physicians';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get List Provider Supervising Physicians
     * 
     * @param providerId 
     
     */
    public getListProviderSupervisingPhysicians(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderSupervisingPhysicianRead>> {
        const apiRoute = '/provider/{provider_id}/list-provider-supervising-physicians';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderSupervisingPhysicianApi = new ProviderSupervisingPhysicianApiResource();
