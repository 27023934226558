import { ProviderDocumentRequestApi } from '@headway/api/resources/ProviderDocumentRequestApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderDocumentRequestsQueryKeyArgs {
  providerId?: number;
  providerAppointmentId?: number;
}
export const getUseProviderDocumentRequestsQueryKey = ({
  providerId,
  providerAppointmentId,
}: UseProviderDocumentRequestsQueryKeyArgs): [
  string,
  number | undefined,
  number | undefined,
] => ['provider-document-request', providerId, providerAppointmentId];

const {
  singleQuery: providerDocumentRequests,
  useSingleQuery: useProviderDocumentRequests,
  useListQuery: useProviderDocumentRequestsList,
  useCachedQuery: useProviderDocumentRequestsCache,
} = createBasicApiHooks(
  getUseProviderDocumentRequestsQueryKey,
  ({ providerId, providerAppointmentId }) =>
    ProviderDocumentRequestApi.findProviderDocumentRequests({
      provider_id: providerId,
      provider_appointment_id: providerAppointmentId,
    }),
  ({ providerId, providerAppointmentId }) =>
    !!(providerId || providerAppointmentId),
  () => 'Failed to fetch provider document requests'
);

export {
  providerDocumentRequests,
  useProviderDocumentRequests,
  useProviderDocumentRequestsList,
  useProviderDocumentRequestsCache,
};
