import { BillingType } from '@headway/api/models/BillingType';
import { EligibilityLookupRead } from '@headway/api/models/EligibilityLookupRead';
import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { InsuranceAuthorizationRead } from '@headway/api/models/InsuranceAuthorizationRead';
import { InsuranceOutageType } from '@headway/api/models/InsuranceOutageType';
import { NestedPatientReadForCalendar } from '@headway/api/models/NestedPatientReadForCalendar';
import { PatientMissingSchedulingInfoType } from '@headway/api/models/PatientMissingSchedulingInfoType';
import { PriceChangeDetailsResponse } from '@headway/api/models/PriceChangeDetailsResponse';
import { PriceChangeReason } from '@headway/api/models/PriceChangeReason';
import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { UserClaimReadinessResponse } from '@headway/api/models/UserClaimReadinessResponse';
import { UserRead } from '@headway/api/models/UserRead';
import {
  canDeductibleBeUsedAsOopMax,
  getDeductibleUnknownDueToOverride,
  getInNetworkInsuranceStages,
  getRemainingFamilyInNetworkDeductible,
  getRemainingFamilyOutOfPocketCosts,
  getRemainingIndividualInNetworkDeductible,
  getRemainingIndividualOutOfPocketCosts,
  InsuranceStage,
  PaymentStructure,
  StageCompletionCriteria,
} from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';
import { formatPrice } from '@headway/shared/utils/payments';
import { checkExhaustive } from '@headway/shared/utils/types';

/**
 * UTIL functions for determining if a patient has the required info
 * to be seen by a provider
 */

export function patientHasActiveInsurance(
  patient: NestedPatientReadForCalendar | UserRead | undefined
): boolean {
  if (
    patient?.activeUserInsurance?.latestOutageActivatedOn &&
    !patient?.activeUserInsurance?.latestOutageDeactivatedOn &&
    patient?.activeUserInsurance?.latestOutageType ===
      InsuranceOutageType.NO_DATA_USE_FULL_COST
  ) {
    return true;
  }
  return !!patient?.activeUserInsurance?.latestEligibilityLookup?.isClaimReady;
}

export function getMissingPatientSchedulingInfo(
  patient: NestedPatientReadForCalendar | UserRead,
  billingType: BillingType,
  claimReadiness: UserClaimReadinessResponse | undefined
): PatientMissingSchedulingInfoType[] {
  const patientMissingSchedulingInfoType: PatientMissingSchedulingInfoType[] =
    [];

  if (
    !patientHasActiveInsurance(patient) &&
    billingType === BillingType.INSURANCE
  ) {
    patientMissingSchedulingInfoType.push(
      PatientMissingSchedulingInfoType.INSURANCE
    );
  }

  if (
    !patient.defaultUserPaymentMethodId &&
    (billingType === BillingType.INSURANCE ||
      billingType === BillingType.SELF_PAY)
  ) {
    patientMissingSchedulingInfoType.push(
      PatientMissingSchedulingInfoType.BILLING
    );
  }

  if (
    claimReadiness?.requirements?.includes(
      UserClaimReadinessCheck.PATIENT_ADDRESS
    ) &&
    billingType === BillingType.INSURANCE
  ) {
    patientMissingSchedulingInfoType.push(
      PatientMissingSchedulingInfoType.ADDRESS
    );
  }

  if (!patientCompletedRequiredForms(patient)) {
    patientMissingSchedulingInfoType.push(
      PatientMissingSchedulingInfoType.FORMS
    );
  }

  return patientMissingSchedulingInfoType;
}

export function patientCompletedRequiredForms(
  patient: NestedPatientReadForCalendar | UserRead
) {
  return (
    patient.privacyPracticesAcknowledgementDate &&
    patient.assignmentOfBenefitsDate
  );
}

export function isPatientMissingRequiredInfo(
  patient: NestedPatientReadForCalendar | UserRead,
  billingType: BillingType,
  claimReadiness: UserClaimReadinessResponse | undefined
) {
  return getMissingPatientSchedulingInfo(
    patient,
    billingType,
    claimReadiness
  ).some((missingInfo) =>
    [
      PatientMissingSchedulingInfoType.BILLING,
      PatientMissingSchedulingInfoType.FORMS,
      PatientMissingSchedulingInfoType.INSURANCE,
    ].find((requiredInfo) => requiredInfo === missingInfo)
  );
}

/**
 * Returns whether a patient has validated the information required to book an appointment with a
 * given billing type.
 */
export function patientCanBookForBillingType(
  patient: UserRead,
  billingType: BillingType
): boolean {
  switch (billingType) {
    case BillingType.INSURANCE:
      return (
        patientHasActiveInsurance(patient) &&
        !!patient.defaultUserPaymentMethodId
      );
    case BillingType.SELF_PAY:
      return !!patient.defaultUserPaymentMethodId;
    case BillingType.EAP:
      return true;
    default:
      return false;
  }
}

/** Returns a string that describes to a provider how much of appointment costs will be covered by their plan. */
export function getPatientPlanDescription(
  patient: UserRead,
  latestEligibilityLookup: EligibilityLookupRead
): string {
  const stages = getInNetworkInsuranceStages(latestEligibilityLookup);
  const currentStageIdx = stages.findIndex((stage) => !stage.completed);
  const currentStage = stages[currentStageIdx];
  const previousStage: InsuranceStage | undefined = stages[currentStageIdx - 1];
  const nextStage: InsuranceStage | undefined = stages[currentStageIdx + 1];
  const {
    inNetworkDeductible,
    familyInNetworkDeductible,
    inNetworkCoinsurance,
    inNetworkCopay,
  } = latestEligibilityLookup;
  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const fullyCoveredDesc = `${patientFirstName} is fully covered, so their sessions are $0.`;
  const exceptionalCaseDesc = `Heads up — ${patientFirstName}'s insurance is a bit complex, so we'll need to double-check their benefits manually.`;
  switch (currentStage.paymentStructure) {
    case PaymentStructure.FULLY_COVERED:
      if (previousStage) {
        // Is fully covered due to reaching an OOP max.
        const individualOopDesc = `${patientFirstName} has met their individual out-of-pocket maximum, so their sessions are $0 for the remainder of their plan.`;
        const familyOopDesc = `${patientFirstName} has met their family out-of-pocket maximum, so their sessions are $0 for the remainder of their plan.`;
        switch (previousStage.stageCompletionCriteria) {
          case StageCompletionCriteria.INDIVIDUAL_THRESHOLD:
            return individualOopDesc;
          case StageCompletionCriteria.FAMILY_THRESHOLD:
            return familyOopDesc;
          case StageCompletionCriteria.INDIVIDUAL_OR_FAMILY_THRESHOLD:
            if (
              getRemainingIndividualOutOfPocketCosts(
                latestEligibilityLookup
              ) === 0
            ) {
              return individualOopDesc;
            } else if (
              getRemainingFamilyOutOfPocketCosts(latestEligibilityLookup) === 0
            ) {
              return familyOopDesc;
            }
            return fullyCoveredDesc;
          case StageCompletionCriteria.NONE:
            return fullyCoveredDesc;
          default:
            checkExhaustive(previousStage.stageCompletionCriteria);
        }
      }
      return fullyCoveredDesc;
    case PaymentStructure.DEDUCTIBLE:
      // We can assume that the deductible has not been reached yet (otherwise the stage would be
      // fully covered)
      const isFamily =
        currentStage.stageCompletionCriteria ===
        StageCompletionCriteria.FAMILY_THRESHOLD;

      const deductibleUnknownDueToOverride = getDeductibleUnknownDueToOverride(
        latestEligibilityLookup,
        isFamily
      );
      if (deductibleUnknownDueToOverride) {
        return `${patientFirstName} has an unmet deductible and is currently paying the full session rate.`;
      }
      const isOopMax = canDeductibleBeUsedAsOopMax(latestEligibilityLookup);
      const deductible = isFamily
        ? familyInNetworkDeductible
        : inNetworkDeductible;
      const remaining = isFamily
        ? getRemainingFamilyInNetworkDeductible(latestEligibilityLookup)
        : getRemainingIndividualInNetworkDeductible(latestEligibilityLookup);
      let message = `${patientFirstName} is ${formatPrice(
        remaining
      )} away from reaching their ${formatPrice(deductible)} ${
        isFamily ? 'family ' : ''
      }${isOopMax ? 'out-of-pocket maximum' : 'deductible'}.`;
      if (
        nextStage &&
        nextStage.paymentStructure === PaymentStructure.FULLY_COVERED
      ) {
        message +=
          ' Once reached, sessions will be covered in full for the remainder of their plan.';
      } else if (
        nextStage &&
        nextStage.paymentStructure === PaymentStructure.COINSURANCE
      ) {
        const percent = ((inNetworkCoinsurance || 0) * 100).toFixed(0);
        message += ` Once reached, they will only owe ${percent}% of each session rate and insurance will cover the rest.`;
      }
      return message;
    case PaymentStructure.COPAY:
      return `${patientFirstName} has a copay of ${formatPrice(
        inNetworkCopay || 0
      )} for each session.`;
    case PaymentStructure.COINSURANCE:
      const percent = ((inNetworkCoinsurance || 0) * 100).toFixed(0);
      // Check if client was paying a deductible before paying coinsurance.
      if (
        previousStage &&
        previousStage.paymentStructure === PaymentStructure.DEDUCTIBLE
      ) {
        const isFamily =
          previousStage.stageCompletionCriteria ===
            StageCompletionCriteria.FAMILY_THRESHOLD ||
          (previousStage.stageCompletionCriteria ===
            StageCompletionCriteria.INDIVIDUAL_OR_FAMILY_THRESHOLD &&
            (getRemainingIndividualInNetworkDeductible(
              latestEligibilityLookup
            ) || 0) > 0);
        const deductible = isFamily
          ? familyInNetworkDeductible
          : inNetworkDeductible;
        const deductibleUnknown = isFamily
          ? latestEligibilityLookup.familyInNetworkDeductibleUnknown
          : latestEligibilityLookup.inNetworkDeductibleUnknown;
        return `${patientFirstName} has met their${
          deductibleUnknown ? '' : ' ' + formatPrice(deductible || 0)
        } ${
          isFamily ? 'family ' : ''
        }deductible, so they will only pay ${percent}% (coinsurance) of each session rate for the remainder of their plan.`;
      }
      return `${patientFirstName} will pay ${percent}% of each full session rate, and insurance will cover the rest.`;
    case PaymentStructure.COPAY_COINSURANCE:
      const copay = (inNetworkCopay || 0).toFixed(2);
      const coinsurancePercentage = ((inNetworkCoinsurance || 0) * 100).toFixed(
        0
      );
      // Check if client was paying a deductible before paying coinsurance.
      if (
        previousStage &&
        previousStage.paymentStructure === PaymentStructure.DEDUCTIBLE
      ) {
        const isFamily =
          previousStage.stageCompletionCriteria ===
            StageCompletionCriteria.FAMILY_THRESHOLD ||
          (previousStage.stageCompletionCriteria ===
            StageCompletionCriteria.INDIVIDUAL_OR_FAMILY_THRESHOLD &&
            (getRemainingIndividualInNetworkDeductible(
              latestEligibilityLookup
            ) || 0) > 0);
        const deductible = isFamily
          ? familyInNetworkDeductible
          : inNetworkDeductible;
        return `${patientFirstName} has met their ${formatPrice(
          deductible || 0
        )} ${
          isFamily ? 'family ' : ''
        }deductible. They will pay $${copay} (copay) and ${coinsurancePercentage}% (coinsurance) of the remaining session rate for the rest of their plan`;
      }
      return `${patientFirstName} will pay $${copay} (copay) and ${coinsurancePercentage}% of the remaining session rate. Insurance will cover the difference.`;
    case PaymentStructure.UNKNOWN:
      return exceptionalCaseDesc;
    default:
      checkExhaustive(currentStage.paymentStructure);
  }
}

export function getPatientPriceChangeDescription({
  patient,
  priceChangeDetails,
}: {
  patient: UserRead;
  priceChangeDetails: PriceChangeDetailsResponse;
}) {
  const priceChangeReason =
    priceChangeDetails.priceChanges &&
    priceChangeDetails.priceChanges[0]?.reason;
  const formattedPrice = formatPrice(priceChangeDetails.currentPrice);
  const insurer =
    patient.activeUserInsurance?.billingFrontEndCarrierName ||
    `${
      patient.displayFirstName ? `${patient.displayFirstName}’s` : 'their'
    } insurer`;
  switch (priceChangeReason) {
    case PriceChangeReason.COPAY_VALUE_INCREASED:
    case PriceChangeReason.COPAY_VALUE_DECREASED:
      return `${insurer} let us know that their copay was actually ${formattedPrice}. Going forward, they’ll pay this updated copay until they hit their out-of-pocket maximum (if that applies to their plan).`;
    case PriceChangeReason.COINSURANCE_VALUE_INCREASED:
      return `${insurer} let us know that their coinsurance was higher than our initial estimate. Going forward, they’ll pay the updated coinsurance until they hit their out-of-pocket maximum (if that applies to their plan).`;
    case PriceChangeReason.COINSURANCE_VALUE_DECREASED:
      return `${insurer} let us know that their coinsurance was lower than our initial estimate. Going forward, they’ll pay the updated coinsurance until they hit their out-of-pocket maximum (if that applies to their plan).`;
    case PriceChangeReason.INDIVIDUAL_DEDUCTIBLE_RESET:
    case PriceChangeReason.FAMILY_DEDUCTIBLE_RESET:
      return `${
        patient.displayFirstName || 'Your client'
      } has not met their deductible yet. Going forward, they’ll pay a higher session cost until they meet their maximum. Once they do, they’ll pay a lower cost for sessions.`;
    case PriceChangeReason.DEDUCTIBLE_MET:
      return `${
        patient.displayFirstName || 'Your client'
      } has met their deductible. Going forward, they’ll pay a lower session cost until they meet their out-of-pocket maximum. Once they do, their sessions will be fully covered.`;
    case PriceChangeReason.INDIVIDUAL_OUT_OF_POCKET_RESET:
    case PriceChangeReason.FAMILY_OUT_OF_POCKET_RESET:
      return `${
        patient.displayFirstName || 'Your client'
      } has not met their out-of-pocket maximum yet. Going forward, they’ll pay a higher session cost until they meet their maximum. Once they do, their sessions will be fully covered.`;
    case PriceChangeReason.GAINED_COPAY:
      return `${insurer} let us know that they now owe a copay, which means their cost is now ${formattedPrice}. Going forward, they’ll pay the copay until they hit their out-of-pocket maximum (if that applies to their plan).`;
    case PriceChangeReason.GAINED_COINSURANCE:
      return `${insurer} let us know that they now owe coinsurance. Going forward, they’ll pay the coinsurance until they hit their out-of-pocket maximum (if that applies to their plan).`;
    case PriceChangeReason.COINSURANCE_TO_COPAY:
      return `${insurer} let us know that they owe a copay instead of coinsurance, which means their cost is higher than our initial estimate. Going forward, they’ll pay the updated copay until they hit their out-of-pocket maximum (if that applies to their plan).`;
    case PriceChangeReason.COPAY_TO_COINSURANCE:
      return `${insurer} let us know that they owe coinsurance instead of a copay, which means their cost is higher than our initial estimate. Going forward, they’ll pay the updated coinsurance until they hit their out-of-pocket maximum (if that applies to their plan).`;
    default:
      return `We do our best to estimate their cost based on everything we know about their plan and our relationships with ${insurer}. However, sometimes the final cost differs based on updated plan info or session details. Going forward, we’ll work with ${insurer} to give them the best estimate possible.`;
  }
}
