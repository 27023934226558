import { FormLabel } from '@mui/material';
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import * as Yup from 'yup';

import { License } from '@headway/api/models/License';
import { LicenseTypes } from '@headway/api/models/LicenseTypes';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { SudAddictionLicense } from '@headway/api/models/SudAddictionLicense';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { VerificationType } from '@headway/api/models/VerificationType';
import '@headway/api/resources/ProviderApi';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import {
  YUP_NPI_ERROR_MESSAGE,
  YUP_NPI_MATCH,
} from '@headway/shared/constants/format';
import unitedStatesDisplayNames, {
  abbreviationToStateEnum,
  statesToDisplayNames,
} from '@headway/shared/constants/unitedStatesDisplayNames';
import { ENABLE_SUPERVISING_PHYSICIANS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import {
  getSelectedLicenseList,
  isOfLicenseTypes,
  nurseLicenseTypes,
  requiresSupervisorInfo,
  shouldRequireNPPrescriptiveAgreement,
} from '@headway/shared/utils/licenseHelper';
import {
  licenseTypes,
  marketSudAddictionLicenseTypes,
  nationalSudAddictionLicenseTypes,
} from '@headway/shared/utils/marketLicenseTypes';
import { getSupportedStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';
import {
  FieldControl,
  FieldDatePicker,
  FieldDropzone,
  FieldErrorText,
} from '@headway/ui/form';

import {
  IN_PROCESS,
  onDropFiles,
  YesNo,
} from '../../../../utils/providerQuestionnaire';
import { getLicenseTypesForMarket } from '../../../../utils/questionnaireVerificationHelpers';
import {
  AddButton,
  FieldArraySection,
  FieldArraySectionItemContainer,
} from '../../FieldArraySection';
import { StepState } from '../../IntakeNavigation';
import { OnBeforeSubmitError } from '../../OnBeforeSubmitErrorCard';
import { useQuestionnaireContext } from '../../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../../QuestionnaireV2Step';
import { CustomComponentProps } from '../../utils/CustomComponentProps';
import { yupSchemaToDefaultValue } from '../../utils/yupSchemaToDefaultValue';
import { LicenseSelectSection } from './LicenseSelectSection';
import { LicenseStepHeader } from './LicenseStepHeader';
import {
  getLicenseKey,
  getLicenseKeys,
  getLicenseVerificationStatus,
  getSelectedLicense,
} from './LicenseStepHelper';

const requireDateObtained = [UnitedStates.LOUISIANA];

const PQV_ATTEMPT_THRESHOLD = 2;

const initialSudAddictionLicenseValues = {
  isNationalLicense: '',
  licenseState: '',
  licenseNumber: '',
  licenseType: '',
  licenseEffectiveDate: '',
  licenseExpirationDate: '',
  licenseUploadUrl: [],
};

const updateSudAddictionLicensesBasedOnLicenses = (
  hasSudLicenses: boolean,
  values: ProviderQuestionnaireRawData,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
) => {
  const sudLicensesInRegularLicenses = getSelectedLicenseList(
    values?.selectedLicenses
  ).filter(
    (license) =>
      marketSudAddictionLicenseTypes[license.licenseState]?.includes(
        license.licenseType
      )
  );
  if (!hasSudLicenses || values?.sudAddictionLicenses?.length) {
    setFieldValue('sudAddictionLicenses', []);
    return;
  }
  if (!sudLicensesInRegularLicenses?.length) {
    setFieldValue('sudAddictionLicenses', [initialSudAddictionLicenseValues]);
    return;
  }
  setFieldValue(
    'sudAddictionLicenses',
    sudLicensesInRegularLicenses.map((sudLicenseInRegularLicenses) => ({
      isNationalLicense: YesNo.NO,
      licenseState: sudLicenseInRegularLicenses.licenseState,
      licenseNumber: sudLicenseInRegularLicenses.licenseNumber,
      licenseType: sudLicenseInRegularLicenses.licenseType,
      licenseEffectiveDate: sudLicenseInRegularLicenses.licenseEffectiveDate,
      licenseExpirationDate: sudLicenseInRegularLicenses.licenseExpirationDate,
      licenseUploadUrl: sudLicenseInRegularLicenses.licenseUploadUrl,
    }))
  );
};

const verifyLicense = async (license: License, questionnaireId: number) => {
  const licenseTypeId = licenseTypes[license?.licenseType]?.licenseTypeId;

  if (
    license.licenseType &&
    license.licenseNumber &&
    license.licenseState &&
    licenseTypeId
  ) {
    const { licenseNumber, licenseState } = license;

    const pqv =
      await ProviderQuestionnaireApi.createProviderQuestionnaireVerification(
        questionnaireId,
        {
          type: VerificationType.LICENSE,
          requestJson: {
            licenseNumber,
            licenseTypeId,
            licenseType: license.licenseType,
            state: licenseState,
          },
        }
      );
    return pqv;
  }
};

const StateLicenseHeader = ({
  state,
  selectedLicense,
}: {
  state: UnitedStates;
  selectedLicense?: License;
}) => {
  const { licensesAndVerifications } = useQuestionnaireContext();
  const verificationStatus = getLicenseVerificationStatus(
    selectedLicense,
    licensesAndVerifications
  );

  return (
    <div
      css={{
        ...theme.stack.vertical,
        justifyContent: 'space-between',
      }}
    >
      <div css={{ ...theme.stack.horizontal }}>
        <SectionHeader>{unitedStatesDisplayNames[state]} license</SectionHeader>
        {verificationStatus && (
          <h2>
            <div
              css={{
                marginLeft: theme.spacing.x2,
                marginTop: theme.spacing.x1,
              }}
            >
              {verificationStatus === StepState.VALID ? (
                <Badge variant="positive">Verified</Badge>
              ) : verificationStatus === StepState.PENDING ? (
                <Badge variant="warning">Verifying...</Badge>
              ) : (
                <Badge variant="negative" icon={IconWarningCircle}>
                  Needs Attention
                </Badge>
              )}
            </div>
          </h2>
        )}
      </div>
    </div>
  );
};
const LicenseForm = ({
  licenseInput,
  formikHelpers,
}: {
  licenseInput?: License;
  formikHelpers: FormikProps<ProviderQuestionnaireRawData>;
}) => {
  const isLicenseUploadDisabled = useFlag('licenseUploadDisabled', false);

  // this moves supervising physician questions to the Prescriber Requirements tab
  const enableSupervisingPhysicians = useFlag(
    ENABLE_SUPERVISING_PHYSICIANS,
    false
  );

  const { licensesAndVerifications, provider } = useQuestionnaireContext();
  if (!licenseInput) {
    return <></>;
  }

  const licenseTypesForMarket = getLicenseTypesForMarket(
    licenseInput.licenseState as UnitedStates
  );
  const verificationStatus = getLicenseVerificationStatus(
    licenseInput,
    licensesAndVerifications
  );
  const shouldShowSupervisingPhysicianQuestions =
    nurseLicenseTypes.includes(licenseInput.licenseType) &&
    formikHelpers.values?.selectedLicenses?.[licenseInput.licenseState]
      .requiresSupervision === YesNo.YES;

  const formPrefix = `selectedLicenses[${licenseInput.licenseState}]`;
  return (
    <>
      {verificationStatus === StepState.INVALID && (
        <LicenseErrorCard
          attemptCounts={licensesAndVerifications?.length || 0}
        />
      )}
      <FormControl
        component={Select}
        name={`${formPrefix}licenseType`}
        label="License type"
        selectionMode="single"
        menuWidth="stretch"
      >
        {licenseTypesForMarket.map((licenseType, index) => (
          <Item key={licenseType.enum}>{licenseType.displayName}</Item>
        ))}
      </FormControl>
      {requireDateObtained.includes(
        licenseInput.licenseState as UnitedStates
      ) && (
        <FieldControl
          name={`${formPrefix}licenseDateObtained`}
          fullWidth={true}
          css={{ marginBottom: '0' }}
        >
          <FieldDatePicker
            label="License date obtained"
            inputFormat="MM/DD/YYYY"
            variant="outlined"
            size="small"
          />
          <FieldErrorText />
        </FieldControl>
      )}
      {!enableSupervisingPhysicians && (
        <>
          <FormControl
            name={`${formPrefix}requiresSupervision`}
            component={RadioGroup}
            label="If required, do you have a supervising physician?"
          >
            <Radio value={YesNo.YES}>Yes, I have a supervising physician</Radio>
            <Radio value={IN_PROCESS}>
              I am in the process of obtaining one and will have a
              supervising/collaborative physician prior to holding my first
              session on Headway
            </Radio>
            <Radio value={YesNo.NO}>
              I don't require a supervising physician
            </Radio>
          </FormControl>
          {shouldShowSupervisingPhysicianQuestions && (
            <>
              <FormControl
                component={TextField}
                name={`${formPrefix}npiOfSupervisingPhysician`}
                label="NPI of Supervising Physician"
                type="number"
              />
              <FormControl
                component={TextField}
                name={`${formPrefix}nameOfSupervisingPhysician`}
                label="Name of Supervising Physician"
              />
            </>
          )}
        </>
      )}
      {licenseInput.licenseState === UnitedStates.TENNESSEE &&
        isOfLicenseTypes(licenseInput, nurseLicenseTypes) && (
          <>
            <FormControl
              name={`${formPrefix}protocolsExistAtLocation`}
              component={RadioGroup}
              label="Protocols exist at the location where this
          practitioner practices?"
            >
              <Radio value={YesNo.YES}>Yes</Radio>
              <Radio value={YesNo.NO}>No</Radio>
            </FormControl>
            <FormControl
              name={`${formPrefix}protocolsWrittenAsRequiredByStateLaw`}
              component={RadioGroup}
              label="Protocols are written as required by the State
          Law?"
            >
              <Radio value={YesNo.YES}>Yes</Radio>
              <Radio value={YesNo.NO}>No</Radio>
            </FormControl>
            <FormControl
              name={`${formPrefix}protocolsAvailableToBeReviewed`}
              component={RadioGroup}
              label="Protocols are available to be reviewed in the
          practicing office?"
            >
              <Radio value={YesNo.YES}>Yes</Radio>
              <Radio value={YesNo.NO}>No</Radio>
            </FormControl>
          </>
        )}
      {licenseInput.licenseState === UnitedStates.MONTANA &&
        isOfLicenseTypes(licenseInput, nurseLicenseTypes) && (
          <FormControl
            name={`${formPrefix}authorizedByBon`}
            component={RadioGroup}
            label="Are you currently authorized to prescribe in Montana by the Board of Nursing?"
          >
            <Radio value={YesNo.YES}>Yes</Radio>
            <Radio value={YesNo.NO}>No</Radio>
          </FormControl>
        )}
      {!isLicenseUploadDisabled && (
        <FieldControl
          name={`${formPrefix}licenseUploadUrl`}
          fullWidth={true}
          css={{ marginBottom: '0' }}
        >
          <FormLabel>
            Please upload a copy of this <b>license</b> in PDF format.
          </FormLabel>
          <FieldDropzone
            accept="application/pdf,image/*"
            onDrop={(file: any) => onDropFiles(provider.id, file)}
            inputTestId={`${formPrefix}licenseUploadUrl`}
            css={{ marginBottom: '0' }}
          />
          <FieldErrorText />
        </FieldControl>
      )}

      {!enableSupervisingPhysicians &&
        requiresSupervisorInfo(
          licenseInput.licenseState as UnitedStates,
          licenseInput.requiresSupervision,
          licenseInput.licenseType
        ) && (
          <FieldControl
            name={`${formPrefix}licensePrescriptiveAgreement`}
            fullWidth={true}
          >
            <FormLabel>
              Please upload a copy of your{' '}
              <b>Prescriptive Authority Agreement</b> (also known as a{' '}
              <b>Collaborative Practice Agreement</b>) in PDF format. This is
              required in order to get you credentialed with payers in{' '}
              {licenseInput.licenseState}.
            </FormLabel>
            <FieldDropzone
              inputTestId={`${formPrefix}licensePrescriptiveAgreement`}
              accept="application/pdf,image/*"
              onDrop={(file: any) => onDropFiles(provider.id, file)}
              css={{ marginBottom: '0' }}
            />
            <FieldErrorText />
          </FieldControl>
        )}

      {licenseInput.licenseState === UnitedStates.TENNESSEE &&
        isOfLicenseTypes(licenseInput, nurseLicenseTypes) && (
          <FieldControl
            name={`${formPrefix}certificateOfFitness`}
            fullWidth={true}
          >
            <FormLabel>
              Please upload a copy of your <b>Certificate of Fitness</b> in PDF
              format. This is required in order to get you credentialed with
              payers in {licenseInput.licenseState}.
            </FormLabel>
            <FieldDropzone
              inputTestId={`${formPrefix}certificateOfFitness`}
              accept="application/pdf,image/*"
              onDrop={(file: any) => onDropFiles(provider.id, file)}
              css={{ marginBottom: '0' }}
            />
            <FieldErrorText />
          </FieldControl>
        )}
    </>
  );
};

const LicenseOutOfRetriesError = () => (
  <div
    css={{
      ...theme.stack.vertical,
    }}
  >
    <div css={{ marginBottom: theme.spacing.x2 }}>
      Seems like we are still unable to verify your license.
    </div>
    <div css={{}}>If your license meets the following criteria:</div>
    <div css={{ marginBottom: theme.spacing.x2 }}>
      <ul
        css={{
          marginBottom: theme.spacing.x3,
        }}
      >
        <li>License type is in the dropdown below</li>
        <li>License number is inputted exactly as shown on your license</li>
        <li>License is currently active</li>
      </ul>
    </div>
    <div>
      Please continue completing the rest of the form and contact your Headway
      Provider Growth Representative. We will get back to you shortly.
    </div>
  </div>
);

const LicenseErrorCard = ({ attemptCounts }: { attemptCounts: number }) => (
  <GuidanceCard variant="error" layout="vertical">
    {attemptCounts > PQV_ATTEMPT_THRESHOLD ? (
      <LicenseOutOfRetriesError />
    ) : (
      'We could not find this license. Please ensure that the license type, license number, and license state are all correct.'
    )}
  </GuidanceCard>
);

const SudAddictionLicenseForm = ({
  arrayHelpers,
  sudAddictionLicense,
  index,
}: {
  arrayHelpers: FieldArrayRenderProps;
  sudAddictionLicense: SudAddictionLicense;
  index: number;
}) => {
  const { provider } = useQuestionnaireContext();

  const licenseStateMenuOptions = Object.keys(marketSudAddictionLicenseTypes)
    .sort((a, b) =>
      statesToDisplayNames[
        abbreviationToStateEnum[a] as UnitedStates
      ].localeCompare(
        statesToDisplayNames[abbreviationToStateEnum[b] as UnitedStates]
      )
    )
    .map((state) => (
      <Item key={state}>
        {statesToDisplayNames[abbreviationToStateEnum[state] as UnitedStates]}
      </Item>
    ));
  let availableLicenseTypes: LicenseTypes[] = [];
  if (sudAddictionLicense.isNationalLicense === YesNo.YES) {
    availableLicenseTypes = nationalSudAddictionLicenseTypes;
  } else if (sudAddictionLicense.licenseState) {
    availableLicenseTypes =
      marketSudAddictionLicenseTypes[sudAddictionLicense.licenseState];
  }
  return (
    <FieldArraySection>
      <FieldArraySectionItemContainer>
        <div
          css={{
            ...theme.stack.horizontal,
            justifyContent: 'space-between',
            alignItems: 'space-between',
          }}
        >
          <h2>
            <SectionHeader>
              SUD or Addiction License Information{' '}
              {index > 0 ? `#${index + 1}` : ''}
            </SectionHeader>
          </h2>
          {index > 0 && (
            <Button
              onPress={() => arrayHelpers.remove(index)}
              variant="secondary"
              size="medium"
            >
              Remove License
            </Button>
          )}
        </div>
        <FormControl
          name={`sudAddictionLicenses[${index}]isNationalLicense`}
          component={RadioGroup}
          label="Is this a national license?"
        >
          <Radio value={YesNo.YES}>Yes</Radio>
          <Radio value={YesNo.NO}>No</Radio>
        </FormControl>
        {sudAddictionLicense.isNationalLicense === YesNo.NO && (
          <FormControl
            component={Select}
            name={`sudAddictionLicenses[${index}]licenseState`}
            label="License state"
            selectionMode="single"
            menuWidth="stretch"
          >
            {licenseStateMenuOptions}
          </FormControl>
        )}
        {(sudAddictionLicense.isNationalLicense === YesNo.YES ||
          sudAddictionLicense.licenseState) && (
          <FormControl
            component={Select}
            name={`sudAddictionLicenses[${index}]licenseType`}
            label="License type"
            selectionMode="single"
            menuWidth="stretch"
          >
            {availableLicenseTypes.map((sudAddictionLicenseType) => (
              <Item key={sudAddictionLicenseType}>
                {sudAddictionLicenseType}
              </Item>
            ))}
          </FormControl>
        )}
        <FormControl
          component={TextField}
          name={`sudAddictionLicenses[${index}]licenseNumber`}
          label="License number"
          helpText="Please input your license number exactly as it is found on your license, including any letters, dashes, or periods."
        />
        <FieldControl
          name={`sudAddictionLicenses[${index}]licenseEffectiveDate`}
          fullWidth={true}
        >
          <FieldDatePicker
            label="License effective date"
            inputFormat="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            variant="outlined"
            size="small"
          />
          <FieldErrorText />
        </FieldControl>
        <FieldControl
          name={`sudAddictionLicenses[${index}]licenseExpirationDate`}
          fullWidth={true}
        >
          <FieldDatePicker
            label="License expiration date"
            inputFormat="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            variant="outlined"
            size="small"
          />
          <FieldErrorText />
        </FieldControl>
        <FieldControl
          name={`sudAddictionLicenses[${index}]licenseUploadUrl`}
          fullWidth={true}
          css={{ marginBottom: '0' }}
        >
          <FormLabel>
            Please upload a copy of this <b>license</b> in PDF format.
          </FormLabel>
          <FieldDropzone
            inputTestId={`sudAddictionLicenses[${index}]licenseUploadUrl`}
            accept="application/pdf,image/*"
            onDrop={(file: any) => onDropFiles(provider.id, file)}
            css={{ marginBottom: '0' }}
          />
          <FieldErrorText />
        </FieldControl>
      </FieldArraySectionItemContainer>
    </FieldArraySection>
  );
};

const SudAddictionLicenseList = ({
  rawData,
}: {
  rawData: ProviderQuestionnaireRawData;
}) => (
  <FieldArray
    name={'sudAddictionLicenses'}
    render={(arrayHelpers) => (
      <>
        {rawData.sudAddictionLicenses?.map((sudAddictionLicense, index) => (
          <SudAddictionLicenseForm
            arrayHelpers={arrayHelpers}
            sudAddictionLicense={sudAddictionLicense}
            index={index}
          />
        ))}
        <AddButton
          buttonText="Add another substance use disorder or addiction license"
          onPress={() => arrayHelpers.push(initialSudAddictionLicenseValues)}
        />
      </>
    )}
  />
);

const SelectedLicensesForm = ({
  states,
  formikHelpers,
}: {
  states: UnitedStates[];
  formikHelpers: FormikProps<ProviderQuestionnaireRawData>;
}) => (
  <>
    {states.map((state) => {
      const selectedLicense = getSelectedLicense(state, formikHelpers);
      return (
        <div
          css={{
            borderBottom: `1px solid ${theme.color.system.borderGray}`,
            paddingBottom: theme.spacing.x6,
          }}
        >
          <FieldArraySection>
            <FieldArraySectionItemContainer>
              <StateLicenseHeader
                state={state}
                selectedLicense={selectedLicense}
              />
              <LicenseSelectSection
                formikHelpers={formikHelpers}
                state={state}
              />
              <LicenseForm
                licenseInput={selectedLicense}
                formikHelpers={formikHelpers}
              />
            </FieldArraySectionItemContainer>
          </FieldArraySection>
        </div>
      );
    })}
  </>
);

const LicenseStep = ({
  initialValues,
  formikHelpers,
}: CustomComponentProps) => {
  const { provider } = useQuestionnaireContext();
  const states = getSupportedStates(provider);
  return (
    <div
      css={{
        ...theme.stack.vertical,
        gap: theme.spacing.x6,
      }}
    >
      <LicenseStepHeader initialValues={initialValues} />
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
          marginTop: '16px',
          borderTop: `1px solid ${theme.color.system.borderGray}`,
          paddingTop: '24px',
        }}
      >
        <SelectedLicensesForm states={states} formikHelpers={formikHelpers} />
        <div
          css={{
            ...theme.stack.vertical,
            gap: theme.spacing.x6,
            marginTop: theme.spacing.x4,
          }}
        >
          <FormControl
            name={`hasSudAddictionLicenses`}
            component={RadioGroup}
            label="Are you a certified addiction counselor, substance use
                          disorder counselor, or alcohol and drug counselor?"
            onChange={(value: string) =>
              updateSudAddictionLicensesBasedOnLicenses(
                value === YesNo.YES,
                formikHelpers.values,
                formikHelpers.setFieldValue
              )
            }
          >
            <Radio value={YesNo.YES}>Yes</Radio>
            <Radio value={YesNo.NO}>No</Radio>
          </FormControl>
          {formikHelpers.values?.hasSudAddictionLicenses === YesNo.YES && (
            <SudAddictionLicenseList rawData={formikHelpers.values} />
          )}
        </div>
      </div>
    </div>
  );
};

const getIndividualValidationSchema = (
  provider: ProviderRead,
  state: UnitedStates,
  flags: { [key: string]: boolean } | undefined
) => {
  const shouldRequireDateObtained = requireDateObtained.includes(state);
  const isLicenseUploadDisabled = flags?.licenseUploadDisabled;
  const enableSupervisingPhysicians = flags?.enableSupervisingPhysicians;
  const validationSchema = {
    licenseType: Yup.string().required('License type is required.'),
    licenseState: Yup.string().required('License state is required.'),
    currentlyPracticesInState: Yup.string().required(
      'This question is required.'
    ),
    licenseNumber: Yup.string().required('License number is required.'),
    licenseDateObtained: Yup.string().when(
      'licenseState',
      (licenseState, schema) =>
        shouldRequireDateObtained
          ? schema.required('License date obtained is required.')
          : schema
    ),
    licenseExpirationDate: Yup.string().required(
      'License expiration date is required.'
    ),
    protocolsExistAtLocation: Yup.string().when(
      ['licenseState', 'licenseType'],
      (licenseState, licenseType, schema) =>
        licenseState === UnitedStates.TENNESSEE &&
        nurseLicenseTypes.includes(licenseType)
          ? schema.required('This question is required.')
          : schema
    ),
    protocolsWrittenAsRequiredByStateLaw: Yup.string().when(
      ['licenseState', 'licenseType'],
      (licenseState, licenseType, schema) =>
        licenseState === UnitedStates.TENNESSEE &&
        nurseLicenseTypes.includes(licenseType)
          ? schema.required('This question is required.')
          : schema
    ),
    protocolsAvailableToBeReviewed: Yup.string().when(
      ['licenseState', 'licenseType'],
      (licenseState, licenseType, schema) =>
        licenseState === UnitedStates.TENNESSEE &&
        nurseLicenseTypes.includes(licenseType)
          ? schema.required('This question is required.')
          : schema
    ),
    authorizedByBon: Yup.string().when(
      ['licenseState', 'licenseType'],
      (licenseState, licenseType, schema) =>
        licenseState === UnitedStates.MONTANA &&
        nurseLicenseTypes.includes(licenseType)
          ? schema.required('This question is required.')
          : schema
    ),
    licenseUploadUrl: isLicenseUploadDisabled
      ? Yup.array().of(
          Yup.object().shape({
            link: Yup.string(),
            name: Yup.string(),
          })
        )
      : Yup.array()
          .of(
            Yup.object().shape({
              link: Yup.string(),
              name: Yup.string(),
            })
          )
          .required('Please upload a copy of your license.'),
    certificateOfFitness: Yup.array()
      .of(
        Yup.object().shape({
          link: Yup.string(),
          name: Yup.string(),
        })
      )
      .when(
        ['licenseState', 'licenseType'],
        (licenseState, licenseType, schema) =>
          licenseState === UnitedStates.TENNESSEE &&
          nurseLicenseTypes.includes(licenseType)
            ? schema.required(
                'Please upload a copy of your Certificate of Fitness.'
              )
            : schema.nullable()
      ),
  };

  if (enableSupervisingPhysicians) {
    // with this flag on, supervising physician questions are no longer handled in this step
    return Yup.object().shape(validationSchema);
  }

  return Yup.object().shape({
    ...validationSchema,
    requiresSupervision: Yup.string().required('This question is required'),
    npiOfSupervisingPhysician: Yup.string()
      .matches(YUP_NPI_MATCH, YUP_NPI_ERROR_MESSAGE)
      .when(
        ['licenseState', 'licenseType', 'requiresSupervision'],
        (licenseState, licenseType, requiresSupervision, schema) =>
          requiresSupervisorInfo(state, requiresSupervision, licenseType)
            ? schema.required('This question is required.')
            : schema
      ),
    nameOfSupervisingPhysician: Yup.string().when(
      ['licenseState', 'licenseType', 'requiresSupervision'],
      (
        licenseState: string,
        licenseType: LicenseTypes,
        requiresSupervision: string,
        schema: Yup.StringSchema
      ) =>
        requiresSupervisorInfo(state, requiresSupervision, licenseType)
          ? schema.required('This question is required.')
          : schema
    ),
    licensePrescriptiveAgreement: Yup.array()
      .of(
        Yup.object().shape({
          link: Yup.string(),
          name: Yup.string(),
        })
      )
      .when(
        ['licenseState', 'licenseType', 'requiresSupervision'],
        (licenseState, licenseType, requiresSupervision, schema) => {
          if (!shouldRequireNPPrescriptiveAgreement(state)) return schema;
          if (!nurseLicenseTypes.includes(licenseType)) return schema;
          if (requiresSupervision !== YesNo.YES) return schema;
          return schema.required(
            'Please upload a copy of your Prescriptive Authority Agreement.'
          );
        }
      ),
  });
};

// This is needed in order to get LicenseSelectSection.tsx's formControl
// To pass the FormMeta check where we need a license selected for each state
const getLicenseSelectMap = (
  states: UnitedStates[],
  rawData?: ProviderQuestionnaireRawData
) => {
  if (!rawData) {
    return {};
  }

  let licenseSelectMap: { [key: string]: string | undefined } = {};
  const licenseKeys = getLicenseKeys(rawData.caqhLicenses || []);
  for (const state of states) {
    const selectedLicense = rawData.selectedLicenses?.[state];
    if (!selectedLicense) {
      licenseSelectMap[state] = undefined;
      continue;
    }
    const selectedLicenseKey = getLicenseKey(selectedLicense);
    if (licenseKeys.has(selectedLicenseKey)) {
      licenseSelectMap[state] = selectedLicenseKey;
    } else {
      licenseSelectMap[state] = undefined;
    }
  }
  return licenseSelectMap;
};

const LicenseConfig: QuestionnaireV2Step = {
  title: 'License Information',
  description:
    'In this section we ask that you share your professional identification numbers and license information.',
  recredDescription: 'Please verify the following fields:',
  Component: LicenseStep,
  onBeforeSubmit: async (
    values,
    setBeforeSubmitError,
    setShowConfirmationModal,
    context
  ) => {
    if (!context.shouldUseVerifiable) {
      return true;
    }

    try {
      await Promise.all(
        Object.entries(values.selectedLicenses || {}).map(
          async ([_, license]) => {
            return await verifyLicense(
              license,
              context.providerQuestionnaire.id
            );
          }
        )
      );
      return true;
    } catch (e) {
      setBeforeSubmitError(OnBeforeSubmitError.LicenseTriggerValidation);
      return false;
    }
  },
  getFormMeta: ({ provider, providerQuestionnaire }, flags) => {
    let stateValidationSchema: { [key: string]: Yup.ObjectSchema } = {};
    let licenseSelectFormSchema: { [key: string]: Yup.StringSchema } = {};
    const states = provider.activeProviderLicenseStates.map((pls) => pls.state);
    for (const state of states) {
      stateValidationSchema[state] = getIndividualValidationSchema(
        provider,
        state,
        flags
      );
      licenseSelectFormSchema[state] = Yup.string().required(
        'This question is required'
      );
    }

    let validationSchema = Yup.object().shape({
      licenseSelectForm: Yup.object().shape(licenseSelectFormSchema).required(),
      selectedLicenses: Yup.object().shape(stateValidationSchema).required(),
      hasSudAddictionLicenses: Yup.string().required(
        'This question is required.'
      ),
      sudAddictionLicenses: Yup.array().of(
        Yup.object()
          .shape({
            licenseType: Yup.string().required('License type is required.'),
            isNationalLicense: Yup.string().required(
              'This question is required.'
            ),
            licenseState: Yup.string().when(
              'isNationalLicense',
              (isNationalLicense, schema) =>
                isNationalLicense === YesNo.NO
                  ? schema.required('License state is required.')
                  : schema
            ),
            licenseNumber: Yup.string().when(
              'licenseState',
              (licenseState, schema) =>
                licenseState === UnitedStates.NEW_YORK
                  ? schema
                      .matches(/^\d{6}$/, 'License number must be 6 digits.')
                      .required('License number is required.')
                  : schema.required('License number is required.')
            ),
            licenseEffectiveDate: Yup.string().required(
              'This question is required'
            ),
            licenseExpirationDate: Yup.string().required(
              'License expiration date is required.'
            ),
            licenseUploadUrl: Yup.array()
              .of(
                Yup.object().shape({
                  link: Yup.string(),
                  name: Yup.string(),
                })
              )
              .required('Please upload a copy of your license.'),
          })
          .when('hasSudAddictionLicenses', (hasSudAddictionLicenses, schema) =>
            hasSudAddictionLicenses === YesNo.YES
              ? schema.min(
                  1,
                  'At least one SUD addiction license is required since you signified you hold one.'
                )
              : schema
          )
      ),
    });

    return {
      validationSchema: validationSchema,
      initialValue: {
        ...yupSchemaToDefaultValue(validationSchema),
        ...providerQuestionnaire.rawData,
        licenseSelectForm: getLicenseSelectMap(
          states,
          providerQuestionnaire.rawData
        ),
      },
    } as FormMeta;
  },
};

export default LicenseConfig;

export const LicenseTriggerValidation = () => {
  return (
    <GuidanceCard variant="error" layout="vertical">
      <BodyText>
        There was a problem while triggering validation for one or more of your
        licenses.
      </BodyText>
    </GuidanceCard>
  );
};
