import { providerIncentiveProgramEnrollment } from '~/legacy/hooks/useGetIsEnrolledProviderIncentiveProgram';
import { patientAssessmentRecurrenceSchedules } from '~/legacy/hooks/usePatientAssessmentRecurrenceSchedules';

import { PatientAssessmentRecurrenceScheduleRead } from '@headway/api/models/PatientAssessmentRecurrenceScheduleRead';
import { ProviderIncentiveProgramEnrollmentResponse } from '@headway/api/models/ProviderIncentiveProgramEnrollmentResponse';
import { PROMS_ONLY_RATE_BOOST } from '@headway/shared/FeatureFlags/flagNames';

import { ProgressNoteState } from '../../stores/AppointmentConfirmationContextV2';
import { Rule } from '../Rule';

export class MichiganPromsIncentivePilot extends Rule<
  [
    ProviderIncentiveProgramEnrollmentResponse,
    PatientAssessmentRecurrenceScheduleRead[],
    boolean,
  ]
> {
  addDataHooks() {
    return [
      async () =>
        await providerIncentiveProgramEnrollment(
          {
            providerId: this.provider.id,
          },
          Rule.queryClient
        ),
      async () =>
        await patientAssessmentRecurrenceSchedules(
          {
            providerPatientId: this.providerPatient.id,
          },
          Rule.queryClient
        ),
      async () => this.flags[PROMS_ONLY_RATE_BOOST] || false,
    ];
  }

  isRuleActive(): boolean {
    const hookData = this.getHookData();

    if (!hookData || !hookData[0] || !hookData[1]) {
      return false;
    }

    const providerEnrollmentSummary = hookData[0];
    const patientAssessmentRecurrenceSchedules = hookData[1];
    const promsOnlyRateBoostEnabled = hookData[2];

    if (promsOnlyRateBoostEnabled) {
      return (
        providerEnrollmentSummary?.isProviderEnrolled &&
        patientAssessmentRecurrenceSchedules?.length === 0
      );
    } else {
      return (
        providerEnrollmentSummary?.isProviderEnrolled &&
        patientAssessmentRecurrenceSchedules?.length === 0 &&
        this.progressNoteState === ProgressNoteState.SIGNED
      );
    }
  }

  get disabledProgressNoteTypes() {
    return [];
  }

  get banners() {
    return {};
  }

  shouldOpenSwoopUpsellModalAfterConfirmation(): boolean {
    return true;
  }
}
