import { useFormikContext } from 'formik';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import uniqueId from 'lodash/uniqueId';
import { useState } from 'react';

import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxTree, CheckboxTreeItem } from '@headway/helix/CheckboxTree';
import { ContentText } from '@headway/helix/ContentText';
import { FormControl } from '@headway/helix/FormControl';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { formatPatientName } from '@headway/shared/utils/patient';
import { pluralize } from '@headway/shared/utils/stringFormatting';

import { shouldRequireTelehealthAttestation } from 'views/AppointmentConfirmation/hooks/validationSchemas/useSessionDetailsValidationSchema';

import {
  BulkConfirmEventsData,
  BulkConfirmFormValues,
} from '../../utils/types';

interface AttestationModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  providerName: string;
  bulkConfirmEventsData: BulkConfirmEventsData;
  /**
   * ID of the parent form element, which is necesssary because Modals are rendered in a portal so
   * they are not direct children of the form.
   */
  formId: string;
}

const SAFETY_KEY = 'safety';

/**
 * Displays attestation checkboxes for each client along with a form submission button.
 * Intended for use in the bulk confirm flow, and assumes it is the child of a Formik context.
 */
export const AttestationModal = ({
  isOpen,
  onDismiss,
  providerName,
  bulkConfirmEventsData,
  formId,
}: AttestationModalProps) => {
  const { values, setValues, errors } =
    useFormikContext<BulkConfirmFormValues>();
  const [telehealthErrorId] = useState(
    uniqueId('telehealth-attestation-error-')
  );

  const hasAnyTelehealthAttestationErrors = Object.values(
    errors.sessions || {}
  ).some((session) => !!session?.telehealthAttestation);

  const sessionsNeedingTelehealthAttestation = Object.entries(values.sessions)
    .filter(([, session]) => shouldRequireTelehealthAttestation(session))
    .map(([virtualId]) => virtualId);
  const patientsNeedingTelehealthAttestation = uniqBy(
    sessionsNeedingTelehealthAttestation.map(
      (virtualId) =>
        bulkConfirmEventsData[virtualId].event.providerAppointment!.patient!
    ),
    (patient) => patient.id
  );

  const sessionCount = Object.keys(values.sessions).length;

  const checkboxItems = [
    {
      id: SAFETY_KEY,
      name:
        patientsNeedingTelehealthAttestation.length === 1
          ? `${providerName} assessed that the client they saw via virtual telehealth is in a safe, private, and known location`
          : `${providerName} assessed that the ${patientsNeedingTelehealthAttestation.length} clients they saw via virtual telehealth are in a safe, private, and known location.`,
      childItems: sortBy(
        patientsNeedingTelehealthAttestation,
        ({ displayLastName, lastName }) => displayLastName || lastName,
        ({ displayFirstName, firstName }) => displayFirstName || firstName
      ).map((patient) => ({
        id: patient.id,
        name: formatPatientName(patient),
      })),
    },
  ];

  const onSafetyAttestationSelectionChange = (
    selectedPatientIds: Set<string | number>
  ) => {
    // Updates all the telehealth attestations at once, since doing them as individual setFieldValue
    // calls results in validation issues. In order to do this, we clone the existing values object
    // and update the telehealth attestation for each session that needs it.
    setValues(
      (values) => ({
        ...values,
        sessions: {
          ...values.sessions,
          ...sessionsNeedingTelehealthAttestation.reduce(
            (acc, virtualId) => ({
              ...acc,
              [virtualId]: {
                ...values.sessions[virtualId],
                telehealthAttestation: selectedPatientIds.has(
                  bulkConfirmEventsData[virtualId].event.providerAppointment!
                    .patient!.id
                ),
              },
            }),
            {}
          ),
        },
      }),
      true
    );
  };

  const selectedKeys: Set<string | number> = new Set(
    patientsNeedingTelehealthAttestation
      .filter((patient) => {
        // Check if all sessions for this patient have been attested
        const relatedSessions = sessionsNeedingTelehealthAttestation.filter(
          (virtualId) =>
            bulkConfirmEventsData[virtualId].event.providerAppointment!.patient!
              .id === patient.id
        );
        return relatedSessions.every(
          (virtualId) => values.sessions[virtualId].telehealthAttestation
        );
      })
      .map((patient) => patient.id)
  );
  if (selectedKeys.size === patientsNeedingTelehealthAttestation.length) {
    selectedKeys.add(SAFETY_KEY);
  }

  return (
    <Modal
      isOpen={isOpen}
      title={`Attestations: ${sessionCount} sessions`}
      onDismiss={onDismiss}
    >
      <ModalContent>
        <div className="space-y-5">
          <FormControl component={Checkbox} name="notesAttestation">
            <span>
              <b>Progress notes:</b> I understand that compliant notes are
              required for payment. {providerName}'s notes are true, accurate
              and meet insurer requirements, and we'll provider our notes on
              request within 3 business days.
            </span>
          </FormControl>
          {patientsNeedingTelehealthAttestation.length > 0 && (
            <div>
              <CheckboxTree
                aria-label="Telehealth safety attestation"
                aria-describedby={
                  hasAnyTelehealthAttestationErrors
                    ? telehealthErrorId
                    : undefined
                }
                items={checkboxItems}
                defaultExpandedKeys={[SAFETY_KEY]}
                selectedKeys={selectedKeys}
                onSelectionChange={(selected) =>
                  onSafetyAttestationSelectionChange(selected as Set<string>)
                }
              >
                {(item) => {
                  return (
                    <CheckboxTreeItem
                      childItems={item.childItems}
                      item={item}
                      textValue={item.name}
                    >
                      {item.id === SAFETY_KEY && (
                        <b>Telehealth client safety: </b>
                      )}
                      {item.name}
                    </CheckboxTreeItem>
                  );
                }}
              </CheckboxTree>
              {/** CheckboxTree does not currently support validation, so we roll our own error message. */}
              {hasAnyTelehealthAttestationErrors && (
                <div id={telehealthErrorId}>
                  <ContentText
                    variant="caption/medium"
                    color="foreground/danger-secondary"
                  >
                    All boxes must be checked to continue.
                  </ContentText>
                </div>
              )}
            </div>
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        <Button onPress={onDismiss}>Cancel</Button>
        <Button form={formId} type="submit">
          Confirm {sessionCount} {pluralize('session', sessionCount)}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
