import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { BulkConfirm } from '~/legacy/views/Practice/BulkConfirm/BulkConfirm';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Modal } from '@headway/helix/Modal';
import {
  NavigationLink as HelixNavigationLink,
  Navigation,
  NavigationDivider,
  NavigationIconAcademy,
  NavigationIconClients,
  NavigationIconDashboard,
  NavigationIconHelpCenter,
} from '@headway/helix/Navigation';
import { useMediaQuery } from '@headway/helix/utils';
import { ProviderAddressProvider } from '@headway/ui/providers/ProviderAddressProvider';
import { theme as legacyTheme } from '@headway/ui/theme';

import { Header } from 'components/Header/Header';
import { ProviderSelect } from 'components/Header/ProviderSelect';
import { NavigationLink } from 'components/Sidebar/Navigation';
import { useAuthStore } from 'stores/AuthStore';
import { Billing } from 'views/Practice/Billing';
import { ManageTeam } from 'views/Practice/ManageTeam';

import { isGroupAdmin } from '../utils/access';

/**
 * Layout for the practice management dashboard.
 */
export const PracticeLayout = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const AuthStore = useAuthStore();
  const groupPracticeProvidersIds = AuthStore.groupPracticeProviders?.map(
    (provider: ProviderRead) => provider.id
  );

  // Use legacy theme for consistency with dashboard sidebar
  const isUnderMedium = useMediaQuery(
    `(max-width: ${legacyTheme.breakpoints.medium}px)`
  );

  useEffect(() => {
    if (!isUnderMedium) {
      setIsModalOpen(false);
    }
  }, [isUnderMedium]);

  if (!isGroupAdmin(AuthStore.user)) {
    return <Navigate replace to="/home" />;
  }

  const handleDrawerToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <ProviderAddressProvider providerIds={groupPracticeProvidersIds}>
      <Header
        startComponent={<ProviderSelect />}
        handleDrawerToggle={handleDrawerToggle}
      />
      <div className="flex bg-system-white">
        <div
          className={clsx(
            'h-screen bg-background-primary pt-[50px]',
            isUnderMedium ? 'hidden' : 'fixed'
          )}
        >
          <div className="grid h-full">
            <PracticeNavigation />
          </div>
        </div>
        <div
          className={clsx('min-h-screen flex-grow', {
            'ml-[238px]': !isUnderMedium,
          })}
        >
          <Routes>
            <Route path="billing" element={<Billing />}>
              <Route path="confirm" element={<BulkConfirm />} />
            </Route>
            <Route path="team" element={<ManageTeam />} />
            <Route
              path="*"
              element={<Navigate replace to="/practice/billing" />}
            />
          </Routes>
        </div>
        <Modal
          title="Navigation"
          variant="fullscreen"
          isOpen={isModalOpen}
          onDismiss={() => setIsModalOpen(false)}
        >
          <PracticeNavigation isFullWidth />
        </Modal>
      </div>
    </ProviderAddressProvider>
  );
};

const PracticeNavigation = ({ isFullWidth }: { isFullWidth?: boolean }) => {
  return (
    <Navigation layout={isFullWidth ? 'full-width' : 'default'}>
      <NavigationLink to="/practice/billing" icon={NavigationIconDashboard}>
        Billing
      </NavigationLink>
      <NavigationLink to="/practice/team" icon={NavigationIconClients}>
        Manage team
      </NavigationLink>
      <NavigationDivider />

      <HelixNavigationLink
        href="https://findheadway.zendesk.com/hc"
        icon={NavigationIconHelpCenter}
        target="_blank"
      >
        Help center
      </HelixNavigationLink>
      <HelixNavigationLink
        href="https://headway-university.northpass.com/app"
        icon={NavigationIconAcademy}
        target="_blank"
      >
        Headway Academy
      </HelixNavigationLink>
    </Navigation>
  );
};
