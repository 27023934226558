import { getLocalTimeZone } from '@internationalized/date';
import { parseAbsoluteToLocal, toCalendarDate } from '@internationalized/date';
import { Skeleton } from '@mui/material';
import { Formik, setNestedObjectValues } from 'formik';
import flatten from 'lodash/flatten';
import uniqueId from 'lodash/uniqueId';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { usePatientAddressesList } from '~/legacy/hooks/usePatientAddresses';
import { useProviderById } from '~/legacy/hooks/useProviderById';
import {
  useProviderEventCache,
  useProviderEventList,
} from '~/legacy/hooks/useProviderEvent';
import { useUpdateProviderEventMutation } from '~/legacy/mutations/providerEvent';
import { useMutationWithSideEffects } from '~/legacy/mutations/utils';

import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { toasts } from '@headway/helix/Toast';
import {
  getProviderDisplayFirstAndLast,
  getProviderDisplayFirstAndLastWithPrenomial,
} from '@headway/shared/utils/providers';
import { checkExhaustive } from '@headway/shared/utils/types';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';

import { SessionDetailsFormV2Values } from '../../AppointmentConfirmation/components/forms/SessionDetails/SessionDetailsFormV2';
import { convertFormValuesToProviderEventUpdate } from '../../AppointmentConfirmation/components/forms/SessionDetails/utils';
import { useConfirmSession } from '../../AppointmentConfirmation/hooks/formActions/useConfirmSession';
import { useSessionDetailsInitialValues } from '../../AppointmentConfirmation/hooks/initialValues/useSessionDetailsInitialValues';
import {
  BULK_CONFIRM_EVENT_URL_SEARCH_KEY,
  BULK_CONFIRM_NEW_URL_SEARCH_KEY,
} from '../utils/constants';
import { useBulkConfirmSideEffectsForBilling } from '../utils/queries';
import {
  BulkConfirmEventsData,
  BulkConfirmFormValues,
  BulkConfirmSubmissionStatus,
  SubmissionStatusMap,
} from '../utils/types';
import { SessionDetailsFormV2ValuesWithOptionalDate } from '../utils/types';
import { getBulkConfirmValidationSchema } from '../utils/validation';
import { AttestationModal } from './steps/AttestationModal';
import { DataEntryStep } from './steps/DataEntryStep';
import { ErrorCorrectionStep } from './steps/ErrorCorrectionStep';
import { SubmissionStep } from './steps/SubmissionStep';
import { SuccessStep } from './steps/SuccessStep';

interface SessionDetailsDataFetcherProps {
  provider: ProviderRead;
  event: ProviderEventRead;
  setInitialValues: React.Dispatch<React.SetStateAction<BulkConfirmFormValues>>;
}

// Our existing hook to fetch sessionDetailsInitialValues can only fetch for a single event
// This component allow us to map through each event and fetch sessionDetailsInitialValues for each one
const SessionDetailsDataFetcher = ({
  provider,
  event,
  setInitialValues,
}: SessionDetailsDataFetcherProps) => {
  const providerAppointment = event.providerAppointment;
  const patient = providerAppointment?.patient;
  const [hasSetInitialValuesForSession, setHasSetInitialValuesForSession] =
    useState(false);

  const { initialValues: sessionDetailsInitialValues } =
    useSessionDetailsInitialValues({
      event,
      provider,
      isEventLoading: false,
      patient,
    });

  useEffect(() => {
    if (
      sessionDetailsInitialValues &&
      event.virtualId &&
      event.providerAppointment?.status !==
        ProviderAppointmentStatus.CANCELED &&
      !hasSetInitialValuesForSession
    ) {
      setInitialValues((current) => ({
        ...current,
        sessions: {
          ...current.sessions,
          [event.virtualId]: sessionDetailsInitialValues,
        },
      }));
      setHasSetInitialValuesForSession(true);
    }
  }, [
    sessionDetailsInitialValues,
    event.virtualId,
    setInitialValues,
    hasSetInitialValuesForSession,
  ]);

  return null;
};

const createBulkConfirmEventDataMap = (
  unconfirmedEvents: ProviderEventRead[],
  allProviderAddresses: ProviderAddressRead[],
  allPatientAddresses: PatientAddressRead[]
): BulkConfirmEventsData => {
  const eventsData: BulkConfirmEventsData = {};

  unconfirmedEvents.forEach((unconfirmedEvent) => {
    const providerAddresses = allProviderAddresses.filter(
      (providerAddress: ProviderAddressRead) =>
        providerAddress.providerId === unconfirmedEvent.providerId
    );
    const patientAddresses = allPatientAddresses.filter(
      (patientAddress: PatientAddressRead) =>
        patientAddress.patientUserId ===
        unconfirmedEvent.providerAppointment?.patient?.id
    );

    eventsData[unconfirmedEvent.virtualId] = {
      event: unconfirmedEvent,
      providerAddresses,
      patientAddresses,
    };
  });

  return eventsData;
};

export const BulkConfirm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const eventVirtualIds = searchParams.getAll(
    BULK_CONFIRM_EVENT_URL_SEARCH_KEY
  );
  const [initiallyUnconfirmedVirtualIds, setInitiallyUnconfirmedVirtualIds] =
    useState<Set<string> | undefined>();
  const [initialValues, setInitialValues] = useState<BulkConfirmFormValues>({
    sessions: {},
    notesAttestation: false,
  });
  const { providerAddresses } = useContext(ProviderAddressContext);

  const providerEventQueries = useProviderEventList(
    eventVirtualIds.map((id) => ({
      queryKeyArgs: { eventIdOrVirtualId: id },
      options: { refetchOnWindowFocus: false, refetchOnMount: 'always' },
    }))
  );

  useEffect(() => {
    // Once all events are fetched the first time, we want to only include events that were unconfirmed
    // when this component first mounted.
    if (
      providerEventQueries.every((query) => query.isFetchedAfterMount) &&
      !initiallyUnconfirmedVirtualIds
    ) {
      setInitiallyUnconfirmedVirtualIds(
        new Set(
          providerEventQueries
            .filter(
              (query) =>
                query.data!.providerAppointment!.status ===
                ProviderAppointmentStatus.SCHEDULED
            )
            .map((query) => query.data!.virtualId)
        )
      );
    }
  }, [initiallyUnconfirmedVirtualIds, providerEventQueries]);

  const events = providerEventQueries
    .map((query) => query.data)
    .filter(
      (event) =>
        event &&
        (initiallyUnconfirmedVirtualIds || new Set()).has(event.virtualId)
    ) as ProviderEventRead[];

  const providerQuery = useProviderById(
    {
      providerId: events[0]?.providerId,
    },
    { refetchOnWindowFocus: false }
  );

  const patientAddressQueries = usePatientAddressesList(
    events.map((event) => ({
      queryKeyArgs: { patientId: event?.providerAppointment?.patient?.id },
      refetchOnWindowFocus: false,
    }))
  );

  const isLoading =
    providerQuery.isLoading ||
    providerEventQueries.some((query) => !query.isFetchedAfterMount) ||
    patientAddressQueries.some((query) => query.isLoading) ||
    initiallyUnconfirmedVirtualIds === undefined;

  const provider = providerQuery.data;

  const patientAddresses = flatten(
    patientAddressQueries.map((query) => query.data)
  ) as PatientAddressRead[];

  if (
    !isLoading &&
    (!provider || !events.length || !providerAddresses.length)
  ) {
    return <Navigate to="/practice/billing" replace />;
  }

  const bulkConfirmEventsData = !isLoading
    ? createBulkConfirmEventDataMap(events, providerAddresses, patientAddresses)
    : undefined;

  const date = events[0]?.startDate;
  const onDismiss = date
    ? () =>
        navigate(
          `/practice/billing?date=${toCalendarDate(
            parseAbsoluteToLocal(date)
          ).toString()}`
        )
    : () => navigate(-1);

  return (
    <>
      {provider &&
        events.map((event) => (
          <SessionDetailsDataFetcher
            key={event.virtualId}
            event={event}
            provider={provider}
            setInitialValues={setInitialValues}
          />
        ))}
      {isLoading ? (
        <BulkConfirmLoading
          provider={provider}
          date={date}
          onDismiss={onDismiss}
        />
      ) : (
        <BulkConfirmContent
          date={date!}
          onDismiss={onDismiss}
          initialValues={initialValues!}
          provider={provider!}
          bulkConfirmEventsData={bulkConfirmEventsData!}
        />
      )}
    </>
  );
};

interface BulkConfirmContentProps {
  onDismiss: () => void;
  initialValues: BulkConfirmFormValues;
  provider: ProviderRead;
  bulkConfirmEventsData: BulkConfirmEventsData;
  date: string;
}

const displayDateFormatter = Intl.DateTimeFormat('en-US', {
  month: 'short',
  day: 'numeric',
  timeZone: getLocalTimeZone(),
});

enum BulkConfirmStep {
  DATA_ENTRY = 'DATA_ENTRY',
  SUBMISSION = 'SUBMISSION',
  ERROR_CORRECTION = 'ERROR_CORRECTION',
  SUCCESS = 'SUCCESS',
}

/** Exported only for testing */
export const BulkConfirmContent = ({
  onDismiss,
  initialValues,
  provider,
  bulkConfirmEventsData,
  date,
}: BulkConfirmContentProps) => {
  const providerEventCache = useProviderEventCache();
  const [isAttestationModalOpen, setIsAttestationModalOpen] = useState(false);
  const formId = useState(uniqueId('bulk-confirm-form-'))[0];
  const updateProviderEventMutation = useUpdateProviderEventMutation();
  const { confirmSession } = useConfirmSession();
  const [searchParams] = useSearchParams();
  const [lastSubmittedValues, setLastSubmittedValues] = useState<
    BulkConfirmFormValues | undefined
  >();

  const [
    submissionStatusByEventVirtualId,
    setSubmissionStatusByEventVirtualId,
  ] = useState<SubmissionStatusMap>(
    Object.keys(bulkConfirmEventsData).reduce(
      (acc, eventVirtualId) => ({
        ...acc,
        [eventVirtualId]: BulkConfirmSubmissionStatus.UNSUBMITTED,
      }),
      {}
    )
  );

  useEffect(() => {
    setSubmissionStatusByEventVirtualId((prevStatus) =>
      Object.keys(bulkConfirmEventsData).reduce(
        (acc, eventVirtualId) => ({
          ...acc,
          [eventVirtualId]: prevStatus[eventVirtualId],
        }),
        {}
      )
    );
  }, [bulkConfirmEventsData]);

  const currentStep = useMemo(() => {
    const values = Object.values(submissionStatusByEventVirtualId);
    if (
      values.every(
        (status) => status === BulkConfirmSubmissionStatus.UNSUBMITTED
      )
    ) {
      return BulkConfirmStep.DATA_ENTRY;
    }
    if (
      values.some((status) => status === BulkConfirmSubmissionStatus.LOADING)
    ) {
      return BulkConfirmStep.SUBMISSION;
    }
    if (values.some((status) => status === BulkConfirmSubmissionStatus.ERROR)) {
      return BulkConfirmStep.ERROR_CORRECTION;
    }
    if (
      values.every((status) => status === BulkConfirmSubmissionStatus.SUCCESS)
    ) {
      return BulkConfirmStep.SUCCESS;
    }
    throw new Error('Unexpected submission status');
  }, [submissionStatusByEventVirtualId]);

  useEffect(() => {
    // Since we do not autosave, prompt the user to confirm before closing the tab/window if they
    // are not on the success screen.
    const unloadHandler = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    if (currentStep !== BulkConfirmStep.SUCCESS) {
      window.addEventListener('beforeunload', unloadHandler);
      return () => window.removeEventListener('beforeunload', unloadHandler);
    }
  }, [currentStep]);

  const sessionCount = Object.keys(submissionStatusByEventVirtualId).length;
  const sessionsWithErrors = Object.values(
    submissionStatusByEventVirtualId
  ).filter((status) => status === BulkConfirmSubmissionStatus.ERROR).length;

  const virtualIdsReadyForSubmission = Object.entries(
    submissionStatusByEventVirtualId
  )
    .filter(
      ([, status]) =>
        status === BulkConfirmSubmissionStatus.UNSUBMITTED ||
        status === BulkConfirmSubmissionStatus.ERROR ||
        status === BulkConfirmSubmissionStatus.LOADING
    )
    .map(([virtualId]) => virtualId);

  const saveAllDraftsMutation = useMutationWithSideEffects(
    async (values: BulkConfirmFormValues) => {
      return await Promise.all(
        virtualIdsReadyForSubmission.map((virtualId) =>
          updateProviderEventMutation.mutateAsync({
            eventIdOrVirtualId: virtualId,
            update: convertFormValuesToProviderEventUpdate(
              values.sessions[virtualId]
            ),
          })
        )
      );
    }
  );

  const bulkConfirmMutation = useMutationWithSideEffects(
    async (values: BulkConfirmFormValues) => {
      setIsAttestationModalOpen(false);
      // Update the submission status to LOADING for the events that are being submitted
      setSubmissionStatusByEventVirtualId((current) =>
        virtualIdsReadyForSubmission.reduce(
          (acc, virtualId) => ({
            ...acc,
            [virtualId]: BulkConfirmSubmissionStatus.LOADING,
          }),
          current
        )
      );

      // Save the draft details first before attempting confirmation.
      const updatedEvents = await saveAllDraftsMutation.mutateAsync(values);

      for (const event of updatedEvents) {
        const virtualId = event.virtualId;
        try {
          const { updatedEvent, errors } = await confirmSession({
            patient: event.providerAppointment!.patient,
            provider: provider,
            event,
            eventVirtualId: virtualId,
            values: values.sessions[virtualId] as SessionDetailsFormV2Values,
          });
          if (updatedEvent) {
            providerEventCache.setAndInvalidate(
              { eventIdOrVirtualId: virtualId },
              updatedEvent
            );
          }

          if (errors.length > 0) {
            setSubmissionStatusByEventVirtualId((current) => ({
              ...current,
              [virtualId]: BulkConfirmSubmissionStatus.ERROR,
            }));
          } else {
            setSubmissionStatusByEventVirtualId((current) => ({
              ...current,
              [virtualId]: BulkConfirmSubmissionStatus.SUCCESS,
            }));
          }
        } catch (e) {
          setSubmissionStatusByEventVirtualId((current) => ({
            ...current,
            [virtualId]: BulkConfirmSubmissionStatus.ERROR,
          }));
        }
      }
      setLastSubmittedValues(values);
    },
    {
      sideEffects: useBulkConfirmSideEffectsForBilling(),
    }
  );

  const handleSaveAndClose = async (values: BulkConfirmFormValues) => {
    await saveAllDraftsMutation.mutateAsync(values, {
      onSuccess: () => toasts.add('Drafts saved'),
    });
    onDismiss();
  };
  const isDismissable =
    !bulkConfirmMutation.isLoading && !saveAllDraftsMutation.isLoading;

  // If the URL contains a `new` query parameter, we know that the user is billing new sessions, and
  // we should make the initial date and duration empty.
  let filteredInitialValues = null;
  const isBillingNewSessions = searchParams.has(
    BULK_CONFIRM_NEW_URL_SEARCH_KEY
  );
  if (currentStep === BulkConfirmStep.DATA_ENTRY && isBillingNewSessions) {
    const updatedInitialValuesSessions: {
      [virtualId: string]: SessionDetailsFormV2ValuesWithOptionalDate;
    } = {};
    Object.entries(initialValues.sessions).forEach(([key, session]) => {
      const { duration, startDate, endDate, ...sessionValuesWithoutTime } =
        session;
      updatedInitialValuesSessions[key] = sessionValuesWithoutTime;
    });
    filteredInitialValues = {
      ...initialValues,
      sessions: updatedInitialValuesSessions,
    };
  }

  return (
    <Formik<BulkConfirmFormValues>
      initialValues={filteredInitialValues || initialValues}
      enableReinitialize={currentStep === BulkConfirmStep.DATA_ENTRY}
      onSubmit={(values) => bulkConfirmMutation.mutateAsync(values)}
      validationSchema={getBulkConfirmValidationSchema(
        submissionStatusByEventVirtualId,
        !!provider.isPrescriber,
        isAttestationModalOpen,
        lastSubmittedValues
      )}
    >
      {({ values, validateForm, setTouched }) => (
        <Modal
          isOpen
          variant="fullscreen"
          title={<BulkConfirmTitle provider={provider} date={date} />}
          isDismissable={isDismissable}
          onDismiss={() => handleSaveAndClose(values)}
        >
          <ModalContent>
            <SafeFormikForm id={formId}>
              {currentStep === BulkConfirmStep.DATA_ENTRY ? (
                <>
                  <DataEntryStep
                    provider={provider}
                    bulkConfirmEventsData={bulkConfirmEventsData}
                    submissionStatusByEventVirtualId={
                      submissionStatusByEventVirtualId
                    }
                    onDismiss={onDismiss}
                  />
                  <AttestationModal
                    formId={formId}
                    isOpen={isAttestationModalOpen}
                    onDismiss={() => setIsAttestationModalOpen(false)}
                    bulkConfirmEventsData={bulkConfirmEventsData}
                    providerName={getProviderDisplayFirstAndLast(provider)}
                  />
                </>
              ) : currentStep === BulkConfirmStep.SUBMISSION ? (
                <SubmissionStep
                  submissionStatusByEventVirtualId={
                    submissionStatusByEventVirtualId
                  }
                  bulkConfirmEventsData={bulkConfirmEventsData}
                />
              ) : currentStep === BulkConfirmStep.ERROR_CORRECTION ? (
                <ErrorCorrectionStep
                  provider={provider}
                  submissionStatusByEventVirtualId={
                    submissionStatusByEventVirtualId
                  }
                  bulkConfirmEventsData={bulkConfirmEventsData}
                />
              ) : currentStep === BulkConfirmStep.SUCCESS ? (
                <SuccessStep
                  provider={provider}
                  submissionStatusByEventVirtualId={
                    submissionStatusByEventVirtualId
                  }
                  bulkConfirmEventsData={bulkConfirmEventsData}
                />
              ) : (
                checkExhaustive(currentStep)
              )}
            </SafeFormikForm>
          </ModalContent>
          <ModalFooter>
            {currentStep === BulkConfirmStep.DATA_ENTRY ? (
              <>
                <Button
                  variant="secondary"
                  disabled={!isDismissable}
                  onPress={() => handleSaveAndClose(values)}
                >
                  Close and save as draft
                </Button>
                <Button
                  disabled={!isDismissable}
                  onPress={async () => {
                    // Validate the form (minus the attestation boxes) before opening the modal
                    const errors = await validateForm();
                    if (Object.keys(errors).length === 0) {
                      setIsAttestationModalOpen(true);
                    } else {
                      // Marking these fields as touched displays the error state in the table.
                      setTouched(setNestedObjectValues(errors, true));
                    }
                  }}
                >
                  Confirm {sessionCount} session{sessionCount > 1 ? 's' : ''}
                </Button>
              </>
            ) : currentStep === BulkConfirmStep.ERROR_CORRECTION ? (
              <>
                <Button
                  variant="secondary"
                  disabled={!isDismissable}
                  onPress={() => handleSaveAndClose(values)}
                >
                  Close and save as draft
                </Button>
                <Button disabled={!isDismissable} type="submit" form={formId}>
                  {sessionsWithErrors === 1
                    ? 'Resubmit unconfirmed session'
                    : `Resubmit unconfirmed sessions (${sessionsWithErrors})`}
                </Button>
              </>
            ) : currentStep === BulkConfirmStep.SUCCESS ? (
              <Button onPress={onDismiss}>Close</Button>
            ) : null}
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );
};

interface BulkConfirmLoadingProps {
  provider: ProviderRead | undefined;
  date: string | undefined;
  onDismiss: () => void;
}

/** Variant of the bulk confirm modal that displays before all data is populated */
const BulkConfirmLoading = ({
  provider,
  date,
  onDismiss,
}: BulkConfirmLoadingProps) => {
  return (
    <Modal
      isOpen
      variant="fullscreen"
      title={
        provider && date ? (
          <BulkConfirmTitle provider={provider} date={date} />
        ) : undefined
      }
      onDismiss={onDismiss}
    >
      <ModalContent>
        <div className="p-10">
          <Skeleton variant="rectangular" height={600} />
        </div>
      </ModalContent>
    </Modal>
  );
};

const BulkConfirmTitle = ({
  provider,
  date,
}: {
  provider: ProviderRead;
  date: string;
}) => (
  <>
    Confirm sessions for {getProviderDisplayFirstAndLastWithPrenomial(provider)}
    <span className="ml-2">
      <ContentText variant="body">
        {displayDateFormatter.format(new Date(date))}
      </ContentText>
    </span>
  </>
);
