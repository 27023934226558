import { LicenseTypes } from '@headway/api/models/LicenseTypes';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UnitedStatesAbbreviations } from '@headway/api/models/UnitedStatesAbbreviations';

// gemini.link(mamba/app/shared/constants/license_types.py)
export const licenseTypes: {
  [x: string]: {
    displayName: string;
    licenseTypeId?: string; // These values were obtained from our contact at Verifiable
    taxonomyCode?: string;
  };
} = {
  // Taxonomy Code - 101YA0400X
  [LicenseTypes.LADC_I]: {
    displayName: 'LADC-I (Licensed Alcohol and Drug Counselor)',
    licenseTypeId: '07448e15-632c-494f-a98e-0c1b01a599fb',
    taxonomyCode: '101YA0400X',
  },
  [LicenseTypes.CADC]: {
    displayName: 'CADC - Addiction (Substance Use Disorder) Counselor',
    licenseTypeId: '07448e15-632c-494f-a98e-0c1b01a599fb',
    taxonomyCode: '101YA0400X',
  },
  [LicenseTypes.LCAS]: {
    displayName: 'LCAS - Addiction (Substance Use Disorder) Counselor',
    licenseTypeId: '07448e15-632c-494f-a98e-0c1b01a599fb',
    taxonomyCode: '101YA0400X',
  },
  [LicenseTypes.LISAC]: {
    displayName: 'LISAC - Licensed Independent Substance Abuse Counselor',
    licenseTypeId: '07448e15-632c-494f-a98e-0c1b01a599fb',
    taxonomyCode: '101YA0400X',
  },
  [LicenseTypes.LCADC]: {
    displayName: 'LCADC - Addiction (Substance Use Disorder) Counselor',
    licenseTypeId: 'd43cfd9c-d758-4521-b77e-eeafead00700',
    taxonomyCode: '101YA0400X',
  },
  // Taxonomy Code - 363LP0808X
  [LicenseTypes.APN]: {
    displayName: 'APN (Advanced Practice Nurse)',
    licenseTypeId: 'b8119f7f-d3d3-4c37-9cc0-f6c77cd7cb14',
    taxonomyCode: '363LP0808X',
  },
  [LicenseTypes.APRN]: {
    displayName: 'APRN (Advanced Practice Registered Nurse)',
    licenseTypeId: 'b8119f7f-d3d3-4c37-9cc0-f6c77cd7cb14',
    taxonomyCode: '363LP0808X',
  },
  [LicenseTypes.APRN_OUT_OF_STATE]: {
    displayName:
      'APRN (Advanced Practice Registered Nurse) Out-of-State Telehealth',
    licenseTypeId: '6c8b836c-8caa-4a03-a4e6-555581591655',
    taxonomyCode: '363LP0808X',
  },
  [LicenseTypes.APRN_FPA]: {
    displayName:
      'FPA APRN (Full Practice Authority Advanced Practice Registered Nurse)',
    licenseTypeId: '273df472-2b51-4afc-bc4a-b16bc888eab6',
    taxonomyCode: '363LP0808X',
  },
  [LicenseTypes.CRNP]: {
    displayName: 'CRNP (Certified Registered Nurse Practitioner)',
    licenseTypeId: 'b8119f7f-d3d3-4c37-9cc0-f6c77cd7cb14',
    taxonomyCode: '363LP0808X',
  },
  [LicenseTypes.NP]: {
    displayName: 'NP (Nurse Practitioner)',
    licenseTypeId: 'b8119f7f-d3d3-4c37-9cc0-f6c77cd7cb14',
    taxonomyCode: '363LP0808X',
  },
  [LicenseTypes.APNP]: {
    displayName: 'APNP (Advanced Practice Nurse Prescriber)',
    licenseTypeId: 'b8119f7f-d3d3-4c37-9cc0-f6c77cd7cb14',
    taxonomyCode: '363LP0808X',
  },
  [LicenseTypes.ARNP]: {
    displayName: 'ARNP (Advanced Registered Nurse Practitioner)',
    licenseTypeId: 'b8119f7f-d3d3-4c37-9cc0-f6c77cd7cb14',
    taxonomyCode: '363LP0808X',
  },
  [LicenseTypes.APRN_CNS]: {
    displayName:
      'APRN-CNS (Advanced Practice Registered Nurse - Clinical Nurse Specialist)',
    licenseTypeId: 'b8119f7f-d3d3-4c37-9cc0-f6c77cd7cb14',
    taxonomyCode: '363LP0808X',
  },
  [LicenseTypes.APRN_CNP]: {
    displayName:
      'APRN-CNP (Advanced Practice Registered Nurse - Certified Nurse Practitioner)',
    licenseTypeId: 'b8119f7f-d3d3-4c37-9cc0-f6c77cd7cb14',
    taxonomyCode: '363LP0808X',
  },
  [LicenseTypes.APRN_NP]: {
    displayName:
      'APRN-NP (Advanced Practice Registered Nurse - Nurse Practitioner)',
    licenseTypeId: 'b8119f7f-d3d3-4c37-9cc0-f6c77cd7cb14',
    taxonomyCode: '363LP0808X',
  },
  [LicenseTypes.TLHT_APRN_CNP]: {
    displayName:
      'TLHT-APRN-CNP (Telehealth Advanced Practice Registered Nurse - Certified Nurse Practitioner)',
    licenseTypeId: 'b8119f7f-d3d3-4c37-9cc0-f6c77cd7cb14',
    taxonomyCode: '363LP0808X',
  },
  [LicenseTypes.CNP]: {
    displayName: 'CNP (Certified Nurse Practitioner)',
    licenseTypeId: 'b8119f7f-d3d3-4c37-9cc0-f6c77cd7cb14',
    taxonomyCode: '363LP0808X',
  },

  // Taxonomy Code - 103TC0700X
  [LicenseTypes.CP]: {
    displayName: 'CP (Clinical Psychologist)',
    licenseTypeId: 'a4fa6129-ad82-49f6-b768-b5bc8a06a01e',
    taxonomyCode: '103TC0700X',
  },
  [LicenseTypes.MP]: {
    displayName: 'MP (Medical Psychologist)',
    licenseTypeId: 'a4fa6129-ad82-49f6-b768-b5bc8a06a01e',
    taxonomyCode: '103TC0700X',
  },
  [LicenseTypes.PSYCHOLOGIST]: {
    displayName: 'Psychologist',
    licenseTypeId: 'a4fa6129-ad82-49f6-b768-b5bc8a06a01e',
    taxonomyCode: '103TC0700X',
  },
  [LicenseTypes.PSYCHOLOGIST_OUT_OF_STATE]: {
    displayName: 'Psychologist Out-of-State Telehealth',
    licenseTypeId: 'a3b723b8-17af-42b8-b72c-936d467dc2e3',
    taxonomyCode: '103TC0700X',
  },
  [LicenseTypes.LICENSED_PSYCHOLOGIST]: {
    displayName: 'LP (Licensed Psychologist)',
    licenseTypeId: 'a4fa6129-ad82-49f6-b768-b5bc8a06a01e',
    taxonomyCode: '103TC0700X',
  },
  [LicenseTypes.PSY]: {
    displayName: 'PSY (Psychologist)',
    licenseTypeId: 'a4fa6129-ad82-49f6-b768-b5bc8a06a01e',
    taxonomyCode: '103TC0700X',
  },
  [LicenseTypes.PSYP]: {
    displayName: 'PSYP (Prescribing Psychologist)',
    licenseTypeId: 'a4fa6129-ad82-49f6-b768-b5bc8a06a01e',
    taxonomyCode: '103TC0700X',
  },
  [LicenseTypes.PSYD]: {
    displayName: 'PSYD (Psychologist, Doctor of Psychology)',
    licenseTypeId: 'a4fa6129-ad82-49f6-b768-b5bc8a06a01e',
    taxonomyCode: '103TC0700X',
  },
  [LicenseTypes.PHD]: {
    displayName: 'PHD (Psychologist, Doctor of Philosophy)',
    licenseTypeId: 'a4fa6129-ad82-49f6-b768-b5bc8a06a01e',
    taxonomyCode: '103TC0700X',
  },
  // Taxonomy Code - 2084P0800X
  [LicenseTypes.DO]: {
    displayName: 'DO (Doctor of Osteopathic Medicine)',
    licenseTypeId: '47ab732e-478c-45c1-bfcf-2947add3d458',
    taxonomyCode: '2084P0800X',
  },
  [LicenseTypes.MD]: {
    displayName: 'MD (Medical Doctor)',
    licenseTypeId: '25cb4aee-c0c2-419b-b7c0-fd37169e9efb',
    taxonomyCode: '2084P0800X',
  },
  // Taxonomy Code - 221700000X
  [LicenseTypes.LCAT]: {
    displayName: 'LCAT (Licensed Creative Arts Therapist)',
    licenseTypeId: '33244e25-717a-42a3-9894-8186f76abc43',
    taxonomyCode: '221700000X',
  },
  // Taxonomy Code - 101YM0800X
  [LicenseTypes.LCMHC]: {
    displayName: 'LCMHC (Licensed Clinical Mental Health Counselor)',
    licenseTypeId: 'afb3b803-37cd-4782-8994-285cc458ebdf',
    taxonomyCode: '101YM0800X',
  },
  [LicenseTypes.LCMHC_S]: {
    displayName:
      'LCMHC-S (Licensed Clinical Mental Health Counselor Supervisor)',
    licenseTypeId: 'bb1339de-30a6-4d93-b699-6428ebc43aba',
    taxonomyCode: '101YM0800X',
  },
  [LicenseTypes.LMHC]: {
    displayName: 'LMHC (Licensed Mental Health Counselor)',
    licenseTypeId: 'afb3b803-37cd-4782-8994-285cc458ebdf',
    taxonomyCode: '101YM0800X',
  },
  [LicenseTypes.LPCMHC]: {
    displayName: 'LPCMHC (Professional Clinical Mental Health Counselor)',
    licenseTypeId: 'afb3b803-37cd-4782-8994-285cc458ebdf',
    taxonomyCode: '101YM0800X',
  },
  [LicenseTypes.LMHC_OUT_OF_STATE]: {
    displayName:
      'LMHC (Licensed Mental Health Counselor) Out-of-State Telehealth',
    licenseTypeId: '746924e2-0cea-4a2e-b2c5-834cc4d9a8a6',
    taxonomyCode: '101YM0800X',
  },
  [LicenseTypes.CMHC]: {
    displayName: 'CMHC (Clinical Mental Health Counselor)',
    licenseTypeId: 'afb3b803-37cd-4782-8994-285cc458ebdf',
    taxonomyCode: '101YM0800X',
  },
  [LicenseTypes.LIMHP]: {
    displayName: 'LIMHP (Licensed Independent Mental Health Practitioner)',
    licenseTypeId: 'afb3b803-37cd-4782-8994-285cc458ebdf',
    taxonomyCode: '101YM0800X',
  },
  // Taxonomy Code - 101YP2500X
  [LicenseTypes.LCPC]: {
    displayName: 'LCPC (Licensed Clinical Professional Counselor)',
    licenseTypeId: 'c82af0ba-8fb5-4fcf-9d2d-9ef4b12e7fc9',
    taxonomyCode: '101YP2500X',
  },
  [LicenseTypes.LPC]: {
    displayName: 'LPC (Licensed Professional Counselor)',
    licenseTypeId: '3d1c90ef-20f6-44d0-a6bc-e7562e252a4b',
    taxonomyCode: '101YP2500X',
  },
  [LicenseTypes.LPCC_S]: {
    displayName: 'LPCC-S (Licensed Professional Clinical Counselor Supervisor)',
    licenseTypeId: 'd283a3b4-7055-4c7a-9af4-ff3a8cead646',
    taxonomyCode: '101YP2500X',
  },
  [LicenseTypes.LPCC]: {
    displayName: 'LPCC (Licensed Professional Clinical Counselor)',
    licenseTypeId: 'c82af0ba-8fb5-4fcf-9d2d-9ef4b12e7fc9',
    taxonomyCode: '101YP2500X',
  },
  [LicenseTypes.LPCMHSP]: {
    displayName:
      'LPCMHSP (Licensed Professional Counselor Mental Health Service Provider)',
    licenseTypeId: '3d1c90ef-20f6-44d0-a6bc-e7562e252a4b',
    taxonomyCode: '101YP2500X',
  },
  [LicenseTypes.CPC]: {
    displayName: 'CPC (Clinical Professional Counselor)',
    licenseTypeId: 'c82af0ba-8fb5-4fcf-9d2d-9ef4b12e7fc9',
    taxonomyCode: '101YP2500X',
  },
  [LicenseTypes.LCPCS]: {
    displayName: 'LCPCS (Licensed Clinical Professional Counselor Senior)',
    licenseTypeId: 'c82af0ba-8fb5-4fcf-9d2d-9ef4b12e7fc9',
    taxonomyCode: '101YP2500X',
  },
  [LicenseTypes.LPCMH]: {
    displayName: 'LPCMH (Licensed Professional Counselor of Mental Health)',
    licenseTypeId: '3d1c90ef-20f6-44d0-a6bc-e7562e252a4b',
    taxonomyCode: '101YP2500X',
  },
  [LicenseTypes.LPC_S]: {
    displayName: 'LPC-S (Licensed Professional Counselor Supervisor)',
    licenseTypeId: 'e8467f17-5a05-4669-ae9d-1a3e6fea8741',
    taxonomyCode: '101YP2500X',
  },
  // Taxonomy Code - 1041C0700X
  [LicenseTypes.LCSW]: {
    displayName: 'LCSW (Licensed Clinical Social Worker)',
    licenseTypeId: 'ec36ca23-0eb4-4e89-a651-409bbfebd63c',
    taxonomyCode: '1041C0700X',
  },
  [LicenseTypes.LCSW_C]: {
    displayName: 'LCSW (Licensed Clinical Social Worker - Clinical)',
    licenseTypeId: 'ec36ca23-0eb4-4e89-a651-409bbfebd63c',
    taxonomyCode: '1041C0700X',
  },
  [LicenseTypes.LMSW]: {
    displayName: 'LMSW (Licensed Master Social Worker)',
    licenseTypeId: 'ec36ca23-0eb4-4e89-a651-409bbfebd63c',
    taxonomyCode: '1041C0700X',
  },
  [LicenseTypes.LISW]: {
    displayName: 'LISW (Licensed Independent Social Worker)',
    licenseTypeId: 'ec36ca23-0eb4-4e89-a651-409bbfebd63c',
    taxonomyCode: '1041C0700X',
  },
  [LicenseTypes.LISW_S]: {
    displayName: 'LISW-S (Licensed Independent Social Worker - Supervisor)',
    licenseTypeId: 'ec36ca23-0eb4-4e89-a651-409bbfebd63c',
    taxonomyCode: '1041C0700X',
  },
  [LicenseTypes.LICSW]: {
    displayName: 'LICSW (Licensed Independent Clinical Social Worker)',
    licenseTypeId: 'ec36ca23-0eb4-4e89-a651-409bbfebd63c',
    taxonomyCode: '1041C0700X',
  },
  [LicenseTypes.LICSW_OUT_OF_STATE]: {
    displayName:
      'LICSW (Licensed Independent Clinical Social Worker) Out-of-State Telehealth',
    licenseTypeId: '04008c78-cf09-409f-a0c0-5f7e1943b924',
    taxonomyCode: '1041C0700X',
  },
  [LicenseTypes.LCSW_OUT_OF_STATE]: {
    displayName:
      'LCSW (Licensed Clinical Social Worker) Out-of-State Telehealth',
    licenseTypeId: '04008c78-cf09-409f-a0c0-5f7e1943b924',
    taxonomyCode: '1041C0700X',
  },
  [LicenseTypes.CSW_PIP]: {
    displayName:
      'CSW-PIP (Certified Social Worker in Private or Independent Practice)',
    licenseTypeId: 'ec36ca23-0eb4-4e89-a651-409bbfebd63c',
    taxonomyCode: '1041C0700X',
  },
  [LicenseTypes.LSCSW]: {
    displayName: 'LSCSW (Licensed Specialist Clinical Social Worker)',
    licenseTypeId: 'ec36ca23-0eb4-4e89-a651-409bbfebd63c',
    taxonomyCode: '1041C0700X',
  },
  [LicenseTypes.LISW_CP]: {
    displayName:
      'LISW-CP (Licensed independent social worker–clinical practice)',
    licenseTypeId: 'ec36ca23-0eb4-4e89-a651-409bbfebd63c',
    taxonomyCode: '1041C0700X',
  },
  [LicenseTypes.LICSW_PIP]: {
    displayName:
      'LICSW-PIP (Licensed Independent Clinical Social Worker - Private Independent Practice)',
    licenseTypeId: 'ec36ca23-0eb4-4e89-a651-409bbfebd63c',
    taxonomyCode: '1041C0700X',
  },
  [LicenseTypes.LC]: {
    displayName: 'LC (Licensed Clinical Social Worker)',
    licenseTypeId: 'ec36ca23-0eb4-4e89-a651-409bbfebd63c',
    taxonomyCode: '1041C0700X',
  },
  // Taxonomy Code - 103TP0814X
  [LicenseTypes.LP]: {
    displayName: 'LP (Licensed Psychoanalyst)',
    licenseTypeId: 'e275c8d8-42f7-4b3a-b5cd-786f2fc57f3a',
    taxonomyCode: '103TP0814X',
  },
  // Taxonomy Code - 106H00000X
  [LicenseTypes.MFT]: {
    displayName: 'LMFT (Licensed Marriage/Family Therapist)',
    licenseTypeId: 'bafd28e8-29d1-44c3-8765-9479679e4066',
    taxonomyCode: '106H00000X',
  },
  [LicenseTypes.MFT_OUT_OF_STATE]: {
    displayName:
      'LMFT (Licensed Marriage/Family Therapist) Out-of-State Telehealth',
    licenseTypeId: 'd3653279-a334-4419-8d35-78c7e3fa59f4',
    taxonomyCode: '106H00000X',
  },
  [LicenseTypes.LIMFT]: {
    displayName: 'LIMFT (Licensed Independent Marriage and Family Therapist)',
    licenseTypeId: 'bafd28e8-29d1-44c3-8765-9479679e4066',
    taxonomyCode: '106H00000X',
  },
  [LicenseTypes.LMFT]: {
    displayName: 'LMFT (Licensed Marriage/Family Therapist)',
    licenseTypeId: 'bafd28e8-29d1-44c3-8765-9479679e4066',
    taxonomyCode: '106H00000X',
  },
  [LicenseTypes.LCMFT]: {
    displayName: 'LCMFT (Licensed Clinical Marriage and Family Therapist)',
    licenseTypeId: 'bafd28e8-29d1-44c3-8765-9479679e4066',
    taxonomyCode: '106H00000X',
  },
  [LicenseTypes.LMFTS]: {
    displayName: 'LMFTS (Licensed Marriage and Family Therapist Supervisor)',
    licenseTypeId: 'bafd28e8-29d1-44c3-8765-9479679e4066',
    taxonomyCode: '106H00000X',
  },
  // Taxonomy Code - 364SP0808X
  [LicenseTypes.CNS]: {
    displayName: 'CNS (Clinical Nurse Specialist)',
    licenseTypeId: '661fcbef-9df1-4a13-94a3-77d95a86730d',
    taxonomyCode: '364SP0808X',
  },
  [LicenseTypes.PCNS]: {
    displayName: 'PCNS (Psychiatric Clinical Nurse Specialist)',
    licenseTypeId: '661fcbef-9df1-4a13-94a3-77d95a86730d',
    taxonomyCode: '364SP0808X',
  },
  [LicenseTypes.OTHER]: {
    displayName: 'Other',
  },
};
// gemini.endlink

// gemini.link(mamba/app/shared/constants/supported_state_license_types.py)
export const marketLicenseTypes: {
  [x: string]: LicenseTypes[];
} = {
  [UnitedStates.FLORIDA]: [
    LicenseTypes.APRN,
    LicenseTypes.APRN_OUT_OF_STATE,
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    LicenseTypes.LCSW_OUT_OF_STATE,
    LicenseTypes.LMFT,
    LicenseTypes.MFT_OUT_OF_STATE,
    LicenseTypes.LMHC,
    LicenseTypes.LMHC_OUT_OF_STATE,
    LicenseTypes.MD,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
    LicenseTypes.PSYCHOLOGIST_OUT_OF_STATE,
  ],
  [UnitedStates.NEW_YORK]: [
    LicenseTypes.LCAT,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.LMHC,
    LicenseTypes.LP,
    LicenseTypes.NP,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.CP,
  ],
  [UnitedStates.NORTH_CAROLINA]: [
    LicenseTypes.CNS,
    LicenseTypes.DO,
    LicenseTypes.LCAS,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.LCMHC,
    LicenseTypes.LCMHC_S,
    LicenseTypes.MD,
    LicenseTypes.NP,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
  ],
  [UnitedStates.NEW_JERSEY]: [
    LicenseTypes.APN,
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    LicenseTypes.LCADC,
    LicenseTypes.LMFT,
    LicenseTypes.LPC,
    LicenseTypes.MD,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
  ],
  [UnitedStates.TEXAS]: [
    LicenseTypes.APRN_CNP,
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.LPC,
    LicenseTypes.MD,
    LicenseTypes.PSYCHOLOGIST,
  ],
  [UnitedStates.COLORADO]: [
    LicenseTypes.APN,
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.LPC,
    LicenseTypes.MD,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.CNS,
    LicenseTypes.NP,
  ],
  [UnitedStates.GEORGIA]: [
    LicenseTypes.APRN,
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.LPC,
    LicenseTypes.MD,
    LicenseTypes.PSYCHOLOGIST,
  ],
  [UnitedStates.MICHIGAN]: [
    LicenseTypes.APN,
    LicenseTypes.DO,
    LicenseTypes.LMFT,
    LicenseTypes.LMSW,
    LicenseTypes.LPC,
    LicenseTypes.MD,
    LicenseTypes.NP,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
  ],
  [UnitedStates.VIRGINIA]: [
    LicenseTypes.CNS,
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.NP,
    LicenseTypes.LPC,
    LicenseTypes.MD,
    LicenseTypes.PSYCHOLOGIST,
  ],
  [UnitedStates.ILLINOIS]: [
    LicenseTypes.APRN,
    LicenseTypes.APRN_FPA,
    LicenseTypes.CNS,
    LicenseTypes.DO,
    LicenseTypes.LCPC,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.MD,
    LicenseTypes.CP,
  ],
  [UnitedStates.WASHINGTON]: [
    LicenseTypes.ARNP,
    LicenseTypes.LICSW,
    LicenseTypes.LMFT,
    LicenseTypes.LMHC,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSYCHOLOGIST,
  ],
  [UnitedStates.TENNESSEE]: [
    LicenseTypes.APRN,
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.LPCMHSP,
    LicenseTypes.MD,
    LicenseTypes.PSYCHOLOGIST,
  ],
  [UnitedStates.MASSACHUSETTS]: [
    LicenseTypes.APRN,
    LicenseTypes.DO,
    LicenseTypes.LICSW,
    LicenseTypes.LMFT,
    LicenseTypes.LMHC,
    LicenseTypes.MD,
    LicenseTypes.CNS,
    LicenseTypes.CP,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
    LicenseTypes.LADC_I,
  ],
  [UnitedStates.MINNESOTA]: [
    LicenseTypes.APRN,
    LicenseTypes.APRN_CNP,
    LicenseTypes.DO,
    LicenseTypes.LICSW,
    LicenseTypes.LMFT,
    LicenseTypes.LPC,
    LicenseTypes.LPCC,
    LicenseTypes.MD,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
  ],
  [UnitedStates.OREGON]: [
    LicenseTypes.APRN_NP,
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.LPC,
    LicenseTypes.CADC,
    LicenseTypes.MD,
    LicenseTypes.PSYCHOLOGIST,
  ],
  [UnitedStates.LOUISIANA]: [
    LicenseTypes.APRN,
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    //TODO LCSW-BACS
    LicenseTypes.LMFT,
    LicenseTypes.LMFTS,
    LicenseTypes.LPC,
    LicenseTypes.LPC_S,
    LicenseTypes.MD,
    LicenseTypes.MP,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
  ],
  [UnitedStates.CONNECTICUT]: [
    LicenseTypes.APRN,
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    LicenseTypes.MFT,
    LicenseTypes.LPC,
    LicenseTypes.MD,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
  ],
  [UnitedStates.PENNSYLVANIA]: [
    LicenseTypes.CNS,
    LicenseTypes.CRNP,
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.LPC,
    LicenseTypes.MD,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
  ],
  [UnitedStates.DISTRICT_OF_COLUMBIA]: [
    LicenseTypes.NP,
    LicenseTypes.LICSW,
    LicenseTypes.MFT,
    LicenseTypes.LPC,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.CP,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
  ],
  [UnitedStates.MARYLAND]: [
    LicenseTypes.APRN,
    LicenseTypes.DO,
    LicenseTypes.MFT,
    LicenseTypes.LCPC,
    LicenseTypes.LCSW_C,
    LicenseTypes.MD,
    LicenseTypes.CP,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
  ],
  [UnitedStates.ARIZONA]: [
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.LPC,
    LicenseTypes.LISAC,
    LicenseTypes.MD,
    LicenseTypes.APRN_CNP,
    LicenseTypes.PSYCHOLOGIST,
  ],
  [UnitedStates.OHIO]: [
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
    LicenseTypes.CP,
    LicenseTypes.APRN_CNS,
    LicenseTypes.APRN_CNP,
    LicenseTypes.LPCC,
    LicenseTypes.LPCC_S,
    LicenseTypes.LMFT,
    LicenseTypes.LIMFT,
    LicenseTypes.LISW,
    LicenseTypes.LISW_S,
    LicenseTypes.CNS,
  ],
  [UnitedStates.CALIFORNIA]: [
    LicenseTypes.CNS,
    LicenseTypes.NP,
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.LPCC,
    LicenseTypes.MD,
    LicenseTypes.CP,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
  ],
  [UnitedStates.ALASKA]: [
    LicenseTypes.LPC,
    LicenseTypes.LMFT,
    LicenseTypes.LCSW,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.APRN,
  ],
  [UnitedStates.ALABAMA]: [
    LicenseTypes.LPC,
    LicenseTypes.LICSW,
    LicenseTypes.LICSW_PIP,
    LicenseTypes.CRNP,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.LMFT,
    LicenseTypes.CNS,
  ],
  [UnitedStates.ARKANSAS]: [
    LicenseTypes.LPC,
    LicenseTypes.LCSW,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.APRN_CNP,
    LicenseTypes.CNS,
  ],
  [UnitedStates.DELAWARE]: [
    LicenseTypes.LPCMH,
    LicenseTypes.LMFT,
    LicenseTypes.LCSW,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.APRN,
  ],
  [UnitedStates.HAWAII]: [
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.APRN,
    LicenseTypes.LMHC,
    LicenseTypes.LMFT,
    LicenseTypes.LCSW,
    LicenseTypes.CSAC,
  ],
  [UnitedStates.IOWA]: [
    LicenseTypes.ARNP,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.LMHC,
    LicenseTypes.LISW,
    LicenseTypes.LMFT,
    LicenseTypes.PSYCHOLOGIST,
  ],
  [UnitedStates.IDAHO]: [
    LicenseTypes.LCPC,
    LicenseTypes.LCPCS,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.LMFTS,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSY,
    LicenseTypes.PSYP,
    LicenseTypes.APRN,
    LicenseTypes.CNS,
  ],
  [UnitedStates.INDIANA]: [
    LicenseTypes.LCSW,
    LicenseTypes.LMHC,
    LicenseTypes.LMFT,
    LicenseTypes.APRN,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.NP_COMPACT,
  ],
  [UnitedStates.KANSAS]: [
    LicenseTypes.APRN_CNS,
    LicenseTypes.APRN_NP,
    LicenseTypes.DO,
    LicenseTypes.LCMFT,
    LicenseTypes.LCPC,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
    LicenseTypes.LSCSW,
    LicenseTypes.MD,
  ],
  [UnitedStates.KENTUCKY]: [
    LicenseTypes.LPCC,
    LicenseTypes.LCSW,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.LMFT,
    LicenseTypes.APRN,
    LicenseTypes.CNS,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
  ],
  [UnitedStates.MAINE]: [
    LicenseTypes.LCPC,
    LicenseTypes.LC,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.LMFT,
    LicenseTypes.APRN_CNP,
  ],
  [UnitedStates.MISSOURI]: [
    LicenseTypes.APRN,
    LicenseTypes.CNS,
    LicenseTypes.DO,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
    LicenseTypes.LPC,
    LicenseTypes.MD,
    LicenseTypes.NP,
  ],
  [UnitedStates.MISSISSIPPI]: [
    LicenseTypes.LPC,
    LicenseTypes.LPC_S,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.APRN_CNP,
  ],
  [UnitedStates.MONTANA]: [
    LicenseTypes.LCPC,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.APRN_CNP,
  ],
  [UnitedStates.NORTH_DAKOTA]: [
    LicenseTypes.LPCC,
    LicenseTypes.LMFT,
    LicenseTypes.LCSW,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.APRN,
  ],
  [UnitedStates.NEBRASKA]: [
    LicenseTypes.LIMHP,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.APRN,
  ],
  [UnitedStates.NEW_HAMPSHIRE]: [
    LicenseTypes.LCMHC,
    LicenseTypes.LICSW,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.APRN,
    LicenseTypes.LMFT,
  ],
  [UnitedStates.NEW_MEXICO]: [
    LicenseTypes.LPCC,
    LicenseTypes.LPCMHC,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.LICENSED_PSYCHOLOGIST,
    LicenseTypes.PSYP,
    LicenseTypes.APRN_CNP,
    LicenseTypes.APRN_CNS,
  ],
  [UnitedStates.NEVADA]: [
    LicenseTypes.CPC,
    LicenseTypes.MFT,
    LicenseTypes.LCSW,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.APRN_CNP,
    LicenseTypes.APRN_CNS,
  ],
  [UnitedStates.OKLAHOMA]: [
    LicenseTypes.LPC,
    LicenseTypes.LCSW,
    LicenseTypes.LMFT,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.APRN_CNP,
  ],
  [UnitedStates.RHODE_ISLAND]: [
    LicenseTypes.LMHC,
    LicenseTypes.LMFT,
    LicenseTypes.LICSW,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.APRN_CNP,
  ],
  [UnitedStates.SOUTH_CAROLINA]: [
    LicenseTypes.LPC,
    LicenseTypes.LPC_S,
    LicenseTypes.LMFT,
    LicenseTypes.LISW_CP,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.APRN,
    LicenseTypes.PSYCHOLOGIST,
  ],
  [UnitedStates.SOUTH_DAKOTA]: [
    LicenseTypes.LPC,
    LicenseTypes.LPCMH,
    LicenseTypes.LMFT,
    LicenseTypes.CSW_PIP,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PHD,
    LicenseTypes.PSYD,
    LicenseTypes.CNP,
  ],
  [UnitedStates.UTAH]: [
    LicenseTypes.APRN,
    LicenseTypes.CMHC,
    LicenseTypes.LMFT,
    LicenseTypes.LCSW,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSYCHOLOGIST,
  ],
  [UnitedStates.VERMONT]: [
    LicenseTypes.LCMHC,
    LicenseTypes.LMFT,
    LicenseTypes.LICSW,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PHD,
    LicenseTypes.PSYD,
    LicenseTypes.APRN,
  ],
  [UnitedStates.WISCONSIN]: [
    LicenseTypes.LPC,
    LicenseTypes.LMFT,
    LicenseTypes.LCSW,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.APNP,
  ],
  [UnitedStates.WEST_VIRGINIA]: [
    LicenseTypes.LPC,
    LicenseTypes.LMFT,
    LicenseTypes.LICSW,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.APRN_CNP,
    LicenseTypes.TLHT_APRN_CNP,
  ],
  [UnitedStates.WYOMING]: [
    LicenseTypes.LPC,
    LicenseTypes.LMFT,
    LicenseTypes.LCSW,
    LicenseTypes.MD,
    LicenseTypes.DO,
    LicenseTypes.PSYCHOLOGIST,
    LicenseTypes.APRN,
  ],
};
// gemini.endlink

export const nationalSudAddictionLicenseTypes = [
  LicenseTypes.NCAC_I,
  LicenseTypes.NCAC_II,
  LicenseTypes.MAC,
  LicenseTypes.NDS,
  LicenseTypes.NCAAC,
  LicenseTypes.NCPRSS,
  LicenseTypes.NCSE,
];

export const marketSudAddictionLicenseTypes: {
  [x: string]: LicenseTypes[];
} = {
  [UnitedStatesAbbreviations.NY]: [LicenseTypes.CASAC, LicenseTypes.CASAC_T],
  [UnitedStatesAbbreviations.NC]: [LicenseTypes.LCAS, LicenseTypes.CSAC],
  [UnitedStatesAbbreviations.GA]: [
    LicenseTypes.CPRC,
    LicenseTypes.CCS,
    LicenseTypes.CAADC,
    LicenseTypes.CADC_II,
    LicenseTypes.CADC_I,
    LicenseTypes.CADC_T,
  ],
  [UnitedStatesAbbreviations.TX]: [LicenseTypes.LCDC, LicenseTypes.LCDC_INTERN],
  [UnitedStatesAbbreviations.WA]: [LicenseTypes.SUDP, LicenseTypes.SUDPT],
  [UnitedStatesAbbreviations.VA]: [
    LicenseTypes.LSATP,
    LicenseTypes.CSAC,
    LicenseTypes.CSAC_ASSISTANT,
  ],
  [UnitedStatesAbbreviations.DC]: [LicenseTypes.CAC_I, LicenseTypes.CAC_II],
  [UnitedStatesAbbreviations.MD]: [
    LicenseTypes.LCADC,
    LicenseTypes.CAC_AD,
    LicenseTypes.CSC_AD,
  ],
  [UnitedStatesAbbreviations.OR]: [
    LicenseTypes.CADC_I,
    LicenseTypes.CADC_II,
    LicenseTypes.CADC_III,
  ],
  [UnitedStatesAbbreviations.FL]: [LicenseTypes.CAC, LicenseTypes.CAP],
  [UnitedStatesAbbreviations.TN]: [LicenseTypes.LADAC],
  [UnitedStatesAbbreviations.MI]: [LicenseTypes.CAADC, LicenseTypes.CADC],
  [UnitedStatesAbbreviations.PA]: [
    LicenseTypes.CAADC,
    LicenseTypes.CADC,
    LicenseTypes.CAAC,
    LicenseTypes.AAC_II,
  ],
  [UnitedStatesAbbreviations.AZ]: [
    LicenseTypes.LISAC,
    LicenseTypes.LASAC,
    LicenseTypes.LSAT,
  ],
  [UnitedStatesAbbreviations.CO]: [
    LicenseTypes.LAC,
    LicenseTypes.CAS,
    LicenseTypes.CAT,
  ],
  [UnitedStatesAbbreviations.LA]: [
    LicenseTypes.LAC,
    LicenseTypes.CAC,
    LicenseTypes.RAC,
  ],
  [UnitedStatesAbbreviations.OH]: [
    LicenseTypes.LICDC,
    LicenseTypes.LCDC_III,
    LicenseTypes.LCDC_II,
  ],
  [UnitedStatesAbbreviations.NJ]: [LicenseTypes.LCADC, LicenseTypes.CADC],
  [UnitedStatesAbbreviations.CT]: [LicenseTypes.LADC, LicenseTypes.A_AND_DC],
  [UnitedStatesAbbreviations.MA]: [
    LicenseTypes.LADC_I,
    LicenseTypes.LADC_II,
    LicenseTypes.LADC_ASSISTANT,
  ],
  [UnitedStatesAbbreviations.IL]: [
    LicenseTypes.CSADC,
    LicenseTypes.CAADC,
    LicenseTypes.CRADC,
    LicenseTypes.CADC,
  ],
  [UnitedStatesAbbreviations.CA]: [
    LicenseTypes.CATC_I,
    LicenseTypes.CATC_II,
    LicenseTypes.CATC_III,
    LicenseTypes.CATC_IV,
    LicenseTypes.CATC_V,
    LicenseTypes.CATC_N,
  ],
};
