import { useContext, useMemo } from 'react';
import { useProvider } from '~/legacy/hooks';

import { AppointmentConfirmationModalFormV2Values } from '../components/modals/AppointmentConfirmationModalV2';
import { AppointmentConfirmationContextV2 } from '../stores/AppointmentConfirmationContextV2';
import { useAddendumsEditInitialValues } from './initialValues/useAddendumsInitialValues';
import { useAttachmentsInitialValues } from './initialValues/useAttachmentsInitialValues';
import { useProgressNoteInitialValues } from './initialValues/useProgressNoteInitialValues';
import { useSessionDetailsInitialValues } from './initialValues/useSessionDetailsInitialValues';

export const useInitialValues = () => {
  const {
    event,
    isEventLoading,
    isEventFetching,
    isProgressNoteFetching,
    isProgressNoteLoading,
    progressNoteType,
    patient,
    updateAppointmentAddressState,
    isDocumentRemediationLoading,
  } = useContext(AppointmentConfirmationContextV2);
  const provider = useProvider();
  const {
    isInitialized: areSessionDetailsInitialized,
    initialValues: sessionDetailsInitialValues,
  } = useSessionDetailsInitialValues({
    event,
    isEventLoading,
    patient,
    provider,
    updateAppointmentAddressState,
  });
  const {
    isInitialized: areProgressNotesInitialized,
    initialValues: progressNotesInitialValues,
  } = useProgressNoteInitialValues();
  const {
    isInitialized: areAddendumsEditInitialized,
    initialValues: addendumsEditInitialValues,
  } = useAddendumsEditInitialValues();
  const {
    isInitialized: areAttachmentsInitialized,
    initialValues: attachmentsInitialValues,
  } = useAttachmentsInitialValues();

  const initialValues: AppointmentConfirmationModalFormV2Values = useMemo(
    () => ({
      sessionDetails: sessionDetailsInitialValues,
      progressNote: progressNotesInitialValues,
      addendumsEdit: addendumsEditInitialValues,
      attachments: attachmentsInitialValues,
      attestation: false,
    }),
    [
      sessionDetailsInitialValues,
      progressNotesInitialValues,
      addendumsEditInitialValues,
      attachmentsInitialValues,
    ]
  );

  return {
    isInitialized:
      areSessionDetailsInitialized &&
      areProgressNotesInitialized &&
      areAddendumsEditInitialized &&
      areAttachmentsInitialized &&
      !isDocumentRemediationLoading &&
      !isEventFetching &&
      !isEventLoading &&
      !isProgressNoteLoading &&
      !isProgressNoteFetching &&
      progressNoteType !== undefined,
    initialValues: initialValues,
  };
};
