import { IconButton } from '@mui/material';
import clsx from 'clsx';
import { FormikErrors, FormikTouched, getIn, useFormikContext } from 'formik';
import moment from 'moment';
import React, { CSSProperties, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelectedEvent } from '~/legacy/hooks/useSelectedEvent';
import { SessionDetailsFormV2Values } from '~/legacy/views/AppointmentConfirmation/components/forms/SessionDetails/SessionDetailsFormV2';
import { SupportedScrollToId } from '~/legacy/views/AppointmentConfirmation/components/forms/SessionDetails/SessionDetailsFormV2';
import {
  commonTimes,
  getAllTimes,
  getFiveMinuteIntervals,
  isSessionDetailsFormValueTelehealth,
} from '~/legacy/views/AppointmentConfirmation/components/forms/SessionDetails/utils';
import { AppointmentCancelModalHelix } from '~/legacy/views/Calendar/components/AppointmentCancelModalHelix';

import { ControlledSubstance } from '@headway/api/models/ControlledSubstance';
import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserReadByProvider } from '@headway/api/models/UserReadByProvider';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { FormControl } from '@headway/helix/FormControl';
import { IconTrash } from '@headway/helix/icons/Trash';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { Link } from '@headway/helix/Link';
import { Item, Section, Select } from '@headway/helix/Select';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import { theme } from '@headway/helix/theme';
import { CONTROLLED_SUBSTANCE_DISPLAY_NAMES } from '@headway/shared/constants/controlledSubstances';
import { CPTCodeInfo } from '@headway/shared/constants/cptCodes';
import { telehealthPlatformDisplayNames } from '@headway/shared/constants/telehealthPlatformDisplayNames';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { SelectedEventContext } from '@headway/shared/events/SelectedEventContext';
import { formatPatientName } from '@headway/shared/utils/patient';
import { CPTCodeComboBox } from '@headway/ui/CPTCodeComboBox';

import { BULK_CONFIRM_EVENT_URL_SEARCH_KEY } from '../../utils/constants';
import {
  useCancelRecurringEventSideEffectsForBilling,
  useCancelSingleEventSideEffectsForBilling,
} from '../../utils/queries';
import {
  BulkConfirmEventsData,
  BulkConfirmFormValues,
  BulkConfirmSubmissionStatus,
  SessionDetailsFormV2ValuesWithOptionalDate,
  SubmissionStatusMap,
} from '../../utils/types';
import { SessionDetailsSidesheet } from './SessionDetailsSidesheet';

interface SessionTableProps {
  provider: ProviderRead;
  bulkConfirmEventsData: BulkConfirmEventsData;
  submissionStatusByEventVirtualId: SubmissionStatusMap;
  /** When provided, only the events in the list are displayed in the table. */
  displayedVirtualIds?: string[];
  readonly?: boolean;
  onDismiss?: () => void;
}

export const SessionDetailsTable = ({
  provider,
  bulkConfirmEventsData,
  submissionStatusByEventVirtualId,
  displayedVirtualIds,
  readonly = false,
  onDismiss,
}: SessionTableProps) => {
  const { values, setValues, errors, touched } =
    useFormikContext<BulkConfirmFormValues>();

  const [openSidesheet, setOpenSidesheet] = useState(false);
  const [scrollTo, setScrollTo] = useState<SupportedScrollToId>();
  const [patientHasCignaEapAuthorization] = useState<boolean>(false);

  const sessionTableColumns = getSessionTableColumns(provider, readonly);

  const tableBorderStyles =
    '[&_table]:border-separate [&_table]:border-spacing-0 [&_td]:border [&_td]:border-solid [&_td]:border-system-borderGray [&_td]:border-separate [&_th]:border [&_th]:border-solid [&_th]:border-system-borderGray';
  const regularCellStyles =
    '[&_td]:bg-system-white [&_td]:relative [&_tr>:not(:first-child):not(:last-child)]:z-0 [&_td]:align-top';
  const stickyFirstColumnStyles =
    '[&_tr>:first-child]:sticky [&_tr>:first-child]:left-0 [&_tr>:first-child]:border-r-[3px] [&_tr>:first-child]:border-system-borderGray [&_tr>:first-child]:z-[50]';
  const stickyLastColumnStyles =
    '[&_tr>:last-child]:sticky [&_tr>:last-child]:right-0 [&_tr>:last-child]:border-l-[3px] [&_tr>:last-child]:border-system-borderGray [&_tr>:last-child]:z-[50]';

  const tableAriaLabel = readonly
    ? 'Table of Confirmed Sessions'
    : 'Table of Unconfirmed Sessions';

  const { setSelectedEventVirtualId } = useContext(SelectedEventContext);
  const { event } = useSelectedEvent();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isCancelModelOpen, setIsCancelModelOpen] = useState(false);

  const handleHideCanceledAppointments = async () => {
    if (!event) return;

    const virtualId = event.virtualId;
    const { [virtualId]: canceledSessionValues, ...remainingSessionValues } =
      values.sessions;

    // Update form values to remove canceled session
    setValues({
      ...values,
      sessions: remainingSessionValues,
    });

    // If there are no more events, navigate back to the previous page
    if (onDismiss && Object.keys(remainingSessionValues).length === 0) {
      onDismiss();
    } else {
      setSearchParams(
        (params) => {
          return [...params.entries()].filter(
            ([key, val]) =>
              !(key === BULK_CONFIRM_EVENT_URL_SEARCH_KEY && val === virtualId)
          );
        },
        { replace: true }
      );
    }
  };

  const cancelSingleEventSideEffectsForBilling =
    useCancelSingleEventSideEffectsForBilling().add({
      onSuccess: async () => await handleHideCanceledAppointments(),
    });

  const cancelRecurringEventSideEffectsForBilling =
    useCancelRecurringEventSideEffectsForBilling().add({
      onSuccess: async () => await handleHideCanceledAppointments(),
    });

  return (
    <div
      className={`sticky grid overflow-scroll [&_td:has(.soft-validate)]:bg-hue-lightYellow [&_th]:bg-system-white ${tableBorderStyles} ${regularCellStyles} ${stickyFirstColumnStyles} ${stickyLastColumnStyles}`}
    >
      <Table aria-label={tableAriaLabel}>
        <TableHeader>
          {sessionTableColumns.map((column: SessionTableColumns) => (
            <Column key={`${column.key}`}>{column.label}</Column>
          ))}
        </TableHeader>
        <TableBody>
          {Object.entries(values.sessions)
            .filter(([virtualId]) =>
              displayedVirtualIds
                ? displayedVirtualIds.includes(virtualId)
                : true
            )
            .map(([virtualId, sessionDetails]) => {
              const event = bulkConfirmEventsData[virtualId].event;
              const patient = event.providerAppointment
                ?.patient as UserReadByProvider;

              const providerAddress = bulkConfirmEventsData[
                virtualId
              ].providerAddresses?.find(
                (providerAddress: ProviderAddressRead) =>
                  providerAddress.id === sessionDetails.providerAddressId
              );
              const patientAddress = bulkConfirmEventsData[
                virtualId
              ].patientAddresses?.find(
                (patientAddress: PatientAddressRead) =>
                  patientAddress.id ===
                  sessionDetails?.appointmentLocationPatientAddressId
              );
              const submissionStatus =
                submissionStatusByEventVirtualId[virtualId];

              const sessionTableCells = getSessionTableRowCells(
                event,
                provider,
                patient,
                sessionDetails,
                submissionStatus,
                patientHasCignaEapAuthorization,
                readonly,
                setOpenSidesheet,
                setSelectedEventVirtualId,
                setIsCancelModelOpen,
                setScrollTo,
                errors,
                touched,
                patientAddress,
                providerAddress
              );

              return (
                <Row key={event.virtualId}>
                  {sessionTableColumns.map((column: SessionTableColumns) => (
                    <Cell key={`${event.virtualId}-${column.key}-row`}>
                      {sessionTableCells[column.key]}
                    </Cell>
                  ))}
                </Row>
              );
            })}
        </TableBody>
      </Table>

      {!readonly && (
        <SessionDetailsSidesheet
          openSidesheet={openSidesheet}
          provider={provider}
          setSelectedEventVirtualId={setSelectedEventVirtualId}
          setIsCancelModelOpen={setIsCancelModelOpen}
          setOpenSidesheet={setOpenSidesheet}
          scrollTo={scrollTo}
        />
      )}

      <AppointmentCancelModalHelix
        isOpen={isCancelModelOpen}
        onDismiss={() => {
          setIsCancelModelOpen(false);
          setSelectedEventVirtualId(undefined);
        }}
        cancelSingleEventSideEffects={cancelSingleEventSideEffectsForBilling}
        cancelRecurringEventSideEffects={
          cancelRecurringEventSideEffectsForBilling
        }
      />
    </div>
  );
};

type SessionTableColumns = {
  key: string;
  label: string;
};

const getSessionTableColumns = (
  provider: ProviderRead,
  readonly: boolean
): SessionTableColumns[] => {
  const columns = [
    {
      key: 'client',
      label: 'Client',
    },
    {
      key: 'startTime',
      label: 'Start time',
    },
    {
      key: 'duration',
      label: 'Duration',
    },
    {
      key: 'cptCodes',
      label: 'CPT codes',
    },
    {
      key: 'diagnosisCodes',
      label: 'Diagnosis codes',
    },
    {
      key: 'sessionLocation',
      label: 'Session location',
    },
  ];

  if (!readonly) {
    columns.push({
      key: 'edit',
      label: '',
    });
  }

  if (provider.isPrescriber) {
    columns.splice(6, 0, {
      key: 'prescriptions',
      label: 'Prescriptions',
    });
  }
  return columns;
};

const getSessionTableRowCells = (
  event: ProviderEventRead,
  provider: ProviderRead,
  patient: UserReadByProvider,
  sessionDetails: SessionDetailsFormV2ValuesWithOptionalDate,
  submissionStatus: BulkConfirmSubmissionStatus,
  patientHasCignaEapAuthorization: boolean,
  readonly: boolean,
  setOpenSidesheet: (isOpen: boolean) => void,
  setSelectedEventVirtualId: (id: string | undefined) => void,
  setIsCancelModelOpen: (isOpen: boolean) => void,
  setScrollTo: (scrollToId?: SupportedScrollToId) => void,
  errors: FormikErrors<BulkConfirmFormValues>,
  touched: FormikTouched<BulkConfirmFormValues>,
  patientAddress?: PatientAddressRead,
  providerAddress?: ProviderAddressRead
): {
  [key: string]: React.ReactNode;
} => {
  const fiveMinuteIntervals = getFiveMinuteIntervals(event.startDate);
  const allTimes = getAllTimes();
  const { controlledSubstanceAttestation } = sessionDetails;

  const openSidesheet = (scrollToLocation?: SupportedScrollToId) => {
    setSelectedEventVirtualId(event.virtualId);
    setOpenSidesheet(true);
    setScrollTo(scrollToLocation);
  };

  const isTelehealthSession = isSessionDetailsFormValueTelehealth(
    sessionDetails.providerAddressId
  );

  const shouldDisplayError = (field: keyof SessionDetailsFormV2Values) => {
    return !!(
      getIn(errors, `sessions.${event.virtualId}.${field}`) &&
      getIn(touched, `sessions.${event.virtualId}.${field}`)
    );
  };
  const shouldDisplayTelehealthError =
    shouldDisplayError('telehealth') ||
    shouldDisplayError('appointmentLocationPatientAddressId') ||
    shouldDisplayError('telehealthPlatform') ||
    shouldDisplayError('telehealthProviderState') ||
    shouldDisplayError('providerAddressId');

  return {
    client: (
      <div className="flex w-[200px] gap-2">
        <b>
          <u>{formatPatientName(patient)}</u>
        </b>
        {submissionStatus === BulkConfirmSubmissionStatus.ERROR && (
          <IconWarningCircle fill={theme.color.foreground.danger} />
        )}
      </div>
    ),
    diagnosisCodes:
      readonly ||
      (sessionDetails.diagnosisCodes &&
        sessionDetails.diagnosisCodes?.length > 0) ? (
        sessionDetails?.diagnosisCodes?.map((code) => {
          return (
            <div className="min-w-[150px]">
              <Badge key={code} variant="neutral">
                {code.value}
              </Badge>
            </div>
          );
        })
      ) : (
        <div
          className={`min-w-[150px] ${clsx({
            'soft-validate': !shouldDisplayError('diagnosisCodes'),
          })}`}
        >
          <SidesheetTriggerButton
            label="Select code"
            hasError={shouldDisplayError('diagnosisCodes')}
            onClick={() => openSidesheet(SupportedScrollToId.DIAGNOSIS_CODE)}
          />
        </div>
      ),
    startTime: readonly ? (
      moment(sessionDetails.startDate).format('h:mm A')
    ) : (
      <div
        className={`mt-[-4px] min-w-[150px]
          ${
            !sessionDetails.startDate && !shouldDisplayError('startDate')
              ? `[&_button]:bg-hue-lightYellow`
              : ''
          } `}
      >
        <FormControl
          component={Select}
          name={`sessions.${event.virtualId}.startDate`}
          selectionMode="single"
          menuWidth="stretch"
          aria-label="Start time"
        >
          {fiveMinuteIntervals.map((interval) => {
            const intervalDate = moment(interval);
            const label = intervalDate.format('h:mma');
            const key = intervalDate.toISOString();

            return (
              <Item key={key} textValue={label}>
                {label}
              </Item>
            );
          })}
        </FormControl>
      </div>
    ),
    duration: readonly ? (
      `${sessionDetails.duration} minutes`
    ) : (
      <div
        className={`mt-[-4px] min-w-[150px]
          ${
            !sessionDetails.duration && !shouldDisplayError('duration')
              ? `[&_button]:bg-hue-lightYellow`
              : ''
          }`}
      >
        <FormControl
          name={`sessions.${event.virtualId}.duration`}
          component={Select}
          selectionMode="single"
          menuWidth="stretch"
          aria-label="Duration"
        >
          <Section title="Common times">
            {commonTimes.map((time) => (
              <Item key={time.toString()} textValue={`${time} minutes`}>
                {time} minutes
              </Item>
            ))}
          </Section>
          <Section title="All times">
            {allTimes.map((time) => (
              <Item key={time.toString()} textValue={`${time} minutes`}>
                {time} minutes
              </Item>
            ))}
          </Section>
        </FormControl>
      </div>
    ),
    cptCodes: readonly ? (
      sessionDetails?.cptCodes?.map((code: CPTCodeInfo) => {
        return (
          <Badge key={code.value} variant="neutral">
            {code.value}
          </Badge>
        );
      })
    ) : (
      <div
        className={`
          mt-[-4px] min-w-[220px]
         ${
           !sessionDetails?.cptCodes?.length && !shouldDisplayError('cptCodes')
             ? `[&_.hlx-combobox-control]:bg-hue-lightYellow`
             : ''
         }`}
      >
        <CPTCodeComboBox
          name={`sessions.${event.virtualId}.cptCodes`}
          label=""
          placeholder="Select"
          patient={patient}
          provider={provider}
          patientHasCignaEapAuthorization={patientHasCignaEapAuthorization}
          searchable
          selectionMode="multiple"
          aria-label="CPT codes"
        />
      </div>
    ),
    sessionLocation:
      isTelehealthSession &&
      sessionDetails.appointmentLocationPatientAddressId &&
      sessionDetails.telehealthPlatform &&
      sessionDetails.telehealthProviderState &&
      patientAddress ? (
        <div className="grid min-w-[250px] gap-1">
          <ContentText>Virtual Telehealth</ContentText>

          <div className="grid">
            <ContentText variant="body-small">
              Type:{' '}
              {
                telehealthPlatformDisplayNames[
                  sessionDetails.telehealthPlatform
                ]
              }
            </ContentText>
            <ContentText variant="body-small">
              Client: {patientAddress.streetLine1}, {patientAddress.city},{' '}
              {statesToDisplayNames[patientAddress.state]}{' '}
              {patientAddress.zipCode}
            </ContentText>
            <ContentText variant="body-small">
              Provider:{' '}
              {statesToDisplayNames[sessionDetails.telehealthProviderState]}
            </ContentText>
          </div>
        </div>
      ) : providerAddress ? (
        <div className="grid min-w-[250px] gap-0">
          <ContentText>{providerAddress.streetLine1}</ContentText>
          <ContentText>
            {providerAddress.city}, {providerAddress.state}{' '}
            {providerAddress.zipCode}
          </ContentText>
        </div>
      ) : (
        <div
          className={`min-w-[250px] ${clsx({
            'soft-validate': !shouldDisplayTelehealthError,
          })}`}
        >
          <SidesheetTriggerButton
            label="Select location"
            hasError={shouldDisplayTelehealthError}
            onClick={() => openSidesheet(SupportedScrollToId.LOCATION)}
          />
        </div>
      ),
    prescriptions:
      provider.isPrescriber &&
      sessionDetails.didPrescribeControlledSubstance &&
      controlledSubstanceAttestation.prescribedSubstances &&
      controlledSubstanceAttestation.exceedsRecommendedDosage !== undefined &&
      controlledSubstanceAttestation.prescribedSubstances.length > 0 &&
      controlledSubstanceAttestation.notes ? (
        <div className="grid min-w-[250px] gap-1">
          {controlledSubstanceAttestation.prescribedSubstances.map(
            (substance) => (
              <Badge key={substance} variant="neutral">
                {
                  CONTROLLED_SUBSTANCE_DISPLAY_NAMES[
                    substance as ControlledSubstance
                  ]
                }
              </Badge>
            )
          )}
          <div className="grid gap-0">
            <ContentText variant="body-small">
              {controlledSubstanceAttestation.exceedsRecommendedDosage
                ? 'Yes, at least one prescription exceeded recommended limits.'
                : 'No, all prescriptions were within recommended limits'}
            </ContentText>
            <Link
              onClick={() => openSidesheet(SupportedScrollToId.PRESCRIPTION)}
            >
              See more
            </Link>
          </div>
        </div>
      ) : provider.isPrescriber &&
        sessionDetails.didPrescribeControlledSubstance === false ? (
        <div className="min-w-[250px]">
          <ContentText>None</ContentText>
        </div>
      ) : (
        <div
          className={`min-w-[250px] ${clsx({
            'soft-validate': !(
              shouldDisplayError('didPrescribeControlledSubstance') ||
              shouldDisplayError('controlledSubstanceAttestation')
            ),
          })}`}
        >
          <SidesheetTriggerButton
            label="Select prescription"
            hasError={
              shouldDisplayError('didPrescribeControlledSubstance') ||
              shouldDisplayError('controlledSubstanceAttestation')
            }
            onClick={() => openSidesheet(SupportedScrollToId.PRESCRIPTION)}
          />
        </div>
      ),
    edit: !readonly && (
      <div className="flex gap-2">
        <Button variant="link" onPress={() => openSidesheet()}>
          {submissionStatus === BulkConfirmSubmissionStatus.ERROR
            ? 'View issues and edit'
            : 'Edit'}
        </Button>
        {submissionStatus === BulkConfirmSubmissionStatus.UNSUBMITTED && (
          <IconButton
            size="medium"
            aria-label="Open cancel appointment modal"
            onClick={() => {
              setSelectedEventVirtualId(event.virtualId);
              setIsCancelModelOpen(true);
            }}
          >
            <IconTrash />
          </IconButton>
        )}
      </div>
    ),
  };
};

interface SidesheetTriggerButtonProps {
  label: string;
  hasError: boolean;
  onClick: () => void;
}

const SidesheetTriggerButton = ({
  label,
  hasError,
  onClick,
}: SidesheetTriggerButtonProps) => {
  return (
    <Link
      onClick={onClick}
      className="flex gap-1"
      style={
        hasError
          ? ({
              '--text-color': theme.color.foreground.danger,
            } as CSSProperties)
          : undefined
      }
    >
      {hasError && <IconWarningCircle fill={theme.color.foreground.danger} />}
      {label}
    </Link>
  );
};
