import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { PatientAddressCreate } from '@headway/api/models/PatientAddressCreate';
import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { PatientAddressType } from '@headway/api/models/PatientAddressType';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { PatientAddressApi } from '@headway/api/resources/PatientAddressApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Button, ButtonProps } from '@headway/helix/Button';
import { Button as HelixButton } from '@headway/helix/Button';
import { theme as helixTheme } from '@headway/helix/theme';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useMutation, useQueryClient } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';
import useScript from '@headway/shared/utils/useScript';

import { SafeFormikForm } from './form/SafeFormikForm';
import {
  PatientAddressFormFieldNames,
  PatientAddressFormFields,
  PatientAddressFormValues,
} from './PatientAddressFormFields';
import { theme } from './theme';

const ValidationSchema = {
  city: Yup.string().required('City is required.'),
  state: Yup.string().required('State is required.'),
  streetLine1: Yup.string().required('Street line 1 is required.'),
  streetLine2: Yup.string().nullable().default(null),
  zipCode: Yup.string().required('Zip code is required.'),
};

export interface LocationFilterAddress {
  streetLine1: string;
  streetLine2?: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface PatientAddressFormImplProps {
  formVariant?: 'default' | 'helix';
  onSuccess?: (address: PatientAddressRead, user: UserRead, args?: any) => void;
  apiKey: string;
  user: UserRead;
  isActiveAddress?: boolean;
  isTelehealth?: boolean;
  onCancel?: () => void;
  variant?: ButtonProps['variant'];
  saveAddressButtonText?: string;
}

export const PatientAddressForm = ({
  formVariant = 'helix',
  onSuccess,
  apiKey,
  user,
  onCancel,
  isTelehealth,
  isActiveAddress = true,
  variant = 'primary',
  saveAddressButtonText = 'Save Address',
}: PatientAddressFormImplProps) => {
  const AddressSchema = Yup.object().shape({
    ...ValidationSchema,
    ...(isTelehealth
      ? {
          telehealth: Yup.bool().nullable().required('Choose an option.'),
        }
      : {}),
    addressType: Yup.string().nullable().required('Description is required.'),
  });

  const gmapsScriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3&libraries=places`
  );

  const createPatientAddressMutation = useMutation(
    (variables: PatientAddressCreate) => {
      return PatientAddressApi.createPatientAddress(variables);
    }
  );

  const queryClient = useQueryClient();

  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);

  return (
    <React.Fragment>
      <Formik
        validationSchema={AddressSchema}
        initialValues={
          {
            [PatientAddressFormFieldNames.City]: '',
            [PatientAddressFormFieldNames.State]: null,
            [PatientAddressFormFieldNames.StreetLine1]: '',
            [PatientAddressFormFieldNames.StreetLine2]: '',
            [PatientAddressFormFieldNames.ZipCode]: '',
            [PatientAddressFormFieldNames.AddressType]: isActiveAddress
              ? PatientAddressType.HOME
              : null,
            [PatientAddressFormFieldNames.Telehealth]: null,
          } as PatientAddressFormValues
        }
        onSubmit={async (values) => {
          try {
            const address = await createPatientAddressMutation.mutateAsync({
              patientUserId: user.id,
              addressType: values.addressType
                ? PatientAddressType[values.addressType as PatientAddressType]
                : undefined,
              city: values.city,
              state: values.state as UnitedStates,
              streetLine1: values.streetLine1,
              streetLine2: values.streetLine2,
              zipCode: values.zipCode,
            });

            const args = isTelehealth
              ? {
                  telehealth: values.telehealth === 'true',
                }
              : undefined;

            if (isActiveAddress) {
              const updatedUser = await UserApi.updateUser(user.id, {
                activePatientAddressId: address.id,
              });
              onSuccess && onSuccess(address, updatedUser, args);
            } else {
              onSuccess && onSuccess(address, user, args);
            }

            await queryClient.invalidateQueries(['claim-readiness']);
          } catch (e) {
            logException(e);
          }
        }}
      >
        {(formik) => {
          return (
            <SafeFormikForm css={{ marginBottom: 0 }}>
              <PatientAddressFormFields
                variant={formVariant}
                gmapsScriptStatus={gmapsScriptStatus}
                isMSCEnabled={isMSCEnabled}
                user={user}
                isTelehealth={isTelehealth}
                createPatientAddressMutationStatus={
                  createPatientAddressMutation.status
                }
              />
              {formVariant === 'helix' ? (
                <div className="flex flex-col gap-3 py-6">
                  <HelixButton
                    disabled={formik.isSubmitting}
                    variant="primary"
                    type="submit"
                    onPress={() => formik.submitForm()}
                  >
                    {formik.isSubmitting ? 'Saving' : saveAddressButtonText}
                  </HelixButton>
                  {onCancel && (
                    <HelixButton variant="secondary" onPress={onCancel}>
                      Cancel
                    </HelixButton>
                  )}
                </div>
              ) : (
                <div
                  css={{
                    paddingTop: theme.space.base,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    gap: theme.space.base,
                  }}
                >
                  <Button
                    disabled={formik.isSubmitting}
                    variant={variant}
                    type="submit"
                  >
                    {formik.isSubmitting ? 'Saving' : saveAddressButtonText}
                  </Button>
                  {onCancel && (
                    <Button variant="secondary" onPress={onCancel}>
                      Cancel
                    </Button>
                  )}
                </div>
              )}
            </SafeFormikForm>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
