import { ProviderPaymentPauseApi } from '@headway/api/resources/ProviderPaymentPauseApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface useProviderPaymentPauseQueryKeyArgs {
  providerId: number | undefined;
  activeOnly?: boolean;
}

export const getUseProviderPaymentPauseQueryKey = ({
  providerId,
  activeOnly = false,
}: useProviderPaymentPauseQueryKeyArgs): [
  string,
  number | undefined,
  boolean,
] => ['get-provider-payment-pauses', providerId, activeOnly];

const {
  singleQuery: providerPaymentPause,
  useSingleQuery: useProviderPaymentPause,
  useListQuery: useProviderPaymentPauseList,
  useCachedQuery: useProviderPaymentPauseCache,
} = createBasicApiHooks(
  getUseProviderPaymentPauseQueryKey,
  ({ providerId, activeOnly }) =>
    ProviderPaymentPauseApi.getProviderPaymentPauses({
      provider_ids: [providerId!],
      active_only: activeOnly,
    }),
  ({ providerId }) => !!providerId,
  () => 'Failed to fetch provider payment pause.'
);

const updateProviderPaymentPause = async ({
  providerPaymentPauseId,
  acknowledgedOn,
  acknowledgedReleaseOn,
}: {
  providerPaymentPauseId: number;
  acknowledgedOn?: Date;
  acknowledgedReleaseOn?: Date;
}) => {
  const updatedPaymentPause =
    await ProviderPaymentPauseApi.updateProviderPaymentPause(
      providerPaymentPauseId,
      {
        acknowledgedOn: acknowledgedOn?.toISOString() ?? undefined,
        acknowledgedReleaseOn:
          acknowledgedReleaseOn?.toISOString() ?? undefined,
      }
    );
  return updatedPaymentPause;
};

export {
  providerPaymentPause,
  useProviderPaymentPause,
  useProviderPaymentPauseList,
  useProviderPaymentPauseCache,
  updateProviderPaymentPause,
};
