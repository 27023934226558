import { useFormikContext } from 'formik';
import { noop } from 'lodash';
import { useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useProvider, useProviderPatient } from '~/legacy/hooks';
import { useSelectedEvent } from '~/legacy/hooks/useSelectedEvent';
import { useSetSelectedProvider } from '~/legacy/utils/practice';
import { SessionDetailsFormV2 } from '~/legacy/views/AppointmentConfirmation/components/forms/SessionDetails/SessionDetailsFormV2';
import { SupportedScrollToId } from '~/legacy/views/AppointmentConfirmation/components/forms/SessionDetails/SessionDetailsFormV2';
import { AddressModalV2 } from '~/legacy/views/AppointmentConfirmation/components/modals/AddressModalV2';
import { useSessionDetailsInitialValues } from '~/legacy/views/AppointmentConfirmation/hooks/initialValues/useSessionDetailsInitialValues';
import {
  AppointmentConfirmationContextProvider,
  AppointmentConfirmationContextV2,
} from '~/legacy/views/AppointmentConfirmation/stores/AppointmentConfirmationContextV2';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserReadByProvider } from '@headway/api/models/UserReadByProvider';
import { Button } from '@headway/helix/Button';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { SelectedEventContext } from '@headway/shared/events/SelectedEventContext';
import { useUser } from '@headway/shared/hooks/useUser';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { ProviderFrontEndCarrierProvider } from '@headway/ui/providers/ProviderFrontEndCarrierProvider';

import { BulkConfirmFormValues } from '../../utils/types';

interface SessionDetailsSidesheetProps {
  openSidesheet: boolean;
  setOpenSidesheet: (value: boolean) => void;
  setSelectedEventVirtualId: (value: string | undefined) => void;
  setIsCancelModelOpen: (value: boolean) => void;
  provider: ProviderRead;
  scrollTo?: SupportedScrollToId;
}

interface SessionDetailsSidesheetImplProps
  extends SessionDetailsSidesheetProps {
  providerPatient: ProviderPatientRead;
}

const SessionDetailsSidesheetImpl = ({
  openSidesheet,
  setOpenSidesheet,
  setIsCancelModelOpen,
  provider,
  providerPatient,
  scrollTo,
}: SessionDetailsSidesheetImplProps) => {
  const { setFieldValue, values } = useFormikContext<BulkConfirmFormValues>();
  const { isAddressModalOpen, setIsAddressModalOpen } = useContext(
    AppointmentConfirmationContextV2
  );
  const { data: patient } = useUser({
    userId: providerPatient.userId,
  });

  const { setSelectedEventVirtualId } = useContext(SelectedEventContext);
  const { event } = useSelectedEvent();

  const { initialValues: sessionDetailsInitialValues } =
    useSessionDetailsInitialValues({
      event,
      isEventLoading: false,
      patient,
      provider,
    });

  const methods = useForm({
    defaultValues: useMemo(() => {
      if (!event) return { sessionDetails: sessionDetailsInitialValues };

      return {
        sessionDetails: {
          ...sessionDetailsInitialValues,
          ...values.sessions[event?.virtualId],
        },
      };
    }, [sessionDetailsInitialValues, event?.virtualId, values.sessions]),
  });

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={openSidesheet}
        onDismiss={() => setOpenSidesheet(false)}
        title="Edit details"
        variant="sidesheet"
        sidesheetWidth="wide"
      >
        <ModalContent>
          <SafeFormikForm id="sessionDetailsSidesheetForm">
            <SessionDetailsFormV2
              onOpenContactFormInsuranceIssues={noop}
              variant="sidesheet"
              scrollTo={scrollTo}
            />
          </SafeFormikForm>
        </ModalContent>
        <ModalFooter>
          <Button
            variant="secondary"
            onPress={() => {
              setOpenSidesheet(false);
              setIsCancelModelOpen(true);
            }}
          >
            Cancel session
          </Button>
          <Button
            variant="primary"
            onPress={() => {
              // Pull the session details from the RHF sidesheet into the formik values
              const sessionsDetails = methods.getValues();
              setFieldValue(
                `sessions.${event?.virtualId}`,
                sessionsDetails.sessionDetails
              );
              setOpenSidesheet(false);
              setSelectedEventVirtualId(undefined);
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
      <AddressModalV2
        open={isAddressModalOpen}
        onClose={() => setIsAddressModalOpen(false)}
      />
    </FormProvider>
  );
};

export const SessionDetailsSidesheet = ({
  openSidesheet,
  setOpenSidesheet,
  setSelectedEventVirtualId,
  setIsCancelModelOpen,
  provider,
  scrollTo,
}: SessionDetailsSidesheetProps) => {
  const { event } = useSelectedEvent();

  const { data: providerPatient } = useProviderPatient({
    providerId: event?.providerId,
    patientId: event?.patientUserId,
  });
  const authStoreProvider = useProvider();
  const setSelectedProvider = useSetSelectedProvider();

  const patient = event?.providerAppointment?.patient as UserReadByProvider;

  // The appointment confirmation flow reads the provider from AuthStore, so we need to make sure
  // AuthStore has the same provider as BulkConfirm before opening anything.
  if (authStoreProvider.id !== provider.id) {
    setSelectedProvider(provider, { noRedirect: true });
  }

  if (
    !openSidesheet ||
    !event ||
    !providerPatient ||
    authStoreProvider.id !== provider.id
  ) {
    return null;
  }

  return (
    <ProviderFrontEndCarrierProvider providerId={provider.id}>
      <AppointmentConfirmationContextProvider
        patient={patient}
        eventVirtualId={event.virtualId}
        provider={provider}
        onCloseAppointmentConfirmationModal={noop}
        isDateEditable={false}
        openTreatmentPlanAdoptionModal={noop}
      >
        <SessionDetailsSidesheetImpl
          openSidesheet={openSidesheet}
          setOpenSidesheet={setOpenSidesheet}
          setSelectedEventVirtualId={setSelectedEventVirtualId}
          setIsCancelModelOpen={setIsCancelModelOpen}
          provider={provider}
          providerPatient={providerPatient}
          scrollTo={scrollTo}
        />
      </AppointmentConfirmationContextProvider>
    </ProviderFrontEndCarrierProvider>
  );
};
