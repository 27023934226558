import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

// allow-list query params rather than block-list to cover future cases. should be easy to add more allowed query
// params when we need to see that data
const ALLOWED_QUERY_PARAMS = new Set([
  'provider_id',
  'event_types',
  'channels',
  'date_range_end',
  'date_range_start',
  'expand',
  'expand_estimated_prices',
  'use_minimal_appointment_fields',
  'type',
  'is_active',
  'states',
  'limit',
  'offset',
]);

const getRedactedUrl = (urlString: string): string => {
  const url = new URL(urlString);
  const keysToRedact = new Set<string>();
  for (const key of url.searchParams.keys()) {
    if (!ALLOWED_QUERY_PARAMS.has(key)) {
      keysToRedact.add(key);
    }
  }
  for (const key of keysToRedact) {
    const count = url.searchParams.getAll(key).length;
    url.searchParams.set(key, 'REDACTED');
    if (count > 1) {
      for (let i = 1; i < count; i++) {
        url.searchParams.append(key, 'REDACTED');
      }
    }
  }
  return url.toString();
};

function initializeDatadogRum() {
  // Initialize Datadog Real User Metrics
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APPLICATION_ID!,
    clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN!,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'sigmund-web',
    env: process.env.REACT_APP_DD_ENV ?? process.env.ENVIRONMENT ?? 'local',
    version: process.env.REACT_APP_DD_VERSION,
    sessionSampleRate: 100, // TODO(peter): update to 10 before actually merging
    // Important to note: We are not turning session replay on for now, there are legal considerations to this, so please talk to people before changing this
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    // Enable Launch Darkly integration
    enableExperimentalFeatures: ['feature_flags'],
    // This connects the RUM data to the DD Traces coming out of Express/ Mamba/ Postgres
    allowedTracingUrls: [
      process.env.REACT_APP_API_URL!,
      process.env.REACT_APP_SIGMUND_URL!,
    ],
    beforeSend: (event) => {
      // redact all urls
      event.view.url = getRedactedUrl(event.view.url);
      if (event.view.referrer) {
        event.view.referrer = getRedactedUrl(event.view.referrer);
      }
      if (event.type === 'resource' && event.resource.url) {
        event.resource.url = getRedactedUrl(event.resource.url);
      }
      if (event.type === 'error' && event.error.resource?.url) {
        event.error.resource.url = getRedactedUrl(event.error.resource.url);
      }

      if (event.type === 'action') {
        // Check if the target element is opted into tracking by providing the data-dd-action-name attribute
        // Doing this because Datadog will use the label / inner text if this attribute doesn't exist
        // Being careful here around sending any text to Datadog that we don't explicitly intend to
        if (
          (event.action && event.action.type === 'custom') ||
          (event._dd.action &&
            event._dd.action.target?.selector?.includes('data-dd-action-name'))
        ) {
          // Keep the event
          return true;
        } else {
          // Discard the event
          return false;
        }
      }
      // Keep the event by default
      return true;
    },
    // only enable if on HTTPS
    useSecureSessionCookie: true,
    // disable telemetry data sent to DataDog
    telemetrySampleRate: 0,
  });
}

function initializeDatadogLogs() {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN!,
    site: 'datadoghq.com',
    sessionSampleRate: 100, // TODO(peter): update to 10 before actually merging
    env: process.env.REACT_APP_DD_ENV ?? process.env.ENVIRONMENT ?? 'local',
    service: 'sigmund-web',
    version: process.env.REACT_APP_DD_VERSION,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    // only enable if on HTTPS
    useSecureSessionCookie: true,
    // disable telemetry data sent to DataDog
    telemetrySampleRate: 0,
  });
}

export function initializeDatadogRumAndLogs() {
  if (!process.env.REACT_APP_DD_RUM_CLIENT_TOKEN) {
    return;
  }

  initializeDatadogRum();
  initializeDatadogLogs();
}
