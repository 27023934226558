import { CalendarDate } from '@internationalized/date';

import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UsePatientConfirmabilityKeyArgs {
  providerId: number;
  patientId: number;
  sessionDate: CalendarDate;
}
export type UsePatientConfirmabilityQueryKey = [
  string,
  number,
  number,
  CalendarDate,
];

export const getUsePatientConfirmabilityKey = ({
  providerId,
  patientId,
  sessionDate,
}: UsePatientConfirmabilityKeyArgs): UsePatientConfirmabilityQueryKey => [
  'patient-confirmability',
  providerId,
  patientId,
  sessionDate,
];

const {
  useSingleQuery: usePatientConfirmability,
  useListQuery: usePatientConfirmabilityList,
} = createBasicApiHooks(
  getUsePatientConfirmabilityKey,
  ({ providerId, patientId, sessionDate }) =>
    ProviderPatientApi.getPatientConfirmability(providerId!, {
      patient_user_id: patientId,
      appointment_date: sessionDate.toString(),
    }),
  ({ providerId, patientId, sessionDate }) => {
    return !!providerId && !!patientId && !!sessionDate;
  },
  () => 'Failed to fetch patient confirmability'
);

export { usePatientConfirmability, usePatientConfirmabilityList };
