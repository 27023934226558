import { ComponentProps } from 'react';

import { FormControl } from '@headway/helix/FormControl';
import { Item, Select } from '@headway/helix/Select';

import {
  ASSESSMENT_SCHEDULE_START_DATE_TYPE_DISPLAY_NAMES,
  AssessmentScheduleStartDateType,
} from './constants';

const ALL_START_DATE_TYPES = Object.values(AssessmentScheduleStartDateType);

export function AssessmentScheduleStartDateTypeSelector(
  props: Omit<ComponentProps<typeof Select>, 'selectionMode'>
) {
  return (
    <FormControl
      component={Select}
      selectionMode="single"
      menuWidth="stretch"
      label="Send first assessment"
      {...props}
    >
      {ALL_START_DATE_TYPES.map((dateType) => {
        const value =
          ASSESSMENT_SCHEDULE_START_DATE_TYPE_DISPLAY_NAMES[dateType];

        return (
          <Item key={dateType} textValue={value}>
            {value}
          </Item>
        );
      })}
    </FormControl>
  );
}
