import { providerTreatmentPlans } from '~/legacy/hooks/useProviderTreatmentPlans';
import {
  getLatestAttestedTreatmentPlan,
  isTreatmentPlanExpired,
} from '~/legacy/views/Clients/TreatmentPlan/TreatmentPlanUtils';

import { BillingType } from '@headway/api/models/BillingType';
import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderTreatmentPlanRead } from '@headway/api/models/ProviderTreatmentPlanRead';
import { isClientMedicareOrMedicaid } from '@headway/shared/utils/insuranceUtils';

import { Rule } from '../Rule';
import { DefaultTemplateFilters, TemplateFilter } from '../TemplateFilter';
import { MedicareAndMedicaidProgressNoteBanner } from './MedicareAndMedicaidProgressNoteBanner';

export class MedicareAndMedicaid extends Rule<
  [boolean, ProviderTreatmentPlanRead | undefined]
> {
  addDataHooks() {
    return [
      async () => {
        const isMedicareOrMedicaid = isClientMedicareOrMedicaid(this.patient);
        return (
          isMedicareOrMedicaid &&
          this.providerPatient.billingTypeDefault === BillingType.INSURANCE
        );
      },
      async () => {
        const treatmentPlans = await providerTreatmentPlans(
          {
            providerPatientId: this.providerPatient.id,
          },
          Rule.queryClient
        );

        const latestAttestedPlan =
          getLatestAttestedTreatmentPlan(treatmentPlans);

        return latestAttestedPlan &&
          !isTreatmentPlanExpired(latestAttestedPlan, true)
          ? latestAttestedPlan
          : undefined;
      },
    ];
  }

  isRuleActive(): boolean {
    const data = this.getHookData();

    if (!data) {
      return false;
    }

    return data[0];
  }

  get disabledProgressNoteTypes(): ProgressNoteType[] {
    return [ProgressNoteType.NONE, ProgressNoteType.UPLOAD];
  }

  get banners() {
    return {
      progressNote: {
        header: <MedicareAndMedicaidProgressNoteBanner />,
      },
    };
  }
  templateFilter(): TemplateFilter {
    return DefaultTemplateFilters.RemoveFreeText;
  }
  isDocumentationRequired(): boolean {
    return true;
  }
  isTreatmentPlanRequired(): boolean {
    const data = this.getHookData();

    if (!data) {
      return false;
    }

    const isIntakeSession =
      this.formValues.sessionDetails.cptCodes?.find(
        (cptCode) => cptCode.value === '90791'
      ) !== undefined;
    const hasActiveTreatmentPlan = data[1] !== undefined;

    return !(isIntakeSession || hasActiveTreatmentPlan);
  }
}
