import { ModalProps } from '@mui/material';
import { FormikProps } from 'formik';
import { useProvider } from 'hooks';
import moment, { Moment } from 'moment';
import { useCallback, useRef, useState } from 'react';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { useUser } from '@headway/shared/hooks/useUser';
import { formatPatientName } from '@headway/shared/utils/patient';

import { useClaimReadiness } from 'hooks/useClaimReadiness';
import { useProviderEvent } from 'hooks/useProviderEvent';
import { useProviderPatient } from 'hooks/useProviderPatient';
import { useAuthStore } from 'stores/AuthStore';
import { isPast } from 'views/Calendar/events/util/events';
import { TreatmentPlanWithContext } from 'views/Clients/TreatmentPlan/TreatmentPlan';
import { Attachment } from 'views/Patients/AttachmentsList';

import { AddendumsForm } from './components/forms/AddendumsForm';
import { AppointmentAttachmentForm } from './components/forms/AppointmentAttachmentForm';
import { AppointmentAttestationForm } from './components/forms/AppointmentAttestationForm';
import { ProgressNotesHeader } from './components/forms/ProgressNote/components/ProgressNotesHeader';
import { ProgressNoteSignedText } from './components/forms/ProgressNote/components/ProgressNoteSignedText';
import { ProgressNotesForm } from './components/forms/ProgressNote/ProgressNotesForm';
import {
  SessionDetailsForm,
  SessionDetailsFormValues,
} from './components/forms/SessionDetails/SessionDetailsForm';
import { AppointmentConfirmationModal } from './components/modals/AppointmentConfirmationModal';
import { ComplianceGuideModal } from './components/modals/ComplianceGuideModal';
import { SwoopUpsellModal } from './components/modals/SwoopUpsellModal';
import { ProgressNotesComplianceInfo } from './components/ProgressNoteComplianceInfo';
import { StackingHeader } from './components/StackingHeader';
import { TreatmentPlanRequirementWarning } from './components/TreatmentPlanRequirementWarning';
import { AppointmentContextProvider } from './stores/AppointmentContext';
import { ProgressNoteContextProvider } from './stores/ProgressNotesContext';

type AppointmentConfirmationImplProps = Omit<ModalProps, 'children'> & {
  open: boolean;
  onClose: () => void;
  eventVirtualId: string | number;
  patient: UserRead;
  progressNoteOnly: boolean;
  onOpenContactFormInsuranceIssues: () => void;
  openTreatmentPlanAdoptionModal?: (isIntakeSession: boolean) => void;
  onProgressNoteUpdate?: () => void;
  setSelectedEvent?: (event?: ConcreteProviderEventRead) => void;
};

const AppointmentConfirmationImpl = ({
  open,
  onClose,
  eventVirtualId,
  patient,
  progressNoteOnly,
  onOpenContactFormInsuranceIssues,
  openTreatmentPlanAdoptionModal,
  onProgressNoteUpdate,
  setSelectedEvent,
}: AppointmentConfirmationImplProps) => {
  const authStore = useAuthStore();

  const provider = useProvider();
  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventVirtualId,
  });

  const sessionDetailsRef = useRef<FormikProps<SessionDetailsFormValues>>();
  const sessionDetailsErrorRef = useRef();
  const attachmentRef =
    useRef<FormikProps<{ attachments: Attachment<string>[] }>>();
  const progressNoteRef = useRef<FormikProps<any>>();
  const attestationRef = useRef<FormikProps<any>>();

  const [dismissCantConfirmYetBanner, setDismissCantConfirmYetBanner] =
    useLocalStorage('dismissCantConfirmYetBanner');

  const [providerClosedComplianceModal, setProviderClosedComplianceModal] =
    useLocalStorage('providerClosedComplianceModal');

  const [openComplianceModal, setOpenComplianceModal] = useState(
    !providerClosedComplianceModal
  );
  const [treatmentPlanModalIsOpened, setTreatmentPlanModalIsOpened] =
    useState(false);
  const [isSwoopUpsellModalOpened, setIsSwoopUpsellModalOpened] =
    useState(false);

  const [providerClosedConsiderHeadwayTemplateCard] = useLocalStorage(
    'providerClosedConsiderHeadwayTemplateCard'
  );

  const [onCloseTreatmentPlanModal, setOnCloseTreatmentPlanModal] = useState<
    (() => void) | undefined
  >(() => {});

  const [isSavingInProgress, setIsSavingInProgress] = useState(false);
  var saveRequestTimestamp = useRef<Moment>();
  const [isSelectedTemplateFreeText, setIsSelectedTemplateFreeText] =
    useState(false);
  const [isSelectedNoteUpload, setIsSelectedNoteUpload] = useState(false);

  const { data: claimReadiness } = useClaimReadiness({ patientUser: patient });

  const { data: providerPatient } = useProviderPatient({
    providerId: event?.providerId,
    patientId: event?.patientUserId,
  });

  progressNoteOnly =
    progressNoteOnly ||
    !!claimReadiness?.requirements?.includes(
      UserClaimReadinessCheck.INSUFFICIENT_OR_INCORRECT_INFORMATION
    );

  const updateSavingState = useCallback((changeTo: boolean) => {
    if (changeTo === true) {
      setIsSavingInProgress(true);
      saveRequestTimestamp.current = moment();
    } else {
      const timeElapsed = moment().diff(
        saveRequestTimestamp.current,
        'seconds'
      );

      // Show the spinner for at least 2 seconds
      if (timeElapsed < 2) {
        setTimeout(
          () => setIsSavingInProgress(false),
          (2 - timeElapsed) * 1000
        );
      } else {
        setIsSavingInProgress(false);
      }
    }
  }, []);

  const onModalClose = useCallback(async () => {
    onClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, authStore, onClose]);

  const openTreatmentPlanModal = () => {
    setTreatmentPlanModalIsOpened(true);
  };

  // Checks that the legal language has been attested,
  // by accessing the attestation form
  const checkAttestation = async () => {
    if (!attestationRef.current) {
      return false;
    }

    await attestationRef.current.submitForm();
    return attestationRef.current?.isValid ?? false;
  };

  const resetAttestationState = async () => {
    if (!attestationRef.current) {
      return;
    }
    attestationRef.current.resetForm();
  };

  // If the open value switches to false we return null to make sure that the appointment context and
  // the progress note context fully unmount since its not part of the unmount logic of the Modal
  if (!open || !providerPatient) {
    return null;
  }

  if (!event) {
    return null;
  }

  return (
    <ProgressNoteContextProvider
      progressNoteRef={progressNoteRef}
      attachmentRef={attachmentRef}
      eventVirtualId={event.virtualId}
      onProgressNoteUpdate={() => {
        if (onProgressNoteUpdate) onProgressNoteUpdate();
      }}
    >
      <AppointmentContextProvider
        updateSavingState={updateSavingState}
        sessionDetailsRef={sessionDetailsRef}
        attachmentRef={attachmentRef}
        sessionDetailsErrorRef={sessionDetailsErrorRef}
        eventVirtualId={event.virtualId}
        patient={patient}
        setSelectedEvent={setSelectedEvent}
        onProgressNoteUpdate={() => {
          if (onProgressNoteUpdate) onProgressNoteUpdate();
        }}
      >
        <AppointmentConfirmationModal
          open={open}
          onModalClose={onModalClose}
          checkAttestation={checkAttestation}
          resetAttestation={resetAttestationState}
          isSavingInProgress={isSavingInProgress}
          eventVirtualId={event.virtualId!}
          provider={provider}
          patient={patient}
          onOpenTreatmentPlanAdoptionModal={openTreatmentPlanAdoptionModal}
          onOpenTreatmentPlanModal={openTreatmentPlanModal}
          setOnCloseTreatmentPlanModal={setOnCloseTreatmentPlanModal}
          setIsSwoopUpsellModalOpened={setIsSwoopUpsellModalOpened}
        >
          <div
            css={{
              width: '800px',
              position: 'relative',
              [theme.__futureMedia.phone]: {
                width: '100%',
                minWidth: '0',
              },
              [theme.__futureMedia.tablet]: {
                width: '100%',
                minWidth: '0',
              },
            }}
          >
            <>
              <StackingHeader
                sections={[
                  {
                    topOffset: -20,
                    header: (
                      <div
                        css={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          height: '55px',
                          paddingTop: '8px',
                        }}
                      >
                        <SectionHeader>
                          {formatPatientName(patient)}
                        </SectionHeader>
                        <div css={{ marginTop: '5px' }}>
                          <SubBodyText>
                            {patient?.dob
                              ? `${moment(patient.dob).format(
                                  'MM/DD/YY'
                                )} (${moment().diff(
                                  moment(patient.dob),
                                  'years'
                                )})`
                              : ''}
                          </SubBodyText>
                        </div>
                      </div>
                    ),
                    content: (
                      <section
                        css={{ marginBottom: theme.spacing.x7 }}
                        aria-label="Session details"
                      >
                        <ProgressNotesComplianceInfo />
                        <SessionDetailsForm
                          innerRef={sessionDetailsRef}
                          errorRef={sessionDetailsErrorRef}
                          eventVirtualId={event.virtualId}
                          provider={provider}
                          patient={patient}
                          onOpenContactFormInsuranceIssues={
                            onOpenContactFormInsuranceIssues
                          }
                        />
                      </section>
                    ),
                  },
                  {
                    topOffset: 32,
                    header: (
                      <ProgressNotesHeader
                        eventVirtualId={event.virtualId}
                        resetAttestation={resetAttestationState}
                      />
                    ),
                    content: (
                      <section aria-labelledby="progress-note-section-heading">
                        <ProgressNotesForm
                          innerRef={progressNoteRef}
                          eventVirtualId={event.virtualId}
                          patient={patient}
                          providerPatient={providerPatient}
                          updateSavingState={updateSavingState}
                          resetAttestation={resetAttestationState}
                          setIsSelectedTemplateFreeText={
                            setIsSelectedTemplateFreeText
                          }
                          setIsSelectedNoteUpload={setIsSelectedNoteUpload}
                        />
                      </section>
                    ),
                  },
                ]}
              />
              {!providerClosedConsiderHeadwayTemplateCard &&
              (isSelectedTemplateFreeText || isSelectedNoteUpload) ? null : (
                <AppointmentAttachmentForm
                  eventVirtualId={event.virtualId!}
                  innerRef={attachmentRef}
                  onProgressNoteUpdate={onProgressNoteUpdate}
                />
              )}

              <AddendumsForm />
              {!isPast(event) && !dismissCantConfirmYetBanner && (
                <div css={{ paddingBottom: theme.spacing.x8 }}>
                  <GuidanceCard variant="info">
                    <BodyText>
                      You can finish confirming this session after the scheduled
                      end time, {moment(event.endDate).format('hh:mm A')} on{' '}
                      {moment(event.endDate).format('MMMM DD')}.
                    </BodyText>
                    <Button
                      variant="link"
                      onPress={() => setDismissCantConfirmYetBanner('true')}
                    >
                      Dismiss
                    </Button>
                  </GuidanceCard>
                </div>
              )}

              <ProgressNoteSignedText />
              <AppointmentAttestationForm
                patient={patient}
                innerRef={attestationRef}
                progressNoteOnly={progressNoteOnly}
                eventVirtualId={event.virtualId!}
              />
              <TreatmentPlanRequirementWarning patient={patient} />
              <ComplianceGuideModal
                open={openComplianceModal}
                onClose={() => setOpenComplianceModal(false)}
                provider={provider}
                setProviderClosedComplianceModal={
                  setProviderClosedComplianceModal
                }
              />
            </>
          </div>
        </AppointmentConfirmationModal>
        <TreatmentPlanWithContext
          isEditing={treatmentPlanModalIsOpened}
          setIsEditing={setTreatmentPlanModalIsOpened}
          providerPatient={providerPatient}
          onCloseTreatmentPlanModal={onCloseTreatmentPlanModal}
        />
        <SwoopUpsellModal
          open={isSwoopUpsellModalOpened}
          onClose={() => {
            // close the entire modal which will cascade-close all the other modals opened on top
            onModalClose();
          }}
          patient={patient}
          eventVirtualId={event.virtualId}
        />
      </AppointmentContextProvider>
    </ProgressNoteContextProvider>
  );
};

interface AppointmentConfirmationProps
  extends Omit<AppointmentConfirmationImplProps, 'patient'> {
  patientId: number;
}

export const AppointmentConfirmation = ({
  patientId,
  ...props
}: AppointmentConfirmationProps) => {
  const { data: patient, isLoading: isPatientLoading } = useUser({
    userId: patientId,
  });

  if (isPatientLoading || !patient) {
    return null;
  }

  return <AppointmentConfirmationImpl patient={patient} {...props} />;
};
