import { getLocalTimeZone, today } from '@internationalized/date';
import { ArrowDropDown, AssessmentTwoTone } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useProviderIncentiveProgramEnrollment } from '~/legacy/hooks/useGetIsEnrolledProviderIncentiveProgram';

import { AssessmentsUpsellReadinessIssueMoreThanOneSessionInPastTwoMonthsType } from '@headway/api/models/AssessmentsUpsellReadinessIssueMoreThanOneSessionInPastTwoMonths';
import { AssessmentsUpsellReadinessIssueScheduledOrIncompleteType } from '@headway/api/models/AssessmentsUpsellReadinessIssueScheduledOrIncomplete';
import { PatientAssessmentRecurrenceCadence } from '@headway/api/models/PatientAssessmentRecurrenceCadence';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserRead } from '@headway/api/models/UserRead';
import { ContentText } from '@headway/helix/ContentText';
import { dateValueToMoment } from '@headway/helix/date';
import { Link } from '@headway/helix/Link';
import { SHORT_ASSESSMENT_NAMES } from '@headway/shared/constants/patientAssessments';
import {
  FieldCheckbox,
  FieldControl,
  FieldDatePicker,
  FieldErrorText,
  FieldInputLabel,
  FieldSelect,
  FormIconRow,
} from '@headway/ui/form';
import { theme } from '@headway/ui/theme';

import { useAvailableAssessmentTypes } from '../../Clients/Assessments/helpers/useAvailableAssessmentTypes';
import { CADENCE_DISPLAY_NAMES } from '../../Clients/Assessments/helpers/utils';
import { INCENTIVE_BONUS_AMOUNT_DOLLARS } from '../../Incentives/constants';

type AppointmentSchedulePromsUpsellProps = {
  provider: ProviderRead;
  patient?: UserRead;
  upsellReadiness?: UserAppointmentReadiness['assessmentsUpsell'];
};

export function AppointmentSchedulePromsUpsell({
  provider: { id: providerId },
  patient,
  upsellReadiness,
}: AppointmentSchedulePromsUpsellProps) {
  const { data: incentiveEnrollment, isLoading: isIncentiveEnrollmentLoading } =
    useProviderIncentiveProgramEnrollment({ providerId });

  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [{ value: upsellChecked }] = useField('assessmentsUpsell');

  const shouldUpsell = upsellReadiness?.every(
    ({ type }) =>
      type !==
      AssessmentsUpsellReadinessIssueScheduledOrIncompleteType.ASSESSMENTS_SCHEDULED_OR_INCOMPLETE
  );

  const shouldDefaultCheck = upsellReadiness?.every(
    ({ type }) =>
      type !==
      AssessmentsUpsellReadinessIssueMoreThanOneSessionInPastTwoMonthsType.MORE_THAN_ONE_SESSION_IN_PAST_TWO_MONTHS
  );

  useEffect(() => {
    setFieldValue('assessmentsUpsell', shouldUpsell && shouldDefaultCheck);
  }, [patient?.id, shouldUpsell, shouldDefaultCheck, setFieldValue]);

  const availableAssessmentTypes = useAvailableAssessmentTypes();

  if (isIncentiveEnrollmentLoading || !shouldUpsell) {
    return null;
  }

  return (
    <>
      <FieldControl
        name="assessmentsUpsell"
        css={{ marginLeft: theme.space.xs }}
      >
        <FieldCheckbox
          fieldType="boolean"
          value={upsellChecked}
          label={
            <ContentText>
              {incentiveEnrollment?.isProviderEnrolled ? (
                <>
                  Send assessments and earn ${INCENTIVE_BONUS_AMOUNT_DOLLARS}{' '}
                  extra for <b>each and every session.</b>{' '}
                  <Link href="/incentives/rate-boost" target="_blank">
                    Learn&nbsp;more.
                  </Link>
                </>
              ) : (
                'Send assessments to track progress'
              )}
            </ContentText>
          }
        />
      </FieldControl>
      {upsellChecked && (
        <FormIconRow icon={AssessmentTwoTone} spacing={theme.space.sm}>
          <FieldControl name="assessmentSchedule.assessmentType" fullWidth>
            <FieldInputLabel>Assessment</FieldInputLabel>
            <FieldSelect>
              {availableAssessmentTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {SHORT_ASSESSMENT_NAMES[type]}
                </MenuItem>
              ))}
            </FieldSelect>
          </FieldControl>
          <FieldControl name="assessmentSchedule.cadence" fullWidth>
            <FieldInputLabel>Frequency</FieldInputLabel>
            <FieldSelect>
              {Object.values(PatientAssessmentRecurrenceCadence).map(
                (cadence) => (
                  <MenuItem key={cadence} value={cadence}>
                    {CADENCE_DISPLAY_NAMES[cadence]}
                  </MenuItem>
                )
              )}
            </FieldSelect>
          </FieldControl>
          <FieldControl name="assessmentSchedule.startDate" fullWidth>
            <FieldDatePicker
              label="Next send date"
              margin="normal"
              inputFormat="MMM Do, YYYY"
              disableMaskedInput
              components={{ OpenPickerIcon: ArrowDropDown }}
              minDate={dateValueToMoment(today(getLocalTimeZone()))}
              onChange={() =>
                setFieldTouched('assessmentSchedule.startDate', true)
              }
            />
            <FieldErrorText />
          </FieldControl>
        </FormIconRow>
      )}
    </>
  );
}
