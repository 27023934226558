import { Button } from '@headway/helix/Button';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';

import {
  NpiLookupResult,
  ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep,
} from './helpers';

export const NpiVerificationModal = ({
  isOpen,
  onDismiss,
  npiLookupResults,
  formValues,
  onSubmit,
  confirmButtonDisabled,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  npiLookupResults: NpiLookupResult[];
  formValues: ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep;
  onSubmit: () => void;
  confirmButtonDisabled: boolean;
}) => {
  const failedNpiLookups = npiLookupResults.filter(
    (result) => result.error !== undefined
  );
  const didAnyNpiLookupsFail = failedNpiLookups.length > 0;

  return (
    <Modal
      title={
        didAnyNpiLookupsFail
          ? 'We were unable to verify these NPIs. Please try again.'
          : 'Are These Your Collaborating/Supervising Physicians?'
      }
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <ModalContent>
        {didAnyNpiLookupsFail
          ? failedNpiLookups.map((result) => {
              const failedLookupFormValues = Object.values(
                formValues.userInputtedSupervisingPhysicians ?? {}
              )
                .flatMap((spArray) => spArray)
                .find((sp) => sp.supervisingPhysicianNpi === result.npi);
              const failedLookupName =
                failedLookupFormValues?.supervisingPhysicianName;
              return (
                <div key={result.npi}>
                  {result.npi} - {failedLookupName}
                </div>
              );
            })
          : npiLookupResults.map((result) => (
              <div key={result.npi}>
                {result.firstName} {result.lastName}, {result.credential}
              </div>
            ))}
      </ModalContent>
      <ModalFooter>
        <Button variant="secondary" type="button" onPress={onDismiss}>
          {didAnyNpiLookupsFail ? 'Go Back' : 'No, go back'}
        </Button>
        {!didAnyNpiLookupsFail && (
          <Button onPress={onSubmit} disabled={confirmButtonDisabled}>
            Yes
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
