import { Route, Routes } from '@remix-run/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { HelixProvider } from '@headway/helix/Provider';
import { theme } from '@headway/helix/theme';
import { ToastContainer } from '@headway/helix/Toast';
import { useMediaQuery } from '@headway/helix/utils';
import { FlagsConsumer } from '@headway/shared/FeatureFlags/react';
import { ToastManager } from '@headway/ui';
import { NqfConsentFormPreview } from '@headway/ui/NqfConsentFormPreview';
import { theme as legacyTheme, ThemeProvider } from '@headway/ui/theme';

import 'assets/scss/material-dashboard-pro-react.css?v=1.4.0';
import AuthLayout from 'layouts/Auth';
import DashboardLayout from 'layouts/Dashboard';
import { QueryClientProvider } from 'providers/QueryClientProvider';
import { Incentives } from 'views/Incentives/Incentives';
import { IntakeFormPreviews } from 'views/IntakeFormPreviews/IntakeFormPreviews';
import { SubmissionSuccess } from 'views/IntakeQuestionnaireV2/SubmissionSuccess';
import { Legal } from 'views/LegalV2/Legal';
import { Onboarding } from 'views/Onboarding/Onboarding';

import { themeOverrides } from './assets/themeOverrides';
import { AuthRequired } from './AuthRequiredRoute';
import { PracticeLayout } from './layouts/PracticeLayout';
import { QuestionnaireV2Layout } from './layouts/QuestionnaireV2Layout';
import { AuthProvider } from './providers/AuthProvider';
import { OnboardingCompletionCardProvider } from './providers/OnboardingCompletionCardProvider';
import { UiStoreProvider } from './stores/UiStore';
import { PostIntakeInfoCollection } from './views/PostIntakeInfoCollection/PostIntakeInfoCollection';

class App extends Component<{ includeBrowserRouter?: boolean }> {
  render() {
    const Wrapper = this.props.includeBrowserRouter
      ? BrowserRouter
      : React.Fragment;
    return (
      <QueryClientProvider>
        <UiStoreProvider>
          <ThemeProvider themeOverrides={themeOverrides}>
            <HelixProvider>
              <ToastManager>
                <ToastContainer />
                <AuthProvider>
                  <OnboardingCompletionCardProvider>
                    <Wrapper>
                      <FlagsConsumer
                        flagRequests={{
                          skipIntake: false,
                          nqfOptIn: false,
                          practiceDashboard: false,
                        }}
                      >
                        {({ skipIntake, nqfOptIn, practiceDashboard }) => (
                          <Routes>
                            <Route path="/auth/*" element={<AuthLayout />} />
                            <Route
                              path="/credentials/intake-submit-success/*"
                              element={
                                <AuthRequired>
                                  <SubmissionSuccess />
                                </AuthRequired>
                              }
                            />
                            <Route
                              path="/payer-questionnaire/:id/submit-success/*"
                              element={
                                <AuthRequired>
                                  <SubmissionSuccess />
                                </AuthRequired>
                              }
                            />
                            {!skipIntake && (
                              <Route
                                path="/credentials/*"
                                element={
                                  <AuthRequired>
                                    <QuestionnaireV2Layout />
                                  </AuthRequired>
                                }
                              />
                            )}
                            <Route
                              path="/legal/*"
                              element={
                                <AuthRequired>
                                  <Legal />
                                </AuthRequired>
                              }
                            />
                            {practiceDashboard && (
                              <Route
                                path="/practice/*"
                                element={
                                  <AuthRequired>
                                    <PracticeLayout />
                                  </AuthRequired>
                                }
                              />
                            )}
                            <Route
                              path="/onboarding/*"
                              element={
                                <AuthRequired>
                                  <Onboarding />
                                </AuthRequired>
                              }
                            />
                            <Route
                              path="/payer-questionnaire/*"
                              element={
                                <AuthRequired>
                                  <PostIntakeInfoCollection />
                                </AuthRequired>
                              }
                            />
                            <Route
                              path="/intake-forms/*"
                              element={
                                <AuthRequired>
                                  <IntakeFormPreviews />
                                </AuthRequired>
                              }
                            />
                            <Route
                              path="/incentives/rate-boost"
                              element={
                                <AuthRequired>
                                  <Incentives />
                                </AuthRequired>
                              }
                            />
                            {nqfOptIn && (
                              <Route
                                path="/aligned-innovation-study-consent/*"
                                element={
                                  <AuthRequired>
                                    <NqfConsentFormPreview />
                                  </AuthRequired>
                                }
                              />
                            )}
                            <Route
                              path="*"
                              element={
                                <AuthRequired>
                                  <DashboardLayout />
                                </AuthRequired>
                              }
                            />
                          </Routes>
                        )}
                      </FlagsConsumer>
                    </Wrapper>
                  </OnboardingCompletionCardProvider>
                </AuthProvider>
              </ToastManager>
            </HelixProvider>
          </ThemeProvider>
        </UiStoreProvider>
        <RQDevtools />
      </QueryClientProvider>
    );
  }
}

function RQDevtools() {
  const isUnderMedium = useMediaQuery(
    `(max-width: ${legacyTheme.breakpoints.medium}px)`
  );
  return (
    <FlagsConsumer flagRequests={{ solvvyIntegration: undefined }}>
      {(solvvyIntegration) => (
        <ReactQueryDevtools
          initialIsOpen={false}
          // The Solvvy widget has a z-index of 1900000000.
          toggleButtonProps={
            solvvyIntegration
              ? {
                  style: {
                    zIndex: theme.layers.base + 1,
                    left: isUnderMedium ? undefined : '65px',
                    bottom: '10px',
                  },
                }
              : {}
          }
          panelProps={
            solvvyIntegration
              ? { style: { zIndex: theme.layers.base + 1 } }
              : {}
          }
          closeButtonProps={
            solvvyIntegration
              ? { style: { zIndex: theme.layers.base + 1 } }
              : {}
          }
        />
      )}
    </FlagsConsumer>
  );
}

export default App;
