import { groupBy, partition, reject } from 'lodash';
import moment from 'moment';

import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventReadForCalendar } from '@headway/api/models/ProviderEventReadForCalendar';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';

import { isMultiDayEvent, withoutContainedEvents } from './util/events';

interface WorkingHoursByDate {
  [date: string]: any[];
}

type GroupedEventsByDate = { [date: string]: ProviderEventReadForCalendar[] };
type EventList = ProviderEventReadForCalendar[];

export const createWorkingHoursByDateId = (date: moment.Moment) =>
  date.startOf('day').toISOString();

// If HWMA is enabled we hide any events that fall outside of our
// hours. Working hours themselves are events so we first need to find them
// and then check other events against them.
// TODO: Make this a user controlled filter?
// Split the list into two lists, one for AVAILABILTIY and
// one for everything else
export const separateWorkingHoursAndEvents = (
  allEvents: ProviderEventReadForCalendar[]
): { workingHoursByDate: GroupedEventsByDate; events: EventList } => {
  const [workingHours, events] = partition(allEvents, {
    type: ProviderEventType.AVAILABILITY,
  });

  // We filter out any working hours that have a recurrenceEndDate
  // before today, since those working hours will not be active
  const activeWorkingHours = reject(workingHours, (event) =>
    event.recurrenceEndDate
      ? moment(event.recurrenceEndDate).isBefore(moment())
      : false
  );

  // Convert the working hours array to an object keyed by
  // date.
  // { [ date: ISODateString ]: ProviderEventRead[] }
  return {
    workingHoursByDate: groupBy(activeWorkingHours, (e) =>
      createWorkingHoursByDateId(moment(e.startDate))
    ),
    events,
  };
};

export const removeWorkingHoursContainedInBusyEvents = (
  workingHoursByDay: WorkingHoursByDate,
  events: ProviderEventReadForCalendar[]
) => {
  // Check if any events fully contain a working hour event, and if so
  // remove it from our workingHoursByDay
  events.forEach((e) => {
    const startDate = moment(e.startDate);
    const endDate = moment(e.endDate);

    // Things get extra complicated if its a multiday event,
    // we have to check all the days from start to enddate - 1 to see if
    // there are any fully encompassing events
    if (isMultiDayEvent(e)) {
      const dateDifference = endDate.diff(startDate, 'days');

      // Check out each workingHour by day to see if this event
      // fully contains the working hour, if so remove it
      for (let d = 0; d <= dateDifference; d++) {
        const newDayId = createWorkingHoursByDateId(
          moment(startDate).add(d, 'day')
        );

        // We don't have any working hours to remove
        if (!workingHoursByDay[newDayId]) {
          continue;
        }

        // Removes any working hours contained in this event
        workingHoursByDay[newDayId] = withoutContainedEvents(
          workingHoursByDay[newDayId],
          e
        );
      }
    } else {
      const dayId = createWorkingHoursByDateId(startDate);

      // We don't have any working hours for that day
      if (!workingHoursByDay[dayId]) {
        return;
      }

      // We remove the working hour if the event fully encompasses it
      workingHoursByDay[createWorkingHoursByDateId(startDate)] =
        withoutContainedEvents(workingHoursByDay[dayId], e);
    }
  });

  const whs = [];
  for (const whsByDay of Object.values(workingHoursByDay)) {
    whs.push(...whsByDay);
  }

  return whs;
};

export const mergeOverlappingWorkingHours = (
  workingHours: ProviderEventReadForCalendar[]
) => {
  const sorted = workingHours.sort(
    (a, b) =>
      new Date(a.startDate!).getTime() - new Date(b.startDate!).getTime()
  );

  const merged = sorted.reduce((acc, curr) => {
    // Accept the first one as the starting point
    if (acc.length === 0) {
      return [curr];
    }

    const prev = acc.pop()!;

    // Current range is completely inside previous
    if (new Date(curr.endDate!) <= new Date(prev.endDate!)) {
      return [...acc, prev];
    }

    // Current range overlaps previous
    if (new Date(curr.startDate!) <= new Date(prev.endDate!)) {
      return [
        ...acc,
        { ...curr, startDate: prev.startDate, endDate: curr.endDate },
      ];
    }

    // Ranges do not overlap
    return [...acc, prev, curr];
  }, [] as ProviderEventReadForCalendar[]);

  return merged;
};
