import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import { useProvider, useProviderPatient } from 'hooks';
import qs from 'qs';
import React, { useEffect, useMemo } from 'react';
import { Link, Navigate, useMatch, useNavigate } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { useAllPatientAssessmentRecurrenceSchedules } from '~/legacy/hooks/useAllPatientAssessmentRecurrenceSchedules';
import {
  ProviderAppointmentReferralStatus,
  useClientReferralStatus,
} from '~/legacy/hooks/useClientReferralStatus';

import { BillingType } from '@headway/api/models/BillingType';
import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ClientAssessmentTabClickedEvent } from '@headway/avo';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { Item } from '@headway/helix/collections';
import { ContentText } from '@headway/helix/ContentText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconCaretLeft } from '@headway/helix/icons/CaretLeft';
import { LinkButton } from '@headway/helix/LinkButton';
import { TabList, TabPanels, Tabs } from '@headway/helix/Tabs';
import { theme } from '@headway/helix/theme';
import { ENABLE_AETNA_IFP_WARNING } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { getUseUserQueryKey, useUser } from '@headway/shared/hooks/useUser';
import { useUserAppointmentReadiness } from '@headway/shared/hooks/useUserAppointmentReadiness';
import { useQueryClient } from '@headway/shared/react-query';
import { shouldShowAetnaIFPWarning } from '@headway/shared/utils/aetnaIFP';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';
import { EmergencyContactModal } from '@headway/ui/EmergencyContact';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

import { getUseActivePatientAddressQueryKey } from 'hooks/useActivePatientAddress';
import { useGetIsPromsScheduleRequired } from 'hooks/useGetIsPromsScheduleRequired';
import { usePatientAssessmentUnreadCount } from 'hooks/usePatientAssessmentUnreadCount';
import { PanelLayout } from 'layouts/PanelLayout';
import { useAuthStore } from 'stores/AuthStore';
import {
  isAdminImpersonatingProviderUser,
  isMedicalRecordAuditorImpersonatingProvider,
  isOffshoreAgentImpersonatingProviderUser,
} from 'utils/access';
import { PromsScheduleRequireModal } from 'views/Patients/AddPatient/PromsScheduleRequireModal';
import { AddressModal } from 'views/Patients/AddressModal';
import { EditContactModal } from 'views/Patients/EditContactModal';

import { AddressManagement } from './AddressManagement';
import { Assessments } from './Assessments/Assessments';
import { arePromsAutoScheduled } from './Assessments/helpers/utils';
import { BillingDetail } from './BillingDetail';
import { BillingMethodModal } from './BillingMethodModal';
import { ClientAlerts } from './ClientAlerts';
import { ClientAvatar } from './ClientAvatar';
import { ClientInfoBox } from './ClientInfoBox';
import { ClinicalDetail } from './ClinicalDetail';
import { CommunicationDetail } from './CommunicationDetail';
import { InsuranceOutageToIneligibleAlertModal } from './InsuranceOutageToIneligibleAlertModal';
import { InsuranceOutageVerificationInProgressModal } from './InsuranceOutageVerificationInProgressModal';
import { PaymentMethodModal } from './PaymentMethodModal';
import { ClientContextProvider } from './stores/ClientContext';

/** Top-level component for the client details page. */
export const Client = () => {
  const { user, impersonatingUser, impersonatingUserRoles } = useAuthStore();
  const isSpoofing = isAdminImpersonatingProviderUser(user, impersonatingUser);
  const isSpooferWithoutPermission =
    isSpoofing &&
    !isMedicalRecordAuditorImpersonatingProvider(impersonatingUserRoles);
  const shouldShowAddressModalDefault =
    useSearchParam('showAddressModal') !== null;
  const [isEditContactModalOpen, setIsEditContactModalOpen] =
    React.useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = React.useState(
    shouldShowAddressModalDefault
  );
  const [isEmergencyContactModalOpen, setIsEmergencyContactModalOpen] =
    React.useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] =
    React.useState<boolean>(false);
  const [isBillingMethodModalOpen, setIsBillingMethodModalOpen] =
    React.useState<boolean>(false);
  const [isPlanIneligibleAlertModalOpen, setIsPlanIneligibleAlertModalOpen] =
    React.useState<boolean>(false);
  const [
    isNoDataOutageVerificationInProgressModalOpen,
    setIsNoDataOutageVerificationInProgressModalOpen,
  ] = React.useState<boolean>(false);
  const [
    isOldDataOutageVerificationInProgressModalOpen,
    setIsOldDataOutageVerificationInProgressModalOpen,
  ] = React.useState<boolean>(false);
  const [isPromsScheduleRequireModalOpen, setIsPromsScheduleRequireModalOpen] =
    React.useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const match = useMatch('/clients/:id/:tab?');
  const provider = useProvider();
  const clientId = Number(match?.params.id);

  const clientQuery = useUser({ userId: clientId });
  const client = clientQuery.data;

  const { data: providerPatient }: { data: ProviderPatientRead | undefined } =
    useProviderPatient({
      providerId: provider.id,
      patientId: clientId,
    });

  const appointmentReadinessQuery = useUserAppointmentReadiness({
    userId: clientId,
    providerId: provider.id,
  });

  const { data: patientAssessmentUnreadCount } =
    usePatientAssessmentUnreadCount(
      { providerPatientId: providerPatient?.id },
      {
        enabled: !!providerPatient?.id && !isSpooferWithoutPermission,
        refetchOnWindowFocus: false,
      }
    );

  const { data: patientAssessmentSchedules } =
    useAllPatientAssessmentRecurrenceSchedules(
      { providerPatientId: providerPatient?.id },
      { refetchOnWindowFocus: false, enabled: !isSpooferWithoutPermission }
    );

  const { data: isPromsScheduleRequiredDetails } =
    useGetIsPromsScheduleRequired({
      providerPatientId: providerPatient?.id,
    });

  const [
    autoSchedulePROMsShownClientsString,
    setAutoSchedulePROMsShownClientsString,
  ] = useLocalStorage('autoSchedulePROMsShownClients');

  const autoSchedulePROMsShownClients = useMemo((): number[] => {
    if (!autoSchedulePROMsShownClientsString) {
      return [];
    }

    const parsed = JSON.parse(autoSchedulePROMsShownClientsString);

    return Array.isArray(parsed) ? parsed : [];
  }, [autoSchedulePROMsShownClientsString]);

  useEffect(() => {
    const shouldShowAutoSchedulePROMsModal =
      !isPromsScheduleRequireModalOpen &&
      !!isPromsScheduleRequiredDetails?.isPromsScheduleRequired &&
      !autoSchedulePROMsShownClients.includes(clientId) &&
      arePromsAutoScheduled(patientAssessmentSchedules);

    if (!shouldShowAutoSchedulePROMsModal) {
      return;
    }

    setIsPromsScheduleRequireModalOpen(true);
    setAutoSchedulePROMsShownClientsString(
      JSON.stringify([...new Set([...autoSchedulePROMsShownClients, clientId])])
    );
  }, [
    isPromsScheduleRequireModalOpen,
    autoSchedulePROMsShownClients,
    clientId,
    patientAssessmentSchedules,
    isPromsScheduleRequiredDetails?.isPromsScheduleRequired,
    setAutoSchedulePROMsShownClientsString,
  ]);

  const isSelfPay =
    providerPatient?.billingTypeDefault === BillingType.SELF_PAY;
  const hidden = providerPatient?.hidden || false;

  const showOffshoreBrokenLinks = !isOffshoreAgentImpersonatingProviderUser(
    impersonatingUserRoles
  );

  const onContactEdit = (updatedClient: UserRead) => {
    queryClient.setQueryData(
      getUseUserQueryKey({ userId: clientId }),
      updatedClient
    );
    queryClient.invalidateQueries(getUseUserQueryKey({ userId: clientId }));
  };

  const goToMessages = () => {
    const messagesLink = qs.stringify({
      patient: clientId,
      archived_filter: !hidden,
    });
    navigate(`/messages?${messagesLink}`);
  };

  const onAddressAdded = (
    updatedAddress: PatientAddressRead,
    updatedClient: UserRead
  ) => {
    queryClient.setQueryData(
      getUseActivePatientAddressQueryKey({ patientUser: client }),
      updatedAddress
    );
    queryClient.invalidateQueries(
      getUseActivePatientAddressQueryKey({ patientUser: client })
    );

    queryClient.setQueryData(
      getUseUserQueryKey({ userId: clientId }),
      updatedClient
    );
    queryClient.invalidateQueries(getUseUserQueryKey({ userId: clientId }));

    setIsAddressModalOpen(false);
  };

  const { data: clientReferralStatusData } = useClientReferralStatus({
    providerId: provider.id,
    clientId: clientId,
  });
  const clientReferralStatus = clientReferralStatusData?.status;

  const clientHasSignedForms = !!(
    client?.assignmentOfBenefitsDate &&
    client?.privacyPracticesAcknowledgementDate
  );

  const referralWarningBadgeLabel = useMemo(() => {
    if (clientReferralStatus) {
      switch (clientReferralStatus) {
        case ProviderAppointmentReferralStatus.CANCELED:
          return 'Pending referral cancelled';
        case ProviderAppointmentReferralStatus.PENDING:
          return 'Pending referral';
      }
    }

    return null;
  }, [clientReferralStatus]);

  const showReferralWarningBadge = !!referralWarningBadgeLabel;

  const showMissingFormsWarningBadge =
    !showReferralWarningBadge && !clientHasSignedForms;

  const enableAetnaIFPWarning = useFlag(ENABLE_AETNA_IFP_WARNING, false);
  const showAetnaIFPGuidanceAlert = shouldShowAetnaIFPWarning(
    enableAetnaIFPWarning,
    client?.activeUserInsurance?.memberId,
    client?.activeUserInsurance?.frontEndCarrierId
  );

  if (Number.isNaN(clientId)) {
    return <Navigate replace to={'/clients'} />;
  }

  const currentTab = match?.params.tab;
  if (
    currentTab !== 'clinical' &&
    currentTab !== 'billing' &&
    currentTab !== 'communication' &&
    currentTab !== 'assessments'
  ) {
    return <Navigate replace to={`/clients/${clientId}/billing`} />;
  }

  const handleEventTracking = () => {
    trackEvent({
      name: 'Client Assessment Tab Clicked',
      properties: {
        patientUserId: clientId,
        providerId: provider.id,
        providerPatientId: providerPatient?.id,
      },
    } as ClientAssessmentTabClickedEvent);
  };

  return (
    <ClientContextProvider client={client}>
      <PanelLayout>
        <div css={clientCss.container}>
          <div>
            <LinkButton
              component={Link}
              variant="link"
              to="/clients"
              elementType="a"
              disabled={!showOffshoreBrokenLinks}
            >
              <span css={{ ...theme.stack.horizontal }}>
                <IconCaretLeft size="1em" css={clientCss.caretLeft} />
                All Clients
              </span>
            </LinkButton>
          </div>
          <div css={clientCss.avatarRow}>
            {client ? (
              <>
                <div css={clientCss.badge}>
                  <ClientAvatar client={client} size="large" />
                  <h1 css={clientCss.clientName}>
                    <ContentText variant="page-title">
                      {formatPatientName(client, {
                        appendLegalName: true,
                      })}
                    </ContentText>
                  </h1>
                  {showReferralWarningBadge && (
                    <Badge variant="warning">{referralWarningBadgeLabel}</Badge>
                  )}
                  {showMissingFormsWarningBadge && (
                    <Badge variant="warning">Missing forms</Badge>
                  )}
                </div>
                <div css={clientCss.buttons}>
                  <Button
                    size="large"
                    variant="secondary"
                    onPress={goToMessages}
                  >
                    Message
                  </Button>
                  {!client.isVerified && (
                    <Button
                      size="large"
                      variant="secondary"
                      onPress={() => setIsEditContactModalOpen(true)}
                    >
                      Edit
                      <VisuallyHidden> Client</VisuallyHidden>
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <>
                <Skeleton variant="circular" width="56px" height="56px" />
                <h1>
                  <ContentText variant="page-title">
                    <Skeleton variant="text" width="200px" />
                  </ContentText>
                </h1>
              </>
            )}
          </div>
          {showAetnaIFPGuidanceAlert && (
            <GuidanceCard variant="warning">
              <span className="flex items-center gap-2 font-bold">
                Beginning January 1st, 2025,{' '}
                {client?.firstName || 'your client'}'s specific{' '}
                {client?.activeUserInsurance?.frontEndCarrierName ||
                  'insurance'}{' '}
                plan will no longer be in-network with Headway. You can continue
                to see {client?.firstName || 'your client'} until then through
                their insurance. From there, you are welcome to see them via
                private pay. Headway will continue to be in-network with all
                Aetna commercial plans.
              </span>
            </GuidanceCard>
          )}
          <ClientAlerts
            clientId={clientId}
            userAppointmentReadiness={appointmentReadinessQuery?.data}
            onOpenAddressModal={() => setIsAddressModalOpen(true)}
            onOpenBillingMethodModal={() => setIsBillingMethodModalOpen(true)}
            onOpenPaymentModal={() => setIsPaymentModalOpen(true)}
            onOpenPlanIneligibleAlertModal={() =>
              setIsPlanIneligibleAlertModalOpen(true)
            }
            onOpenNoDataOutageVerificationInProgressModal={() =>
              setIsNoDataOutageVerificationInProgressModalOpen(true)
            }
            onOpenOldDataOutageVerificationInProgressModal={() =>
              setIsOldDataOutageVerificationInProgressModalOpen(true)
            }
          />
          <ClientInfoBox
            clientId={clientId}
            onOpenAddressModal={() => setIsAddressModalOpen(true)}
            onOpenEmergencyContactModal={() =>
              setIsEmergencyContactModalOpen(true)
            }
          />
          <div>
            <Tabs
              selectedKey={currentTab}
              onSelectionChange={(key) => {
                if (key === 'assessments') {
                  handleEventTracking();
                }
                navigate(`/clients/${clientId}/${key}`, { replace: true });
              }}
            >
              <TabList>
                <Item key="clinical">Clinical</Item>
                <Item key="billing">Billing</Item>
                <Item key="communication">Communications</Item>
                <Item key="assessments">
                  <span
                    css={clientCss.assessments}
                    data-intercom-target="proms-early-access"
                  >
                    Assessments
                    {patientAssessmentUnreadCount &&
                    patientAssessmentUnreadCount > 0 ? (
                      <div
                        css={clientCss.unreadAssessmentsBadge}
                        aria-label="Number of unread assessments"
                      >
                        {patientAssessmentUnreadCount}
                      </div>
                    ) : null}
                  </span>
                </Item>
              </TabList>
              <TabPanels>
                <Item textValue="Clinical Detail" key="clinical">
                  {providerPatient && (
                    <ClinicalDetail
                      clientId={clientId}
                      providerPatient={providerPatient}
                    />
                  )}
                </Item>
                <Item textValue="Billing" key="billing">
                  {providerPatient && !isSelfPay && (
                    <AddressManagement client={client} />
                  )}
                  <BillingDetail clientId={clientId} />
                </Item>
                <Item textValue="Communications" key="communication">
                  <CommunicationDetail clientId={clientId} />
                </Item>
                <Item textValue="Assessments" key="assessments">
                  <Assessments clientId={clientId} />
                </Item>
              </TabPanels>
            </Tabs>
          </div>
        </div>
        {client && (
          <EditContactModal
            open={isEditContactModalOpen}
            onClose={() => {
              setIsEditContactModalOpen(false);
            }}
            patientUser={client}
            onContactEdit={onContactEdit}
          />
        )}
        {client && (
          <AddressModal
            open={isAddressModalOpen}
            onClose={() => setIsAddressModalOpen(false)}
            onSuccess={onAddressAdded}
            patientUser={client}
          />
        )}
        <BillingMethodModal
          clientId={clientId}
          open={isBillingMethodModalOpen}
          onClose={() => setIsBillingMethodModalOpen(false)}
        />
        {client && (
          <EmergencyContactModal
            patientUser={client}
            open={isEmergencyContactModalOpen}
            onClose={() => setIsEmergencyContactModalOpen(false)}
            appIsSigmund={true}
            provider={provider}
          />
        )}
        {client && (
          <InsuranceOutageVerificationInProgressModal
            client={client}
            open={isNoDataOutageVerificationInProgressModalOpen}
            onClose={() =>
              setIsNoDataOutageVerificationInProgressModalOpen(false)
            }
          />
        )}
        {client && (
          <InsuranceOutageVerificationInProgressModal
            client={client}
            open={isOldDataOutageVerificationInProgressModalOpen}
            onClose={() =>
              setIsOldDataOutageVerificationInProgressModalOpen(false)
            }
          />
        )}
        {client && (
          <InsuranceOutageToIneligibleAlertModal
            client={client}
            clientInsurance={client?.activeUserInsurance}
            open={isPlanIneligibleAlertModalOpen}
            onClose={() => setIsPlanIneligibleAlertModalOpen(false)}
            onUpdateInsurance={() => {
              setIsPlanIneligibleAlertModalOpen(false);
              setIsBillingMethodModalOpen(true);
            }}
            issues={appointmentReadinessQuery?.data?.insurance || []}
          />
        )}
        <PaymentMethodModal
          clientId={clientId}
          open={isPaymentModalOpen}
          onClose={() => setIsPaymentModalOpen(false)}
        />
        {client && isPromsScheduleRequiredDetails?.carrierName && (
          <PromsScheduleRequireModal
            client={client}
            promsScheduleRequiredModalOpen={isPromsScheduleRequireModalOpen}
            setPromsScheduleRequiredModalOpen={
              setIsPromsScheduleRequireModalOpen
            }
            carrierName={isPromsScheduleRequiredDetails.carrierName}
          />
        )}
      </PanelLayout>
    </ClientContextProvider>
  );
};

const clientCss = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing.x5,
    paddingBottom: theme.spacing.x8,
    gap: theme.spacing.x5,
  }),
  caretLeft: css({
    marginLeft: theme.spacing.x1,
    verticalAlign: 'text-bottom',
  }),
  avatarRow: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing.x4,
    justifyContent: 'space-between',
  }),
  clientName: css({
    flexGrow: 1,
  }),
  badge: css({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.x4,
  }),
  buttons: css({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.x2,
    marginLeft: 'auto',
  }),
  assessments: css({
    display: 'flex',
    gap: theme.spacing.x1,
  }),
  unreadAssessmentsBadge: css({
    ...theme.typography.subbody.medium,
    width: '29px',
    height: theme.spacing.x5,
    backgroundColor: theme.color.system.lightGray,
    color: theme.color.system.textBlack,
    borderRadius: theme.spacing.x5,
    padding: `1px 10px 1px 10px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};
