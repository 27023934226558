import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

interface UseProviderConfirmabilityQueryKeyArgs {
  providerId: number | undefined;
}

const getProviderConfirmabilityForProviderQueryKey = ({
  providerId,
}: UseProviderConfirmabilityQueryKeyArgs): [string, number] => [
  'provider-confirmability',
  providerId!,
];

const { useSingleQuery: useProviderConfirmability } = createBasicApiHooks(
  getProviderConfirmabilityForProviderQueryKey,
  ({ providerId }) => {
    return ProviderApi.getProviderConfirmability(providerId!);
  },
  ({ providerId }) => !!providerId,
  () => 'Failed to fetch provider confirmability'
);

export { useProviderConfirmability };
