import { CalendarDate, getLocalTimeZone, today } from '@internationalized/date';
import moment from 'moment';

export const shouldBlockProviderWithIroncladAgreement = (
  isIroncladBlockAppointmentConfirmationEnabled: boolean,
  startDate?: Date
) => {
  return startDate
    ? isIroncladBlockAppointmentConfirmationEnabled &&
        moment(startDate) < moment().subtract(30, 'days')
    : isIroncladBlockAppointmentConfirmationEnabled;
};

export const getSignerId = (user: any, isGroupPractice: boolean) => {
  return isGroupPractice
    ? `group-${user.group_practice.id}`
    : (user?.id as string);
};

/**
 * Returns the furthest date in the past that is still in the window where a provider can
 * confirm appointments. This is controlled by the `ironcladBlockAppointmentConfirmation` flag,
 * though that name is misleading since it has little to do with Ironclad now.
 */
export const getLastConfirmableDay = (
  isIroncladBlockAppointmentConfirmationEnabled: boolean
): CalendarDate => {
  return today(getLocalTimeZone()).subtract({
    days: isIroncladBlockAppointmentConfirmationEnabled ? 30 : 90,
  });
};
