import { View } from '@react-pdf/renderer';
import { Formik } from 'formik';
import mapValues from 'lodash/mapValues';
import React from 'react';
import * as Yup from 'yup';

import {
  PHQ9Submission,
  PHQ9SubmissionAssessmentType,
} from '@headway/api/models/PHQ9Submission';
import { SubBodyText } from '@headway/helix/SubBodyText';

import { SafeFormikForm } from '../form/SafeFormikForm';
import { PdfText } from '../pdf';
import { ErrorScrollListener } from './components/ErrorScrollListener';
import {
  OptionsSchema,
  PdfScorableQuestionList,
  QuestionsSchema,
  ReadonlyScorableQuestionList,
  ScorableQuestionListForm,
} from './components/ScorableQuestionList';
import { AssessmentFormProps, ReadonlyAssessmentProps } from './types';

const PHQ9_OPTIONS: OptionsSchema = [
  {
    displayText: 'Not at all',
    value: 0,
  },
  {
    displayText: 'Several days',
    value: 1,
  },
  {
    displayText: 'More than half the days',
    value: 2,
  },
  {
    displayText: 'Nearly every day',
    value: 3,
  },
];

const PHQ9_QUESTIONS: QuestionsSchema = [
  {
    key: 'PHQ9_1',
    questionText: 'Little interest or pleasure in doing things',
  },
  {
    key: 'PHQ9_2',
    questionText: 'Feeling down, depressed, or hopeless',
  },
  {
    key: 'PHQ9_3',
    questionText: 'Trouble falling or staying asleep, or sleeping too much',
  },
  {
    key: 'PHQ9_4',
    questionText: 'Feeling tired or having little energy',
  },
  {
    key: 'PHQ9_5',
    questionText: 'Poor appetite or overeating',
  },
  {
    key: 'PHQ9_6',
    questionText:
      'Feeling bad about yourself or that you are a failure or have let yourself or your family down',
  },
  {
    key: 'PHQ9_7',
    questionText:
      'Trouble concentrating on things, such as reading the newspaper or watching television',
  },
  {
    key: 'PHQ9_8',
    questionText:
      'Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual',
  },
  {
    key: 'PHQ9_9',
    questionText:
      'Thoughts that you would be better off dead, or of hurting yourself',
  },
];

const INSTRUCTION =
  'Over the last 2 weeks, how often have you been bothered by any of the following problems?';

const PHQ9_VALIDATION_SCHEMA = Yup.object().shape(
  PHQ9_QUESTIONS.reduce(
    (acc, { key }) => {
      acc[key] = Yup.string().required('Select an option');
      return acc;
    },
    {} as { [key: string]: Yup.StringSchema }
  )
);

type PHQ9FormValues = {
  [key in keyof Omit<PHQ9Submission, 'assessmentType'>]: string;
};

export const PHQ9AssessmentForm = ({ id, onSubmit }: AssessmentFormProps) => {
  const innerOnSubmit = (values: PHQ9FormValues) => {
    return onSubmit({
      assessmentType: PHQ9SubmissionAssessmentType.PHQ9,
      ...mapValues(values, Number),
    });
  };

  return (
    <Formik<PHQ9FormValues>
      onSubmit={innerOnSubmit}
      validationSchema={PHQ9_VALIDATION_SCHEMA}
      initialValues={{
        PHQ9_1: '',
        PHQ9_2: '',
        PHQ9_3: '',
        PHQ9_4: '',
        PHQ9_5: '',
        PHQ9_6: '',
        PHQ9_7: '',
        PHQ9_8: '',
        PHQ9_9: '',
      }}
    >
      <SafeFormikForm id={id}>
        <ErrorScrollListener />
        <section className="flex flex-col gap-6">
          <b>{INSTRUCTION}</b>
          <ScorableQuestionListForm
            questions={PHQ9_QUESTIONS}
            options={PHQ9_OPTIONS}
          />
        </section>
      </SafeFormikForm>
    </Formik>
  );
};

export const ReadonlyPHQ9Assessment = (props: ReadonlyAssessmentProps) => {
  return (
    <section className="flex flex-col gap-6">
      <SubBodyText>
        <b>{INSTRUCTION}</b>
      </SubBodyText>
      <ReadonlyScorableQuestionList
        questions={PHQ9_QUESTIONS}
        options={PHQ9_OPTIONS}
        response={props.response?.scorableResponseJson}
      />
    </section>
  );
};

export const PdfPHQ9Assessment = (props: ReadonlyAssessmentProps) => {
  return (
    <View style={{ flexDirection: 'column', gap: 8 }}>
      <PdfText style={{ fontWeight: 'medium' }}>{INSTRUCTION}</PdfText>
      <PdfScorableQuestionList
        questions={PHQ9_QUESTIONS}
        options={PHQ9_OPTIONS}
        response={props.response?.scorableResponseJson}
      />
    </View>
  );
};
