import { useContext } from 'react';
import { useRuleSet } from '~/legacy/views/AppointmentConfirmation/ruleset/useRuleSet';
import { AppointmentConfirmationContextV2 } from '~/legacy/views/AppointmentConfirmation/stores/AppointmentConfirmationContextV2';

export const SessionDetailsTopBanner = () => {
  const { patient, provider, event, providerPatient, progressNoteState } =
    useContext(AppointmentConfirmationContextV2);
  const activeRule = useRuleSet({
    patient,
    provider,
    event,
    providerPatient,
    progressNoteState,
  });
  return activeRule?.banners?.sessionDetails?.header || null;
};
