import { getLocalTimeZone, today } from '@internationalized/date';

import { DatePickerField } from '@headway/helix/DatePickerField';
import { FormControl } from '@headway/helix/FormControl';

type AssessmentScheduleDatePickerProps = {
  name: string;
  label: string;
  disabled?: boolean;
};

export function AssessmentScheduleDatePicker(
  props: AssessmentScheduleDatePickerProps
) {
  return (
    <FormControl
      component={DatePickerField}
      minValue={today(getLocalTimeZone())}
      {...props}
    />
  );
}
