import { useCallback, useContext, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useCreateProviderProgressNoteMutation } from '~/legacy/mutations/providerProgressNote';
import { useProgressNoteInitialValues } from '~/legacy/views/AppointmentConfirmation/hooks/initialValues/useProgressNoteInitialValues';
import { useRuleSet } from '~/legacy/views/AppointmentConfirmation/ruleset/useRuleSet';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { Badge } from '@headway/helix/Badge';
import { Item, Select } from '@headway/helix/Select';
import { theme } from '@headway/helix/theme';
import { CPTCodeInfo } from '@headway/shared/constants/cptCodes';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import {
  AppointmentConfirmationContextV2,
  ProgressNoteState,
} from '../../../../stores/AppointmentConfirmationContextV2';
import { FormControlRHF } from '../../../FormControlRHF';
import { ProgressNoteFormV2Values } from '../ProgressNoteFormV2';
import { GenericTemplate } from '../Template/Renderer/types';
import {
  alphabeticallySortTemplates,
  applyTemplateRolloutFilterV2,
} from '../Template/utils';
import {
  createNoteJsonObject,
  getRecommendedTemplateForCPTCode,
  getTemplateIdAndVersionFromFormValue,
} from '../utils';

export const TemplateSelect = () => {
  const {
    progressNote,
    setProgressNote,
    progressNoteState,
    isProgressNoteLoading,
    providerPatient,
    templates,
  } = useContext(AppointmentConfirmationContextV2);
  const { getTemplateDefaultValues } = useProgressNoteInitialValues();
  const { patient, provider, event } = useContext(
    AppointmentConfirmationContextV2
  );
  const activeRule = useRuleSet({
    patient,
    provider,
    event,
    providerPatient,
    progressNoteState,
  });
  const { setValue, clearErrors } = useFormContext();
  const progressNoteValues: ProgressNoteFormV2Values = useWatch({
    name: 'progressNote',
  });
  const cptCodes = useWatch({ name: 'sessionDetails.cptCodes' });
  const progressNotesTemplatesRollout = useFlag('progressNoteTemplates', false);
  const createProviderProgressNoteMutation =
    useCreateProviderProgressNoteMutation();

  // Memoize recommended template ID
  const recommendedTemplateId = useMemo(() => {
    return getRecommendedTemplateForCPTCode(
      progressNotesTemplatesRollout,
      cptCodes?.map((codeInfo: CPTCodeInfo) => codeInfo.value)
    )?.id;
  }, [progressNotesTemplatesRollout, cptCodes]);

  // Help text for the template
  const helpTextForTemplate = useMemo(() => {
    if (progressNoteValues?.template) {
      const currentTemplateId = getTemplateIdAndVersionFromFormValue(
        progressNoteValues?.template
      )[0];
      return currentTemplateId === recommendedTemplateId
        ? 'This is the best template for the CPT code you selected'
        : '';
    }
    return '';
  }, [progressNoteValues?.template, recommendedTemplateId]);

  // Filter and sort templates
  const filteredTemplateList = useMemo(() => {
    const result = alphabeticallySortTemplates(
      applyTemplateRolloutFilterV2(templates, progressNotesTemplatesRollout)
    );
    return activeRule ? activeRule.templateFilter()(result) : result;
  }, [templates, progressNotesTemplatesRollout, activeRule]);

  // Handle selection changes
  const handleSelectionChange = useCallback(
    async (keys: Set<string>) => {
      const selectedTemplate =
        keys.size > 0 ? keys.values().next().value : undefined;
      // We want to clear all the filled fields if we switch templates => This should automatically reset those
      setValue('progressNote', {
        template: selectedTemplate,
        progressNoteType: ProgressNoteType.TEMPLATE,
      });

      // Every time a template is selected we update the form values with response json defaults
      // for the new template
      const [id, version] = selectedTemplate.split('-');
      const newTemplateInitialValues = getTemplateDefaultValues(
        parseInt(id),
        parseInt(version),
        {}
      );
      Object.entries(newTemplateInitialValues).forEach(([key, value]) => {
        setValue(`progressNote.${key}`, value);
      });

      clearErrors();

      if (!progressNote) {
        // if the progressNote doesn't exist yet we create a new one
        const {
          template,
          progressNoteType,
          previousNoteId,
          lateEntryReason,
          lateEntryOtherReason,
          ...response
        } = progressNoteValues;
        const noteJson = createNoteJsonObject(selectedTemplate, response);
        const data = await createProviderProgressNoteMutation.mutateAsync({
          provider_progress_note_data: {
            providerId: event?.providerId!,
            patientId: event?.patientUserId!,
            providerAppointmentId: event?.providerAppointment?.id!,
            noteJson: noteJson,
            lastSavedOn: new Date().toISOString(),
          },
        });
        setProgressNote(data.provider_progress_note);
      }
    },
    [
      setValue,
      clearErrors,
      getTemplateDefaultValues,
      createProviderProgressNoteMutation,
      event?.patientUserId,
      event?.providerAppointment?.id,
      event?.providerId,
      progressNote,
      progressNoteValues,
      setProgressNote,
    ]
  );

  // Render template item (useCallback)
  const renderTemplateItem = useCallback(
    (template: GenericTemplate<unknown>) => {
      const templateId = getTemplateIdAndVersionFromFormValue(
        `${template.templateInfo.id}-${template.templateInfo.version}`
      )[0];
      const isRecommended = templateId === recommendedTemplateId;

      return (
        <Item
          textValue={template.templateInfo.name}
          key={`${template.templateInfo.id}-${template.templateInfo.version}`}
        >
          <div className="flex flex-row gap-2">
            {template.templateInfo.name}{' '}
            {isRecommended && <Badge variant="info">Recommended</Badge>}
            {template.templateInfo.id === 37 && (
              <Badge variant="positive">AI Assisted</Badge>
            )}
          </div>
        </Item>
      );
    },
    [recommendedTemplateId]
  );

  return (
    <div
      css={{
        flexGrow: 1,
        [theme.__futureMedia.phone]: { width: '100%' },
      }}
    >
      <FormControlRHF
        name="progressNote.template"
        disabled={
          progressNoteState !== ProgressNoteState.EDITING ||
          isProgressNoteLoading
        }
        component={Select}
        placeholder="Choose template"
        label="Template"
        selectionMode="single"
        menuWidth="stretch"
        helpText={helpTextForTemplate}
        selectedKeys={
          progressNoteValues.template
            ? new Set([progressNoteValues.template])
            : new Set()
        }
        onSelectionChange={handleSelectionChange}
      >
        {filteredTemplateList.map(renderTemplateItem)}
      </FormControlRHF>
    </div>
  );
};
