export const languages = [
  'Albanian',
  'American Sign Language',
  'Arabic',
  'Armenian',
  'Assamese',
  'Bengali',
  'Bosnian',
  'Bulgarian',
  'Cantonese',
  'Croatian',
  'Danish',
  'Dutch',
  'English',
  'Farsi',
  'Flemish',
  'French',
  'Fuzhounese',
  'German',
  'Greek',
  'Gujarati',
  'Haitian Creole',
  'Hebrew',
  'Hindi',
  'Hmong',
  'Hungarian',
  'Icelandic',
  'Igbo',
  'Italian',
  'Japanese',
  'Kashmiri',
  'Korean',
  'Kurdish',
  'Malayalam',
  'Mandarin',
  'Marathi',
  'Norwegian',
  'Polish',
  'Portuguese',
  'Punjabi',
  'Romanian',
  'Russian',
  'Serbian',
  'Shona',
  'Somali',
  'Spanish',
  'Swahili',
  'Tagalog',
  'Thai',
  'Turkish',
  'Twi',
  'Ukrainian',
  'Urdu',
  'Vietnamese',
  'Yiddish',
  'Yoruba',
];
