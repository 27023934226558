/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum LookupSource {
    PRELIMINARY_BACKGROUND_REFRESH = 'PRELIMINARY_BACKGROUND_REFRESH',
    SECONDARY_BACKGROUND_REFRESH = 'SECONDARY_BACKGROUND_REFRESH',
    PRELIMINARY_FIRST_OF_THE_MONTH_REFRESH = 'PRELIMINARY_FIRST_OF_THE_MONTH_REFRESH',
    SECONDARY_FIRST_OF_THE_MONTH_REFRESH = 'SECONDARY_FIRST_OF_THE_MONTH_REFRESH',
    PRELIM_PRICING_REFRESH = 'PRELIM_PRICING_REFRESH',
    PATIENT_INSURANCE_UPLOAD = 'PATIENT_INSURANCE_UPLOAD',
    PROVIDER_INSURANCE_UPLOAD = 'PROVIDER_INSURANCE_UPLOAD',
    ATLAS_INSURANCE_UPLOAD = 'ATLAS_INSURANCE_UPLOAD',
    ATLAS_REFRESH = 'ATLAS_REFRESH',
    ATLAS_HARD_REFRESH = 'ATLAS_HARD_REFRESH',
    INSURANCE_VERIFICATION_REQUEST_SERVICE_RETRY = 'INSURANCE_VERIFICATION_REQUEST_SERVICE_RETRY',
    PRICE_FOR_PROVIDER_TYPE_REFRESH = 'PRICE_FOR_PROVIDER_TYPE_REFRESH',
    HEALTHCARE_REFERRAL_SERVICE_REFRESH = 'HEALTHCARE_REFERRAL_SERVICE_REFRESH',
    BULK_REFRESH = 'BULK_REFRESH',
    BULK_HARD_REFRESH = 'BULK_HARD_REFRESH',
    ZOCDOC_CREATE_APPOINTMENT = 'ZOCDOC_CREATE_APPOINTMENT',
    ZOCDOC_CREATE_PATIENT = 'ZOCDOC_CREATE_PATIENT',
    EXTERNAL_PLATFORM_CREATE_PATIENT = 'EXTERNAL_PLATFORM_CREATE_PATIENT',
    EXTERNAL_PLATFORM_CREATE_APPOINTMENT = 'EXTERNAL_PLATFORM_CREATE_APPOINTMENT',
    PROVIDER_CREATE_APPOINTMENT_WITHIN_FIVE_DAYS = 'PROVIDER_CREATE_APPOINTMENT_WITHIN_FIVE_DAYS',
    MANUAL_OVERRIDE = 'MANUAL_OVERRIDE',
    STABILIZATION_OVERRIDE = 'STABILIZATION_OVERRIDE',
    ERA_OVERRIDE = 'ERA_OVERRIDE',
    REDUNDANT_BIT_UNSET_OVERRIDE = 'REDUNDANT_BIT_UNSET_OVERRIDE',
    AVAILITY_INELIGIBLE_DETERMINATION = 'AVAILITY_INELIGIBLE_DETERMINATION',
    BULK_SEND_INTO_HUMAN_INPUT_ERROR = 'BULK_SEND_INTO_HUMAN_INPUT_ERROR',
    STEDI_SCRIPT_LOOKUP_GENERATION = 'STEDI_SCRIPT_LOOKUP_GENERATION',
    ELIGIBILITY_REQUEST_EXPLORER = 'ELIGIBILITY_REQUEST_EXPLORER',
    FLAKE_EXP_LOOKUP_GENERATION = 'FLAKE_EXP_LOOKUP_GENERATION',
    MAGELLAN_BSCA_ROUTING = 'MAGELLAN_BSCA_ROUTING',
    AGORA_BENEFITS_PAGE_USER_VIEW = 'AGORA_BENEFITS_PAGE_USER_VIEW',
    AGORA_CONTACT_PAGE_REFRESH = 'AGORA_CONTACT_PAGE_REFRESH'
}
