import { FormHelperText, InputAdornment, MenuItem } from '@mui/material';
import { Formik } from 'formik';
import { useProvider } from 'hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { ProviderAppointmentStatusNotes } from '@headway/api/models/ProviderAppointmentStatusNotes';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderRebookingPreference } from '@headway/api/models/ProviderRebookingPreference';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { RichTextArea } from '@headway/helix/RichTextArea';
import { Switch } from '@headway/helix/Switch';
import { RecurringUpdateApplyTo } from '@headway/shared/events/constants';
import { USE_PATIENT_REMATCHING_FOR_PROVIDER_CANCELLATIONS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useShouldShowAnthemEAPExperience } from '@headway/shared/hooks/useShouldShowAnthemEAPExperience';
import { useUser } from '@headway/shared/hooks/useUser';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';
import { Radio } from '@headway/ui';
import {
  FieldControl,
  FieldErrorText,
  FieldRadioGroup,
  FieldSelect,
  FieldTextField,
  FormRow,
} from '@headway/ui/form';
import { NumberFormatCustom } from '@headway/ui/form/FieldNumberFormatInput';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { theme } from '@headway/ui/theme';

import { useSelectedEvent } from 'hooks/useSelectedEvent';
import { shouldBlockProviderWithIroncladAgreement } from 'utils/ironcladAgreement';

import {
  isAppointment,
  isCanceled,
  isIntakeCall,
  isPatientBooked,
} from '../events/util/events';
import { PopUpModalTitleType } from '../utils/constants';

const PATIENT_CANCELLATION_REASONS = [
  ProviderAppointmentStatusNotes.PATIENT_CANCELLATION,
  ProviderAppointmentStatusNotes.NO_SHOW,
];

export interface AppointmentCancelFormValues {
  recurringUpdateApplyTo: keyof typeof RecurringUpdateApplyTo;
  content: string;
  sendMessage: boolean;
  statusNotes: ProviderAppointmentStatusNotes | '';
  patientResponsibilityAmount?: number;
  cancellationReasonDescription?: string;
  providerRebookingPreference?: ProviderRebookingPreference;
}

const AppointmentCancelReasonFieldSelect = ({
  event,
  provider,
  setFieldValue,
}: {
  event: ProviderEventRead;
  provider: ProviderRead;
  setFieldValue: (field: string, value: any) => void;
}) => {
  return (
    <FieldSelect
      onChange={({
        target: { value },
      }: {
        target: { value: ProviderAppointmentStatusNotes };
      }) => {
        if (
          isAppointment(event) &&
          PATIENT_CANCELLATION_REASONS.includes(value)
        ) {
          setFieldValue(
            'patientResponsibilityAmount',
            isCanceled(event)
              ? event.providerAppointment?.patientResponsibilityAmount
              : provider.customBilling
              ? 0
              : provider.cancellationDefaultFee || 75
          );
        } else {
          setFieldValue('patientResponsibilityAmount', 0);
        }
      }}
      data-testid="appointmentCancelReasonField"
      /* Clearly show all menu items to desktop users  */
      MenuProps={{ disableScrollLock: true }}
      variant="outlined"
      label="Cancellation reason"
    >
      <MenuItem value={ProviderAppointmentStatusNotes.RESCHEDULING}>
        Rebooking {isIntakeCall(event) ? 'call' : 'session'} at another time
      </MenuItem>
      <MenuItem value={ProviderAppointmentStatusNotes.NOT_AWARE}>
        Wasn't aware of the {isIntakeCall(event) ? 'call' : 'session'}
      </MenuItem>
      <MenuItem value={ProviderAppointmentStatusNotes.NOT_AVAILABLE}>
        Not available at this time
      </MenuItem>
      <MenuItem value={ProviderAppointmentStatusNotes.CLIENT_NOT_AVAILABLE}>
        Client not available
      </MenuItem>
      <MenuItem value={ProviderAppointmentStatusNotes.SEEING_OUTSIDE_HEADWAY}>
        Seeing client outside of Headway
      </MenuItem>
      <MenuItem value={ProviderAppointmentStatusNotes.STOPPING_CARE}>
        Stopping care with client
      </MenuItem>
      <MenuItem value={ProviderAppointmentStatusNotes.NOT_RIGHT_FIT}>
        Client not the right fit
      </MenuItem>
      <MenuItem
        value={ProviderAppointmentStatusNotes.PATIENT_CANCELLATION}
        data-testid="appointmentCancelPatientCancellation"
      >
        Client canceled {isIntakeCall(event) ? 'call' : 'session'}
      </MenuItem>
      <MenuItem value={ProviderAppointmentStatusNotes.NO_SHOW}>
        Client did not {isIntakeCall(event) ? 'pick up call' : 'show'}
      </MenuItem>
      <MenuItem value={ProviderAppointmentStatusNotes.NOT_TAKING_NEW_CLIENTS}>
        Not currently taking new clients
      </MenuItem>
      <MenuItem value={ProviderAppointmentStatusNotes.OTHER}>Other</MenuItem>
    </FieldSelect>
  );
};

const appointmentCancelSchema = (maxPatientPaymentAmount?: number) =>
  Yup.object().shape({
    statusNotes: Yup.string().required('Cancellation reason is required.'),
    patientResponsibilityAmount: Yup.number().when('statusNotes', {
      is: (statusNotes) => PATIENT_CANCELLATION_REASONS.includes(statusNotes),
      then: (schema: any) =>
        schema
          .required('Amount is required.')
          .max(
            maxPatientPaymentAmount !== undefined
              ? maxPatientPaymentAmount
              : 200,
            maxPatientPaymentAmount !== undefined
              ? `You cannot increase the cancellation fee after you've charged your patient.`
              : 'Amount is greater than $200.'
          )
          .min(0, 'Cannot be a negative number'),
      otherwise: (schema: any) =>
        schema
          .max(
            maxPatientPaymentAmount !== undefined
              ? maxPatientPaymentAmount
              : 200,
            maxPatientPaymentAmount !== undefined
              ? `You cannot increase the cancellation fee after you've charged your patient.`
              : 'Amount is greater than $200.'
          )
          .min(0, 'Cannot be a negative number'),
    }),
    cancellationReasonDescription: Yup.string().when(
      'statusNotes',
      (statusNotes, schema) =>
        statusNotes === ProviderAppointmentStatusNotes.OTHER
          ? schema.max(200, 'Cannot exceed 200 characters.')
          : schema
    ),
  });

interface AppointmentCancelFormDeprecatedProps {
  onClose: () => void;
  onSubmit: (values: AppointmentCancelFormValues) => void;
}

export const AppointmentCancelFormDeprecated = ({
  onClose,
  onSubmit,
  ...rest
}: AppointmentCancelFormDeprecatedProps) => {
  const [trackedEventOnce, setTrackedEventOnce] = useState(false);

  const isIroncladBlockAppointmentConfirmationEnabled = useFlag(
    'ironcladBlockAppointmentConfirmation'
  );
  const isPatientRematchingForProviderCancellationsEnabled = useFlag(
    USE_PATIENT_REMATCHING_FOR_PROVIDER_CANCELLATIONS,
    false
  );
  const { event } = useSelectedEvent();
  const provider = useProvider();
  const { data: patient } = useUser(
    { userId: event?.patientUserId! },
    { enabled: !!event?.patientUserId }
  );

  useEffect(() => {
    if (trackedEventOnce || !event) {
      return;
    }

    setTrackedEventOnce(true);
    trackEvent({
      name: 'Pop Up Modal Viewed',
      properties: {
        popUpPageTitle: PopUpModalTitleType.CANCEL_SESSION,
        providerId: provider.id,
        patientUserId: event.patientUserId,
        providerAppointmentId:
          event.recurrence === undefined
            ? event.providerAppointment?.id
            : undefined,
      },
    });
  }, [trackedEventOnce, provider, event]);

  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const shouldBlockProviderFromConfirming =
    !!event?.startDate &&
    shouldBlockProviderWithIroncladAgreement(
      isIroncladBlockAppointmentConfirmationEnabled,
      new Date(event.startDate)
    ) &&
    isAppointment(event);

  const {
    data: shouldShowAnthemEAPExperience,
    isLoading: isLoadingShouldShowAnthemEAPExperience,
  } = useShouldShowAnthemEAPExperience(
    event?.patientUserId,
    provider?.id,
    event?.providerAppointment?.billingType
  );

  if (!event || !patient) {
    return null;
  }

  const initialValues: AppointmentCancelFormValues = {
    statusNotes: event.providerAppointment?.statusNotes || '',
    patientResponsibilityAmount: isCanceled(event)
      ? event.providerAppointment?.patientResponsibilityAmount
      : 0,
    recurringUpdateApplyTo: RecurringUpdateApplyTo.THIS_EVENT,
    content: '',
    sendMessage: true,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={appointmentCancelSchema(
        isCanceled(event)
          ? event.providerAppointment?.patientResponsibilityAmount
          : undefined
      )}
      enableReinitialize={true}
      onSubmit={onSubmit}
      {...rest}
    >
      {({ values, setFieldValue, isSubmitting }) => {
        return (
          <SafeFormikForm>
            {shouldBlockProviderFromConfirming && (
              <div css={{ marginBottom: theme.space.base }}>
                <GuidanceCard variant="warning">
                  To ensure you are paid in a timely manner, we can only
                  facilitate cancellation fees for sessions scheduled within the
                  past 30 days
                </GuidanceCard>
              </div>
            )}
            <BodyText>
              <strong>Why are you cancelling this session?</strong>
            </BodyText>
            <FieldControl
              name="statusNotes"
              fullWidth={true}
              size="small"
              css={{ marginTop: theme.space.xs }}
            >
              <AppointmentCancelReasonFieldSelect
                event={event}
                provider={provider}
                setFieldValue={setFieldValue}
              />
              <FieldErrorText />
            </FieldControl>
            {values.statusNotes &&
              values.statusNotes === ProviderAppointmentStatusNotes.OTHER && (
                <div css={{ marginTop: theme.space.base }}>
                  <BodyText>
                    <strong>
                      Please share more details on this cancellation
                    </strong>
                  </BodyText>
                  <FieldControl name="cancellationReasonDescription" fullWidth>
                    <FieldTextField
                      multiline={true}
                      size="small"
                      variant="outlined"
                      placeholder="What happened?"
                    />
                    <FieldErrorText />
                  </FieldControl>
                </div>
              )}
            {!provider.customBilling && // custom billing providers shouldn't charge cancellation fees
              !isIntakeCall(event) &&
              values.statusNotes &&
              !shouldShowAnthemEAPExperience &&
              !isLoadingShouldShowAnthemEAPExperience && (
                <div css={{ marginTop: theme.space.base }}>
                  <BodyText>
                    <strong>
                      How much should we bill the client? (optional)
                    </strong>
                  </BodyText>
                  <FieldControl
                    name="patientResponsibilityAmount"
                    fullWidth={true}
                  >
                    <FieldTextField
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      size="small"
                      variant="outlined"
                    />
                    <FieldErrorText />
                    <FormHelperText>
                      {isCanceled(event)
                        ? `You can reduce the amount you've already charged your client. We will refund the client the difference.`
                        : 'You can charge up to $200.'}
                    </FormHelperText>
                  </FieldControl>
                </div>
              )}
            {event.recurrence && (
              <FieldControl name="recurringUpdateApplyTo" fullWidth={true}>
                <BodyText css={{ marginTop: theme.space.xs }}>
                  <strong>
                    Which sessions should this cancellation apply to?
                  </strong>
                </BodyText>
                <FieldRadioGroup>
                  <Radio
                    value={RecurringUpdateApplyTo.THIS_EVENT}
                    label="Only this session"
                    color="black"
                  />
                  <Radio
                    value={RecurringUpdateApplyTo.FOLLOWING_EVENTS}
                    label="This and all following sessions"
                    color="black"
                  />
                </FieldRadioGroup>
              </FieldControl>
            )}
            {!isPatientBooked(event) ? (
              <div>
                <FieldControl
                  name="sendMessage"
                  fullWidth={true}
                  css={{ marginBottom: 0, marginTop: theme.space.sm }}
                >
                  <FormRow>
                    <BodyText css={{ marginTop: theme.space.xs }}>
                      <strong>Send a note to {patientFirstName}</strong>
                    </BodyText>
                    <div
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Switch
                        name={'default'}
                        selected={values.sendMessage}
                        onChange={(value) =>
                          setFieldValue('sendMessage', value)
                        }
                      >
                        {''}
                      </Switch>
                    </div>
                  </FormRow>
                </FieldControl>
                <div css={{ marginTop: theme.space.xs }}>
                  {values.sendMessage ? (
                    event.recurrence &&
                    values.recurringUpdateApplyTo ===
                      RecurringUpdateApplyTo.FOLLOWING_EVENTS ? (
                      <BodyText>
                        We’ll let {patientFirstName} know that their session on
                        and following{' '}
                        <strong>
                          {moment(event.startDate).format('dddd, MMMM D')}
                        </strong>{' '}
                        was canceled. You can add a personal message to include
                        with the cancellation email.
                      </BodyText>
                    ) : (
                      <BodyText>
                        We’ll let {patientFirstName} know that their session on{' '}
                        <strong>
                          {moment(event.startDate).format('dddd, MMMM D')}
                        </strong>{' '}
                        was canceled. You can add a personal message to include
                        with the cancellation email.
                      </BodyText>
                    )
                  ) : null}
                </div>
              </div>
            ) : event.recurrence &&
              values.recurringUpdateApplyTo ===
                RecurringUpdateApplyTo.FOLLOWING_EVENTS ? (
              <BodyText>
                We'll let <strong>{patientFirstName}</strong> know that their
                sessions on and following{' '}
                <strong>
                  {moment(event.startDate).format('dddd, MMMM Do')}
                </strong>{' '}
                were canceled. You can add a personal message to include with
                the cancellation email.
              </BodyText>
            ) : (
              <BodyText>
                We'll let <strong>{patientFirstName}</strong> know that their
                session on{' '}
                <strong>
                  {moment(event.startDate).format('dddd, MMMM Do')}
                </strong>{' '}
                was canceled. You can add a personal message to include with the
                cancellation email.
              </BodyText>
            )}
            {values.sendMessage && (
              <FieldControl
                name="content"
                fullWidth={true}
                css={{
                  marginBottom: theme.space.sm,
                  marginTop: theme.space.sm,
                }}
              >
                <RichTextArea
                  name="content"
                  autoFocus={false}
                  label="Add a custom note"
                  optionalityText="Optional"
                  value={values.content}
                  onChange={(content) => setFieldValue('content', content)}
                  data-testid="notifyPatientMessageField"
                />
              </FieldControl>
            )}
            {isPatientRematchingForProviderCancellationsEnabled ? (
              <FieldControl name="providerRebookingPreference" fullWidth={true}>
                <BodyText css={{ marginTop: theme.space.xs }}>
                  <strong>
                    How would you like us to help to reschedule{' '}
                    {patientFirstName}?
                  </strong>{' '}
                  We share options in their cancellation email so they can
                  easily book another session.
                </BodyText>
                <FieldRadioGroup>
                  <Radio
                    value={ProviderRebookingPreference.ALLOW_SHARE_AVAILABILITY}
                    label="Share my availability, along with similar providers’ availability."
                    color="black"
                  />
                  <Radio
                    value={ProviderRebookingPreference.DENY_SHARE_AVAILABILITY}
                    label="Only share availability for similar providers."
                    color="black"
                  />
                  <Radio
                    value={
                      ProviderRebookingPreference.RESCHEDULING_INDEPENDENTLY
                    }
                    label="Neither, I’ll reschedule with them myself."
                    color="black"
                  />
                </FieldRadioGroup>
              </FieldControl>
            ) : null}
            <div className="mt-6 flex justify-end gap-2">
              <Button
                type="button"
                variant="secondary"
                onPress={onClose}
                data-testid="appointmentCancelCancelButton"
              >
                Back
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                data-testid="appointmentCancelSubmitButton"
              >
                {isCanceled(event) ? 'Modify' : 'Cancel session'}
              </Button>
            </div>
          </SafeFormikForm>
        );
      }}
    </Formik>
  );
};
