import { useFormikContext } from 'formik';
import { uniqueId } from 'lodash';
import { useState } from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ContentText } from '@headway/helix/ContentText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconCaretDown } from '@headway/helix/icons/CaretDown';
import { IconCaretRight } from '@headway/helix/icons/CaretRight';
import { IconCheckCircle } from '@headway/helix/icons/CheckCircle';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { theme } from '@headway/helix/theme';
import { pluralize } from '@headway/shared/utils/stringFormatting';

import {
  BulkConfirmEventsData,
  BulkConfirmFormValues,
  BulkConfirmSubmissionStatus,
} from '../../utils/types';
import { SessionDetailsTable } from '../components/SessionDetailsTable';
import { TotalEarnings } from '../components/TotalEarnings';

interface ErrorCorrectionStepProps {
  provider: ProviderRead;
  submissionStatusByEventVirtualId: Record<string, BulkConfirmSubmissionStatus>;
  bulkConfirmEventsData: BulkConfirmEventsData;
}

export const ErrorCorrectionStep = ({
  provider,
  submissionStatusByEventVirtualId,
  bulkConfirmEventsData,
}: ErrorCorrectionStepProps) => {
  const { errors } = useFormikContext<BulkConfirmFormValues>();
  const [confirmedSessionsContentId] = useState(
    uniqueId('error-correction-confirmed-sessions-')
  );
  const [isShowingConfirmedSessions, setIsShowingConfirmedSessions] =
    useState(false);
  const confirmedSessionVirtualIds = getIdsWithStatus(
    submissionStatusByEventVirtualId,
    BulkConfirmSubmissionStatus.SUCCESS
  );
  const confirmedSessionCount = confirmedSessionVirtualIds.length;
  const unconfirmedSessionVirtualIds = getIdsWithStatus(
    submissionStatusByEventVirtualId,
    BulkConfirmSubmissionStatus.ERROR
  );
  const unconfirmedSessionCount = unconfirmedSessionVirtualIds.length;

  return (
    <div className="flex flex-col py-10">
      <div className="flex flex-col px-5">
        <ContentText variant="page-title">
          {confirmedSessionCount > 0 ? (
            'Some sessions could not be confirmed'
          ) : (
            <>
              <IconWarningCircle
                className="mr-2"
                fill={theme.color.foreground.danger}
              />
              Sessions could not be confirmed
            </>
          )}
        </ContentText>
        {confirmedSessionCount > 0 && (
          <ContentText variant="body">
            <span className="mt-2 flex">
              <IconCheckCircle
                className="mr-1"
                fill={theme.color.foreground.compliance}
              />
              {confirmedSessionCount}{' '}
              {pluralize('session', confirmedSessionCount)} confirmed
              <span className="mx-2">|</span>
              <IconWarningCircle
                variant="outlined"
                className="mr-1"
                fill={theme.color.foreground.danger}
              />
              {unconfirmedSessionCount}{' '}
              {pluralize('session', unconfirmedSessionCount)} unconfirmed
            </span>
          </ContentText>
        )}
        <div className="mt-6">
          <ContentText variant="section-title/medium">
            {unconfirmedSessionCount === 1
              ? '1 session requires updates'
              : `${unconfirmedSessionCount} sessions require updates`}
          </ContentText>
        </div>
        <ContentText variant="body">
          <ol className="mb-5 mt-2 pl-5">
            <li>
              Check for typos to make sure each field matches the details in
              your EHR.
            </li>
            <li>
              Consult the treating provider to double check the details. We'll
              save these sessions as drafts for you to return to later.
            </li>
          </ol>
        </ContentText>
        {
          /** This will be a string if the deep-equality validation fails */
          typeof errors.sessions === 'string' && (
            <div className="mb-4">
              <GuidanceCard variant="error">
                Please update session details before resubmitting these
                sessions.
              </GuidanceCard>
            </div>
          )
        }
      </div>
      <SessionDetailsTable
        provider={provider}
        bulkConfirmEventsData={bulkConfirmEventsData}
        submissionStatusByEventVirtualId={submissionStatusByEventVirtualId}
        displayedVirtualIds={unconfirmedSessionVirtualIds}
      />
      {confirmedSessionCount > 0 && (
        <>
          <div className="mt-6 px-5">
            <button
              type="button"
              aria-expanded={isShowingConfirmedSessions}
              aria-controls={confirmedSessionsContentId}
              onClick={() =>
                setIsShowingConfirmedSessions((isShowing) => !isShowing)
              }
              className="-mx-1 flex cursor-pointer items-center gap-2 rounded border-0 bg-background-primary p-0 ring-border-focus focus:ring-2"
            >
              <ContentText variant="section-title/medium">
                {confirmedSessionCount} confirmed{' '}
                {pluralize('session', confirmedSessionCount)}
              </ContentText>
              {isShowingConfirmedSessions ? (
                <IconCaretDown />
              ) : (
                <IconCaretRight />
              )}
            </button>
          </div>
          {isShowingConfirmedSessions && (
            <div id={confirmedSessionsContentId}>
              <div className="mx-5 my-6">
                <TotalEarnings
                  confirmedEvents={confirmedSessionVirtualIds.map(
                    (id) => bulkConfirmEventsData[id].event
                  )}
                />
              </div>
              <SessionDetailsTable
                provider={provider}
                bulkConfirmEventsData={bulkConfirmEventsData}
                submissionStatusByEventVirtualId={
                  submissionStatusByEventVirtualId
                }
                displayedVirtualIds={confirmedSessionVirtualIds}
                readonly
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const getIdsWithStatus = (
  submissionStatusByEventVirtualId: Record<string, BulkConfirmSubmissionStatus>,
  status: BulkConfirmSubmissionStatus
) => {
  return Object.entries(submissionStatusByEventVirtualId)
    .filter(([id, s]) => s === status)
    .map(([id, s]) => id);
};
