import { useProvider, useProviderPatient } from 'hooks';
import React, { useState } from 'react';

import { FrontEndCarrierNested } from '@headway/api/models/FrontEndCarrierNested';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { Modal as HelixModal, ModalContent } from '@headway/helix/Modal';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { getUseUserQueryKey, useUser } from '@headway/shared/hooks/useUser';
import { getUseUserAppointmentReadinessQueryKey } from '@headway/shared/hooks/useUserAppointmentReadiness';
import { useQueryClient } from '@headway/shared/react-query';
import { formatPatientName } from '@headway/shared/utils/patient';
import { Modal } from '@headway/ui/Modal';

import { SelfPayOptInForm } from 'components/SelfPayOptInForm/SelfPayOptInForm';
import { getUseEligibilityLookupQueryKey } from 'hooks/useEligibilityLookup';
import { getUseProviderPatientQueryKey } from 'hooks/useProviderPatient';
import { getUseUserInsuranceQueryKey } from 'hooks/useUserInsurance';
import { isPatientInNoDataOutage } from 'utils/userInsuranceOutage';
import {
  getAuthorizationInstructionsModalTitle,
  InsuranceAuthorizationInstructionsModalContent,
} from 'views/Patients/InsuranceAuthorizationInstructionsModalContent';
import {
  PatientBillingForm,
  PatientBillingFormUpdate,
} from 'views/Patients/PatientBillingForm';

import { InsuranceOutageVerificationInProgressModal } from './InsuranceOutageVerificationInProgressModal';
import { InsuranceVerifyMatch } from './InsuranceVerifyMatch';

export interface BillingMethodModalProps {
  clientId: number;
  open: boolean;
  onClose: () => void;
}

/** Modal which allows the user to choose between Insurance and Self-Pay billing. */
export const BillingMethodModal = ({
  clientId,
  open,
  onClose,
}: BillingMethodModalProps) => {
  const [didAttemptSelfPayOptIn, setDidAttemptSelfPayOptIn] =
    useState<boolean>(false);
  const [currentModal, setCurrentModal] = useState<
    | 'billingMethod'
    | 'selfPayOptIn'
    | 'authzInstructions'
    | 'insuranceVerifyMatch'
  >('billingMethod');

  const { data: client } = useUser({ userId: clientId });
  const provider = useProvider();
  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId: clientId,
  });
  const queryClient = useQueryClient();
  const [
    carrierToShowAuthzInstructionsFor,
    setCarrierToShowAuthzInstructionsFor,
  ] = useState<FrontEndCarrierRead | FrontEndCarrierNested>();

  const [
    isVerificationInProgressModalOpen,
    setIsVerificationInProgressModalOpen,
  ] = React.useState<boolean>(false);

  const onUpdateSuccess = (update: PatientBillingFormUpdate) => {
    const providerPatientQueryKey = getUseProviderPatientQueryKey({
      providerId: provider.id,
      patientId: clientId,
    });
    queryClient.setQueryData(providerPatientQueryKey, update.providerPatient);
    queryClient.invalidateQueries(providerPatientQueryKey);

    const userQueryKey = getUseUserQueryKey({ userId: clientId });
    queryClient.setQueryData(userQueryKey, update.userRead);
    queryClient.invalidateQueries(providerPatientQueryKey);

    const userInsuranceQueryKey = getUseUserInsuranceQueryKey({
      userInsuranceId: update.userRead.activeUserInsuranceId,
    });
    queryClient.setQueryData(userInsuranceQueryKey, update.userInsurance);
    queryClient.invalidateQueries(userInsuranceQueryKey);

    if (
      isPatientInNoDataOutage(
        update.userInsurance,
        update.userRead,
        provider.id
      )
    ) {
      setIsVerificationInProgressModalOpen(true);
    }

    const eligibilityLookupQueryKey = getUseEligibilityLookupQueryKey({
      eligibilityLookupId: update.eligibilityLookup?.id,
    });
    queryClient.setQueryData(
      eligibilityLookupQueryKey,
      update.eligibilityLookup
    );
    queryClient.invalidateQueries(eligibilityLookupQueryKey);

    const userAppointmentReadinessKey = getUseUserAppointmentReadinessQueryKey({
      userId: clientId,
    });
    queryClient.invalidateQueries(userAppointmentReadinessKey);

    setDidAttemptSelfPayOptIn(false);

    if (update.eligibilityLookup?.isFuzzyMatched) {
      setCurrentModal('insuranceVerifyMatch');
      return;
    }
    onClose();
  };

  if (!providerPatient || !client) {
    return null;
  }
  const patientNameFormatted = formatPatientName(client, {
    firstNameOnly: true,
  });

  return (
    <>
      <Modal
        open={open && currentModal === 'billingMethod'}
        onClose={onClose}
        title="Edit Billing Method"
      >
        <PatientBillingForm
          providerPatient={providerPatient}
          patient={client}
          provider={provider}
          startFormOnSelfPay={didAttemptSelfPayOptIn}
          onUpdateSuccess={onUpdateSuccess}
          onCancel={onClose}
          openSelfPayProviderOptInModal={() => {
            setDidAttemptSelfPayOptIn(true);
            setCurrentModal('selfPayOptIn');
          }}
          showAuthorizationInstructionsModal={(carrier) => {
            setCarrierToShowAuthzInstructionsFor(carrier);
            setCurrentModal('authzInstructions');
          }}
        />
      </Modal>
      <Modal
        open={open && currentModal === 'insuranceVerifyMatch'}
        onClose={onClose}
        title="Did you mean this?"
      >
        <InsuranceVerifyMatch
          patient={client}
          onEdit={() => setCurrentModal('billingMethod')}
          onSuccess={() => {
            onClose();
            setCurrentModal('billingMethod');
          }}
        />
      </Modal>
      <Modal
        title="Welcome to Private Pay"
        open={open && currentModal === 'selfPayOptIn'}
        onClose={() => {
          onClose();
          setCurrentModal('billingMethod');
        }}
      >
        <SelfPayOptInForm
          provider={provider}
          onSubmit={() => {
            setCurrentModal('billingMethod');
          }}
        />
      </Modal>

      {carrierToShowAuthzInstructionsFor && (
        <HelixModal
          title={getAuthorizationInstructionsModalTitle(patientNameFormatted)}
          isOpen={
            open &&
            currentModal === 'authzInstructions' &&
            !!carrierToShowAuthzInstructionsFor
          }
          onDismiss={() => {
            onClose();
            setCurrentModal('billingMethod');
          }}
        >
          <InsuranceAuthorizationInstructionsModalContent
            carrier={carrierToShowAuthzInstructionsFor}
            clientDisplayName={patientNameFormatted}
            closeModal={() => {
              onClose();
              setCurrentModal('billingMethod');
            }}
          />
        </HelixModal>
      )}
      {client && (
        <InsuranceOutageVerificationInProgressModal
          open={isVerificationInProgressModalOpen}
          onClose={() => {
            setIsVerificationInProgressModalOpen(false);
          }}
          client={client}
        />
      )}
    </>
  );
};
