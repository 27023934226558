import { css } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { InsuranceAuthorizationRead } from '@headway/api/models/InsuranceAuthorizationRead';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { theme } from '@headway/helix/theme';
import { OPTUM_COMMERCIAL_IDS } from '@headway/shared/constants/carrierIds';
import {
  ANTHEM_EAP_CODE,
  CIGNA_EAP_CODE,
  OPTUM_EAP_CODES,
} from '@headway/shared/constants/cptCodes';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import {
  Accordion,
  AccordionDetails,
  AccordionDetailsHeader,
  AccordionSummary,
} from '@headway/ui/Accordion';

import {
  calculateRemainingSessions,
  determineActiveEAPStatus,
  hasExpirationDatePassed,
  validateSessionCount,
} from 'views/Patients/utils/patientInsuranceStatus';

import { EAP } from './EmployeeAssistancePrograms';

interface EmployeeAssistanceProgramDetailProps {
  eap: EAP;
  frontEndCarrierId: number;
}

/**
 * Card section that displays details of Employee Assistance Program insurance authorizations
 */
export const EmployeeAssistanceProgramDetail: React.FC<
  React.PropsWithChildren<EmployeeAssistanceProgramDetailProps>
> = ({ eap, frontEndCarrierId }) => {
  const frontEndCarriers = useFrontEndCarriers();
  const programDetails: Array<[string, string]> = [
    [
      'Used Sessions',
      getSessionsText(eap.usedSessions, eap.pendingSessions, eap.validSessions),
    ],
    ['Authorized CPT Codes', getAuthorizedCPTCodes(frontEndCarrierId)],
  ];

  if (eap.effectiveDate) {
    programDetails.push([
      'Effective date',
      formatDateForEAPDetail(eap.effectiveDate),
    ]);
  }
  if (eap.expirationDate) {
    programDetails.push([
      'Expiration date',
      formatDateForEAPDetail(eap.expirationDate),
    ]);
  }

  return (
    <Accordion css={detailCss.container}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div css={detailCss.header}>
          <BodyText>
            <b>
              {frontEndCarriers.carriersById[frontEndCarrierId]?.name || ''}{' '}
              {eap.authorizationNumber ?? eap.memberReferenceNumber}
            </b>
          </BodyText>
          {determineActiveEAPStatus(eap) ? (
            <Badge variant="positive">Active</Badge>
          ) : (
            <Badge variant="negative" icon={IconWarningCircle}>
              Inactive
            </Badge>
          )}
          <BodyText>{getProgramDescriptionText(eap)}</BodyText>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionDetailsHeader css={detailCss.detailsHeader}>
          <div>Program Details</div>
        </AccordionDetailsHeader>
        <dl css={detailCss.programDetails}>
          {programDetails.map(([label, value]) => (
            <React.Fragment key={label}>
              <dt>
                <BodyText>
                  <b>{label}</b>
                </BodyText>
              </dt>
              <dd>
                <BodyText>{value || '—'}</BodyText>
              </dd>
            </React.Fragment>
          ))}
        </dl>
      </AccordionDetails>
    </Accordion>
  );
};

const detailCss = {
  container: css({
    '.MuiAccordionSummary-root': {
      flexDirection: 'row-reverse',
      gap: theme.spacing.x4,
    },
    '&.MuiAccordion-root': {
      borderRadius: 0,
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none',
      borderColor: theme.color.system.borderGray,
      margin: 0,
      '&:last-child': {
        borderBottom: `1px solid ${theme.color.system.borderGray}`,
      },
    },
    '.MuiAccordionSummary-content': {
      margin: `${theme.spacing.x4} 0`,
    },
    '.MuiAccordionDetails-root': {
      border: 'none',
    },
  }),
  header: {
    display: 'flex',
    flexGrow: 1,
    gap: theme.spacing.x2,
    alignItems: 'center',
    '& > :last-child': {
      marginLeft: 'auto',
    },
  },
  programDetails: {
    display: 'grid',
    gridTemplateColumns:
      'minmax(min-content, 170px) auto minmax(min-content, 170px) auto',
    gap: theme.spacing.x3,
    dd: { margin: 0 },
  },
  detailsHeader: {
    '& > div': {
      ...theme.typography.list,
      color: theme.color.system.textBlack,
    },
  },
};

function getSessionsText(
  usedSessions: number | undefined,
  pendingSessions: number | undefined,
  validSessions: number | undefined
) {
  const totalUsedSessions =
    validateSessionCount(usedSessions) + validateSessionCount(pendingSessions);
  return `${totalUsedSessions} out of ${validSessions} authorized sessions`;
}

function getProgramDescriptionText(eap: EAP) {
  const remainingSessionCount = calculateRemainingSessions(eap);
  const formattedExpirationDate = eap.expirationDate
    ? formatDateForEAPDetail(eap.expirationDate)
    : undefined;
  if (determineActiveEAPStatus(eap)) {
    let description = `${remainingSessionCount} remaining session`;
    if (remainingSessionCount > 1) {
      description += 's';
    }
    return (
      `${description}` +
      (formattedExpirationDate ? ` until ${formattedExpirationDate}` : '')
    );
  } else if (remainingSessionCount <= 0) {
    return eap.validSessions
      ? `All ${eap.validSessions} sessions used`
      : 'All sessions used';
  } else if (
    eap.expirationDate &&
    hasExpirationDatePassed(eap.expirationDate)
  ) {
    return `Expired on ${formattedExpirationDate}`;
  }
}

function getAuthorizedCPTCodes(frontEndCarrierId: number) {
  if (frontEndCarrierId === FrontEndCarrierIdentifier.CIGNA) {
    return CIGNA_EAP_CODE;
  } else if (OPTUM_COMMERCIAL_IDS.includes(frontEndCarrierId)) {
    return OPTUM_EAP_CODES.join(', ');
  } else if (frontEndCarrierId === FrontEndCarrierIdentifier.ANTHEM_EAP) {
    return ANTHEM_EAP_CODE;
  }
  return 'N/A';
}

export function formatDateForEAPDetail(dateString: string): string {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}
