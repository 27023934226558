import { PatientAssessmentRecurrenceScheduleApi } from '@headway/api/resources/PatientAssessmentRecurrenceScheduleApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export type UseAllPatientAssessmentRecurrenceSchedulesQueryKeyArgs = {
  providerPatientId?: number;
};

export const getUseAllPatientAssessmentRecurrenceSchedulesQueryKey = ({
  providerPatientId,
}: UseAllPatientAssessmentRecurrenceSchedulesQueryKeyArgs) =>
  ['all-patient-assessment-recurrence-schedules', providerPatientId] as const;

export const {
  singleQuery: allPatientAssessmentRecurrenceSchedules,
  useSingleQuery: useAllPatientAssessmentRecurrenceSchedules,
  useListQuery: useAllPatientAssessmentRecurrenceSchedulesList,
  useCachedQuery: useAllPatientAssessmentRecurrenceSchedulesCache,
} = createBasicApiHooks(
  getUseAllPatientAssessmentRecurrenceSchedulesQueryKey,
  ({ providerPatientId }) =>
    PatientAssessmentRecurrenceScheduleApi.getAllPatientAssessmentRecurrenceSchedules(
      providerPatientId as number
    ),
  ({ providerPatientId }) => !!providerPatientId,
  () => 'Failed to fetch all patient assessment recurrence schedules'
);
