import { useField } from 'formik';

import { AssessmentScheduleDatePicker } from './AssessmentScheduleDatePicker';
import { AssessmentScheduleStartDateTypeSelector } from './AssessmentScheduleStartDateTypeSelector';
import { AssessmentScheduleStartDateType } from './constants';

type PendingAssessmentScheduleDateInputProps = {
  name: string;
  disabled?: boolean;
};

type AssessmentScheduleDateInputProps =
  PendingAssessmentScheduleDateInputProps & {
    withPendingSchedules?: boolean;
  };

function PendingAssessmentScheduleDateInput({
  name,
  ...props
}: PendingAssessmentScheduleDateInputProps) {
  const startDateTypeName = `${name}.startDateType`;
  const [{ value: startDateType }] = useField(startDateTypeName);

  return (
    <>
      <AssessmentScheduleStartDateTypeSelector
        name={startDateTypeName}
        {...props}
      />
      {startDateType === AssessmentScheduleStartDateType.ExactDate && (
        <AssessmentScheduleDatePicker
          name={`${name}.startDate`}
          label="On date"
          {...props}
        />
      )}
    </>
  );
}

export function AssessmentScheduleDateInput({
  withPendingSchedules = false,
  name,
  ...props
}: AssessmentScheduleDateInputProps) {
  return withPendingSchedules ? (
    <PendingAssessmentScheduleDateInput name={name} {...props} />
  ) : (
    <AssessmentScheduleDatePicker
      name={`${name}.startDate`}
      label="Next send date"
      {...props}
    />
  );
}
