import { getLocalTimeZone, today } from '@internationalized/date';
import { Formik } from 'formik';
import moment from 'moment/moment';
import { useProvider, useProviderPatient } from '~/legacy/hooks';

import { PatientAssessmentRecurrenceCadence } from '@headway/api/models/PatientAssessmentRecurrenceCadence';
import { PatientAssessmentRecurrenceScheduleCreateRequest } from '@headway/api/models/PatientAssessmentRecurrenceScheduleCreateRequest';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { UserRead } from '@headway/api/models/UserRead';
import {
  FillOutOnBehalfOfClientAcceptRescheduleRecurrenceButtonClickedEvent,
  FillOutOnBehalfOfClientKeepPreviousRescheduleRecurrenceButtonClickedEvent,
} from '@headway/avo';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { momentToDateValue } from '@headway/helix/date';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { trackEvent } from '@headway/shared/utils/analytics';
import { LogoLoader } from '@headway/ui/LogoLoader';

import { generateAssessmentSchedulesValidationSchema } from 'components/AssessmentScheduler/utils';
import { usePatientAssessmentRecurrenceSchedules } from 'hooks/usePatientAssessmentRecurrenceSchedules';
import { useReplacePatientAssessmentRecurrenceSchedulesMutation } from 'mutations/assessments';
import {
  ManageAssessmentsModalFormValues,
  MaybePendingScheduleConfig,
} from 'views/Clients/Assessments/helpers/types';
import { useReplacePatientAssessmentRecurrenceSchedulesSideEffects } from 'views/Clients/Assessments/helpers/utils';

import { AssessmentScheduleStartDateType } from '../AssessmentScheduleInput/constants';
import { ManageAssessmentsModalContent } from '../ManageAssessmentsModal';

const CADENCE_TO_DAYS_TO_ADD: {
  [cadence in PatientAssessmentRecurrenceCadence]: number;
} = {
  [PatientAssessmentRecurrenceCadence.ONE_TIME]: 30,
  [PatientAssessmentRecurrenceCadence.EVERY_7_DAYS]: 7,
  [PatientAssessmentRecurrenceCadence.EVERY_14_DAYS]: 14,
  [PatientAssessmentRecurrenceCadence.EVERY_30_DAYS]: 30,
  [PatientAssessmentRecurrenceCadence.EVERY_60_DAYS]: 60,
  [PatientAssessmentRecurrenceCadence.EVERY_90_DAYS]: 90,
};

enum ScheduleRecurringEvent {
  CANCEL = 'CANCEL',
  UPDATE_SCHEDULE = 'UPDATE_SCHEDULE',
}

const EVENT_NAMES = {
  [ScheduleRecurringEvent.CANCEL]:
    'Fill Out on Behalf of Client Keep Previous Reschedule Recurrence Button Clicked',
  [ScheduleRecurringEvent.UPDATE_SCHEDULE]:
    'Fill Out on Behalf of Client Accept Reschedule Recurrence Button Clicked',
};

interface ScheduleRecurringProps {
  onDismiss: () => void;
  client: UserRead;
  selectedAssessments: PatientAssessmentType[];
}

export const ScheduleRecurring = ({
  onDismiss,
  client,
  selectedAssessments,
}: ScheduleRecurringProps) => {
  const replacePatientAssessmentRecurrenceSchedulesMutation =
    useReplacePatientAssessmentRecurrenceSchedulesMutation({
      sideEffects: useReplacePatientAssessmentRecurrenceSchedulesSideEffects(),
    });

  const provider = useProvider();
  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId: client?.id,
  });
  const { data: existingSchedules, isLoading: areExistingSchedulesLoading } =
    usePatientAssessmentRecurrenceSchedules(
      {
        providerPatientId: providerPatient?.id,
      },
      { refetchOnWindowFocus: false }
    );

  const initialValues = {
    enabledAssessmentTypes: selectedAssessments,
    ...selectedAssessments.reduce(
      (acc, assessmentType) => {
        // assuming existingSchedules is small & there is only 1 schedule per assessment type
        const existingSchedule = existingSchedules?.find(
          (x) => x.assessmentType === assessmentType
        );
        const cadence =
          existingSchedule?.cadence ||
          PatientAssessmentRecurrenceCadence.EVERY_30_DAYS;
        const newStartDate = momentToDateValue(
          moment().add(CADENCE_TO_DAYS_TO_ADD[cadence], 'day')
        );
        acc[assessmentType] = {
          cadence: cadence,
          startDate: newStartDate,
          startDateType: AssessmentScheduleStartDateType.ExactDate,
        };
        return acc;
      },
      {} as {
        [assessmentType in PatientAssessmentType]: MaybePendingScheduleConfig;
      }
    ),
  };
  const existingSchedulesOverlapWithSelectedAssessments = (
    existingSchedules || []
  ).some((schedule) => {
    const assessmentMatches = selectedAssessments.some(
      (assessmentType) => schedule.assessmentType === assessmentType
    );
    if (!assessmentMatches) {
      return false;
    }

    // calculate today - start date, mod cadence
    // if modulus is more than 14, relevant for scheduling
    const nextDate = moment(schedule.nextScheduledDate);
    const recurrenceEndDate = moment(schedule.recurrenceEndDate);

    return (
      (!!recurrenceEndDate || nextDate.isBefore(recurrenceEndDate)) &&
      nextDate.isAfter(moment())
    );
  });

  selectedAssessments.some(
    (x) => existingSchedules?.some((y) => y.assessmentType === x)
  );

  const cancelButtonText = existingSchedulesOverlapWithSelectedAssessments
    ? 'Keep previous schedule'
    : 'Skip for now';

  if (!providerPatient || areExistingSchedulesLoading) {
    return (
      <ModalContent>
        <div className="flex flex-col items-center gap-8 p-8">
          <ContentText variant="section-title/medium">Loading...</ContentText>
          <LogoLoader />
        </div>
      </ModalContent>
    );
  }

  const validationSchema = generateAssessmentSchedulesValidationSchema({
    assessments: selectedAssessments,
  });

  const handleSubmit = async (values: ManageAssessmentsModalFormValues) => {
    const { enabledAssessmentTypes, ...configs } = values;
    const schedules: PatientAssessmentRecurrenceScheduleCreateRequest[] =
      Object.entries(configs)
        .filter(([assessmentType]) =>
          enabledAssessmentTypes.includes(
            assessmentType as PatientAssessmentType
          )
        )
        .map(([assessmentType, config]) => {
          return {
            assessmentType: assessmentType as PatientAssessmentType,
            cadence: config.cadence,
            startDate: config.startDate.toString(),
          };
        });
    await Promise.all([
      replacePatientAssessmentRecurrenceSchedulesMutation.mutateAsync({
        providerPatientId: providerPatient.id,
        body: {
          currentLocalDate: today(getLocalTimeZone()).toString(),
          schedules,
        },
      }),
    ]);
    handleEventTracking(ScheduleRecurringEvent.UPDATE_SCHEDULE);
    onDismiss();
  };

  const handleDismissWithoutChanges = () => {
    handleEventTracking(ScheduleRecurringEvent.CANCEL);
    onDismiss();
  };

  const handleEventTracking = (event: ScheduleRecurringEvent) => {
    const eventName = EVENT_NAMES[event];

    trackEvent({
      name: eventName,
      properties: {
        patientUserId: client.id,
        providerId: provider.id,
        providerPatientId: providerPatient.id,
        assessmentTypeList: selectedAssessments,
      },
    } as
      | FillOutOnBehalfOfClientKeepPreviousRescheduleRecurrenceButtonClickedEvent
      | FillOutOnBehalfOfClientAcceptRescheduleRecurrenceButtonClickedEvent);
  };

  return (
    <Formik<ManageAssessmentsModalFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty }) => {
        let confirmButtonText;
        if (!existingSchedulesOverlapWithSelectedAssessments || dirty) {
          confirmButtonText = 'Save schedule';
        } else {
          confirmButtonText = 'Accept updated schedule';
        }
        return (
          <>
            <ManageAssessmentsModalContent
              client={client}
              provider={provider}
              providerPatient={providerPatient}
              assessmentsToDisplay={selectedAssessments}
              isPostFillOutOnBehalfOfClientScheduling={true}
              hasExistingSchedules={
                existingSchedulesOverlapWithSelectedAssessments
              }
            />

            <ModalFooter>
              <div className="justify-right flex flex-row gap-3">
                <Button
                  variant="secondary"
                  onPress={handleDismissWithoutChanges}
                >
                  {cancelButtonText}
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  form="manage-assessments-modal-form"
                  disabled={!isValid}
                >
                  {confirmButtonText}
                </Button>
              </div>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};
