import { FormLabel } from '@mui/material';
import sortBy from 'lodash/sortBy';
import React from 'react';
import * as Yup from 'yup';

import { SpecialtyRead } from '@headway/api/models/SpecialtyRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { ComboBox } from '@headway/helix/ComboBox';
import { FormControl, validity } from '@headway/helix/FormControl';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { hasPrescriberLicenseType } from '@headway/shared/utils/licenseHelper';
import {
  getSupportedStates,
  isProviderInState,
} from '@headway/shared/utils/ProviderLicenseStatesHelper';
import { FieldControl, FieldDropzone, FieldErrorText } from '@headway/ui/form';

import { onDropFiles } from '../../../utils/providerQuestionnaire';
import { useQuestionnaireContext } from '../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../QuestionnaireV2Step';
import { CustomComponentProps } from '../utils/CustomComponentProps';
import { YesNoQuestion } from '../utils/YesNoQuestion';
import { yupSchemaToDefaultValue } from '../utils/yupSchemaToDefaultValue';

const focusAreaSources = [
  'Graduate Coursework',
  'Practicum or Internship',
  'Formal Supervision',
  'Fellowship or Residency',
  'CEU/CME',
  'Certificate',
  'Other',
];

const groupBy = (arr: any[], key: any) => {
  const res: any[] = [];
  arr.forEach((arrItem) => {
    let groupIndex = res.findIndex((obj) => {
      return obj.title === arrItem[key];
    });
    if (groupIndex > -1) {
      res[groupIndex].items.push(arrItem);
    } else {
      res.push({ title: arrItem[key], items: [arrItem] });
    }
  });
  res.sort((a, b) => {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
    return 0;
  });
  return res;
};

type FocusAreaStepProps = CustomComponentProps & {
  specialties: SpecialtyRead[];
};

function FocusAreas({ formikHelpers }: FocusAreaStepProps) {
  const { provider, specialties } = useQuestionnaireContext();

  function handleDropFiles(files: File[]) {
    onDropFiles(provider.id, files);
  }

  if (!specialties) {
    return null;
  }

  const { values, setFieldValue } = formikHelpers;

  return (
    <div css={{ ...theme.reset }}>
      <section css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
        <FormControl
          name="primaryFocusArea"
          label="Please choose your primary area of expertise."
          component={Select}
          selectionMode={'single'}
        >
          {sortBy(specialties, 'clinicalDisplayName').map((specialty) => (
            <Item key={specialty.key}>{specialty.clinicalDisplayName}</Item>
          ))}
        </FormControl>
        <FormControl
          name="primaryFocusAreaSource"
          label="Please choose where you received training in this area."
          component={Select}
          selectionMode={'single'}
        >
          {focusAreaSources.map((focusAreaSource) => (
            <Item key={focusAreaSource}>{focusAreaSource}</Item>
          ))}
        </FormControl>
        {values.primaryFocusArea === 'eating_disorders' &&
          values.primaryFocusAreaSource === 'Certificate' && (
            <FieldControl
              name={`eatingDisorderCertificate`}
              fullWidth={true}
              css={{ marginTop: theme.spacing.x5 }}
            >
              <FormLabel>
                Please upload a copy of this <b>Certificate</b>.
              </FormLabel>
              <FieldDropzone
                accept="application/pdf,image/*"
                onDrop={handleDropFiles}
              />
              <FieldErrorText />
            </FieldControl>
          )}
        {values.primaryFocusAreaSource === 'Other' && (
          <FormControl
            name="otherPrimaryFocusAreaSource"
            label="Please explain."
            component={TextField}
          />
        )}

        <ComboBox
          name="otherPrimaryFocusAreas"
          label="Please choose other areas of expertise (up to 4)."
          selectionMode="multiple"
          items={sortBy(specialties, 'clinicalDisplayName')}
          selectedKeys={values.otherPrimaryFocusAreas}
          onSelectionChange={(selected: Set<string>) => {
            const newValues = Array.from(selected);
            setFieldValue('otherPrimaryFocusAreas', newValues);
          }}
          validation={validity('otherPrimaryFocusAreas', formikHelpers)}
        >
          {(specialty: SpecialtyRead) => (
            <Item key={specialty.key}>{specialty.clinicalDisplayName}</Item>
          )}
        </ComboBox>
      </section>
      <section
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
          marginTop: theme.spacing.x6,
        }}
      >
        <FormControl
          component={CheckboxGroup}
          name="practiceFocusAreas"
          label="Please choose the areas of expertise that best describe your
            practice."
        >
          {groupBy(specialties, 'category').map((item) => (
            <div>
              <span css={{ ...theme.typography.body.medium }}>
                {item.title}
              </span>
              <div
                css={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridRowGap: theme.spacing.x1,
                }}
              >
                {item.items.map((specialty: SpecialtyRead) => (
                  <div key={specialty.key}>
                    <Checkbox value={specialty.key}>
                      {specialty.clinicalDisplayName}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </FormControl>
      </section>
      {isProviderInState(provider, UnitedStates.ILLINOIS) && (
        <section
          css={{
            ...theme.stack.vertical,
            gap: theme.spacing.x6,
            borderTop: `1px solid ${theme.color.system.borderGray}`,
            marginTop: theme.spacing.x8,
            paddingTop: theme.spacing.x8,
          }}
        >
          <h2>
            <SectionHeader>
              Do you offer these services to patients?
            </SectionHeader>
          </h2>
          <YesNoQuestion
            name="offersBreastfeedingSupport"
            label="Do you provide lactation/breastfeeding support services, including counseling and education?"
          />
          <YesNoQuestion
            name="offersMATForOpioidUse"
            label="Is Medication Assisted Treatment for Opioid Use Disorders provided at this location?"
          />
          <YesNoQuestion
            name="isCounselingProvidedForOpioidUse"
            label="Is Counseling provided for Opioid Use Disorder at this location?"
          />
          <YesNoQuestion
            name="isAuthorizedToDispenseMATForOpioidUse"
            label="Is this Physician authorized to dispense Medication Assisted Treatment (MAT) for Opioid Use Disorders?"
          />
          <YesNoQuestion
            name="prefersToKeepMATPrivate"
            label="Would you prefer to keep the MAT answers private? You can choose to not disclose this information with our members."
          />
        </section>
      )}
    </div>
  );
}

const ServicesSectionSchema: Partial<Record<UnitedStates, object>> = {
  [UnitedStates.ILLINOIS]: {
    offersBreastfeedingSupport: Yup.string().required(
      'This question is required.'
    ),
    offersMATForOpioidUse: Yup.string().required('This question is required.'),
    isCounselingProvidedForOpioidUse: Yup.string().required(
      'This question is required.'
    ),
    isAuthorizedToDispenseMATForOpioidUse: Yup.string().when(
      'selectedLicenses',
      (selectedLicenses: any, schema: any) =>
        hasPrescriberLicenseType(selectedLicenses, [UnitedStates.ILLINOIS])
          ? schema.required('This question is required.')
          : schema
    ),
    prefersToKeepMATPrivate: Yup.string().required(
      'This question is required.'
    ),
  },
} as const;

const ServicesSectionInitialValues = {
  [UnitedStates.ILLINOIS.toString()]: {
    offersBreastfeedingSupport: '',
    offersMATForOpioidUse: '',
    isCounselingProvidedForOpioidUse: '',
    isAuthorizedToDispenseMATForOpioidUse: '',
    prefersToKeepMATPrivate: '',
  },
};

const stepConfig: QuestionnaireV2Step = {
  title: 'Focus Areas',
  description:
    'In this section, we ask you to share details on your practice focus areas.',
  Component: FocusAreas,
  getFormMeta: ({
    provider,
    providerQuestionnaire: { rawData },
    specialties,
  }) => {
    let stateSpecificSchema = {};
    let stateSpecificInitialValues = {};

    getSupportedStates(provider).forEach((state) => {
      stateSpecificSchema = {
        ...stateSpecificSchema,
        ...ServicesSectionSchema[state],
      };
      stateSpecificInitialValues = {
        ...stateSpecificInitialValues,
        ...ServicesSectionInitialValues[state],
      };
    });

    const validationSchema = Yup.object().shape({
      primaryFocusArea: Yup.string().test(
        'is-valid specialty',
        'Primary area of expertise is required',
        (value) => !!value && specialties.map((s) => s.key).includes(value)
      ),
      primaryFocusAreaSource: Yup.string().required(
        'Primary area of expertise source is required.'
      ),
      otherPrimaryFocusAreas: Yup.array().max(
        4,
        'Please select up to a maximum of 4 practice areas.'
      ),
      practiceFocusAreas: Yup.array().of(Yup.string()),
      eatingDisorderCertificate: Yup.string().when(
        ['primaryFocusArea', 'primaryFocusAreaSource'],
        {
          is: (primaryFocusArea, primaryFocusAreaSource) => {
            return (
              primaryFocusArea === 'eating_disorders' &&
              primaryFocusAreaSource === 'Certificate'
            );
          },

          then: Yup.string().required(
            'Please upload a copy of your certificate'
          ),
          otherwise: Yup.string(),
        }
      ),
      ...stateSpecificSchema,
    });

    return {
      validationSchema: validationSchema,
      initialValue: {
        ...stateSpecificInitialValues,
        ...yupSchemaToDefaultValue(validationSchema),
        ...rawData,
      },
    } as FormMeta;
  },
};
export default stepConfig;
