import { useCallback } from 'react';

import { Button } from '@headway/helix/Button';
import { AddPlus } from '@headway/icons/dist/AddPlus';

import { AssessmentScheduleStartDateType } from '../AssessmentScheduleInput/constants';
import { MultiAssessmentScheduleValue } from './types';
import { getAssessmentScheduleConfigWithDefaults } from './utils';

type MultiAssessmentScheduleInputAddButtonProps = {
  addToForm: (schedule: MultiAssessmentScheduleValue) => void;
  withPendingSchedules?: boolean;
};

export const MultiAssessmentScheduleInputAddButton = ({
  addToForm,
  withPendingSchedules,
}: MultiAssessmentScheduleInputAddButtonProps) => {
  const addSchedule = useCallback(() => {
    addToForm(
      getAssessmentScheduleConfigWithDefaults({
        assessmentType: undefined,
        editing: true,
        startDateType: withPendingSchedules
          ? AssessmentScheduleStartDateType.BeforeFirstSession
          : AssessmentScheduleStartDateType.ExactDate,
      })
    );
  }, [addToForm, withPendingSchedules]);

  return (
    <Button variant="link" onPress={addSchedule}>
      <div className="flex items-center">
        <AddPlus />
        Add another assessment
      </div>
    </Button>
  );
};
