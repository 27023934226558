import { Alert, FormHelperText, Skeleton } from '@mui/material';
import { useFormikContext } from 'formik';

import { PatientAddressType } from '@headway/api/models/PatientAddressType';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { ContentText } from '@headway/helix/ContentText';
import { FormControl } from '@headway/helix/FormControl';
import { IconLock } from '@headway/helix/icons/Lock';
import { Radio as HelixRadio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme as helixTheme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';
import { addressTypeDisplayNames } from '@headway/shared/constants/addressTypeDisplayNames';
import {
  abbreviationToStateEnum,
  statesToDisplayNames,
} from '@headway/shared/constants/unitedStatesDisplayNames';
import { formatPatientName } from '@headway/shared/utils/patient';
import {
  FieldAutocomplete,
  FieldControl,
  FieldErrorText,
  FieldFormLabel,
  FieldInput,
  FieldInputLabel,
  FieldRadioGroup,
} from '@headway/ui/form';
import { LocationFilter } from '@headway/ui/LocationFilter';
import { Radio } from '@headway/ui/Radio';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

import {
  HandleLocationSelectProps,
  HelixLocationFilter,
} from './HelixLocationFilter';
import { LocationFilterAddress } from './PatientAddressForm';
import { theme } from './theme';

export enum PatientAddressFormFieldNames {
  City = 'city',
  State = 'state',
  StreetLine1 = 'streetLine1',
  StreetLine2 = 'streetLine2',
  ZipCode = 'zipCode',
  AddressType = 'addressType',
  Telehealth = 'telehealth',
}

export type PatientAddressFormValues = {
  [PatientAddressFormFieldNames.City]: string;
  [PatientAddressFormFieldNames.State]: UnitedStates | null;
  [PatientAddressFormFieldNames.StreetLine1]: string;
  [PatientAddressFormFieldNames.StreetLine2]: string;
  [PatientAddressFormFieldNames.ZipCode]: string;
  [PatientAddressFormFieldNames.AddressType]: PatientAddressType | null;
  [PatientAddressFormFieldNames.Telehealth]: string | null;
};

type PatientAddressFormFieldsProps = {
  variant?: 'default' | 'helix';
  gmapsScriptStatus: 'idle' | 'loading' | 'ready' | 'error';
  isMSCEnabled: boolean;
  user: UserRead;
  isTelehealth?: boolean;
  createPatientAddressMutationStatus: 'error' | 'idle' | 'loading' | 'success';
  doNotAutoFocus?: boolean;
};

export const PatientAddressFormFields = ({
  variant = 'default',
  gmapsScriptStatus,
  isMSCEnabled,
  user,
  isTelehealth,
  createPatientAddressMutationStatus,
  doNotAutoFocus,
}: PatientAddressFormFieldsProps) => {
  const isMobile = useMediaQuery(helixTheme.media.mobile);
  const { setFieldValue, isSubmitting } = useFormikContext();

  const apiKey = process.env.GOOGLE_MAPS_API_KEY!;

  if (variant === 'helix') {
    return (
      <div className="grid gap-4">
        {gmapsScriptStatus === 'ready' ? (
          <FormControl
            component={HelixLocationFilter}
            selectionMode="single"
            GOOGLE_MAPS_API_KEY={apiKey}
            name="address"
            label="Search for an address"
            handleLocationSelect={({
              streetLine1,
              city,
              state,
              zipCode,
            }: HandleLocationSelectProps) => {
              setFieldValue(
                PatientAddressFormFieldNames.StreetLine1,
                streetLine1
              );
              setFieldValue(PatientAddressFormFieldNames.State, state);
              setFieldValue(PatientAddressFormFieldNames.City, city);
              setFieldValue(PatientAddressFormFieldNames.ZipCode, zipCode);
            }}
            helpText={
              isMSCEnabled && (
                <ContentText variant="caption">
                  If {formatPatientName(user, { firstNameOnly: true })} is a
                  Telehealth client, use the address where they join virtual
                  sessions from.
                </ContentText>
              )
            }
          />
        ) : gmapsScriptStatus === 'loading' ? (
          <Skeleton variant="rectangular" height={40} />
        ) : null}
        <FormControl
          name={PatientAddressFormFieldNames.StreetLine1}
          component={TextField}
          label="Street Line 1"
        />
        <FormControl
          name={PatientAddressFormFieldNames.StreetLine2}
          component={TextField}
          optionalityText="Optional"
          label="Street Line 2"
        />
        <div className={'desktop:flex-row flex flex-col items-start gap-3'}>
          <div className={'desktop:w-auto w-full flex-1'}>
            <FormControl
              name={PatientAddressFormFieldNames.City}
              component={TextField}
              label="City"
            />
          </div>
          <div className={'desktop:w-auto w-full flex-1'}>
            <FormControl
              name={PatientAddressFormFieldNames.State}
              component={Select}
              selectionMode="single"
              label="State"
            >
              {(Object.keys(statesToDisplayNames) as UnitedStates[]).map(
                (state) => (
                  <Item key={state}>{statesToDisplayNames[state]}</Item>
                )
              )}
            </FormControl>
          </div>
          <div className={'desktop:w-auto w-full flex-1'}>
            <FormControl
              name={PatientAddressFormFieldNames.ZipCode}
              component={TextField}
              label="Zip code"
            />
          </div>
        </div>
        <FormControl
          name="addressType"
          label="Describe this location"
          component={Select}
          selectionMode="single"
        >
          {(Object.keys(addressTypeDisplayNames) as PatientAddressType[]).map(
            (addressType) => (
              <Item key={addressType}>
                {addressTypeDisplayNames[addressType]}
              </Item>
            )
          )}
        </FormControl>

        {isMSCEnabled && (
          <div>
            <ContentText variant="caption">
              We will not use this information otherwise, nor will we share it
              with anyone besides the insurance and provider.
            </ContentText>
          </div>
        )}

        {isTelehealth && (
          <div>
            <FormControl
              label="Is the above address where you plan to join video telehealth
                sessions?"
              name={PatientAddressFormFieldNames.Telehealth}
              component={RadioGroup}
            >
              <HelixRadio value="true">
                Yes, I plan to join video sessions from the address above
              </HelixRadio>
              <HelixRadio value="false">
                No, I plan to join from another location
              </HelixRadio>
            </FormControl>
          </div>
        )}
        {createPatientAddressMutationStatus === 'error' && (
          <Alert severity="error">
            We couldn't save this address. Please try again.
          </Alert>
        )}
      </div>
    );
  }

  return (
    <div>
      <fieldset
        css={{
          border: 'none',
          marginTop: theme.space.base,
          padding: 0,
        }}
        disabled={isSubmitting}
      >
        {gmapsScriptStatus === 'ready' ? (
          <div css={{ marginBottom: theme.space.sm }}>
            <LocationFilter
              autoFocus={!doNotAutoFocus}
              handleLocationSelect={(address: LocationFilterAddress) => {
                const newAddress = {
                  ...address,
                  state: abbreviationToStateEnum[address.state],
                };

                setFieldValue(
                  PatientAddressFormFieldNames.City,
                  newAddress.city
                );
                setFieldValue(
                  PatientAddressFormFieldNames.State,
                  newAddress.state
                );
                setFieldValue(
                  PatientAddressFormFieldNames.StreetLine1,
                  newAddress.streetLine1
                );
                setFieldValue(
                  PatientAddressFormFieldNames.StreetLine2,
                  newAddress.streetLine2
                );
                setFieldValue(
                  PatientAddressFormFieldNames.ZipCode,
                  newAddress.zipCode
                );
              }}
              google={window.google}
              placeType="address"
            />
            {isMSCEnabled ? (
              <FormHelperText>
                If {formatPatientName(user, { firstNameOnly: true })} is a
                Telehealth client, use the address where they join virtual
                sessions from.
              </FormHelperText>
            ) : (
              <></>
            )}
          </div>
        ) : gmapsScriptStatus === 'loading' ? (
          <Skeleton variant="rectangular" height={40} />
        ) : null}
        <legend css={{ margin: 0 }}>
          <VisuallyHidden>Address</VisuallyHidden>
        </legend>
        <FieldControl
          name={PatientAddressFormFieldNames.StreetLine1}
          fullWidth={true}
        >
          <FieldInputLabel>Street Line 1</FieldInputLabel>
          <FieldInput />
          <FieldErrorText />
        </FieldControl>
        <FieldControl
          name={PatientAddressFormFieldNames.StreetLine2}
          fullWidth={true}
        >
          <FieldInputLabel>
            (Optional) Apartment Number or Suite
          </FieldInputLabel>
          <FieldInput />
          <FieldErrorText />
        </FieldControl>
        <FieldControl name={PatientAddressFormFieldNames.City} fullWidth={true}>
          <FieldInputLabel>City</FieldInputLabel>
          <FieldInput />
          <FieldErrorText />
        </FieldControl>
        <FieldControl
          name={PatientAddressFormFieldNames.State}
          fullWidth={true}
        >
          <FieldAutocomplete
            label="State"
            options={Object.keys(statesToDisplayNames) as UnitedStates[]}
            getOptionLabel={(key: UnitedStates) => statesToDisplayNames[key]}
          />
          <FieldErrorText />
        </FieldControl>
        <FieldControl
          name={PatientAddressFormFieldNames.ZipCode}
          fullWidth={true}
        >
          <FieldInputLabel>Zip code</FieldInputLabel>
          <FieldInput />
          <FieldErrorText />
        </FieldControl>
        <FieldControl
          name={PatientAddressFormFieldNames.AddressType}
          fullWidth={true}
        >
          <FieldAutocomplete
            label="Describe this location"
            options={
              Object.keys(addressTypeDisplayNames) as PatientAddressType[]
            }
            getOptionLabel={(key: PatientAddressType) =>
              addressTypeDisplayNames[key]
            }
          />
          <FieldErrorText />
        </FieldControl>
        {isMSCEnabled && (
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              gap: theme.space.xs,
            }}
          >
            <IconLock color={theme.color.gray} />
            <FormHelperText>
              We will not use this information otherwise, nor will we share it
              with anyone besides the insurance and provider.
            </FormHelperText>
          </div>
        )}
      </fieldset>
      {isTelehealth && (
        <fieldset css={{ border: 'none', padding: 0 }} disabled={isSubmitting}>
          <FieldControl name={PatientAddressFormFieldNames.Telehealth}>
            <FieldRadioGroup>
              <FieldFormLabel
                css={{
                  color: theme.color.black,
                  fontWeight: theme.fontWeight.base,
                  marginTop: theme.space.base,
                }}
              >
                Is the above address where you plan to join video telehealth
                sessions?
              </FieldFormLabel>
              <Radio
                value={true}
                label={
                  'Yes, I plan to join video sessions from the address above'
                }
              />
              <Radio
                value={false}
                label={'No, I plan to join from another location'}
              />
            </FieldRadioGroup>
            <FieldErrorText />
          </FieldControl>
        </fieldset>
      )}
      {createPatientAddressMutationStatus === 'error' && (
        <Alert severity="error">
          We couldn't save this address. Please try again.
        </Alert>
      )}
    </div>
  );
};
