import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { useUserEmployeeAssistancePrograms } from '@headway/shared/hooks/useUserEmployeeAssistancePrograms';

type EAPBillingDetailGuidanceCardProps = {
  patientId: number;
  patientFirstName: string;
};

const EAPBillingDetailGuidanceCard = (
  props: EAPBillingDetailGuidanceCardProps
) => {
  const { data: eaps, isLoading: eapsAreLoading } =
    useUserEmployeeAssistancePrograms({
      query: {
        has_unused_sessions: true,
        user_id: props.patientId,
      },
    });

  if (eapsAreLoading) {
    return <div />;
  }
  const earliestExpiringEAP = eaps?.[0];
  const sessionsRemaining =
    (earliestExpiringEAP?.validSessions ?? 0) -
    ((earliestExpiringEAP?.usedSessions ?? 0) +
      (earliestExpiringEAP?.pendingSessions ?? 0));

  if (eaps?.length === 0) {
    return (
      <GuidanceCard variant="warning">
        <BodyText>
          {props.patientFirstName} has no EAP sessions remaining. To continue
          their care, advise them to add their insurance to access in-network
          care. If they believe they need more sessions, they can also request
          additional ones from their EAP carrier.
        </BodyText>
      </GuidanceCard>
    );
  } else if (sessionsRemaining === 1) {
    return (
      <GuidanceCard variant="warning">
        <BodyText>
          {props.patientFirstName} has 1 EAP session remaining. To continue
          their care, advise them to add their insurance to access in-network
          care. If they believe they need more sessions, they can also request
          additional ones from their EAP carrier.
        </BodyText>
      </GuidanceCard>
    );
  } else {
    return (
      <GuidanceCard variant="info">
        <BodyText>
          {props.patientFirstName} is covered by an active EAP program. Eligible
          sessions will first use their free EAP sessions. Once those benefits
          are exhausted, their sessions will be covered by insurance.
          <div>
            <LinkButton
              href={
                'https://help.headway.co/hc/en-us/articles/7473561009428-Seeing-a-client-with-an-Employee-Assistance-Program-EAP'
              }
              target="_blank"
              variant="link"
            >
              Learn more
            </LinkButton>
          </div>
        </BodyText>
      </GuidanceCard>
    );
  }
};

export default EAPBillingDetailGuidanceCard;
