import moment from 'moment';
import { useMemo } from 'react';
import { useAllPatientAssessmentRecurrenceSchedules } from '~/legacy/hooks/useAllPatientAssessmentRecurrenceSchedules';
import { useFindProviderEvents } from '~/legacy/hooks/useFindProviderEvents';
import { useGetIsPromsScheduleRequired } from '~/legacy/hooks/useGetIsPromsScheduleRequired';

import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';

const useGetEarliestAppointment = (providerPatient: ProviderPatientRead) => {
  const { data, ...rest } = useFindProviderEvents({
    expand_estimated_prices: false,
    event_types: [ProviderEventType.APPOINTMENT],
    order_by: 'start_date',
    order: 'asc',
    provider_id: providerPatient.providerId,
    patient_user_id: providerPatient.userId,
    limit: 1,
  });

  return { data: data?.data?.[0], ...rest };
};

export function useAllowPendingSchedules(providerPatient: ProviderPatientRead) {
  const providerPatientId = providerPatient.id;

  const { data: promSchedules, isLoading: isPromSchedulesLoading } =
    useAllPatientAssessmentRecurrenceSchedules({ providerPatientId });

  const { data: isPromsRequired, isLoading: isIsPromsRequiredLoading } =
    useGetIsPromsScheduleRequired({ providerPatientId });

  const { data: firstAppointment, isLoading: isFirstAppointmentLoading } =
    useGetEarliestAppointment(providerPatient);

  const pendingSchedulesAllowed = useMemo(() => {
    const hasPendingSchedules = (promSchedules?.pending.length ?? 0) > 0;

    const isPromsScheduleRequired = !!isPromsRequired?.isPromsScheduleRequired;

    // check first appointment since the only currently supported pending schedule
    // option is for 2 days before the first appointment.
    const now = moment();
    const hasHadFirstAppointmentAlready =
      !!firstAppointment?.startDate &&
      now.isSameOrAfter(firstAppointment.startDate);

    return (
      hasPendingSchedules ||
      (isPromsScheduleRequired && !hasHadFirstAppointmentAlready)
    );
  }, [isPromsRequired, promSchedules, firstAppointment]);

  const isLoading =
    isPromSchedulesLoading ||
    isIsPromsRequiredLoading ||
    isFirstAppointmentLoading;

  return { pendingSchedulesAllowed, isLoading };
}
