import { PatientAssessmentRecurrenceScheduleRead } from '@headway/api/models/PatientAssessmentRecurrenceScheduleRead';
import { PatientAssessmentRecurrenceScheduleApi } from '@headway/api/resources/PatientAssessmentRecurrenceScheduleApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UsePatientAssessmentRecurrenceSchedulesQueryKeyArgs {
  providerPatientId: number | undefined;
}
export const getUsePatientAssessmentRecurrenceSchedulesQueryKey = ({
  providerPatientId,
}: UsePatientAssessmentRecurrenceSchedulesQueryKeyArgs) =>
  ['patient-assessment-recurrence-schedules', providerPatientId] as const;
const {
  singleQuery: patientAssessmentRecurrenceSchedules,
  useSingleQuery: usePatientAssessmentRecurrenceSchedules,
  useListQuery: usePatientAssessmentRecurrenceSchedulesList,
  useCachedQuery: usePatientAssessmentRecurrenceSchedulesCache,
} = createBasicApiHooks(
  getUsePatientAssessmentRecurrenceSchedulesQueryKey,
  async ({ providerPatientId }) => {
    const response =
      await PatientAssessmentRecurrenceScheduleApi.getPatientAssessmentRecurrenceSchedules(
        providerPatientId!
      );

    return 'current' in response
      ? (response.current as PatientAssessmentRecurrenceScheduleRead[])
      : response;
  },
  ({ providerPatientId }) => providerPatientId != null,
  () => 'Failed to fetch patient assessment recurrence schedules'
);
export {
  patientAssessmentRecurrenceSchedules,
  usePatientAssessmentRecurrenceSchedules,
  usePatientAssessmentRecurrenceSchedulesList,
  usePatientAssessmentRecurrenceSchedulesCache,
};
