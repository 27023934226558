import { CalendarDate, getLocalTimeZone } from '@internationalized/date';
import moment from 'moment';
import * as Yup from 'yup';
import { AssessmentScheduleStartDateType } from '~/legacy/views/Clients/Assessments/components/AssessmentScheduleInput/constants';
import { MaybePendingAssessmentScheduleConfig } from '~/legacy/views/Clients/Assessments/helpers/types';

import { PatientAssessmentPendingScheduleRead } from '@headway/api/models/PatientAssessmentPendingScheduleRead';
import { PatientAssessmentRecurrenceCadence } from '@headway/api/models/PatientAssessmentRecurrenceCadence';
import { PatientAssessmentRecurringOrPendingScheduleCreateRequest } from '@headway/api/models/PatientAssessmentRecurringOrPendingScheduleCreateRequest';
import { PatientAssessmentTrigger } from '@headway/api/models/PatientAssessmentTrigger';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { momentToDateValue } from '@headway/helix/date';

const localTimeZone = getLocalTimeZone();

const getScheduleConfigValidationSchema = (
  schema: Yup.ObjectSchema = Yup.object()
) =>
  schema.shape({
    cadence: Yup.string()
      .required('Assessment frequency is required')
      .oneOf(Object.values(PatientAssessmentRecurrenceCadence)),
    startDate: Yup.date()
      .transform(
        (_value, originalValue: Date | CalendarDate | string | null) => {
          if (originalValue instanceof CalendarDate) {
            return originalValue.toDate(localTimeZone);
          }

          if (typeof originalValue === 'string') {
            return moment(originalValue).toDate();
          }

          return originalValue;
        }
      )
      .nullable(true)
      .required('Select a date')
      .min(moment().startOf('day').toDate(), 'Date must be today or later')
      .when('startDateType', (startDateType, schema) =>
        startDateType === AssessmentScheduleStartDateType.BeforeFirstSession
          ? schema.notRequired()
          : schema
      ),
    startDateType: Yup.string()
      .optional()
      .oneOf(Object.values(AssessmentScheduleStartDateType)),
  });

export const getAssessmentScheduleValidationSchema = (
  schema: Yup.ObjectSchema = Yup.object()
) =>
  getScheduleConfigValidationSchema(schema).shape({
    assessmentType: Yup.string()
      .required('Assessment type is required')
      .oneOf(Object.values(PatientAssessmentType)),
  });

export const generateAssessmentSchedulesValidationSchema = ({
  assessments,
  isPromsScheduleRequired,
}: {
  assessments?: PatientAssessmentType[];
  isPromsScheduleRequired?: boolean;
}): Yup.ObjectSchema => {
  const assessmentTypes = assessments || Object.values(PatientAssessmentType);

  return Yup.object().shape({
    enabledAssessmentTypes: Yup.array()
      // Validate that at least one assessment is selected
      .test(
        'at-least-one-assessment-selected',
        'You must select at least one assessment',
        (value) => {
          if (!isPromsScheduleRequired) return true;
          return Array.isArray(value) && value.length > 0;
        }
      )
      // Validate that at least one recurring assessment is selected
      .test(
        'at-least-one-recurring',
        'You must select at least one recurring assessment',
        function (value) {
          if (!isPromsScheduleRequired || !value?.length) return true;
          const { parent } = this;
          const areThereRecurringAssessments = value.some((assessmentType) => {
            return (
              parent[assessmentType as PatientAssessmentType].cadence !==
              PatientAssessmentRecurrenceCadence.ONE_TIME
            );
          });
          return areThereRecurringAssessments;
        }
      ),
    ...assessmentTypes.reduce(
      (acc, current: PatientAssessmentType) => {
        acc[current] = Yup.object().when(
          'enabledAssessmentTypes',
          (enabledTypes: PatientAssessmentType[], schema) => {
            return enabledTypes.includes(current)
              ? getScheduleConfigValidationSchema(schema)
              : schema;
          }
        );
        return acc;
      },
      {} as { [assessmentType in PatientAssessmentType]: Yup.ObjectSchema }
    ),
  });
};

export const parseAssessmentScheduleConfig = ({
  startDate,
  startDateType,
  ...config
}: MaybePendingAssessmentScheduleConfig): PatientAssessmentRecurringOrPendingScheduleCreateRequest => {
  if (startDateType === AssessmentScheduleStartDateType.BeforeFirstSession) {
    return { ...config, trigger: PatientAssessmentTrigger.FIRST_APPOINTMENT };
  }

  const startDateValue =
    typeof startDate === 'string'
      ? momentToDateValue(moment(startDate))
      : startDate;

  return { ...config, startDate: startDateValue.toString() };
};

export const getAssessmentStartDateType = ({
  trigger,
}: PatientAssessmentPendingScheduleRead) => {
  if (trigger === PatientAssessmentTrigger.FIRST_APPOINTMENT) {
    return AssessmentScheduleStartDateType.BeforeFirstSession;
  }

  throw new Error(`Invalid patient assessment trigger: ${trigger}`);
};
