import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ContentText } from '@headway/helix/ContentText';
import { IconCheckCircle } from '@headway/helix/icons/CheckCircle';
import { theme } from '@headway/helix/theme';
import { pluralize } from '@headway/shared/utils/stringFormatting';

import {
  BulkConfirmEventsData,
  BulkConfirmSubmissionStatus,
} from '../../utils/types';
import { SessionDetailsTable } from '../components/SessionDetailsTable';
import { TotalEarnings } from '../components/TotalEarnings';

interface SuccessStepProps {
  provider: ProviderRead;
  submissionStatusByEventVirtualId: Record<string, BulkConfirmSubmissionStatus>;
  bulkConfirmEventsData: BulkConfirmEventsData;
}

export const SuccessStep = ({
  provider,
  submissionStatusByEventVirtualId,
  bulkConfirmEventsData,
}: SuccessStepProps) => {
  const confirmedSessionVirtualIds = Object.entries(
    submissionStatusByEventVirtualId
  )
    .filter(([id, status]) => status === BulkConfirmSubmissionStatus.SUCCESS)
    .map(([id, status]) => id);
  const confirmedSessionCount = confirmedSessionVirtualIds.length;

  return (
    <div className="flex flex-col py-10">
      <div className="mb-5 flex flex-col gap-5 px-5">
        <ContentText variant="page-title">
          <IconCheckCircle
            className="mr-2"
            fill={theme.color.foreground.compliance}
          />
          {confirmedSessionCount} {pluralize('session', confirmedSessionCount)}{' '}
          confirmed
        </ContentText>
        <ContentText variant="body">
          We've verified and confirmed your{' '}
          {pluralize('session', confirmedSessionCount)}.
        </ContentText>
        <TotalEarnings
          confirmedEvents={confirmedSessionVirtualIds.map(
            (id) => bulkConfirmEventsData[id].event
          )}
        />
      </div>
      <SessionDetailsTable
        provider={provider}
        submissionStatusByEventVirtualId={submissionStatusByEventVirtualId}
        bulkConfirmEventsData={bulkConfirmEventsData}
        readonly
      />
    </div>
  );
};
