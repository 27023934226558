import { groupBy, orderBy } from 'lodash';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { useAllPatientAssessmentRecurrenceSchedulesCache } from '~/legacy/hooks/useAllPatientAssessmentRecurrenceSchedules';

import { ASRSAgeCategory } from '@headway/api/models/ASRSAgeCategory';
import { ASRSScoreSummary } from '@headway/api/models/ASRSScoreSummary';
import { ASRSSubmission } from '@headway/api/models/ASRSSubmission';
import { PatientAssessmentCreateRequest } from '@headway/api/models/PatientAssessmentCreateRequest';
import { PatientAssessmentPendingScheduleRead } from '@headway/api/models/PatientAssessmentPendingScheduleRead';
import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';
import { PatientAssessmentRecurrenceCadence } from '@headway/api/models/PatientAssessmentRecurrenceCadence';
import { PatientAssessmentRecurrenceScheduleRead } from '@headway/api/models/PatientAssessmentRecurrenceScheduleRead';
import { PatientAssessmentRecurrenceScheduleReadAllResponse } from '@headway/api/models/PatientAssessmentRecurrenceScheduleReadAllResponse';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { UnreadAssessment } from '@headway/api/models/UnreadAssessment';
import { BadgeProps } from '@headway/helix/Badge';
import { checkExhaustive } from '@headway/shared/utils/types';
import { getOrdinal } from '@headway/ui/utils/numbers';

import { usePatientAssessmentCountByTypeCache } from 'hooks/usePatientAssessmentCountByType';
import { usePatientAssessmentRecurrenceSchedulesCache } from 'hooks/usePatientAssessmentRecurrenceSchedules';
import { usePatientAssessmentsCache } from 'hooks/usePatientAssessments';
import { useProvider } from 'hooks/useProvider';
import { useProviderHasSentAssessmentsCache } from 'hooks/useProviderHasSentAssessments';
import {
  ReplaceAllPatientAssessmentRecurrenceSchedulesMutationArgs,
  ReplacePatientAssessmentRecurrenceSchedulesMutationArgs,
  useReplaceAllPatientAssessmentRecurrenceSchedulesMutation,
} from 'mutations/assessments';
import { SideEffectsBuilder } from 'mutations/utils';

import {
  ASSESSMENT_SCORE_RANGES,
  AssessmentScoreRange,
  TIME_SCALE_TO_DURATION,
} from './constants';
import { AssessmentBadgeInfo, TimeScale } from './types';

const MDY = 'M/D/YY';
const LONG_MONTH_NAME = 'MMMM Do';

export type BadgeData = {
  // Color displayed on the badge.
  badgeVariant: BadgeProps['variant'];
  // Text displayed on the badge.
  badgeText: string;
};

/**
 * Returns the appropriate variant color for a badge or line chart glyph displaying an assessment
 * result. Assumes the score is valid for the assessment.
 */
export const getVariantForAssessmentResult = (
  assessmentType: PatientAssessmentType,
  score: number
): AssessmentScoreRange['variant'] => {
  return ASSESSMENT_SCORE_RANGES[assessmentType].find(
    (range) =>
      score >= range.startScore && score <= (range.endScore ?? Infinity)
  )!.variant;
};

/** Returns a descriptor appropriate for the given score on an assessment. */
export const getAssessmentScoreDescriptor = (
  assessmentType: PatientAssessmentType,
  score: number
): string | undefined => {
  return ASSESSMENT_SCORE_RANGES[assessmentType].find(
    (range) =>
      score >= range.startScore && score <= (range.endScore ?? Infinity)
  )?.descriptor;
};

/** Returns the abridged descriptor if present, else the standard descriptor. */
export const getAssessmentScoreAbridgedDescriptor = (
  assessmentType: PatientAssessmentType,
  score: number
): string => {
  const range = ASSESSMENT_SCORE_RANGES[assessmentType].find(
    (range) =>
      score >= range.startScore && score <= (range.endScore ?? Infinity)
  )!;
  return range.abridgedDescriptor || range.descriptor;
};

/**
 * Returns the appropriate variant color for a badge displaying the change between two assessment
 * scores. `scoreDiff` should be positive if the score increased, negative if the score decreased.
 */
export const getBadgeVariantForAssessmentScoreDiff = (
  assessmentType: PatientAssessmentType,
  scoreDiff: number
): BadgeProps['variant'] => {
  if (scoreDiff === 0) {
    return 'neutral';
  }
  switch (assessmentType) {
    case PatientAssessmentType.WHO5:
      return scoreDiff > 0 ? 'positive' : 'negative';
    case PatientAssessmentType.ADNM8:
    case PatientAssessmentType.ANCHOR:
    case PatientAssessmentType.ASRS:
    case PatientAssessmentType.GAD7:
    case PatientAssessmentType.IESR:
    case PatientAssessmentType.ISI:
    case PatientAssessmentType.PCL5:
    case PatientAssessmentType.PHQ9:
    case PatientAssessmentType.PROMIS:
    case PatientAssessmentType.WHODAS_2:
      return scoreDiff > 0 ? 'negative' : 'positive';
    default:
      checkExhaustive(assessmentType);
  }
};

/**
 * Returns a list of BadgeData used to render in AssessmentTable's Result column
 */
export const getResultBadgeDataUsingSubscores = (
  assessment: AssessmentBadgeInfo
): Array<BadgeData> | undefined => {
  let resultBadgeData: Array<BadgeData> = [];
  const { assessmentType, subscores, scorableResponseJson } = assessment;

  if (assessment.assessmentType !== PatientAssessmentType.ASRS) {
    throw new Error(
      'getResultBadgeDataUsingSubscores is not implemented for this assessment type'
    );
  }
  if (
    scorableResponseJson &&
    isASRSInvalid(assessmentType, scorableResponseJson)
  ) {
    return [{ badgeVariant: 'neutral', badgeText: `No result - under 18` }];
  }
  if (subscores == null) {
    return [];
  }

  // construct badge data for completed ASRS assessments
  const scoreForVariant = subscores.partA_score;
  const scoreForText = subscores.partA_score;
  const isInvestigationNeeded = isASRSInvestigationWarranted(subscores);
  resultBadgeData = [
    {
      badgeVariant: getVariantForAssessmentResult(
        PatientAssessmentType.ASRS,
        scoreForVariant
      ),
      badgeText:
        getAssessmentScoreDescriptor(assessmentType, scoreForText) ||
        'No result',
    },
  ];

  resultBadgeData = isInvestigationNeeded
    ? [
        ...resultBadgeData,
        { badgeVariant: 'warning', badgeText: 'Investigation warranted' },
      ]
    : resultBadgeData;

  return [
    ...resultBadgeData,
    { badgeVariant: 'neutral', badgeText: '+3 subscales' },
  ];
};

/**
 * Returns an instance of BadgeData used to render in AssessmentTable's Score column
 */
export const getScoreBadgeDataUsingSubscores = (
  assessment: AssessmentBadgeInfo
): BadgeData | undefined => {
  const { subscores, score } = assessment;
  if (assessment.assessmentType === PatientAssessmentType.ASRS) {
    if (subscores == null || score == null) {
      throw new Error(
        'ASRS assessment is missing a score for Part A. Confirm that the submission was made by a 18+ user.'
      );
    }
    const scoreForVariant = subscores.partA_score;
    const scoreForText = getScoreRepresentationForAssessmentType(
      score,
      assessment.assessmentType
    );
    const ordinal = getOrdinal(Number(scoreForText));
    return {
      badgeVariant: getVariantForAssessmentResult(
        PatientAssessmentType.ASRS,
        scoreForVariant
      ),
      badgeText: `${scoreForText}${ordinal}`,
    };
  } else {
    throw new Error(
      'getScoreBadgeDataUsingSubscores is not implemented for this assessment type'
    );
  }
};

/**
 * Side effects for updating react-query caches relevant to the assessments tab when new assessments are created.
 */
export const useCreatePatientAssessmentsSideEffects = () => {
  const provider = useProvider();
  const assessmentCache = usePatientAssessmentsCache();
  const assessmentCountCache = usePatientAssessmentCountByTypeCache();
  const hasSentAssessmentsCache = useProviderHasSentAssessmentsCache();

  return new SideEffectsBuilder<
    PatientAssessmentRead[],
    unknown,
    PatientAssessmentCreateRequest
  >().add({
    onSuccess: (_data, { providerPatientId, assessmentTypes }) => {
      assessmentCountCache.invalidate({
        providerPatientId,
      });
      for (const assessmentType of assessmentTypes) {
        assessmentCache.invalidate({
          provider_patient_id: providerPatientId,
          assessment_type: assessmentType,
        });
      }
      hasSentAssessmentsCache.setAndInvalidate(
        { providerId: provider.id },
        true
      );
    },
  });
};

/**
 * renders a recurrence schedule object as nice human-readable information at the header of the assessments table
 */
export const recurrenceScheduleAsMetadata = (
  recurrenceSchedule: PatientAssessmentRecurrenceScheduleRead,
  mostRecentAssessment: PatientAssessmentRead | undefined
): string => {
  const nextScheduledDate = moment(recurrenceSchedule.nextScheduledDate).format(
    MDY
  );

  let nextSendMetadataString = '';

  if (
    mostRecentAssessment &&
    mostRecentAssessment.recurrenceScheduleId === recurrenceSchedule.id &&
    moment(mostRecentAssessment.originalCreatedOn).isSame(moment(), 'day')
  ) {
    nextSendMetadataString = 'Sent today';
  } else {
    nextSendMetadataString = `Next send ${nextScheduledDate}`;
  }

  const recurrenceMetadataString =
    CADENCE_DISPLAY_NAMES[recurrenceSchedule.cadence];

  const remainingSendsString = recurrenceSchedule.remainingSendCount
    ? `${recurrenceSchedule.remainingSendCount} remaining send${
        recurrenceSchedule.remainingSendCount > 1 ? 's' : ''
      }`
    : '';

  const metadataStrings = [
    nextSendMetadataString,
    recurrenceMetadataString,
    remainingSendsString,
  ].filter(Boolean);

  return metadataStrings.join(' • ');
};
/**
 * Side effects for updating react-query caches relevant to the assessments tab when new assessment schedules are created.
 */
export const useReplacePatientAssessmentRecurrenceSchedulesSideEffects = () => {
  const provider = useProvider();
  const schedulesCache = usePatientAssessmentRecurrenceSchedulesCache();
  const assessmentCache = usePatientAssessmentsCache();
  const assessmentCountCache = usePatientAssessmentCountByTypeCache();
  const hasSentAssessmentsCache = useProviderHasSentAssessmentsCache();

  return new SideEffectsBuilder<
    PatientAssessmentRecurrenceScheduleRead[],
    unknown,
    ReplacePatientAssessmentRecurrenceSchedulesMutationArgs
  >().add({
    onSuccess: (created, { providerPatientId, body }) => {
      schedulesCache.setAndInvalidate({ providerPatientId }, created);
      assessmentCountCache.invalidate({ providerPatientId });

      for (const schedule of created) {
        assessmentCache.invalidate({
          provider_patient_id: providerPatientId,
          assessment_type: schedule.assessmentType,
        });
      }

      const now = moment();
      const sentAssessments = body.schedules.some((schedule) =>
        moment(schedule.startDate).isSame(now, 'day')
      );
      if (sentAssessments) {
        hasSentAssessmentsCache.setAndInvalidate(
          { providerId: provider.id },
          true
        );
      }
    },
  });
};

/**
 * Side effects for updating react-query caches relevant to the assessments tab when new assessment schedules are created.
 * Similar to {@link useReplacePatientAssessmentRecurrenceSchedulesSideEffects} but includes both pending and current schedules.
 */
export const useReplaceAllPatientAssessmentRecurrenceSchedulesSideEffects =
  () => {
    const provider = useProvider();
    const currentSchedulesCache =
      usePatientAssessmentRecurrenceSchedulesCache();
    const allSchedulesCache = useAllPatientAssessmentRecurrenceSchedulesCache();
    const assessmentCache = usePatientAssessmentsCache();
    const assessmentCountCache = usePatientAssessmentCountByTypeCache();
    const hasSentAssessmentsCache = useProviderHasSentAssessmentsCache();

    return new SideEffectsBuilder<
      PatientAssessmentRecurrenceScheduleReadAllResponse,
      unknown,
      ReplaceAllPatientAssessmentRecurrenceSchedulesMutationArgs
    >().add({
      onSuccess: (created, { providerPatientId }) => {
        assessmentCountCache.invalidate({ providerPatientId });
        hasSentAssessmentsCache.invalidate({ providerId: provider.id });
        allSchedulesCache.setAndInvalidate({ providerPatientId }, created);
        currentSchedulesCache.setAndInvalidate(
          { providerPatientId },
          created.current
        );

        for (const schedule of [...created.current, ...created.pending]) {
          assessmentCache.invalidate({
            provider_patient_id: providerPatientId,
            assessment_type: schedule.assessmentType,
          });
        }
      },
    });
  };

export const useReplaceAllPatientAssessmentRecurrenceSchedules = () =>
  useReplaceAllPatientAssessmentRecurrenceSchedulesMutation({
    sideEffects: useReplaceAllPatientAssessmentRecurrenceSchedulesSideEffects(),
  });

export const CADENCE_DISPLAY_NAMES: {
  [cadence in PatientAssessmentRecurrenceCadence]: string;
} = {
  [PatientAssessmentRecurrenceCadence.ONE_TIME]: 'One-time',
  [PatientAssessmentRecurrenceCadence.EVERY_7_DAYS]: 'Weekly',
  [PatientAssessmentRecurrenceCadence.EVERY_14_DAYS]: 'Every two weeks',
  [PatientAssessmentRecurrenceCadence.EVERY_30_DAYS]: 'Every 30 days',
  [PatientAssessmentRecurrenceCadence.EVERY_60_DAYS]: 'Every 60 days',
  [PatientAssessmentRecurrenceCadence.EVERY_90_DAYS]: 'Every 90 days',
};

export const latestCompletionDateAmongUnreadAssessments = (
  unreadAssessments: UnreadAssessment[]
): string => {
  if (unreadAssessments.length === 0) {
    return '';
  }

  const completionDates = unreadAssessments.map((assessment) =>
    moment(assessment.completedOn)
  );

  const latestCompletionDate = completionDates.reduce((latest, current) => {
    return current > latest ? current : latest;
  });

  return latestCompletionDate.format(LONG_MONTH_NAME);
};

export interface AssessmentInsight {
  key: string;
  badgeVariant: BadgeProps['variant'];
  badgeText: string;
  safetyRisk: boolean;
}

/**
 * On the launchpad module, we want to display insights alongside each patient's summary of unread assessments.
 * We have enough space to display 4 badges, and want to prioritize insights indicating safety risk and worse wellbeing scores.
 * If we have 4 insights or fewer, we'll return them unchanged.
 * If we have more, we'll take the first 3 and replace the remaining elements with a '+x more' badge.
 */
export const getAssessmentInsights = (
  unreadAssessments: UnreadAssessment[]
): AssessmentInsight[] => {
  const insights = unreadAssessments.map((assessment) => {
    const assessmentType = assessment.assessmentType;
    // This can occur for invalid assessments, e.g. ASRS for patients under 18
    if (assessment.score == null) {
      return [];
    }
    const score =
      assessmentType === PatientAssessmentType.ASRS && assessment.subscores
        ? assessment.subscores.partA_score
        : assessment.score || 0;
    const variant = getVariantForAssessmentResult(assessmentType, score);
    const badgeText = getAssessmentScoreAbridgedDescriptor(
      assessmentType,
      score
    );
    const scoreInsight: AssessmentInsight = {
      key: `assessment-score-insight-${assessment.id}`,
      badgeVariant: variant,
      badgeText: badgeText,
      safetyRisk: false,
    };
    const results = [scoreInsight];
    if (indicatesSiRisk(assessmentType, assessment.safetyRisk)) {
      const safetyInsight: AssessmentInsight = {
        key: `assessment-safety-insight-${assessment.id}`,
        badgeVariant: 'negative',
        badgeText: 'Safety risk',
        safetyRisk: true,
      };
      results.push(safetyInsight);
    }
    return results;
  });
  const flattenedInsights = insights.flat();
  const uniqueInsights = uniqBy(flattenedInsights, (i) => i.badgeText);
  const sortedInsights = uniqueInsights.sort((a, b) => {
    // insights with safety risk should precede those without
    if (a.safetyRisk !== b.safetyRisk) {
      return a.safetyRisk ? -1 : 1;
    }
    // if safety risk is equal, compare variants
    const variantOrder: { [key: string]: number } = {
      negative: 0,
      warning: 1,
      positive: 2,
    };
    return variantOrder[a.badgeVariant] - variantOrder[b.badgeVariant];
  });
  if (sortedInsights.length <= 4) {
    return sortedInsights;
  }
  // if we have more than 4 insights, return the first 3 plus a "more" insight
  // the variant (color) of the "more" insight should equal the variant of the first hidden insight (the 4th one)
  const nextInsightVariant = sortedInsights[3].badgeVariant;
  const moreInsights: AssessmentInsight = {
    key: 'assessment-insights-more',
    badgeVariant: nextInsightVariant,
    badgeText: `+${sortedInsights.length - 3} more`,
    safetyRisk: false,
  };
  return [...sortedInsights.slice(0, 3), moreInsights];
};

/**
 * ASRS results are invalid for patients under 18
 */
export const isASRSInvalid = (
  assessmentType: PatientAssessmentType,
  scorableResponseJson?: object | null
): boolean => {
  return (
    !!scorableResponseJson &&
    assessmentType === PatientAssessmentType.ASRS &&
    scorableResponseJson &&
    (scorableResponseJson as ASRSSubmission)['AGE'] === ASRSAgeCategory.UNDER_18
  );
};

/**
 * An ASRS should be investigated if Part A score is in the warning range
 */
export const isASRSInvestigationWarranted = (
  subscores: ASRSScoreSummary
): boolean => {
  return (
    getVariantForAssessmentResult(
      PatientAssessmentType.ASRS,
      subscores.partA_score
    ) === 'warning'
  );
};

/**
 * Returns an x-axis domain suitable for use in a recharts line chart. For the 'ALL' time scale, the
 * domain is set to span the range of data points, with 15 days of padding on either side. For other
 * time scales, the right side of the domain is fixed to 15 days in the future.
 * @param earliestDateTimestamp Earliest data point in the line chart
 * @param latestDateTimestamp Latest data point in the line chart
 */
export const getLineChartXDomain = (
  earliestDateTimestamp: number | undefined,
  latestDateTimestamp: number | undefined,
  timeScale: TimeScale
): [number, number] => {
  return timeScale === TimeScale.ALL
    ? [
        moment(earliestDateTimestamp)
          .subtract(15, 'days')
          .startOf('day')
          .valueOf(),
        moment(latestDateTimestamp).add(15, 'days').startOf('day').valueOf(),
      ]
    : [
        moment()
          .subtract(TIME_SCALE_TO_DURATION[timeScale])
          .startOf('day')
          .valueOf(),
        moment().add(15, 'days').startOf('day').valueOf(),
      ];
};

/**
 * Returns a y-axis domain suitable for use in a recharts line chart for the given assessment type.
 */
export const getLineChartYDomain = (
  assessmentType: PatientAssessmentType
): ((dataRange: [number, number]) => [number, number]) => {
  return ([_dataMin, dataMax]) => {
    // ASRS uses percentiles instead of standard score ranges.
    if (assessmentType === PatientAssessmentType.ASRS) {
      return [0, 100];
    }
    const topOfScoreRange =
      ASSESSMENT_SCORE_RANGES[assessmentType].slice(-1)[0];
    // If there is no top of the score range, then allow the range to expand to match the maximum
    // data point.
    return topOfScoreRange.endScore
      ? [0, topOfScoreRange.endScore]
      : [0, Math.max(topOfScoreRange.startScore, dataMax + 5)];
  };
};

/**
 * Returns the appropriate ticks for a recharts line chart based on the timestamps in the domain.
 * Ticks fall on month boundaries
 */
export const getLineChartXTicks = (domain: [number, number]): number[] => {
  const ticks: number[] = [];
  for (
    let current = moment(domain[0]).add(1, 'month').startOf('month');
    current.isSameOrBefore(domain[1]);
    current = current.add(1, 'month')
  ) {
    ticks.push(current.valueOf());
  }
  return ticks;
};

/**
 * Returns the presentational form of score for a given assessment type.
 */
export const getScoreRepresentationForAssessmentType = (
  score: number,
  assessmentType: PatientAssessmentType
): number => {
  if (assessmentType === PatientAssessmentType.ASRS) {
    return Math.floor(score);
  }
  if (assessmentType === PatientAssessmentType.WHODAS_2) {
    return parseFloat((Math.round(score * 100) / 100).toFixed(2));
  }
  return score;
};

/**
 * Returns true if the given assessment is type PHQ9 or PCL5 and safety risk is true
 */
export const indicatesSiRisk = (
  assessmentType: PatientAssessmentType,
  safetyRisk: boolean | null | undefined
): boolean => {
  return (
    !!safetyRisk &&
    (assessmentType === PatientAssessmentType.PHQ9 ||
      assessmentType === PatientAssessmentType.PCL5)
  );
};

/**
 * Returns true if the given assessment is type WHODAS 2.0 and safety risk is true
 */
export const indicatesDisabilityRisk = (
  assessmentType: PatientAssessmentType,
  safetyRisk: boolean | null | undefined
): boolean => {
  return !!safetyRisk && assessmentType === PatientAssessmentType.WHODAS_2;
};

/** Given a list of assessment types, returns the list sorted by NQF assessments if isProviderOptedIntoNQF, then alphabetically. */
export const getSortedAssessmentTypes = (
  assessmentTypeList: PatientAssessmentType[],
  isProviderOptedIntoNQF: boolean
): PatientAssessmentType[] => {
  return [...assessmentTypeList].sort().sort((a, b) => {
    if (isProviderOptedIntoNQF) {
      if (a === PatientAssessmentType.WHODAS_2) return -1;
      if (b === PatientAssessmentType.WHODAS_2) return 1;
      if (a === PatientAssessmentType.PROMIS) return -1;
      if (b === PatientAssessmentType.PROMIS) return 1;
      if (a === PatientAssessmentType.PHQ9) return -1;
      if (b === PatientAssessmentType.PHQ9) return 1;
      if (a === PatientAssessmentType.GAD7) return -1;
      if (b === PatientAssessmentType.GAD7) return 1;
      if (a === PatientAssessmentType.ANCHOR) return -1;
      if (b === PatientAssessmentType.ANCHOR) return 1;
    }
    return 0;
  });
};

/** Retrieve the correct assessment type associated with the primary diagnosis code from the appointment */
const targetGAD7Codes = ['F41', 'F41.0', 'F41.1', 'F41.3', 'F41.8', 'F41.9'];
const targetPCL5Codes = ['F43', 'F43.0', 'F43.1', 'F43.8', 'F43.9'];
const targetADNM8Codes = ['F43.2'];
export const getRelevantAssessmentTypeBasedOnDXCodes = (
  diagnosisCodes?: string[]
): PatientAssessmentType => {
  if (!diagnosisCodes || diagnosisCodes.length === 0)
    return PatientAssessmentType.PHQ9;

  if (targetGAD7Codes.includes(diagnosisCodes[0]))
    return PatientAssessmentType.GAD7;
  if (targetPCL5Codes.includes(diagnosisCodes[0]))
    return PatientAssessmentType.PCL5;
  if (targetADNM8Codes.includes(diagnosisCodes[0]))
    return PatientAssessmentType.ADNM8;

  return PatientAssessmentType.PHQ9;
};

/** Groups completed assessments by type, sorts each group by descending completed on date, then orders groups by most recent assessment */
export const groupAndSortAssessments = (
  assessments: PatientAssessmentRead[]
): PatientAssessmentRead[] => {
  if (assessments.some((assessment) => !assessment.completedOn)) {
    throw new Error(
      'Attempting to sort incomplete assessments by completion date'
    );
  }

  // Group assessments by type, then sort each group by completion date
  const groups = Object.values(groupBy(assessments, 'assessmentType')).map(
    (group) => orderBy(group, 'completedOn', 'desc')
  );

  // Sort groups by most recent assessment
  return orderBy(groups, (group) => group[0].completedOn, 'desc').flat();
};

const REQUIREMENT_PILOT_AUTO_SCHEDULES = [
  {
    assessmentType: PatientAssessmentType.PHQ9,
    cadence: PatientAssessmentRecurrenceCadence.EVERY_30_DAYS,
  },
  {
    assessmentType: PatientAssessmentType.GAD7,
    cadence: PatientAssessmentRecurrenceCadence.EVERY_30_DAYS,
  },
];

const isAutoCreatedPromSchedule = (
  schedule:
    | PatientAssessmentRecurrenceScheduleRead
    | PatientAssessmentPendingScheduleRead,
  autoSchedule: (typeof REQUIREMENT_PILOT_AUTO_SCHEDULES)[number]
) =>
  schedule.isHeadwayGenerated &&
  schedule.assessmentType === autoSchedule.assessmentType &&
  schedule.cadence === autoSchedule.cadence;

export const arePromsAutoScheduled = (
  assessmentSchedules?: PatientAssessmentRecurrenceScheduleReadAllResponse
) => {
  if (!assessmentSchedules) {
    return false;
  }

  const { current, pending } = assessmentSchedules;

  const hasAutoPendingSchedules = REQUIREMENT_PILOT_AUTO_SCHEDULES.every(
    (autoSchedule) =>
      pending.some((schedule) =>
        isAutoCreatedPromSchedule(schedule, autoSchedule)
      )
  );

  if (hasAutoPendingSchedules) {
    return true;
  }

  const hasNonAutoPendingSchedules = pending.length > 0;

  if (hasNonAutoPendingSchedules) {
    return false;
  }

  const hasAutoCurrentSchedules = REQUIREMENT_PILOT_AUTO_SCHEDULES.every(
    (autoSchedule) =>
      current.some(
        (schedule) =>
          isAutoCreatedPromSchedule(schedule, autoSchedule) &&
          !!schedule.triggeredBy
      )
  );

  return hasAutoCurrentSchedules;
};
