import * as Components from '.';
import { TemplateV1 } from '../Renderer/v1/types';
import { TemplateV2 } from '../Renderer/v2/types';
import { Component, TemplateV3 } from '../Renderer/v3/types';

export interface SchemaComponent {
  isOptional?: boolean;
  element: Component<unknown>;
  template?: TemplateV1<unknown> | TemplateV2<unknown> | TemplateV3<unknown>;
  disabled?: boolean;
  requiresEdit?: boolean;
}

export { Checkbox } from './Checkbox';
export { Checklist } from './Checklist';
export { FormRadio } from './Radio';
export { FormDate } from './Date';
export { FormSelect } from './Select';
export { FormComboBox } from './ComboBox';
export { FormDiagnosisSelect } from './Diagnosis';
export { FormTextField } from './TextField';
export { FormTextArea } from './TextArea';
export { FormTextAreaWithCheckbox } from './TextAreaWithCheckbox';
export { FormRichTextArea } from './RichTextArea';
export { InfoGuidanceCard } from './InfoGuidanceCard';
export { AISoapNote } from './AISoapNote';

export const ComponentMap = {
  checkbox: Components.Checkbox,
  checklist: Components.Checklist,
  radio: Components.FormRadio,
  date: Components.FormDate,
  dropdown: Components.FormSelect,
  dropdownMulti: Components.FormComboBox,
  dropdownDiagnosis: Components.FormDiagnosisSelect,
  longFreeText: Components.FormTextArea,
  longFreeTextWithCheckbox: Components.FormTextAreaWithCheckbox,
  shortFreeText: Components.FormTextField,
  richFreeText: Components.FormRichTextArea,
  infoGuidanceCard: Components.InfoGuidanceCard,
  aiSoapNote: Components.AISoapNote,
};

export type ComponentTypes = keyof typeof ComponentMap;
