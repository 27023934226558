import React, { useState } from 'react';

import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { UserRead } from '@headway/api/models/UserRead';
import { Badge } from '@headway/helix/Badge';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { trackPageView } from '@headway/shared/utils/analytics';
import { Tooltip } from '@headway/ui';

import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import {
  wasPatientInNoDataOutage,
  wasPatientInOldDataOutage,
} from 'utils/userInsuranceOutage';

import { PatientInsuranceStatusToConfigMap } from './utils/patientInsuranceStatus';

interface PatientInsuranceStatusLabelProps {
  providerId: number;
  insuranceStatus: PatientInsuranceOrEAPStatus;
  patientUser?: UserRead;
}

export const PatientInsuranceStatusLabel: React.FC<
  React.PropsWithChildren<PatientInsuranceStatusLabelProps>
> = ({ providerId, insuranceStatus, patientUser }) => {
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const { isMSCGuardrailWarning, isMSCGuardrailRestriction, restrictionDate } =
    useMSCGuardrail();
  const { chipLabel, badgeVariant, getExplanation } =
    PatientInsuranceStatusToConfigMap[insuranceStatus];
  const { data: matchingProviderFrontEndCarrier } =
    useMatchingProviderFrontEndCarrierQuery(
      {
        providerId,
        patientUserId: patientUser?.id,
        includeHiddenCarriers: true,
      },
      { enabled: !!patientUser?.id }
    );
  const isInNoDataOutage =
    insuranceStatus === PatientInsuranceOrEAPStatus.NO_DATA_OUTAGE;
  const isInOldDataOutage =
    insuranceStatus === PatientInsuranceOrEAPStatus.OLD_DATA_OUTAGE;
  const wasInNoDataOutage = wasPatientInNoDataOutage(
    patientUser?.activeUserInsurance,
    patientUser,
    providerId
  );
  const wasInOldDataOutage = wasPatientInOldDataOutage(
    patientUser?.activeUserInsurance,
    patientUser,
    providerId
  );
  const accumulatorsPresumedReset =
    patientUser?.activeUserInsurance?.latestOutage?.accumulatorsPresumedReset;
  const [open, setOpen] = useState<boolean>();

  return (
    <Tooltip
      title={getExplanation(
        patientUser,
        isMSCEnabled || isMSCGuardrailWarning || isMSCGuardrailRestriction,
        undefined,
        matchingProviderFrontEndCarrier?.frontEndCarrier.name,
        isMSCGuardrailWarning,
        restrictionDate
      )}
      open={open}
      onOpen={() => {
        setOpen(true);
        if (
          (isInNoDataOutage || isInOldDataOutage) &&
          patientUser &&
          patientUser.activeUserInsurance
        )
          trackPageView({
            name: 'Verification In Progress Banner Viewed',
            properties: {
              providerId: providerId,
              patientUserId: patientUser.id,
              userInsuranceId: `${patientUser.activeUserInsuranceId}`,
              prelimPricingType: `${patientUser.activeUserInsurance.latestOutageType}`,
              copyVariant: '',
              presumptiveAccumulatorsApplied: accumulatorsPresumedReset ?? [],
            },
          });
      }}
      onClose={() => setOpen(false)}
    >
      <div>
        <Badge
          variant={badgeVariant}
          icon={badgeVariant === 'negative' ? IconWarningCircle : undefined}
        >
          {(wasInNoDataOutage || wasInOldDataOutage) &&
          insuranceStatus ===
            PatientInsuranceOrEAPStatus.MATCHING_FAILED_VERIFICATION
            ? 'Ineligible'
            : chipLabel}
        </Badge>
      </div>
    </Tooltip>
  );
};
