import { DateValue, TimeFields } from '@internationalized/date';
import { Alert, Tooltip } from '@mui/material';
import { TimeValue } from '@react-types/datepicker';
import { useExistingVerificationRequest } from 'hooks';
import moment from 'moment';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SkeletonBlock } from '~/legacy/components/SkeletonBlock';

import { BillingType } from '@headway/api/models/BillingType';
import { CodingEnforcementErrorType } from '@headway/api/models/CodingEnforcementErrorType';
import { ControlledSubstance } from '@headway/api/models/ControlledSubstance';
import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { TelehealthPlatform } from '@headway/api/models/TelehealthPlatform';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { BodyText } from '@headway/helix/BodyText';
import { CaptionText } from '@headway/helix/CaptionText';
import { Checkbox } from '@headway/helix/Checkbox';
import { Item } from '@headway/helix/collections';
import { CurrencyField } from '@headway/helix/CurrencyField';
import { dateValueToMoment, momentToDateValue } from '@headway/helix/date';
import { DatePickerField } from '@headway/helix/DatePickerField';
import { GlossaryTerm } from '@headway/helix/GlossaryTerm';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconInfo } from '@headway/helix/icons/Info';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { Section, Select } from '@headway/helix/Select';
import { TextArea } from '@headway/helix/TextArea';
import { theme } from '@headway/helix/theme';
import { TimeField } from '@headway/helix/TimeField';
import {
  CONTROLLED_SUBSTANCE_DISPLAY_NAMES,
  MAX_DOSAGE,
} from '@headway/shared/constants/controlledSubstances';
import { CPTCodeInfo } from '@headway/shared/constants/cptCodes';
import {
  CONTROLLED_SUBSTANCE_DATA_COLLECTION,
  MULTI_STATE_CREDENTIALING_BETA,
  PRESCRIBER_PSYCHOTHERAPY_TIMES_CARRIERS,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { formatPatientName } from '@headway/shared/utils/patient';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';

import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { useSessionDetailsEditability } from 'hooks/useSessionDetailsEditability';
import { isPast } from 'views/Calendar/events/util/events';
import { useDocumentationRequirement } from 'views/Calendar/utils/documentationRequirement';

import { checkIfExactDurationIsRequiredForSelectedProgressNote } from '../../../hooks/validationSchemas/useSessionDetailsValidationSchema';
import { useRuleSet } from '../../../ruleset/useRuleSet';
import { AppointmentConfirmationContextV2 } from '../../../stores/AppointmentConfirmationContextV2';
import { CustomFormRow } from '../../CustomFormRow';
import { FormControlRHF } from '../../FormControlRHF';
import { AppointmentConfirmationModalFormV2Values } from '../../modals/AppointmentConfirmationModalV2';
import { ProgressNoteFormV2Values } from '../ProgressNote/ProgressNoteFormV2';
import { forceTypeBoolean } from '../utils';
import { ControlledSubstanceComboBoxV2 } from './components/ControlledSubstanceComboBoxV2';
import { CPTCodeComboBoxV2 } from './components/CptCodeComboBoxV2';
import { DiagnosisCodeComboBoxV2 } from './components/DiagnosisCodeComboBoxV2';
import { InsuranceStatusBannerForWarningV2 } from './components/InsuranceStatusBannerForWarningV2';
import { InsuranceStatusBannerV2 } from './components/InsuranceStatusBannerV2';
import { PatientAddressDropdownHelixV2 } from './components/PatientAddressDropdownHelixV2';
import { TelehealthLocationsInputsV2 } from './components/TelehealthLocationsInputsV2';
import { ValidationWarningAlert } from './components/ValidationWarningAlert';
import {
  isBillingAddOnPsychotherapy,
  isFormValueTelehealthAndBilledWithInsuranceOrEAP,
} from './utils';
import {
  CodingError,
  CodingErrorLocation,
  useMiscodingResultsQuery,
  ValidationWarning,
  ValidationWarningLocation,
} from './validation/miscoding';

export type SessionDetailsFormV2Props = {
  onOpenContactFormInsuranceIssues: () => void;
  variant?: 'normal' | 'sidesheet';
  scrollTo?: SupportedScrollToId;
};

export type SessionDetailsFormV2Values = {
  providerAddressId?: number | null;
  appointmentLocationPatientAddressId?: number | null;
  startDate: string;
  startDateTime?: string;
  endDate: string;
  duration?: string;
  exactStartTime?: TimeFields;
  exactEndTime?: TimeFields;
  billingType?: string;
  patientResponsibilityAmount?: number;
  cptCodes?: CPTCodeInfo[]; // Replace any[] with a specific type, if known
  diagnosisCodes?: any[]; // Replace any[] with a specific type, if known
  telehealth?: boolean;
  telehealthPlatform?: TelehealthPlatform;
  telehealthProviderState?: UnitedStates;
  telehealthAttestation?: boolean;
  didPrescribeControlledSubstance?: boolean;
  controlledSubstanceAttestation: {
    prescribedSubstances?: string[];
    notes?: string;
    exceedsRecommendedDosage?: boolean;
  };
  prescriberPsychotherapyStartTime?: TimeFields;
  prescriberPsychotherapyEndTime?: TimeFields;
};

export enum SupportedScrollToId {
  DIAGNOSIS_CODE = 'select-diagnosis-codes',
  LOCATION = 'location',
  PRESCRIPTION = 'prescription',
}

export const SessionDetailsFormV2 = ({
  onOpenContactFormInsuranceIssues,
  variant = 'normal',
  scrollTo,
}: SessionDetailsFormV2Props) => {
  const {
    provider,
    patient,
    providerPatient,
    progressNoteState,
    event,
    isEventLoading,
    isEventFetching,
    isDateEditable,
    isDocumentRemediationLoading,
  } = useContext(AppointmentConfirmationContextV2);

  const locationRef = useRef<HTMLDivElement>(null);
  const diagnosisRef = useRef<HTMLDivElement>(null);
  const prescriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollTo === SupportedScrollToId.LOCATION) {
      locationRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (scrollTo === SupportedScrollToId.DIAGNOSIS_CODE) {
      diagnosisRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (scrollTo === SupportedScrollToId.PRESCRIPTION) {
      prescriptionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [
    scrollTo,
    locationRef,
    diagnosisRef,
    prescriptionRef,
    isEventLoading,
    isEventFetching,
    isDocumentRemediationLoading,
  ]);

  const activeRule = useRuleSet({
    patient,
    provider,
    event,
    providerPatient,
    progressNoteState,
  });
  const { data: editabilityStatus } = useSessionDetailsEditability(
    event?.virtualId
  );
  const { setValue, trigger } =
    useFormContext<AppointmentConfirmationModalFormV2Values>();
  const sessionDetailsValues: SessionDetailsFormV2Values =
    useWatch<AppointmentConfirmationModalFormV2Values>({
      name: 'sessionDetails',
    });
  const progressNoteValues: ProgressNoteFormV2Values =
    useWatch<AppointmentConfirmationModalFormV2Values>({
      name: 'progressNote',
    });
  const [patientHasCignaEapAuthorization] = useState<boolean>(false);

  const { providerAddresses: allProviderAddresses } = useContext(
    ProviderAddressContext
  );
  const providerAddresses = allProviderAddresses.filter(
    (address) => address.providerId === provider?.id
  );
  const { isMSCGuardrailWarning, isMSCGuardrailRestriction } =
    useMSCGuardrail();

  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const isControlledSubstanceDataCollectionEnabled = useFlag(
    CONTROLLED_SUBSTANCE_DATA_COLLECTION,
    false
  );

  const prescriberPsychotherapyTimesCarriers = useFlag(
    PRESCRIBER_PSYCHOTHERAPY_TIMES_CARRIERS,
    []
  );

  const { isRequired: isDocumentationRequired } = useDocumentationRequirement(
    provider,
    patient,
    event?.providerAppointment
  );

  const { data: miscodingResults } = useMiscodingResultsQuery(
    sessionDetailsValues?.cptCodes || [],
    sessionDetailsValues?.diagnosisCodes || [],
    sessionDetailsValues?.exactStartTime,
    sessionDetailsValues?.exactEndTime,
    patient!,
    provider!,
    event!,
    sessionDetailsValues?.prescriberPsychotherapyStartTime,
    sessionDetailsValues?.prescriberPsychotherapyEndTime
  );

  const eventDatePart = moment(event?.startDate).format('YYYY-MM-DD');
  const fiveMinuteIntervals = useMemo(() => {
    const baseDate = moment(eventDatePart).startOf('day');
    return Array.from({ length: 288 }, (_, index) =>
      baseDate
        .clone()
        .add(index * 5, 'minutes')
        .toDate()
    );
  }, [eventDatePart]);

  const commonTimes = [15, 30, 45, 60];
  const allTimes = useMemo(() => {
    const commonTimes_ = [15, 30, 45, 60];
    const excludedTimes = new Set(commonTimes_);
    return Array.from({ length: 24 }, (_, idx) => (idx + 1) * 5).filter(
      (time) => !excludedTimes.has(time)
    );
  }, []);

  const { data: matchingProviderFrontEndCarrier } =
    useMatchingProviderFrontEndCarrierQuery(
      {
        providerId: provider?.id!,
        patientUserId: patient?.id!,
        appointmentId: event?.providerAppointment?.id,
      },
      { enabled: !!provider && !!patient }
    );

  const { data: hasExistingVerificationRequest } =
    useExistingVerificationRequest(patient!);

  const matchingCarrierId = matchingProviderFrontEndCarrier?.frontEndCarrierId;
  const isPrescriberPsychotherapyTimesEnabled =
    prescriberPsychotherapyTimesCarriers?.includes(matchingCarrierId);

  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const validationWarnings = miscodingResults?.warnings;
  const appointmentCodingErrors = miscodingResults?.errors;

  const canUpdateSessionDetails =
    editabilityStatus === SessionDetailsEditabilityStatus.ALLOWED;

  const shouldRequirePrescriberPsychotherapyTimes =
    isPrescriberPsychotherapyTimesEnabled &&
    provider?.isPrescriber &&
    isBillingAddOnPsychotherapy(
      sessionDetailsValues?.cptCodes?.map((code) => code.value) || []
    );

  // ensure psychotherapy time window is within appointment window by requiring exact appointment start and end time
  const isExactDurationRequired =
    !!isDocumentationRequired ||
    checkIfExactDurationIsRequiredForSelectedProgressNote(
      progressNoteValues?.progressNoteType,
      progressNoteValues?.template
    ) ||
    shouldRequirePrescriberPsychotherapyTimes;

  const conflictingSessionError =
    appointmentCodingErrors?.length! > 0
      ? appointmentCodingErrors?.filter(
          (error) => error.preferredLocation === 'SESSION_TIME'
        )
      : [];

  // Filter out overlapping conflicting session errors so it does not show in the diagnosis code errors
  // Filter out coding cardinality errors as they will show up in field level errors
  const cardinalityErrorTypes = [
    CodingEnforcementErrorType.NO_CPT_CODES,
    CodingEnforcementErrorType.NO_DIAGNOSIS_CODES,
  ];
  const filteredAppointmentCodingErrors = appointmentCodingErrors?.filter(
    (error) =>
      error.preferredLocation !== 'SESSION_TIME' &&
      !cardinalityErrorTypes.includes(error.type)
  );

  const shouldShowAppointmentCodingErrors =
    filteredAppointmentCodingErrors?.length! > 0 &&
    isPast(event!) &&
    (sessionDetailsValues?.cptCodes?.length ||
      sessionDetailsValues?.diagnosisCodes?.length);

  // If every coding error is related to CPT codes, then show it underneath the CPT code input; otherwise show it underneath the DX code input.
  const appointmentCodingErrorsLocation =
    filteredAppointmentCodingErrors?.every(
      (error) => error.preferredLocation === CodingErrorLocation.CPT_CODES
    )
      ? CodingErrorLocation.CPT_CODES
      : CodingErrorLocation.DIAGNOSIS_CODES;

  const getValidationWarningAlert = (
    validationWarning: ValidationWarning,
    key: number
  ) =>
    hasExistingVerificationRequest ? (
      <></>
    ) : (
      <ValidationWarningAlert
        onOpenContactFormInsuranceIssues={onOpenContactFormInsuranceIssues}
        validationWarning={validationWarning}
        id={key}
      />
    );

  if (isEventLoading || isEventFetching || isDocumentRemediationLoading)
    return <SessionDetailsFormV2Skeleton />;

  return (
    <>
      <ConflictingSessionErrorGuidanceCard
        conflictingSessionError={conflictingSessionError}
      />
      {hasExistingVerificationRequest &&
        validationWarnings?.filter((item) => item.showVerificationEntryPoint)
          .length! > 0 && (
          <ValidationAlert
            billingFrontEndCarrierName={
              patient?.activeUserInsurance?.billingFrontEndCarrierName
            }
          />
        )}
      {
        <div className="mt-8 flex flex-col gap-5">
          <CustomFormRow variant={variant}>
            <FormControlRHF
              disabled={!canUpdateSessionDetails || !isDateEditable}
              label="Date"
              name={'sessionDetails.startDate'}
              component={DatePickerField}
              onChange={(dateValue: DateValue) => {
                setValue(
                  'sessionDetails.startDate',
                  dateValueToMoment(dateValue).toISOString()
                );
              }}
              value={
                sessionDetailsValues.startDate
                  ? momentToDateValue(moment(sessionDetailsValues.startDate))
                  : undefined
              }
            />
            <FormControlRHF
              component={Select}
              name="sessionDetails.startDateTime"
              selectionMode="single"
              label={'Scheduled start time'}
              disabled={!canUpdateSessionDetails}
              menuWidth="stretch"
              selectedKeys={
                sessionDetailsValues.startDateTime
                  ? new Set([
                      moment(sessionDetailsValues.startDateTime).toISOString(),
                    ])
                  : new Set()
              }
              onSelectionChange={(selected) => {
                if (selected.size === 0) {
                  setValue('sessionDetails.startDateTime', undefined);
                } else {
                  const selectedDateTime = Array.isArray(selected)
                    ? moment(selected[0]).toISOString()
                    : moment(Array.from(selected)[0]).toISOString();
                  setValue('sessionDetails.startDate', selectedDateTime);
                  setValue('sessionDetails.startDateTime', selectedDateTime);
                }
                trigger('sessionDetails.startDateTime');
              }}
            >
              {fiveMinuteIntervals.map((interval) => {
                const intervalDate = moment(interval);
                const label = intervalDate.format('h:mma');
                const key = intervalDate.toISOString();

                return (
                  <Item key={key} textValue={label}>
                    {label}
                  </Item>
                );
              })}
            </FormControlRHF>
            <FormControlRHF
              name="sessionDetails.duration"
              disabled={!canUpdateSessionDetails}
              component={Select}
              selectionMode="single"
              label={'Scheduled duration'}
              menuWidth="stretch"
              selectedKeys={
                sessionDetailsValues.duration
                  ? new Set([sessionDetailsValues.duration])
                  : new Set()
              }
              onSelectionChange={(selected) => {
                const selectedValue =
                  selected instanceof Set && selected.size > 0
                    ? Array.from(selected)[0]
                    : '';
                setValue('sessionDetails.duration', selectedValue);
                trigger('sessionDetails.duration');
              }}
            >
              <Section title="Common times">
                {commonTimes.map((time) => (
                  <Item key={time.toString()} textValue={`${time} minutes`}>
                    {time} minutes
                  </Item>
                ))}
              </Section>
              <Section title="All times">
                {allTimes.map((time) => (
                  <Item key={time.toString()} textValue={`${time} minutes`}>
                    {time} minutes
                  </Item>
                ))}
              </Section>
            </FormControlRHF>
          </CustomFormRow>
          <div data-intercom-target="Exact Session Time">
            <CustomFormRow variant={variant}>
              <div
                data-testid="actualStartTime"
                css={{ width: '100%', position: 'relative' }}
              >
                <FormControlRHF
                  disabled={!canUpdateSessionDetails}
                  name="sessionDetails.exactStartTime"
                  component={TimeField}
                  label={'Actual start time'}
                  helpText="Enter the actual start time, not just what was booked. Eg 10:02 AM"
                  optionalityText={isExactDurationRequired ? '' : 'Optional'}
                  value={
                    sessionDetailsValues.exactStartTime as TimeValue | undefined
                  }
                />
                {canUpdateSessionDetails && (
                  <div
                    css={{
                      position: 'absolute',
                      top: '-3px',
                      ...(isExactDurationRequired
                        ? { left: '100px' }
                        : { left: '160px' }),
                    }}
                  >
                    <Tooltip
                      placement="bottom"
                      title="Start/stop times help ensure compliance with coding standards"
                      arrow
                    >
                      <div css={{ width: 'inherit', height: 'inherit' }}>
                        <IconInfo />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
              <div
                data-testid="actualStopTime"
                css={{ width: '100%', position: 'relative' }}
              >
                <FormControlRHF
                  disabled={!canUpdateSessionDetails}
                  name="sessionDetails.exactEndTime"
                  component={TimeField}
                  label={'Actual stop time'}
                  helpText="Enter the actual stop time, not just what was booked. Eg 10:58 AM"
                  optionalityText={isExactDurationRequired ? '' : 'Optional'}
                  value={
                    sessionDetailsValues.exactEndTime as TimeValue | undefined
                  }
                />
                {canUpdateSessionDetails && (
                  <div
                    css={{
                      position: 'absolute',
                      top: '-3px',
                      ...(isExactDurationRequired
                        ? { left: '100px' }
                        : { left: '160px' }),
                    }}
                  >
                    <Tooltip
                      placement="bottom"
                      title="Start/stop times help ensure compliance with coding standards"
                      arrow
                    >
                      <div css={{ width: 'inherit', height: 'inherit' }}>
                        <IconInfo />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
            </CustomFormRow>
          </div>
          <div ref={locationRef}>
            <FormControlRHF
              component={Select}
              disabled={!canUpdateSessionDetails}
              name="sessionDetails.providerAddressId"
              selectionMode="single"
              label={'Session Location'}
              menuWidth="stretch"
              helpText={
                "Remember to confirm the client's identity, location and physical safety at the start of the session"
              }
              onSelectionChange={(items: Set<string>) => {
                const selectedValue =
                  items.size > 0 ? parseInt(Array.from(items)[0]) : undefined;
                setValue('sessionDetails.providerAddressId', selectedValue);
                trigger('sessionDetails.providerAddressId');
              }}
              selectedKeys={
                sessionDetailsValues.providerAddressId
                  ? new Set([sessionDetailsValues.providerAddressId.toString()])
                  : new Set()
              }
            >
              {providerAddresses
                .filter((address) => !!address.streetLine1 && address.isActive)
                .map((address) => (
                  <Item
                    key={address.id}
                    textValue={`${address.streetLine1}, ${address.city}, ${address.state}`}
                  >
                    {address.streetLine1}, {address.city}, {address.state}
                  </Item>
                ))
                .concat(
                  <Item key={-1} textValue="Virtual (Telehealth)">
                    Virtual (Telehealth)
                  </Item>
                )}
            </FormControlRHF>
          </div>
          {isFormValueTelehealthAndBilledWithInsuranceOrEAP(
            sessionDetailsValues?.providerAddressId,
            sessionDetailsValues?.billingType
          ) && (
            <TelehealthLocationsInputsV2
              canUpdateSessionDetails={canUpdateSessionDetails}
            />
          )}
          {isFormValueTelehealthAndBilledWithInsuranceOrEAP(
            sessionDetailsValues?.providerAddressId,
            sessionDetailsValues?.billingType
          ) &&
            patient && (
              <PatientAddressDropdownHelixV2
                patient={patient}
                canUpdateSessionDetails={canUpdateSessionDetails}
              />
            )}

          {(isMSCEnabled || isMSCGuardrailRestriction) && (
            <InsuranceStatusBannerV2 />
          )}
          {isMSCGuardrailWarning && <InsuranceStatusBannerForWarningV2 />}
          {isFormValueTelehealthAndBilledWithInsuranceOrEAP(
            sessionDetailsValues?.providerAddressId,
            sessionDetailsValues?.billingType
          ) && (
            <FormControlRHF
              disabled={!canUpdateSessionDetails}
              name="sessionDetails.telehealthAttestation"
              component={Checkbox}
              checked={sessionDetailsValues.telehealthAttestation}
            >
              I have assessed that {patientFirstName} is in a safe, private, and
              known location.
            </FormControlRHF>
          )}
          {sessionDetailsValues?.billingType === BillingType.SELF_PAY && (
            <FormControlRHF
              disabled={!canUpdateSessionDetails}
              name="sessionDetails.patientResponsibilityAmount"
              label={'Session Rate'}
              component={CurrencyField}
            />
          )}
          <CPTCodeComboBoxV2
            name="sessionDetails.cptCodes"
            patient={patient}
            provider={provider}
            patientHasCignaEapAuthorization={patientHasCignaEapAuthorization}
            searchable
            selectionMode="multiple"
            disabled={!canUpdateSessionDetails}
          />
          {(isPrescriberPsychotherapyTimesEnabled &&
            provider?.isPrescriber &&
            isBillingAddOnPsychotherapy(
              sessionDetailsValues?.cptCodes?.map((code) => code.value) || []
            )) ||
            (activeRule?.isPsychotherapyDurationRequired() && (
              <CustomFormRow variant={variant}>
                <FormControlRHF
                  component={TimeField}
                  name="sessionDetails.prescriberPsychotherapyStartTime"
                  disabled={!canUpdateSessionDetails}
                  label={'Psychotherapy start time'}
                  helpText="When the psychotherapy portion of the session began"
                  value={
                    sessionDetailsValues.prescriberPsychotherapyStartTime as
                      | TimeValue
                      | undefined
                  }
                />
                <FormControlRHF
                  component={TimeField}
                  name="sessionDetails.prescriberPsychotherapyEndTime"
                  disabled={!canUpdateSessionDetails}
                  label={'Psychotherapy stop time'}
                  helpText="When the psychotherapy portion of the session ended"
                  value={
                    sessionDetailsValues.prescriberPsychotherapyEndTime as
                      | TimeValue
                      | undefined
                  }
                />
              </CustomFormRow>
            ))}
          {shouldShowAppointmentCodingErrors
            ? appointmentCodingErrorsLocation ===
                CodingErrorLocation.CPT_CODES &&
              filteredAppointmentCodingErrors && (
                <MiscodingErrorsGuidanceCard
                  errors={filteredAppointmentCodingErrors}
                />
              )
            : null}

          <div ref={diagnosisRef}>
            <DiagnosisCodeComboBoxV2
              helpText={
                'Select multiple if applicable, with the primary diagnosis selected first'
              }
              name="sessionDetails.diagnosisCodes"
              searchable
              selectionMode="multiple"
              disabled={!canUpdateSessionDetails}
            />
          </div>

          {validationWarnings
            ? validationWarnings
                .filter(
                  ({ location }) => location === ValidationWarningLocation.CODES
                )
                .map((warning, i) => getValidationWarningAlert(warning, i))
            : null}
          {shouldShowAppointmentCodingErrors
            ? appointmentCodingErrorsLocation ===
                CodingErrorLocation.DIAGNOSIS_CODES &&
              filteredAppointmentCodingErrors && (
                <MiscodingErrorsGuidanceCard
                  errors={filteredAppointmentCodingErrors}
                />
              )
            : null}
          {validationWarnings
            ? validationWarnings
                .filter(
                  ({ location }) =>
                    location === ValidationWarningLocation.SUBMIT
                )
                .map((warning, i) => getValidationWarningAlert(warning, i))
            : null}
          {isControlledSubstanceDataCollectionEnabled &&
            provider?.isPrescriber && (
              <div ref={prescriptionRef} className="flex flex-col gap-4">
                <FormControlRHF
                  component={RadioGroup}
                  registerOptions={{ setValueAs: forceTypeBoolean }}
                  disabled={!canUpdateSessionDetails}
                  name="sessionDetails.didPrescribeControlledSubstance"
                  label={
                    <>
                      Were any controlled substances prescribed for the client?
                      <span className="ml-1">
                        <GlossaryTerm term="Why Headway is asking for this">
                          To protect you, your patients, and Headway as a
                          network, collecting this information helps us ensure
                          compliant practices. It also allows us to identify
                          increased risk of scrutiny by the DEA so that we can
                          support you. This information is not a part of the
                          official patient medical record. All clinical care
                          provided must continue to be documented in your
                          session notes.
                        </GlossaryTerm>
                      </span>
                    </>
                  }
                  onChange={(value) => {
                    setValue(
                      'sessionDetails.didPrescribeControlledSubstance',
                      value === 'true' ? true : false
                    );
                  }}
                  value={String(
                    sessionDetailsValues?.didPrescribeControlledSubstance
                  )}
                >
                  <Radio value="false">No</Radio>
                  <Radio value="true">
                    Yes, the client received a prescription for a new substance,
                    had an updated dosage, or had their existing prescription
                    renewed/maintained this session (or since last confirmed
                    session)
                  </Radio>
                </FormControlRHF>
                {sessionDetailsValues?.didPrescribeControlledSubstance && (
                  <>
                    <ControlledSubstanceComboBoxV2
                      name="sessionDetails.controlledSubstanceAttestation.prescribedSubstances"
                      disabled={!canUpdateSessionDetails}
                      label="Which substance(s)?"
                    />
                    {!!sessionDetailsValues?.controlledSubstanceAttestation
                      ?.prescribedSubstances &&
                      sessionDetailsValues?.controlledSubstanceAttestation?.prescribedSubstances.filter(
                        (substance) => substance !== ControlledSubstance.OTHER
                      ).length > 0 && (
                        <div>
                          <CaptionText>
                            <strong>
                              Highest{' '}
                              <GlossaryTerm term="recommended dose">
                                <div>
                                  Dosage amounts shown are based on clinical
                                  review of existing FDA approved maximum daily
                                  dosages and expert consensus recommendations
                                  for management of neuropsychiatric conditions.
                                </div>
                                <br />
                                <div>
                                  Prescribing above the dosages indicated may
                                  increase risk of adverse events and should be
                                  done with caution, clinical reasoning, and
                                  informed concent appropriately documented.
                                </div>
                              </GlossaryTerm>{' '}
                              per day
                            </strong>
                          </CaptionText>
                          <ul className="hlx-typography-subbody mb-0 mt-1 pl-6">
                            {sessionDetailsValues?.controlledSubstanceAttestation?.prescribedSubstances
                              .filter(
                                (substance) =>
                                  substance !== ControlledSubstance.OTHER
                              )
                              .map((substance) => (
                                <li key={substance}>
                                  {
                                    CONTROLLED_SUBSTANCE_DISPLAY_NAMES[
                                      substance as Exclude<
                                        ControlledSubstance,
                                        ControlledSubstance.OTHER
                                      >
                                    ]
                                  }
                                  :{' '}
                                  {
                                    MAX_DOSAGE[
                                      substance as Exclude<
                                        ControlledSubstance,
                                        ControlledSubstance.OTHER
                                      >
                                    ]
                                  }
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    <FormControlRHF
                      component={RadioGroup}
                      registerOptions={{ setValueAs: forceTypeBoolean }}
                      name="sessionDetails.controlledSubstanceAttestation.exceedsRecommendedDosage"
                      disabled={!canUpdateSessionDetails}
                      label="Did the client receive a prescription of any controlled substance with a higher dose per day than the recommended amount?"
                      onChange={(value) => {
                        setValue(
                          'sessionDetails.controlledSubstanceAttestation.exceedsRecommendedDosage',
                          value === 'true' ? true : false
                        );
                      }}
                      value={String(
                        sessionDetailsValues?.controlledSubstanceAttestation
                          ?.exceedsRecommendedDosage
                      )}
                    >
                      <Radio value="false">No</Radio>
                      <Radio value="true">Yes (provide explanation)</Radio>
                    </FormControlRHF>
                    <FormControlRHF
                      name="sessionDetails.controlledSubstanceAttestation.notes"
                      disabled={!canUpdateSessionDetails}
                      label="Include frequency, dosage, and any other relevant notes for each substance"
                      component={TextArea}
                    />
                  </>
                )}
              </div>
            )}
        </div>
      }
    </>
  );
};

const MiscodingErrorsGuidanceCard = ({ errors }: { errors: CodingError[] }) => (
  <div>
    <GuidanceCard key={'error'} variant={'error'}>
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ ...theme.typography.list }}>
          Please fix the following{' '}
          {errors.length > 1 ? `${errors.length} errors` : 'error'}:
        </span>
        <ul>
          {errors.map((error, idx) => (
            <li key={idx}>{error.message}</li>
          ))}
        </ul>
      </div>
    </GuidanceCard>
  </div>
);

const SessionDetailsFormV2Skeleton = () => {
  return (
    <div role="status">
      <span className="sr-only">Loading...</span>
      <div className="mt-4 flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
        </div>
        <div className="flex flex-col gap-2">
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
        </div>
        <div className="flex flex-col gap-2">
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
          <SkeletonBlock className="hlx-typography-body h-[24px] w-full" />
        </div>
        <div className="border-1 -mx-5 flex items-center gap-3 border-0 border-y border-solid border-y-system-borderGray p-5">
          <SkeletonBlock className="aspect-square h-[24px] w-[24px] rounded-full" />
          <SkeletonBlock className="hlx-typography-body w-[13ch]" />
        </div>
      </div>
    </div>
  );
};

const ConflictingSessionErrorGuidanceCard = ({
  conflictingSessionError,
}: {
  conflictingSessionError: CodingError[] | undefined;
}) => {
  if (conflictingSessionError && conflictingSessionError.length > 0) {
    return (
      <div css={{ marginBottom: theme.spacing.x6 }}>
        <GuidanceCard variant="error">
          <BodyText>
            <strong>Conflicting session during this time: </strong>
            {conflictingSessionError[0].message}
          </BodyText>
        </GuidanceCard>
      </div>
    );
  } else {
    return null;
  }
};

const ValidationAlert = ({
  billingFrontEndCarrierName,
}: {
  billingFrontEndCarrierName: string | undefined;
}) => {
  if (!billingFrontEndCarrierName) return null;
  return (
    <Alert
      color="warning"
      severity="error"
      css={{ marginBottom: theme.spacing.x1 }}
    >
      We’re still verifying benefits related to this session. We suggest waiting
      to hear back from us before confirming session details in case{' '}
      {billingFrontEndCarrierName} denies the claim.
    </Alert>
  );
};
