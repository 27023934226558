import {
  CalendarDate,
  CalendarDateTime,
  DateValue,
  ZonedDateTime,
} from '@internationalized/date';
import React, { useCallback, useMemo } from 'react';
import {
  get,
  RegisterOptions,
  useController,
  useFormContext,
} from 'react-hook-form';
import { Simplify } from 'type-fest';
import { AppointmentConfirmationModalFormV2Values } from '~/legacy/views/AppointmentConfirmation/components/modals/AppointmentConfirmationModalV2';

import { getValidationProps } from './forms/ProgressNote/Template/utils';

type TextAreaSizingConstraint = {
  min?: number;
  max?: number;
};

type TextAreaSizing =
  | undefined
  | number
  | 'fill'
  | 'content'
  | TextAreaSizingConstraint;

interface FormControlCommonProps {
  name: any;
  disabled?: boolean;
  label?: React.ReactNode;
  placeholder?: string;
  optionalityText?: React.ReactNode;
  instructionalText?: React.ReactNode;
  helpText?: React.ReactNode;
  sizing?: Simplify<TextAreaSizing>;
  selectionMode?: 'single' | 'multiple';
  menuWidth?: 'small' | 'medium' | 'stretch';
  selectedKeys?: Set<any>;
  onSelectionChange?: (value: Set<any>) => void;
  checked?: boolean;
  searchable?: boolean;
  'aria-label'?: string;
  registerOptions?:
    | RegisterOptions<AppointmentConfirmationModalFormV2Values, any>
    | undefined;
}

interface FormControlRHFProps extends FormControlCommonProps {
  children?: React.ReactNode;
  component?: any;
  onChange?: (value: any) => void;
  value?: any;
}

const isDateValue = (value: any): value is DateValue => {
  return (
    value instanceof CalendarDate ||
    value instanceof CalendarDateTime ||
    value instanceof ZonedDateTime
  );
};

export const FormControlRHF: React.FC<FormControlRHFProps> = ({
  name,
  disabled = false,
  label,
  placeholder,
  optionalityText,
  instructionalText,
  helpText,
  sizing,
  selectionMode,
  menuWidth,
  selectedKeys,
  onSelectionChange,
  children,
  component: Component,
  'aria-label': ariaLabel,
  onChange: customOnChange,
  value: customValue,
  checked = false,
  searchable = false,
  registerOptions,
}) => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext<AppointmentConfirmationModalFormV2Values>();
  const { field } = useController({
    name,
    control,
  });

  const error = get(errors, name);
  const validation = useMemo(
    () => (error ? getValidationProps(error) : undefined),
    [error]
  );

  const commonProps = useMemo(
    () => ({
      id: name,
      name,
      disabled,
      label,
      placeholder,
      optionalityText,
      instructionalText,
      helpText,
      sizing,
      selectionMode,
      menuWidth,
      selectedKeys,
      onSelectionChange,
      checked,
      searchable,
      'aria-label': ariaLabel,
    }),
    [
      name,
      disabled,
      label,
      placeholder,
      optionalityText,
      instructionalText,
      helpText,
      sizing,
      selectionMode,
      menuWidth,
      selectedKeys,
      onSelectionChange,
      checked,
      searchable,
      ariaLabel,
    ]
  );

  const finalValue = useMemo(
    () => (customValue !== undefined ? customValue : field.value),
    [customValue, field.value]
  );

  const onChange = useCallback(
    (newValue: any) => {
      if (customOnChange) {
        customOnChange(newValue);
      }
      if (!isDateValue(newValue)) {
        setValue(name, newValue);
      }
      trigger(name);
    },
    [customOnChange, setValue, name, trigger]
  );

  const onBlur = useCallback(
    (event: React.FocusEvent<any>) => {
      if (field.onBlur) {
        field.onBlur();
      }
      if (registerOptions?.onBlur) {
        registerOptions.onBlur(event);
      }
    },
    [field, registerOptions]
  );

  const finalProps = useMemo(
    () => ({
      ...commonProps,
      onChange,
      onBlur,
      value: ariaLabel === 'RHF checkbox' ? undefined : finalValue,
      checked:
        ariaLabel === 'RHF checkbox' && typeof finalValue === 'string'
          ? finalValue.toLowerCase() === 'true'
          : finalValue,
      validation,
    }),
    [commonProps, onChange, finalValue, ariaLabel, validation, onBlur]
  );

  if (Component) {
    return (
      <Component {...register(name, registerOptions)} {...finalProps}>
        {children}
      </Component>
    );
  }

  if (children) {
    return <>{children}</>;
  }

  // If neither a component nor children are provided, render an error and an empty fallback
  console.error(
    `FormControlRHF: No "component" or "children" provided for field "${name}".`
  );
  return <></>;
};
