import deepEquals from 'react-fast-compare';
import * as Yup from 'yup';

import { getSessionDetailsValidationSchemaForBulkConfirm } from '../../AppointmentConfirmation/hooks/validationSchemas/useSessionDetailsValidationSchema';
import {
  BulkConfirmFormValues,
  BulkConfirmSubmissionStatus,
  SubmissionStatusMap,
} from './types';

/**
 * Creates a validation schema for the bulk confirm form. Each session shares a portion of the
 * validation logic from the single confirmation form.
 */
export const getBulkConfirmValidationSchema = (
  submissionStatusByEventVirtualId: SubmissionStatusMap,
  providerIsPrescriber: boolean,
  includeAttestations: boolean,
  lastSubmittedValues: BulkConfirmFormValues | undefined
) => {
  let schema = Yup.object<BulkConfirmFormValues>().shape({
    sessions: Yup.object().shape(
      Object.entries(submissionStatusByEventVirtualId).reduce(
        (acc, [virtualId, status]) => {
          if (
            status === BulkConfirmSubmissionStatus.UNSUBMITTED ||
            status === BulkConfirmSubmissionStatus.ERROR
          ) {
            acc[virtualId] = getSessionDetailsValidationSchemaForBulkConfirm(
              providerIsPrescriber,
              includeAttestations,
              `sessions.${virtualId}.`
            );
          }
          return acc;
        },
        {} as Record<string, Yup.ObjectSchema>
      )
    ),
    ...(includeAttestations
      ? {
          notesAttestation: Yup.boolean()
            .oneOf([true], 'Box must be checked to continue')
            .required(),
        }
      : {}),
  });
  if (lastSubmittedValues) {
    schema = schema.test({
      name: 'isChanged',
      test: (values) => {
        if (deepEquals(values, lastSubmittedValues)) {
          return new Yup.ValidationError(
            'No changes detected',
            values,
            'sessions'
          );
        }
        return true;
      },
    });
  }
  return schema;
};
