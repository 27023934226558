import { CreateUserInsuranceRequest } from '@headway/api/models/CreateUserInsuranceRequest';
import { UserInsuranceCreate } from '@headway/api/models/UserInsuranceCreate';
import { UserInsuranceApi } from '@headway/api/resources/UserInsuranceApi';
import { useMutation } from '@headway/shared/react-query';

export const getCreateUserInsuranceCacheKey = (userId: number) => [
  'create-user-insurance',
  userId,
];

export const useCreateUserInsurance = (userId: number, options = {}) => {
  return useMutation(
    getCreateUserInsuranceCacheKey(userId),
    (request: CreateUserInsuranceRequest) =>
      UserInsuranceApi.createUserInsurance(request),
    options
  );
};
