import { providerDocumentRemediations } from '~/legacy/hooks/useProviderDocumentRemediations';

import { DocumentRemediationStatus } from '@headway/api/models/DocumentRemediationStatus';
import { ProviderDocumentRemediationWithAuditResults } from '@headway/api/models/ProviderDocumentRemediationWithAuditResults';

import { Rule } from '../Rule';
import { DocumentationRemediationSessionDetailsBanner } from './DocumentationRemediationSessionDetailsBanner';

export class DocumentationRemediation extends Rule<
  [ProviderDocumentRemediationWithAuditResults[]]
> {
  addDataHooks() {
    return [
      async () =>
        await providerDocumentRemediations(
          {
            providerAppointmentId: this.event?.providerAppointment?.id,
          },
          Rule.queryClient
        ),
    ];
  }

  isRuleActive(): boolean {
    const hookData = this.getHookData();

    if (!hookData || !hookData[0] || hookData[0].length === 0) {
      return false;
    }

    const documentRemediation = hookData[0][0];

    return (
      documentRemediation?.status === DocumentRemediationStatus.NEEDS_REVIEW
    );
  }

  get disabledProgressNoteTypes() {
    return [];
  }

  get banners() {
    return {
      sessionDetails: {
        header: <DocumentationRemediationSessionDetailsBanner />,
      },
    };
  }

  isDocumentationRequired(): boolean {
    return true;
  }

  isInRemediationFlow(): boolean {
    return true;
  }
}
