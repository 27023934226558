/** Keys used for API calls via react-query. */

/** Key for /provider/{provider_id}/onboarding-modules */
export const ONBOARDING_MODULE_STATUSES_CACHE_KEY =
  'onboarding-module-statuses';

/** Key for /provider-style-tag */
export const PROVIDER_STYLE_TAGS_CACHE_KEY = 'provider-style-tags';

/** Key for /provider/{provider_id}/price/{user_id} */
export const PROVIDER_PRICE_CACHE_KEY = 'get-provider-price';

/** Key for /provider/{provider_id}/list-provider-supervising-physicians */
export const PROVIDER_SUPERVISING_PHYSICIANS_CACHE_KEY =
  'provider-supervising-physicians';
