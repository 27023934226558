import { providerDocumentRequests } from '~/legacy/hooks/useProviderDocumentRequests';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderDocumentRequestRead } from '@headway/api/models/ProviderDocumentRequestRead';

import { ProgressNoteState } from '../../stores/AppointmentConfirmationContextV2';
import { Rule } from '../Rule';
import { DocumentationRequestProgressNoteBanner } from './DocumentationRequestProgressNoteBanner';

export class DocumentationRequest extends Rule<
  [ProviderDocumentRequestRead[]]
> {
  addDataHooks() {
    return [
      async () =>
        await providerDocumentRequests(
          {
            providerAppointmentId: this.event.providerAppointment?.id,
          },
          Rule.queryClient
        ),
    ];
  }

  isRuleActive(): boolean {
    const hookData = this.getHookData();

    if (
      !hookData ||
      !hookData[0] ||
      hookData[0].length === 0 ||
      this.progressNoteState === ProgressNoteState.SIGNED ||
      this.progressNoteState === ProgressNoteState.ADDENDUM_EDITING
    ) {
      // We add the conditional for the progressNoteState because if it is SIGNED or ADDENDUM_EDITING
      // It means that there is already a note signed so the document request is an old one
      // That is not relevant anymore
      return false;
    }

    return true;
  }

  get disabledProgressNoteTypes() {
    return [ProgressNoteType.NONE];
  }

  get banners() {
    return {
      progressNote: {
        header: <DocumentationRequestProgressNoteBanner />,
      },
    };
  }

  isDocumentationRequired(): boolean {
    return true;
  }
}
