import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';
import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@headway/shared/react-query';

import { PaginatedConcreteProviderEventRead } from 'utils/types';

export type UseGetProviderEventsForCalendarQueryKeyArgs = NonNullable<
  Parameters<(typeof ProviderEventApi)['getEventsForCalendar']>[1]
> & { providerId: number };

export const getProviderEventsForCalendarQueryKey = (
  filters: UseGetProviderEventsForCalendarQueryKeyArgs
) => ['provider-event-api-get-provider-events-for-calendar', filters] as const;

/**
 * Returns whether the given query has a query key associated with useGetProviderEventsForCalendar.
 */
export const isGetProviderEventsForCalendarQueryKey = (
  queryKey: QueryKey
): queryKey is ReturnType<typeof getProviderEventsForCalendarQueryKey> =>
  queryKey[0] === 'provider-event-api-get-provider-events-for-calendar' &&
  !!queryKey[1];

const {
  useSingleQuery: useGetProviderEventsForCalendar,
  useCachedQuery: useGetProviderEventsForCalendarCache,
} = createBasicApiHooks(
  getProviderEventsForCalendarQueryKey,
  ({ providerId, ...filters }) =>
    ProviderEventApi.getEventsForCalendar(providerId, filters),
  ({ providerId }) => !!providerId,
  () => 'Failed to fetch provider events'
);

export {
  useGetProviderEventsForCalendar,
  useGetProviderEventsForCalendarCache,
};
