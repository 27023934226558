import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ProgressNoteFormV2Values } from '../../components/forms/ProgressNote/ProgressNoteFormV2';
import { getInitialTemplateValues } from '../../components/forms/ProgressNote/Template/utils';
import { getTemplate } from '../../components/forms/ProgressNote/utils';
import { AppointmentConfirmationContextV2 } from '../../stores/AppointmentConfirmationContextV2';

export const useProgressNoteInitialValues = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const {
    progressNote,
    progressNoteType,
    isEventLoading,
    isEventFetching,
    isProgressNoteLoading,
    isProgressNoteFetching,
    templates,
  } = useContext(AppointmentConfirmationContextV2);

  const isLoading =
    isEventLoading ||
    isEventFetching ||
    isProgressNoteLoading ||
    isProgressNoteFetching ||
    !progressNoteType ||
    !templates;

  const getTemplateDefaultValues = useCallback(
    (id?: number, version?: number, noteJsonResponse?: Record<string, any>) => {
      const template = getTemplate(templates, id, version);

      return template
        ? getInitialTemplateValues(template, noteJsonResponse)
        : noteJsonResponse;
    },
    [templates]
  );

  const initialValues: ProgressNoteFormV2Values = useMemo(() => {
    const noteJson = progressNote?.noteJson as {
      templateInfo?: { id: number; version: number };
      response?: Record<string, any>;
    };

    const initialTemplateValues = getTemplateDefaultValues(
      noteJson?.templateInfo?.id,
      noteJson?.templateInfo?.version,
      noteJson?.response
    );

    return {
      progressNoteType: progressNoteType,
      template: noteJson?.templateInfo
        ? `${noteJson.templateInfo.id}-${noteJson.templateInfo.version}`
        : undefined,
      previousNoteId: undefined,
      lateEntryReason: progressNote?.lateEntryReason ?? undefined,
      lateEntryOtherReason: progressNote?.lateEntryOtherReason ?? undefined,
      ...initialTemplateValues,
    };
  }, [progressNote, progressNoteType, getTemplateDefaultValues]);

  useEffect(() => {
    if (!isLoading && !isInitialized && initialValues) {
      setIsInitialized(true);
    }
  }, [isLoading, initialValues, isInitialized]);

  return {
    isInitialized: isInitialized,
    initialValues: initialValues,
    getTemplateDefaultValues,
  };
};
