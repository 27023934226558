import * as Yup from 'yup';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import {
  YUP_NPI_ERROR_MESSAGE,
  YUP_NPI_MATCH,
} from '@headway/shared/constants/format';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

import { useQuestionnaireContext } from '../../QuestionnaireV2Context';
import { RestrictedPracticeAuthority } from '../../utils/supervisingPhysicianConfig';
import {
  ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep,
  SupervisingPhysicianRequirement,
  SupervisingPhysicianStatusOption,
} from './helpers';

export const StateSupervisingPhysiciansForm = ({
  state,
  formValues,
  supervisingPhysicianRequirement,
  includeReuseFirstSupervisingPhysicianOption,
}: {
  state: UnitedStates;
  formValues: ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep;
  supervisingPhysicianRequirement: SupervisingPhysicianRequirement;
  includeReuseFirstSupervisingPhysicianOption: boolean;
}) => {
  const { provider } = useQuestionnaireContext();
  const formPrefix = `userInputtedSupervisingPhysicians[${state}]`;

  return (
    <div className="mb-7">
      <div className="my-1">
        <b>Collaborative Practice Agreement</b>
      </div>
      {Array.from({
        length: supervisingPhysicianRequirement.numberOfPhysiciansRequired,
      }).map((_, index) => (
        <SupervisingPhysicianForm
          key={index}
          index={index}
          state={state}
          formValues={formValues}
          includeReuseFirstSupervisingPhysicianOption={
            includeReuseFirstSupervisingPhysicianOption
          }
          supervisingPhysicianRequirement={supervisingPhysicianRequirement}
          provider={provider}
          formPrefix={formPrefix}
          /* TODO: This is a placeholder value. */
          isSubstitute={index === 1 ? true : false}
        />
      ))}
    </div>
  );
};

const SupervisingPhysicianForm = ({
  index,
  state,
  includeReuseFirstSupervisingPhysicianOption,
  supervisingPhysicianRequirement,
  provider,
  formPrefix,
  isSubstitute,
  formValues,
}: {
  index: number;
  state: UnitedStates;
  includeReuseFirstSupervisingPhysicianOption: boolean;
  supervisingPhysicianRequirement: SupervisingPhysicianRequirement;
  provider: ProviderRead;
  formPrefix: string;
  isSubstitute: boolean;
  formValues: ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep;
}) => {
  const hasSupervisingPhysician =
    formValues.userInputtedSupervisingPhysicians?.[state]?.[index]
      ?.hasSupervisingPhysician;
  const shouldCollectSupervisingPhysicianInfo =
    hasSupervisingPhysician === SupervisingPhysicianStatusOption.YES;

  return (
    <div key={index}>
      <FormControl
        name={`${formPrefix}[${index}]hasSupervisingPhysician`}
        component={RadioGroup}
        label={
          isSubstitute
            ? `${statesToDisplayNames[state]} requires two collaborating/supervising physicians. Do you have a second collaborating/supervising physician who is licensed in ${statesToDisplayNames[state]}?`
            : `Do you have a collaborating/supervising physician who is licensed in ${statesToDisplayNames[state]}?`
        }
      >
        {!isSubstitute && includeReuseFirstSupervisingPhysicianOption ? (
          <>
            <Radio value={SupervisingPhysicianStatusOption.REUSE_EXISTING}>
              Yes. I have the same collaborating/supervising physician in all my
              practice states.
            </Radio>
            <Radio value={SupervisingPhysicianStatusOption.YES}>
              Yes. I have a different collaborating/supervising physician in
              this state.
            </Radio>
          </>
        ) : (
          <Radio value={SupervisingPhysicianStatusOption.YES}>Yes</Radio>
        )}
        <Radio value={SupervisingPhysicianStatusOption.NO}>
          No. I would like help getting one.
        </Radio>
        {supervisingPhysicianRequirement.restrictedPracticeAuthority ===
          RestrictedPracticeAuthority.MAYBE && (
          <Radio value={SupervisingPhysicianStatusOption.EXEMPT}>
            <span>
              No. I am authorized to practice independently.{' '}
              <Link href="https://help.headway.co/hc/en-us/articles/34008057943572">
                Learn if I am eligible for this option
              </Link>
            </span>
          </Radio>
        )}
      </FormControl>
      <SupervisingPhysicianGuidanceCard
        hasSupervisingPhysician={hasSupervisingPhysician}
        providerEmail={provider.email}
      />
      {shouldCollectSupervisingPhysicianInfo && (
        <SupervisingPhysicianInfoForm
          isSubstitute={isSubstitute}
          formPrefix={`${formPrefix}[${index}]`}
        />
      )}
    </div>
  );
};

const SupervisingPhysicianGuidanceCard = ({
  hasSupervisingPhysician,
  providerEmail,
}: {
  hasSupervisingPhysician?: string;
  providerEmail?: string;
}) => {
  return (
    <div className="mt-3">
      {hasSupervisingPhysician === SupervisingPhysicianStatusOption.NO && (
        <GuidanceCard variant="info" layout="vertical">
          <div>
            No problem, we’ll email you at <b>{providerEmail}</b> with more
            information after you submit intake. We’ll start the credentialing
            process, but you won’t be able to schedule sessions until you get a
            collaborating/supervising physician who will sign our Collaborating
            Physician Agreement.
          </div>
        </GuidanceCard>
      )}
      {hasSupervisingPhysician === SupervisingPhysicianStatusOption.EXEMPT && (
        <GuidanceCard variant="warning" layout="vertical">
          [Before you’re credentialed, we’ll conduct a review to make sure you
          fit the exemption criteria, and reach out if we have any questions.]
        </GuidanceCard>
      )}
    </div>
  );
};

const SupervisingPhysicianInfoForm = ({
  isSubstitute,
  formPrefix,
}: {
  isSubstitute: boolean;
  formPrefix: string;
}) => {
  const getFieldName = (field: string) =>
    formPrefix + 'supervisingPhysician' + field;

  return (
    <div
      css={{
        ...theme.stack.vertical,
        gap: theme.spacing.x3,
      }}
    >
      <FormControl
        component={TextField}
        name={getFieldName('Npi')}
        label={
          <>
            What is your collaborating/supervising physician’s Type 1 NPI?{' '}
            <Link href="https://npiregistry.cms.hhs.gov/search">
              Look up an NPI here
            </Link>
          </>
        }
        type="number"
        css={{ marginBottom: theme.spacing.x3 }}
      />
      <FormControl
        component={TextField}
        name={getFieldName('Name')}
        label="Collaborating/supervising physician’s full name"
        css={{ marginBottom: theme.spacing.x3 }}
      />
      <FormControl
        component={TextField}
        name={getFieldName('Email')}
        label={
          <>
            <div>Collaborating/supervising physician’s email address</div>
            <div className="font-normal">
              We’ll email you and your collaborating/supervising physician our
              agreement to review and sign.{' '}
              <Link href="https://help.headway.co/hc/en-us/articles/34008057943572">
                Learn more
              </Link>
            </div>
          </>
        }
        type="email"
      />
    </div>
  );
};

export const getSupervisingPhysicianValidationSchema = () => {
  return Yup.array().of(
    Yup.object().shape({
      hasSupervisingPhysician: Yup.string().required('This field is required.'),
      supervisingPhysicianNpi: Yup.string().when('hasSupervisingPhysician', {
        is: SupervisingPhysicianStatusOption.YES,
        then: Yup.string()
          .matches(YUP_NPI_MATCH, YUP_NPI_ERROR_MESSAGE)
          .required('This field is required.'),
      }),
      supervisingPhysicianName: Yup.string().when('hasSupervisingPhysician', {
        is: SupervisingPhysicianStatusOption.YES,
        then: Yup.string().required('This field is required.'),
      }),
      supervisingPhysicianEmail: Yup.string().when('hasSupervisingPhysician', {
        is: SupervisingPhysicianStatusOption.YES,
        then: Yup.string()
          .required('This field is required.')
          .email('Invalid email address.'),
      }),
    })
  );
};
