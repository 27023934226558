import { UserEmployeeAssistanceProgramApi } from '@headway/api/resources/UserEmployeeAssistanceProgramApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

type FindUserEmployeeAssistanceProgramsQuery = Parameters<
  (typeof UserEmployeeAssistanceProgramApi)['findUserEmployeeAssistancePrograms']
>[0];
export interface UseUserEmployeeAssistanceProgramsQueryKeyArgs {
  query: FindUserEmployeeAssistanceProgramsQuery;
}

export const getUseUserEmployeeAssistanceProgramsQueryKey = ({
  query,
}: UseUserEmployeeAssistanceProgramsQueryKeyArgs): [
  string,
  FindUserEmployeeAssistanceProgramsQuery,
] => ['find-user-employee-assistance-programs', query];

const {
  useSingleQuery: useUserEmployeeAssistancePrograms,
  useListQuery: useUserEmployeeAssistanceProgramsList,
} = createBasicApiHooks(
  getUseUserEmployeeAssistanceProgramsQueryKey,
  ({ query }) =>
    UserEmployeeAssistanceProgramApi.findUserEmployeeAssistancePrograms(query),
  ({ query }) => !!query,
  () => 'Failed to fetch user assistance programs'
);

export {
  useUserEmployeeAssistancePrograms,
  useUserEmployeeAssistanceProgramsList,
};
