import range from 'lodash/range';

import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderSupervisingPhysicianCreate } from '@headway/api/models/ProviderSupervisingPhysicianCreate';
import { ProviderSupervisingPhysicianRead } from '@headway/api/models/ProviderSupervisingPhysicianRead';
import { ProviderSupervisingPhysicianUpdate } from '@headway/api/models/ProviderSupervisingPhysicianUpdate';
import { ProviderType } from '@headway/api/models/ProviderType';
import { SupervisingPhysicianStatus } from '@headway/api/models/SupervisingPhysicianStatus';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { logWarning } from '@headway/shared/utils/sentry';

import { NpiError } from '../../utils/npi';
import {
  NP_PRACTICE_AUTHORITY_BY_STATE,
  RestrictedPracticeAuthority,
} from '../../utils/supervisingPhysicianConfig';

export enum IDCertificationType {
  CDS = 'CDS',
  DEA = 'DEA',
}

export type NpiLookupResult = {
  npi: string;
  error?: NpiError;
  firstName?: string;
  lastName?: string;
  credential?: string;
};

export enum SupervisingPhysicianStatusOption {
  YES = SupervisingPhysicianStatus.YES,
  NO = SupervisingPhysicianStatus.NO,
  EXEMPT = SupervisingPhysicianStatus.EXEMPT,
  REUSE_EXISTING = 'REUSE_EXISTING',
}

export type ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep =
  ProviderQuestionnaireRawData & {
    // we don't want to store this in PQ raw data
    userInputtedSupervisingPhysicians?: {
      [key in UnitedStates]?: Array<{
        id?: number;
        hasSupervisingPhysician: SupervisingPhysicianStatusOption | '';
        supervisingPhysicianNpi: string;
        supervisingPhysicianName: string;
        supervisingPhysicianEmail: string;
      }>;
    };
  };

export type SupervisingPhysicianRequirement = {
  restrictedPracticeAuthority: RestrictedPracticeAuthority;
  numberOfPhysiciansRequired: number;
};

export const getSupervisingPhysicianRequirement = (
  state: UnitedStates,
  provider: ProviderRead,
  enableSupervisingPhysicians?: boolean // from feature flag
): SupervisingPhysicianRequirement => {
  if (
    enableSupervisingPhysicians &&
    provider.providerType === ProviderType.NURSE_PRACTITIONER
  ) {
    const restrictedPracticeAuthority = NP_PRACTICE_AUTHORITY_BY_STATE[state];
    const numberOfPhysiciansRequired =
      restrictedPracticeAuthority === RestrictedPracticeAuthority.FULL
        ? 0
        : state === UnitedStates.PENNSYLVANIA
        ? 2
        : 1;

    return {
      restrictedPracticeAuthority,
      numberOfPhysiciansRequired,
    };
  }

  // for non-NP providers, we don't need to collect supervising physician info
  return {
    restrictedPracticeAuthority: RestrictedPracticeAuthority.FULL,
    numberOfPhysiciansRequired: 0,
  };
};

export const removeSupervisingPhysicianValuesFromRawData = (
  formValues: ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep
) => {
  const newFormValues = { ...formValues };
  delete newFormValues.userInputtedSupervisingPhysicians;
  return newFormValues;
};

export const getHasSupervisingPhysicianStatus = (
  answer: SupervisingPhysicianStatusOption
): SupervisingPhysicianStatus => {
  return {
    [SupervisingPhysicianStatusOption.YES]: SupervisingPhysicianStatus.YES,
    [SupervisingPhysicianStatusOption.NO]: SupervisingPhysicianStatus.NO,
    [SupervisingPhysicianStatusOption.EXEMPT]:
      SupervisingPhysicianStatus.EXEMPT,
    [SupervisingPhysicianStatusOption.REUSE_EXISTING]:
      SupervisingPhysicianStatus.YES,
  }[answer];
};

export const getHasSupervisingPhysicianStatusOption = (
  answer: SupervisingPhysicianStatus
): SupervisingPhysicianStatusOption => {
  return {
    [SupervisingPhysicianStatus.YES]: SupervisingPhysicianStatusOption.YES,
    [SupervisingPhysicianStatus.NO]: SupervisingPhysicianStatusOption.NO,
    [SupervisingPhysicianStatus.EXEMPT]:
      SupervisingPhysicianStatusOption.EXEMPT,
  }[answer];
};

export const createSupervisingPhysicianPayloads = (
  userInputtedSupervisingPhysicians: ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep['userInputtedSupervisingPhysicians'],
  providerId: number,
  firstStateWithSupervisingPhysician?: UnitedStates // REUSE_EXISTING answers will copy this state
) => {
  const createPayload: ProviderSupervisingPhysicianCreate[] = [];
  const updatePayload: ProviderSupervisingPhysicianUpdate[] = [];

  const supervisingPhysicians = userInputtedSupervisingPhysicians || {};

  Object.entries(supervisingPhysicians).forEach(([state, answersForState]) => {
    answersForState.forEach((sp, index) => {
      const supervisorInfoToReuse =
        sp.hasSupervisingPhysician ===
          SupervisingPhysicianStatusOption.REUSE_EXISTING &&
        firstStateWithSupervisingPhysician !== undefined
          ? supervisingPhysicians[firstStateWithSupervisingPhysician]?.[0]
          : null;

      const supervisorNpi =
        supervisorInfoToReuse?.supervisingPhysicianNpi ||
        sp.supervisingPhysicianNpi;
      const supervisorName =
        supervisorInfoToReuse?.supervisingPhysicianName ||
        sp.supervisingPhysicianName;
      const supervisorEmailAddress =
        supervisorInfoToReuse?.supervisingPhysicianEmail ||
        sp.supervisingPhysicianEmail;

      const baseItem = {
        state: state as UnitedStates,
        supervisorNpi,
        supervisorName,
        supervisorEmailAddress,
        hasSupervisingPhysician: getHasSupervisingPhysicianStatus(
          sp.hasSupervisingPhysician as SupervisingPhysicianStatusOption
        ),
        isSubstitute: index > 0,
      };

      if (sp.id) {
        updatePayload.push({
          ...baseItem,
          id: sp.id,
        });
      } else {
        createPayload.push({ ...baseItem, providerId });
      }
    });
  });
  return { createPayload, updatePayload };
};

export const getSupervisingPhysicianInitialValues = (
  supervisingPhysicians: ProviderSupervisingPhysicianRead[],
  provider: ProviderRead,
  providerStates: UnitedStates[],
  enableSupervisingPhysicians?: boolean // from feature flag
) => {
  const existingSupervisingPhysiciansByState = supervisingPhysicians?.reduce(
    (acc, sp) => {
      if (!acc[sp.state]) {
        acc[sp.state] = [];
      }
      acc[sp.state].push(sp);
      return acc;
    },
    {} as Record<UnitedStates, ProviderSupervisingPhysicianRead[]>
  );

  const supervisingPhysicianInitialValues = {} as NonNullable<
    ProviderQuestionnaireRawDataExtendedForPrescriberRequirementsStep['userInputtedSupervisingPhysicians']
  >;
  let firstExistingSupervisingPhysician: ProviderSupervisingPhysicianRead;
  providerStates.forEach((state) => {
    const supervisingPhysicianRequirement = getSupervisingPhysicianRequirement(
      state,
      provider,
      enableSupervisingPhysicians
    );

    if (
      !supervisingPhysicianInitialValues[state]?.length &&
      supervisingPhysicianRequirement.numberOfPhysiciansRequired > 0
    ) {
      const existingSupervisingPhysicians =
        existingSupervisingPhysiciansByState?.[state];

      if (existingSupervisingPhysicians?.length) {
        const primarySupervisingPhysician = existingSupervisingPhysicians.find(
          (sp) => !sp.isSubstitute
        );

        if (!primarySupervisingPhysician) {
          logWarning(
            `State ${state} has only substitute supervising physicians, which should never happen`
          );
          return;
        }

        // if a subsequent state reuses the first existing supervising physician, we should
        // select the 'reuse existing' option
        let shouldSelectReuseExistingOption = false;

        if (!firstExistingSupervisingPhysician) {
          if (
            existingSupervisingPhysicians[0].hasSupervisingPhysician ===
            SupervisingPhysicianStatus.YES
          ) {
            firstExistingSupervisingPhysician =
              existingSupervisingPhysicians[0];
          }
        } else {
          shouldSelectReuseExistingOption =
            firstExistingSupervisingPhysician.supervisorNpi ===
              primarySupervisingPhysician?.supervisorNpi &&
            firstExistingSupervisingPhysician.supervisorName ===
              primarySupervisingPhysician?.supervisorName &&
            firstExistingSupervisingPhysician.supervisorEmailAddress ===
              primarySupervisingPhysician?.supervisorEmailAddress;
        }

        supervisingPhysicianInitialValues[state] = [
          // in the form, primary supervising physician should come before any substitutes
          {
            id: primarySupervisingPhysician.id,
            hasSupervisingPhysician: shouldSelectReuseExistingOption
              ? SupervisingPhysicianStatusOption.REUSE_EXISTING
              : getHasSupervisingPhysicianStatusOption(
                  primarySupervisingPhysician.hasSupervisingPhysician
                ),
            supervisingPhysicianNpi:
              primarySupervisingPhysician.supervisorNpi ?? '',
            supervisingPhysicianName:
              primarySupervisingPhysician.supervisorName ?? '',
            supervisingPhysicianEmail:
              primarySupervisingPhysician.supervisorEmailAddress ?? '',
          },
          ...existingSupervisingPhysicians
            .filter((sp) => sp.isSubstitute)
            .map((sp) => ({
              id: sp.id,
              hasSupervisingPhysician: getHasSupervisingPhysicianStatusOption(
                sp.hasSupervisingPhysician
              ),
              supervisingPhysicianNpi: sp.supervisorNpi ?? '',
              supervisingPhysicianName: sp.supervisorName ?? '',
              supervisingPhysicianEmail: sp.supervisorEmailAddress ?? '',
            })),
        ];
      } else {
        supervisingPhysicianInitialValues[state] = Array.from(
          {
            length: supervisingPhysicianRequirement.numberOfPhysiciansRequired,
          },
          () => ({
            hasSupervisingPhysician: '',
            supervisingPhysicianNpi: '',
            supervisingPhysicianName: '',
            supervisingPhysicianEmail: '',
          })
        );
      }
    }
    const currentValuesForState = supervisingPhysicianInitialValues[state];
    if (
      currentValuesForState &&
      supervisingPhysicianRequirement.numberOfPhysiciansRequired >
        currentValuesForState.length
    ) {
      // if the number of supervising physicians required has increased, we need to add more
      range(
        currentValuesForState.length,
        supervisingPhysicianRequirement.numberOfPhysiciansRequired
      ).forEach(() => {
        supervisingPhysicianInitialValues[state]?.push({
          hasSupervisingPhysician: '',
          supervisingPhysicianNpi: '',
          supervisingPhysicianName: '',
          supervisingPhysicianEmail: '',
        });
      });
    }
  });
  return supervisingPhysicianInitialValues;
};
