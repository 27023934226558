/* eslint-disable @typescript-eslint/naming-convention */
export enum AssessmentScheduleStartDateType {
  ExactDate = 'EXACT_DATE',
  BeforeFirstSession = 'BEFORE_FIRST_SESSION',
}
/* eslint-enable @typescript-eslint/naming-convention */

export const ASSESSMENT_SCHEDULE_START_DATE_TYPE_DISPLAY_NAMES: Record<
  AssessmentScheduleStartDateType,
  string
> = {
  [AssessmentScheduleStartDateType.ExactDate]: 'On exact date',
  [AssessmentScheduleStartDateType.BeforeFirstSession]:
    '2 days before first session',
};
