import { useContext, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRuleSet } from '~/legacy/views/AppointmentConfirmation/ruleset/useRuleSet';
import { AppointmentConfirmationContextV2 } from '~/legacy/views/AppointmentConfirmation/stores/AppointmentConfirmationContextV2';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderProgressNoteLateEntryReason } from '@headway/api/models/ProviderProgressNoteLateEntryReason';
import { Banner } from '@headway/helix/Banner';
import { Link } from '@headway/helix/Link';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { lateEntryReasonDisplayNames } from '@headway/shared/constants/lateEntryReasonDisplayNames';

import { FormControlRHF } from '../../../FormControlRHF';
import { AppointmentConfirmationModalFormV2Values } from '../../../modals/AppointmentConfirmationModalV2';

type ProgressNoteLateEntryInputV2Props = {
  disabled: boolean;
  showBanner: boolean;
  isMedicareOrMedicaid: boolean;
};

const UNAVAILABLE_LATE_ENTRY_REASONS = new Set([
  // some late entry reasons should not be an option for newly-submitted notes,
  // but we still need to support them if previously-submitted notes are
  // accessed, so we can't remove them completely
  ProviderProgressNoteLateEntryReason.OTHER,
]);

const compareLateEntryReasonDisplayNames = (
  reason1: ProviderProgressNoteLateEntryReason,
  reason2: ProviderProgressNoteLateEntryReason
) =>
  lateEntryReasonDisplayNames[reason1].localeCompare(
    lateEntryReasonDisplayNames[reason2]
  );

function LateEntryBanner({
  isMedicareOrMedicaid,
  noteSavedElsewhereIsAllowed,
}: {
  isMedicareOrMedicaid: boolean;
  noteSavedElsewhereIsAllowed: boolean;
}) {
  const { patient, provider, event, providerPatient, progressNoteState } =
    useContext(AppointmentConfirmationContextV2);
  const activeRule = useRuleSet({
    patient,
    provider,
    event,
    providerPatient,
    progressNoteState,
  });
  return (
    <Banner variant="warning">
      {isMedicareOrMedicaid
        ? `Medicare and Medicaid notes must be signed within 48 hours`
        : `Notes must be signed within 72 hours`}{' '}
      of each session to meet compliance standards. Over time, late notes can
      lead to payment delays or additional claim reviews, though it’s better to
      sign late than not sign a note at all.{' '}
      <Link
        href="https://help.headway.co/hc/en-us/articles/25175689760532-Compliance-deadlines-for-clinical-documentation-and-why-it-matters"
        target="_blank"
        rel="noreferrer"
      >
        Learn more about note deadlines.
      </Link>
      {noteSavedElsewhereIsAllowed &&
        !activeRule?.isDocumentationRequired() && (
          <p css={{ marginTop: theme.spacing.x6 }}>
            Already signed your note somewhere else? Switch to “My note is saved
            elsewhere” above.
          </p>
        )}
    </Banner>
  );
}

function LateEntryOtherReasonInput({ disabled }: { disabled: boolean }) {
  return (
    <FormControlRHF
      name={'progressNote.lateEntryOtherReason'}
      disabled={disabled}
      component={TextField}
      label="Describe your reason for late submission"
      helpText="This will be included in your signed note"
    />
  );
}

export function ProgressNoteLateEntryInputV2({
  disabled,
  showBanner,
  isMedicareOrMedicaid,
}: ProgressNoteLateEntryInputV2Props) {
  const { patient, provider, providerPatient, progressNoteState, event } =
    useContext(AppointmentConfirmationContextV2);
  const activeRule = useRuleSet({
    patient,
    provider,
    event,
    providerPatient,
    progressNoteState,
  });
  const { setValue, trigger } =
    useFormContext<AppointmentConfirmationModalFormV2Values>();
  const lateEntryReason = useWatch({
    name: 'progressNote.lateEntryReason',
  });

  const showOtherInput =
    lateEntryReason === ProviderProgressNoteLateEntryReason.OTHER;

  const reasonOptions = useMemo(() => {
    const availableReasons = new Set(
      Object.values(ProviderProgressNoteLateEntryReason).filter(
        (reason) => !UNAVAILABLE_LATE_ENTRY_REASONS.has(reason)
      )
    );

    // ensure selected option still renders even if it's no longer available
    if (
      lateEntryReason &&
      lateEntryReason in ProviderProgressNoteLateEntryReason
    ) {
      availableReasons.add(lateEntryReason);
    }

    return [...availableReasons].sort(compareLateEntryReasonDisplayNames);
  }, [lateEntryReason]);

  return (
    <>
      {showBanner && (
        <LateEntryBanner
          isMedicareOrMedicaid={isMedicareOrMedicaid}
          noteSavedElsewhereIsAllowed={
            !!activeRule?.disabledProgressNoteTypes.includes(
              ProgressNoteType.NONE
            )
          }
        />
      )}
      <FormControlRHF
        name={'progressNote.lateEntryReason'}
        disabled={disabled}
        component={Select}
        label="Reason for late submission"
        selectionMode="single"
        menuWidth="stretch"
        selectedKeys={lateEntryReason ? new Set([lateEntryReason]) : new Set()}
        onSelectionChange={(keys) => {
          setValue(
            'progressNote.lateEntryReason',
            keys.size > 0 ? keys.values().next().value : undefined
          );
          trigger('progressNote.lateEntryReason');
        }}
      >
        {reasonOptions.map((reason) => (
          <Item key={reason}>{lateEntryReasonDisplayNames[reason]}</Item>
        ))}
      </FormControlRHF>
      {showOtherInput && <LateEntryOtherReasonInput disabled={disabled} />}
    </>
  );
}
