import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAuthStore } from '~/legacy/stores/AuthStore';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { LDFlagSet } from '@headway/shared/FeatureFlags/react';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';

import { AppointmentConfirmationModalFormV2Values } from '../components/modals/AppointmentConfirmationModalV2';
import { ProgressNoteState } from '../stores/AppointmentConfirmationContextV2';
import { Rule } from './Rule';
import { RulesInPriorityOrder } from './RulePriority';

const getEnabledRules = (
  accessingUser: UserRead | undefined,
  provider: ProviderRead | undefined,
  patient: UserRead | undefined,
  event: ProviderEventRead | undefined,
  formValues: AppointmentConfirmationModalFormV2Values | undefined,
  progressNoteState: ProgressNoteState | undefined,
  providerPatient: ProviderPatientRead | undefined,
  flags: LDFlagSet | undefined,
  carriersById: { [index: number]: FrontEndCarrierRead } | undefined
) => {
  if (
    !provider ||
    !patient ||
    !providerPatient ||
    !event ||
    !accessingUser ||
    !formValues ||
    progressNoteState === undefined ||
    !flags ||
    !carriersById
  ) {
    return [];
  }

  return RulesInPriorityOrder.filter((rule) => rule.enabled).map((Rule) => {
    return new Rule(
      accessingUser,
      provider,
      patient,
      event,
      formValues,
      progressNoteState,
      providerPatient,
      flags,
      carriersById
    );
  });
};

export const useRuleSet = ({
  patient,
  provider,
  event,
  providerPatient,
  progressNoteState,
}: {
  patient?: UserRead;
  provider?: ProviderRead;
  event?: ProviderEventRead;
  providerPatient?: ProviderPatientRead;
  progressNoteState?: ProgressNoteState;
}): Rule<unknown[]> | undefined => {
  const flags = useLDFlags();
  const { carriersById } = useFrontEndCarriers();
  const { user } = useAuthStore();
  const { control } = useFormContext();
  const formValues = useWatch({
    control,
  }) as AppointmentConfirmationModalFormV2Values;

  const [enabledRules, setEnabledRules] = useState<Rule<unknown[]>[]>(
    getEnabledRules(
      user,
      provider,
      patient,
      event,
      formValues,
      progressNoteState,
      providerPatient,
      flags,
      carriersById
    ) ?? []
  );
  const [activeRule, setActiveRule] = useState<Rule<unknown[]> | undefined>();

  /**
   * In order to not cause a rerender whenever the template responses are updated,
   * we remove it from the form values.
   */
  const minifiedFormValues = formValues?.progressNote
    ? {
        ...formValues,
        progressNote: {
          template: formValues.progressNote.template,
          progressNoteType: formValues.progressNote.progressNoteType,
          previousNoteId: formValues.progressNote.previousNoteId,
          lateEntryReason: formValues.progressNote.lateEntryReason,
          lateEntryOtherReason: formValues.progressNote.lateEntryOtherReason,
        },
      }
    : formValues;
  const stringifiedMinifiedFormValues = JSON.stringify(minifiedFormValues);

  /**
   * Re-initializes the enabled rules when the provider, patient, or event changes.
   */
  useEffect(() => {
    setEnabledRules(
      getEnabledRules(
        user,
        provider,
        patient,
        event,
        minifiedFormValues,
        progressNoteState,
        providerPatient,
        flags,
        carriersById
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    provider,
    patient,
    event,
    progressNoteState,
    providerPatient,
    flags,
    carriersById,
    stringifiedMinifiedFormValues, // Stringified to make sure the effect reruns on data changes,
  ]);

  /**
   * When hook data or flags are updated, re-computes the active rule.
   * Filters the rules by the flags and then sets the updated hook data for each rule.
   *
   * @Returns The first rule that is active in the priority order.
   */
  useEffect(() => {
    const fetchDataAndFindActiveRule = async () => {
      // Collect and resolve all hook data for all enabled rules
      const dataHooks = await Promise.all(
        enabledRules.map(async (rule) => {
          // Resolve all hooks for this enabled rule
          const hooks = await Promise.all(
            rule.addDataHooks().map((hook) => hook.apply(rule))
          );
          return hooks; // Return the resolved hooks
        })
      );

      // Assign resolved data to each rule
      enabledRules.map((rule, idx) => {
        return rule.setHookData(dataHooks[idx] as [unknown]);
      });

      // Find and set the active rule
      setActiveRule(enabledRules.find((rule) => rule.isRuleActive()));
    };

    fetchDataAndFindActiveRule();
  }, [enabledRules]);

  return activeRule;
};
