import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';

import { SessionDetailsFormV2Values } from '../../AppointmentConfirmation/components/forms/SessionDetails/SessionDetailsFormV2';

export interface ProviderNameData {
  id: number;
  displayFirstName?: string;
  displayLastName?: string;
  prenomial?: string;
}

export enum BulkConfirmSubmissionStatus {
  UNSUBMITTED = 'UNSUBMITTED',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type SubmissionStatusMap = Record<string, BulkConfirmSubmissionStatus>;

/**
 * Dates are optional in the bulk confirm form because we display blank dates for newly-scheduled events.
 */
export type SessionDetailsFormV2ValuesWithOptionalDate = Omit<
  SessionDetailsFormV2Values,
  'startDate' | 'endDate'
> & {
  startDate?: SessionDetailsFormV2Values['startDate'];
  endDate?: SessionDetailsFormV2Values['endDate'];
};

export interface BulkConfirmFormValues {
  sessions: { [virtualId: string]: SessionDetailsFormV2ValuesWithOptionalDate };
  notesAttestation: boolean;
}

export type BulkConfirmEventsData = {
  [key: ProviderEventRead['virtualId']]: {
    event: ProviderEventRead;
    providerAddresses?: ProviderAddressRead[];
    patientAddresses?: PatientAddressRead[];
  };
};
