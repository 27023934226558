import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePatientAddresses } from '~/legacy/hooks/usePatientAddresses';
import { useProviderAppointmentAddendums } from '~/legacy/hooks/useProviderAppointmentAddendums';
import { useProviderDocumentRemediations } from '~/legacy/hooks/useProviderDocumentRemediations';
import { useProviderEvent } from '~/legacy/hooks/useProviderEvent';
import { useProviderPatient } from '~/legacy/hooks/useProviderPatient';
import { useAuthStore } from '~/legacy/stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from '~/legacy/utils/access';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderAppointmentAddendumRead } from '@headway/api/models/ProviderAppointmentAddendumRead';
import { ProviderDocumentRemediationWithAuditResults } from '@headway/api/models/ProviderDocumentRemediationWithAuditResults';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderProgressNoteRead } from '@headway/api/models/ProviderProgressNoteRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { TemplateError } from '@headway/api/models/TemplateError';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import {
  ConfirmSessionButtonClickedEvent,
  SignAndSubmitAddendumButtonClickedEvent,
  SignNoteButtonClickedEvent,
} from '@headway/avo';
import { abbreviationToStateEnum } from '@headway/shared/constants/unitedStatesDisplayNames';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { trackEvent } from '@headway/shared/utils/analytics';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';

import { isDetailsConfirmed } from '../../Calendar/events/util/events';
import {
  GenericTemplate,
  NoteJson,
} from '../components/forms/ProgressNote/Template/Renderer/types';
import { getTemplates } from '../components/forms/ProgressNote/Template/utils';
import { ProgressNoteComponentMetadata } from '../components/forms/ProgressNote/types';
import { isSessionDetailsFormValueTelehealth } from '../components/forms/SessionDetails/utils';
import { useValidProviderProgressNote } from '../hooks/utils/useValidProviderProgressNote';

export enum ProgressNoteState {
  EDITING,
  SAVED_FOR_LATER,
  SIGNED,
  ADDENDUM_EDITING,
}

export type EventTrackingName =
  | 'Confirm Session Button Clicked'
  | 'Modify Session Button Clicked'
  | 'Sign Note Button Clicked'
  | 'Progress Note Submit Addendum Button Clicked'
  | 'Save and Close Button Clicked'
  | 'Save and Complete Later Button Clicked'
  | 'Sign Note Button Clicked'
  | 'Sign and Submit Addendum Button Clicked';

export interface AppointmentConfirmationContextV2Type {
  // event - appointment
  event: ProviderEventRead | undefined;
  setEvent: (event: ProviderEventRead | undefined) => void;
  eventVirtualId: string | number | undefined;
  isEventLoading: boolean;
  isEventFetching: boolean;
  // progress note
  progressNote: ProviderProgressNoteRead | undefined;
  setProgressNote: (progressNote: ProviderProgressNoteRead | undefined) => void;
  progressNoteState: ProgressNoteState | undefined;
  progressNoteType: ProgressNoteType | undefined;
  setProgressNoteType: (progressNoteType: ProgressNoteType | undefined) => void;
  isProgressNoteLoading: boolean;
  isProgressNoteFetching: boolean;
  progressNoteErrorsFromSubmitting: TemplateError[] | undefined;
  setProgressNoteErrorsFromSubmitting: (
    errors: TemplateError[] | undefined
  ) => void;
  templates: GenericTemplate<ProgressNoteComponentMetadata>[] | undefined;
  previousNoteInitialValues: Record<string, any> | undefined;
  setPreviousNoteInitialValues: (
    previousNoteInitialValues: Record<string, any> | undefined
  ) => void;
  // patient and provider
  patient: UserRead | undefined;
  provider: ProviderRead | undefined;
  providerPatient: ProviderPatientRead | undefined;
  isGroupAdminImpersonating: boolean;
  // remediation - feedback
  documentRemediation: ProviderDocumentRemediationWithAuditResults | undefined;
  isDocumentRemediationLoading: boolean;
  // addendums
  addendums: ProviderAppointmentAddendumRead[] | undefined;
  areAddendumsLoading: boolean;
  // progress note state logic
  switchToEditProgressNote(): void;
  switchToEditAddendum(): void;
  switchToNoteSigned(): void;
  switchToNoteSavedAsDraft(): void;
  // addresses related state and logic
  appointmentAddressState: UnitedStates | undefined;
  updateAppointmentAddressState: (
    providerAddressId?: number | undefined | null,
    appointmentLocationPatientAddressId?: number | undefined | null
  ) => void;
  // form state
  isAutoSaving: boolean;
  setIsAutoSaving: (isAutoSaving: boolean) => void;
  isDateEditable: boolean;
  // modals state
  onCloseAppointmentConfirmationModal: () => void;
  isSubmitAddendumWithoutChangesModalOpen: boolean;
  setIsSubmitAddendumWithoutChangesModalOpen: (isOpen: boolean) => void;
  isProgressNoteDownloadAgreementModalOpen: boolean;
  setIsProgressNoteDownloadAgreementModalOpen: (isOpen: boolean) => void;
  isComplianceGuideModalOpen: boolean;
  setIsComplianceGuideModalOpen: (isOpen: boolean) => void;
  isAddressModalOpen: boolean;
  setIsAddressModalOpen: (isOpen: boolean) => void;
  isRequiredNoteSubmissionModalOpen: boolean;
  setIsRequiredNoteSubmissionModalOpen: (isOpen: boolean) => void;
  openTreatmentPlanAdoptionModal:
    | ((isIntakeSession: boolean) => void)
    | undefined;
  isSwoopUpsellModalOpen: boolean;
  setIsSwoopUpsellModalOpen: (isOpen: boolean) => void;
  // analytics
  trackButtonClicked: (
    name: EventTrackingName,
    previousNoteId?: number
  ) => void;
}

export const AppointmentConfirmationContextV2 =
  createContext<AppointmentConfirmationContextV2Type>({
    event: undefined,
    setEvent: () => {},
    eventVirtualId: undefined,
    isEventLoading: true,
    isEventFetching: true,
    progressNote: undefined,
    setProgressNote: () => {},
    progressNoteState: undefined,
    progressNoteType: undefined,
    setProgressNoteType: () => {},
    isProgressNoteLoading: true,
    isProgressNoteFetching: true,
    progressNoteErrorsFromSubmitting: undefined,
    setProgressNoteErrorsFromSubmitting: () => {},
    templates: undefined,
    previousNoteInitialValues: undefined,
    setPreviousNoteInitialValues: () => {},
    patient: undefined,
    provider: undefined,
    providerPatient: undefined,
    isGroupAdminImpersonating: false,
    documentRemediation: undefined,
    isDocumentRemediationLoading: false,
    addendums: undefined,
    areAddendumsLoading: false,
    switchToEditProgressNote: () => {},
    switchToEditAddendum: () => {},
    switchToNoteSigned: () => {},
    switchToNoteSavedAsDraft: () => {},
    appointmentAddressState: undefined,
    updateAppointmentAddressState: () => {},
    isAutoSaving: false,
    setIsAutoSaving: () => {},
    isDateEditable: false,
    onCloseAppointmentConfirmationModal: () => {},
    isSubmitAddendumWithoutChangesModalOpen: false,
    setIsSubmitAddendumWithoutChangesModalOpen: () => {},
    isProgressNoteDownloadAgreementModalOpen: false,
    setIsProgressNoteDownloadAgreementModalOpen: () => {},
    isComplianceGuideModalOpen: false,
    setIsComplianceGuideModalOpen: () => {},
    isAddressModalOpen: false,
    setIsAddressModalOpen: () => {},
    isRequiredNoteSubmissionModalOpen: false,
    setIsRequiredNoteSubmissionModalOpen: () => {},
    openTreatmentPlanAdoptionModal: undefined,
    isSwoopUpsellModalOpen: false,
    setIsSwoopUpsellModalOpen: () => {},
    trackButtonClicked: () => {},
  });

export type AppointmentConfirmationContextProps = {
  children: React.ReactNode;
  eventVirtualId: string | number | undefined;
  patient: UserRead;
  provider: ProviderRead;
  isDateEditable: boolean;
  onCloseAppointmentConfirmationModal(): void;
  openTreatmentPlanAdoptionModal:
    | ((isIntakeSession: boolean) => void)
    | undefined;
};

export const AppointmentConfirmationContextProvider = ({
  children,
  eventVirtualId,
  patient,
  provider,
  isDateEditable,
  onCloseAppointmentConfirmationModal,
  openTreatmentPlanAdoptionModal,
}: AppointmentConfirmationContextProps) => {
  const { user } = useAuthStore();
  const { providerAddresses } = useContext(ProviderAddressContext);
  const [progressNote, setProgressNote] = useState<
    ProviderProgressNoteRead | undefined
  >(undefined);
  const [event, setEvent] = useState<ProviderEventRead | undefined>(undefined);
  const [progressNoteState, setProgressNoteState] = useState<
    ProgressNoteState | undefined
  >(undefined);
  const [progressNoteType, setProgressNoteType] = useState<
    ProgressNoteType | undefined
  >(undefined);
  const [appointmentAddressState, setAppointmentAddressState] = useState<
    UnitedStates | undefined
  >(undefined);
  const [isAutoSaving, setIsAutoSaving] = useState<boolean>(false);
  const [
    progressNoteErrorsFromSubmitting,
    setProgressNoteErrorsFromSubmitting,
  ] = useState<TemplateError[] | undefined>(undefined);
  const [previousNoteInitialValues, setPreviousNoteInitialValues] = useState<
    Record<string, any> | undefined
  >(undefined);
  const [
    isSubmitAddendumWithoutChangesModalOpen,
    setIsSubmitAddendumWithoutChangesModalOpen,
  ] = useState<boolean>(false);
  const [
    isProgressNoteDownloadAgreementModalOpen,
    setIsProgressNoteDownloadAgreementModalOpen,
  ] = useState<boolean>(false);
  const [providerClosedComplianceModal] = useLocalStorage(
    'providerClosedComplianceModal'
  );
  const [providerClosedConsiderHeadwayTemplateCard] = useLocalStorage(
    'providerClosedConsiderHeadwayTemplateCard'
  );
  const [isComplianceGuideModalOpen, setIsComplianceGuideModalOpen] =
    useState<boolean>(!providerClosedComplianceModal);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);
  const [
    isRequiredNoteSubmissionModalOpen,
    setIsRequiredNoteSubmissionModalOpen,
  ] = useState<boolean>(false);
  const [isSwoopUpsellModalOpen, setIsSwoopUpsellModalOpen] =
    useState<boolean>(false);
  const {
    data: fetchedEvent,
    isFetching: isEventFetching,
    isLoading: isEventLoading,
  } = useProviderEvent(
    {
      eventIdOrVirtualId: eventVirtualId,
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );
  const { data: providerPatient } = useProviderPatient({
    providerId: event?.providerId,
    patientId: event?.patientUserId,
  });
  const {
    data: validPogressNote,
    isFetching: isProgressNoteFetching,
    isLoading: isProgressNoteLoading,
  } = useValidProviderProgressNote(
    {
      providerAppointmentId: event?.providerAppointment?.id!,
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const { data: documentRemediation, isLoading: isDocumentRemediationLoading } =
    useProviderDocumentRemediations(
      {
        providerAppointmentId: event?.providerAppointment?.id,
      },
      {
        select: ([firstItem]) => firstItem ?? undefined,
      }
    );
  const { data: addendums, isLoading: areAddendumsLoading } =
    useProviderAppointmentAddendums({
      providerAppointmentId: event?.providerAppointment?.id,
    });
  const { data: patientAddresses } = usePatientAddresses({
    patientId: patient?.id,
  });

  const templates = useMemo(
    () => getTemplates<ProgressNoteComponentMetadata>(),
    []
  );

  const switchToEditProgressNote = useCallback(() => {
    setProgressNoteState(ProgressNoteState.EDITING);
  }, []);
  const switchToEditAddendum = useCallback(() => {
    setProgressNoteState(ProgressNoteState.ADDENDUM_EDITING);
  }, []);
  const switchToNoteSigned = useCallback(() => {
    setProgressNoteState(ProgressNoteState.SIGNED);
  }, []);
  const switchToNoteSavedAsDraft = useCallback(() => {
    setProgressNoteState(ProgressNoteState.SAVED_FOR_LATER);
  }, []);
  const updateAppointmentAddressState = useCallback(
    (
      providerAddressId?: number | undefined | null,
      appointmentLocationPatientAddressId?: number | undefined | null
    ) => {
      if (isSessionDetailsFormValueTelehealth(providerAddressId)) {
        if (appointmentLocationPatientAddressId && patientAddresses) {
          setAppointmentAddressState(
            patientAddresses.find(
              (address) => address.id === appointmentLocationPatientAddressId
            )?.state
          );
        }
      } else {
        if (providerAddressId) {
          const providerAddressState = providerAddresses.find(
            (address) => address.id === providerAddressId
          )?.state;
          if (
            providerAddressState &&
            providerAddressState in abbreviationToStateEnum
          ) {
            setAppointmentAddressState(
              abbreviationToStateEnum[providerAddressState] as UnitedStates
            );
          }
        }
      }
    },
    [patientAddresses, providerAddresses]
  );
  const trackButtonClicked = useCallback(
    (name: EventTrackingName, previousNoteId?: number) => {
      if (progressNoteType && event?.id) {
        const properties = {
          providerId: event.providerAppointment!.providerId!,
          providerEventId: event.id,
          patientUserId: event.patientUserId!,
          providerAppointmentId: event.providerAppointment!.id,
          prefillSelected: !!previousNoteId,
          progressNoteId: progressNote?.id,
          progressNoteRadio: progressNoteType,
          selectedTemplate: (progressNote?.noteJson as NoteJson)?.templateInfo
            ?.name,
        };

        if (name === 'Confirm Session Button Clicked') {
          const fullProperties: ConfirmSessionButtonClickedEvent['properties'] =
            {
              ...properties,
              nudgePresent: !!providerClosedConsiderHeadwayTemplateCard,
              selectedTemplate:
                progressNoteType === ProgressNoteType.TEMPLATE
                  ? (progressNote?.noteJson as NoteJson)?.templateInfo?.name
                  : undefined,
              telehealthAttestation:
                !!event.providerAppointment?.telehealthAttestation,
            };
          trackEvent({
            name,
            properties: fullProperties,
          });
        } else if (name === 'Sign Note Button Clicked') {
          const fullProperties: SignNoteButtonClickedEvent['properties'] = {
            ...properties,
            nudgePresent: !!providerClosedConsiderHeadwayTemplateCard,
          };
          trackEvent({
            name,
            properties: fullProperties,
          });
        } else if (name === 'Sign and Submit Addendum Button Clicked') {
          const fullProperties: SignAndSubmitAddendumButtonClickedEvent['properties'] =
            {
              ...properties,
              // TODO: fix this
              errorEvent: false,
            };
          trackEvent({
            name,
            properties: fullProperties,
          });
        } else {
          trackEvent({
            name,
            properties,
          });
        }
      }
    },
    [
      event?.patientUserId,
      event?.providerAppointment,
      progressNote?.id,
      progressNote?.noteJson,
      progressNoteType,
      providerClosedConsiderHeadwayTemplateCard,
      event?.id,
    ]
  );
  const isGroupAdminImpersonating: boolean = useMemo(
    () => isGroupAdminImpersonatingProvider(provider, user),
    [user, provider]
  );

  useEffect(() => {
    setProgressNote(validPogressNote);
  }, [validPogressNote]);
  useEffect(() => {
    setEvent(fetchedEvent);
  }, [fetchedEvent]);

  useEffect(() => {
    if (progressNote) {
      if (progressNote.attestedOn) {
        switchToNoteSigned();
      } else {
        switchToEditProgressNote();
      }
    } else {
      if (!!event?.providerAppointment?.attachments?.length) {
        switchToNoteSigned();
      } else {
        switchToEditProgressNote();
      }
    }
  }, [
    progressNote,
    switchToEditProgressNote,
    switchToNoteSigned,
    event?.providerAppointment?.attachments?.length,
  ]);

  useEffect(() => {
    if (
      !isProgressNoteLoading &&
      !isProgressNoteFetching &&
      !isEventLoading &&
      !isEventFetching
    ) {
      const noteType = event?.providerAppointment?.progressNoteType;
      if (isGroupAdminImpersonating) {
        // If we are a group admin impersonating a provider and:
        //   - The note's type is a TEMPLATE
        //   - The note's state is not SIGNED
        //   - The note's state is not ADDENDUM_EDITING
        // We then force the note's type to be defaulted to UPLOAD to prevent bad UX
        setProgressNoteType(
          noteType === ProgressNoteType.TEMPLATE &&
            progressNoteState !== ProgressNoteState.SIGNED &&
            progressNoteState !== ProgressNoteState.ADDENDUM_EDITING
            ? ProgressNoteType.UPLOAD
            : noteType
        );
      } else {
        if (!validPogressNote && noteType === ProgressNoteType.NONE) {
          if (isDetailsConfirmed(event)) {
            // If there are no existing note and the type is NONE but it's a confirmed session => NONE
            setProgressNoteType(ProgressNoteType.NONE);
          } else {
            // If there are no existing note and the type is NONE => TEMPLATE (default back to TEMPLATE)
            setProgressNoteType(ProgressNoteType.TEMPLATE);
          }
        } else {
          setProgressNoteType(noteType);
        }
      }
    }
  }, [
    event?.providerAppointment?.progressNoteType,
    validPogressNote,
    event,
    isProgressNoteFetching,
    isProgressNoteLoading,
    isEventFetching,
    isEventLoading,
    isGroupAdminImpersonating,
    progressNoteState,
  ]);

  const value = useMemo(
    () => ({
      event,
      setEvent,
      eventVirtualId,
      isEventLoading,
      isEventFetching,
      progressNote,
      setProgressNote,
      progressNoteState,
      progressNoteType,
      setProgressNoteType,
      isProgressNoteLoading,
      isProgressNoteFetching,
      progressNoteErrorsFromSubmitting,
      setProgressNoteErrorsFromSubmitting,
      templates,
      previousNoteInitialValues,
      setPreviousNoteInitialValues,
      patient,
      provider,
      providerPatient,
      isGroupAdminImpersonating,
      documentRemediation,
      isDocumentRemediationLoading,
      addendums,
      areAddendumsLoading,
      switchToEditProgressNote,
      switchToEditAddendum,
      switchToNoteSigned,
      switchToNoteSavedAsDraft,
      appointmentAddressState,
      updateAppointmentAddressState,
      isAutoSaving,
      setIsAutoSaving,
      isDateEditable,
      onCloseAppointmentConfirmationModal,
      isSubmitAddendumWithoutChangesModalOpen,
      setIsSubmitAddendumWithoutChangesModalOpen,
      isProgressNoteDownloadAgreementModalOpen,
      setIsProgressNoteDownloadAgreementModalOpen,
      isComplianceGuideModalOpen,
      setIsComplianceGuideModalOpen,
      isAddressModalOpen,
      setIsAddressModalOpen,
      isRequiredNoteSubmissionModalOpen,
      setIsRequiredNoteSubmissionModalOpen,
      openTreatmentPlanAdoptionModal,
      isSwoopUpsellModalOpen,
      setIsSwoopUpsellModalOpen,
      trackButtonClicked,
    }),
    [
      event,
      setEvent,
      eventVirtualId,
      isEventLoading,
      isEventFetching,
      progressNote,
      setProgressNote,
      progressNoteState,
      progressNoteType,
      setProgressNoteType,
      isProgressNoteLoading,
      isProgressNoteFetching,
      progressNoteErrorsFromSubmitting,
      setProgressNoteErrorsFromSubmitting,
      templates,
      previousNoteInitialValues,
      setPreviousNoteInitialValues,
      patient,
      provider,
      providerPatient,
      isGroupAdminImpersonating,
      documentRemediation,
      isDocumentRemediationLoading,
      addendums,
      areAddendumsLoading,
      switchToEditProgressNote,
      switchToEditAddendum,
      switchToNoteSigned,
      switchToNoteSavedAsDraft,
      appointmentAddressState,
      updateAppointmentAddressState,
      isAutoSaving,
      setIsAutoSaving,
      isDateEditable,
      onCloseAppointmentConfirmationModal,
      isSubmitAddendumWithoutChangesModalOpen,
      setIsSubmitAddendumWithoutChangesModalOpen,
      isProgressNoteDownloadAgreementModalOpen,
      setIsProgressNoteDownloadAgreementModalOpen,
      isComplianceGuideModalOpen,
      setIsComplianceGuideModalOpen,
      isAddressModalOpen,
      setIsAddressModalOpen,
      isRequiredNoteSubmissionModalOpen,
      setIsRequiredNoteSubmissionModalOpen,
      openTreatmentPlanAdoptionModal,
      isSwoopUpsellModalOpen,
      setIsSwoopUpsellModalOpen,
      trackButtonClicked,
    ]
  );

  return (
    <AppointmentConfirmationContextV2.Provider value={value}>
      {children}
    </AppointmentConfirmationContextV2.Provider>
  );
};
