import { BillingType } from '@headway/api/models/BillingType';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { PPR_FRONTEND_CARRIERS } from '@headway/shared/FeatureFlags/flagNames';
import { matchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';

import { isBillingAddOnPsychotherapy } from '../../components/forms/SessionDetails/utils';
import { Rule } from '../Rule';
import { PPRAnthemCANVBannerTypes, PprBanner } from './PPRAnthemCANVBanner';

interface PPRProps {
  isPPR: boolean;
  pprCarrier: FrontEndCarrierRead | undefined;
}

const PRESCRIBER_PPR_CPT_CODES: string[] = [];
const AnthemCANV_CARRIER_IDS = [24, 31];
export class PPRAnthemCANV extends Rule<[PPRProps]> {
  addDataHooks() {
    return [
      async () => {
        const pprFrontendCarrierIds = this.flags[PPR_FRONTEND_CARRIERS] || [];
        const interestedPprFrontendCarrierIds = pprFrontendCarrierIds.filter(
          (id: number) => AnthemCANV_CARRIER_IDS.includes(id)
        );

        const matchingProviderFrontEndCarrier =
          await matchingProviderFrontEndCarrierQuery(
            {
              providerId: this.provider.id,
              patientUserId: this.patient.id,
              appointmentId: this.event.providerAppointment?.id,
            },
            Rule.queryClient
          );

        if (this.providerPatient.billingTypeDefault === BillingType.SELF_PAY) {
          return { isPPR: false, pprCarrier: undefined };
        }

        const blanketCarrierId = !!matchingProviderFrontEndCarrier
          ? interestedPprFrontendCarrierIds?.find(
              (id: number) =>
                id === matchingProviderFrontEndCarrier?.frontEndCarrierId
            )
          : undefined;
        const pprCarrier = blanketCarrierId
          ? this.carriersById[blanketCarrierId]
          : undefined;

        const isPPR = !!pprCarrier;

        return { isPPR, pprCarrier };
      },
    ];
  }

  isRuleActive(): boolean {
    const hasSelectedPrescriberPPRCptCodes =
      !!this.formValues.sessionDetails.cptCodes?.some((code) =>
        PRESCRIBER_PPR_CPT_CODES.includes(code.value)
      );

    if (!this.providerRoles.isPrescriber || !hasSelectedPrescriberPPRCptCodes) {
      return false;
    }

    const data = this.getHookData();
    if (!data || !data[0]) {
      return false;
    }

    return data[0].isPPR;
  }

  isDocumentationRequired(): boolean {
    return true;
  }

  isPsychotherapyDurationRequired(): boolean {
    if (
      isBillingAddOnPsychotherapy(
        this.formValues.sessionDetails.cptCodes?.map(
          (cptCode) => cptCode.value
        ) ?? []
      )
    ) {
      return true;
    }

    return false;
  }

  get disabledProgressNoteTypes(): ProgressNoteType[] {
    return [ProgressNoteType.NONE];
  }

  get banners() {
    const data = this.getHookData();
    if (!data) return {};

    let variant = null;

    if (this.userRoles.isGroupAdmin) {
      variant = PPRAnthemCANVBannerTypes.PPR_GROUP_ADMIN_PRESCRIBER;
    } else {
      variant = PPRAnthemCANVBannerTypes.PPR_PRESCRIBER;
    }

    return {
      progressNote: {
        header: (
          <PprBanner version={variant} requiringCarrier={data[0].pprCarrier} />
        ),
      },
    };
  }
}

PPRAnthemCANV.enabled = false;
