import { useField, useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';

import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { ContentText } from '@headway/helix/ContentText';
import { FormControl } from '@headway/helix/FormControl';
import { VisuallyHidden } from '@headway/helix/utils';

import { AssessmentScheduleInput } from '../AssessmentScheduleInput';
import { MultiAssessmentScheduleValue } from './types';
import { useMultiAssessmentScheduleWarnings } from './useMultiAssessmentScheduleWarnings';
import { getAssessmentScheduleDisplayText } from './utils';

type MultiAssessmentScheduleInputRowProps<Name extends string> = {
  name: Name;
  availableAssessmentTypes: PatientAssessmentType[];
  withPendingSchedules?: boolean;
};

export function MultiAssessmentScheduleInputRow<Name extends string>({
  name,
  availableAssessmentTypes,
  withPendingSchedules,
}: MultiAssessmentScheduleInputRowProps<Name>) {
  const { setFieldTouched, setFieldValue } = useFormikContext();

  const [{ value: schedule }] = useField<MultiAssessmentScheduleValue>(name);
  const { selected, editing } = schedule;

  const warningProps = useMultiAssessmentScheduleWarnings(name, selected);

  const label = useMemo(
    () => getAssessmentScheduleDisplayText(schedule) ?? name,
    [name, schedule]
  );

  const startEditing = useCallback(() => {
    setFieldValue(name, { ...schedule, selected: true, editing: true });
    setFieldTouched(name, true, true);
  }, [name, schedule, setFieldTouched, setFieldValue]);

  if (editing) {
    return (
      <div className="flex gap-1 pt-5">
        <FormControl name={`${name}.selected`} component={Checkbox}>
          <VisuallyHidden>{label}</VisuallyHidden>
        </FormControl>
        {/* negative margin offsets parent padding to align inputs to checkbox */}
        <div className="-mt-5 flex-1">
          <AssessmentScheduleInput
            name={name}
            withTypeSelector
            availableAssessmentTypes={availableAssessmentTypes}
            disabled={!selected}
            typeSelectorProps={warningProps.assessmentType}
            cadenceSelectorProps={warningProps.cadence}
            withPendingSchedules={withPendingSchedules}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2">
      <FormControl name={`${name}.selected`} component={Checkbox}>
        {label}
      </FormControl>
      <Button variant="link" onPress={startEditing}>
        <ContentText color="foreground/secondary">Change</ContentText>
      </Button>
    </div>
  );
}
