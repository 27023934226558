import { diff } from 'deep-diff';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useUpdateProviderEventMutation } from '~/legacy/mutations/providerEvent';
import { useUpdateProviderProgressNoteMutation } from '~/legacy/mutations/providerProgressNote';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { toaster } from '@headway/ui/ToastManager';

import { isDetailsConfirmed } from '../../Calendar/events/util/events';
import {
  AppointmentConfirmationContextV2,
  ProgressNoteState,
} from '../stores/AppointmentConfirmationContextV2';
import {
  createNoteJsonObject,
  getTemplate,
  getTemplateIdAndVersionFromFormValue,
} from './forms/ProgressNote/utils';
import { convertFormValuesToProviderEventUpdate } from './forms/SessionDetails/utils';
import { AppointmentConfirmationModalFormV2Values } from './modals/AppointmentConfirmationModalV2';
import { getObjectPaths } from './utils';

export interface AutoSaveListenerProps {
  isInitialized: boolean;
  setIsAutoSaving(value: boolean): void;
}

const AutoSaveListener = ({
  isInitialized,
  setIsAutoSaving,
}: AutoSaveListenerProps) => {
  const {
    event,
    eventVirtualId,
    progressNote,
    progressNoteState,
    templates,
    isGroupAdminImpersonating,
  } = useContext(AppointmentConfirmationContextV2);
  const {
    formState: { isSubmitting },
    getValues,
  } = useFormContext<AppointmentConfirmationModalFormV2Values>();
  const values = useWatch() as AppointmentConfirmationModalFormV2Values;
  const [lastSavedValues, setLastSavedValues] =
    useState<AppointmentConfirmationModalFormV2Values>(cloneDeep(getValues()));
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  // Ref to track if values have already been initialized
  const hasInitializedValuesRef = useRef(false);
  const updateEventMutation = useUpdateProviderEventMutation();
  const updateProviderProgressNoteMutation =
    useUpdateProviderProgressNoteMutation();

  const checkForSessionDetailsChanges = useCallback(
    async (changedFields: any[]) => {
      if (!isDetailsConfirmed(event)) {
        // Only auto-save session details info if session is not confirmed yet

        const sessionDetailPaths = getObjectPaths(values.sessionDetails);
        const formattedChangedFields = changedFields.map((field: any) =>
          Array.isArray(field)
            ? field.filter((val) => val !== 'sessionDetails').join('.')
            : field
        );
        const fieldsInObject = formattedChangedFields.filter((field: string) =>
          sessionDetailPaths.find((item) => item.includes(field))
        );
        if (fieldsInObject.length > 0) {
          setIsAutoSaving(true);
          await updateEventMutation.mutateAsync({
            eventIdOrVirtualId: eventVirtualId!,
            update: convertFormValuesToProviderEventUpdate(
              values.sessionDetails
            ),
          });
        }
      }
    },
    [
      event,
      eventVirtualId,
      setIsAutoSaving,
      updateEventMutation,
      values.sessionDetails,
    ]
  );

  const checkForProgressNoteChanges = useCallback(
    async (changedFields: any[]) => {
      if (
        (values.progressNote.progressNoteType === ProgressNoteType.TEMPLATE &&
          progressNoteState === ProgressNoteState.EDITING &&
          !isGroupAdminImpersonating) ||
        values.progressNote.progressNoteType === ProgressNoteType.NONE
      ) {
        // Only auto-save progress note info if the type is TEMPLATE and
        // we're editing it or if the type is NONE

        const progressNotePaths = getObjectPaths(values.progressNote);
        const formattedChangedFields = changedFields.map((field: any) =>
          Array.isArray(field)
            ? field.filter((val) => val !== 'progressNote').join('.')
            : field
        );
        const fieldsInObject = formattedChangedFields.filter((field: string) =>
          progressNotePaths.find((item) => item.includes(field))
        );
        if (fieldsInObject.length > 0) {
          const selectedTemplate = getTemplate(
            templates,
            ...getTemplateIdAndVersionFromFormValue(
              values.progressNote.template
            )
          );
          if (
            selectedTemplate &&
            event &&
            event.providerAppointment &&
            event.patientUserId
          ) {
            setIsAutoSaving(true);
            if (progressNote) {
              const {
                template,
                progressNoteType,
                previousNoteId,
                lateEntryReason,
                lateEntryOtherReason,
                ...response
              } = values.progressNote;
              const noteJson = createNoteJsonObject(selectedTemplate, response);
              await updateProviderProgressNoteMutation.mutateAsync({
                progressNoteId: progressNote.id,
                update: {
                  provider_progress_note_data: {
                    noteJson:
                      progressNoteType === ProgressNoteType.NONE
                        ? {}
                        : noteJson,
                    lateEntryReason: lateEntryReason,
                    lateEntryOtherReason: lateEntryOtherReason,
                  },
                  note_json_prefilled_from: previousNoteId,
                },
              });
            }
          }
        }
      }
    },
    [
      event,
      progressNote,
      progressNoteState,
      setIsAutoSaving,
      templates,
      updateProviderProgressNoteMutation,
      values.progressNote,
      isGroupAdminImpersonating,
    ]
  );

  const checkForChanges = useCallback(async () => {
    const currentValues = cloneDeep(getValues());
    const changes = diff(lastSavedValues, currentValues);
    const hasChanges = changes !== undefined;
    if (
      hasChanges &&
      !isSubmitting &&
      isInitialized &&
      hasInitializedValuesRef.current
    ) {
      try {
        const changedFields = changes.map((diff: any) => diff.path);
        await checkForSessionDetailsChanges(changedFields);
        await checkForProgressNoteChanges(changedFields);
        setLastSavedValues(currentValues);
      } catch (error) {
        toaster.error(`Failed to save changes: ${error}`);
      } finally {
        setIsAutoSaving(false);
      }
    }
  }, [
    checkForProgressNoteChanges,
    checkForSessionDetailsChanges,
    getValues,
    isInitialized,
    isSubmitting,
    lastSavedValues,
    setIsAutoSaving,
  ]);

  // This useEffect is to make sure we start checking the lastSavedValues after
  // it's been properly initialized so the AutoSaveListener doesn't run right
  // at the opening of the Modal
  useEffect(() => {
    if (isInitialized && !hasInitializedValuesRef.current) {
      const initialValues = cloneDeep(getValues());
      setLastSavedValues(initialValues);
      hasInitializedValuesRef.current = true;
    }
  }, [isInitialized, setLastSavedValues, getValues]);

  // Use a ref to store the latest version of checkForChanges
  const checkForChangesRef = useRef(checkForChanges);
  useEffect(() => {
    checkForChangesRef.current = checkForChanges;
  }, [checkForChanges]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      timerRef.current = setInterval(async () => {
        await checkForChangesRef.current();
      }, 3000);
    }, 1700);

    // Clean up timeout when unmounting or on re-render
    return () => {
      clearTimeout(timeoutId);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  return null; // This component does not render anything
};

export default AutoSaveListener;
