import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { isGroupAdminImpersonatingProvider } from '~/legacy/utils/access';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderFunction } from '@headway/api/models/ProviderFunction';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { LDFlagSet } from '@headway/shared/FeatureFlags/react';
import { QueryClient } from '@headway/shared/react-query';

import { AppointmentConfirmationModalFormV2Values } from '../components/modals/AppointmentConfirmationModalV2';
import { ProgressNoteState } from '../stores/AppointmentConfirmationContextV2';
import { DefaultTemplateFilters, TemplateFilter } from './TemplateFilter';

export type DataResponseHooks<T extends unknown[]> = { [K in keyof T]: T[K] };
export type DataRequestHooks<T extends unknown[]> = Array<() => T[number]>;

interface ProviderRoles {
  isApartOfGroupPractice: boolean;
  isPrescriber: boolean;
  providerFunction: ProviderFunction | undefined;
}

interface UserRoles {
  isGroupAdmin: boolean;
}

const newQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

export abstract class Rule<T extends unknown[]> {
  /**
   * Rule is enabled by default. If the rule is not enabled, the rule will not be checked.
   * The second index is a feature flag name which checks against LD to see if its T/F.
   */
  static enabled: boolean = true;
  static queryClient: QueryClient = newQueryClient;

  /**
   * Data initialized by the hooks set by the Rules. Used to determine if the rule is active.
   */
  public data: T | undefined;

  protected hasProgressNote: boolean = false;

  protected providerRoles: ProviderRoles = {
    isApartOfGroupPractice: false,
    isPrescriber: false,
    providerFunction: undefined,
  };

  protected userRoles: UserRoles = {
    isGroupAdmin: false,
  };

  constructor(
    protected accessingUser: UserRead,
    protected provider: ProviderRead,
    protected patient: UserRead,
    protected event: ProviderEventRead,
    protected formValues: AppointmentConfirmationModalFormV2Values,
    protected progressNoteState: ProgressNoteState,
    protected providerPatient: ProviderPatientRead,
    protected flags: LDFlagSet,
    protected carriersById: { [index: number]: FrontEndCarrierRead }
  ) {
    this.userRoles.isGroupAdmin = isGroupAdminImpersonatingProvider(
      provider,
      accessingUser
    );
    this.providerRoles.isApartOfGroupPractice = !!provider.groupPractice;
    this.providerRoles.isPrescriber = !!provider.isPrescriber;
    this.providerRoles.providerFunction = provider.providerFunction;
  }

  /**
   * Data management methods allowing running react hooks to fetch data.
   */
  protected getHookData(): T | undefined {
    return this.data;
  }

  public setHookData(data: T) {
    this.data = data;
  }

  public isInRemediationFlow(): boolean {
    return false;
  }

  abstract addDataHooks(): (() => Promise<unknown>)[];

  /**
   * Determines if the rule is active or not using the data fetched from hooks.
   */
  abstract isRuleActive(): boolean;

  /**
   * Resulting UI behavior and functionality.
   */
  abstract get disabledProgressNoteTypes(): ProgressNoteType[];
  abstract get banners(): {
    sessionDetails?: {
      header?: EmotionJSX.Element;
    };
    progressNote?: {
      header?: EmotionJSX.Element;
    };
  };

  /*
   * Function for filtering out templates
   */
  public templateFilter(): TemplateFilter {
    return DefaultTemplateFilters.Identity;
  }

  public isPsychotherapyDurationRequired(): boolean {
    return false;
  }

  public isDocumentationRequired(): boolean {
    return false;
  }

  public isTreatmentPlanRequired(): boolean {
    return false;
  }

  public shouldOpenSwoopUpsellModalAfterConfirmation(): boolean {
    return false;
  }
}
