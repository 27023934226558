import {
  AssignmentTwoTone,
  Block,
  CancelTwoTone,
  CheckCircleOutline,
  CheckCircleTwoTone,
  ErrorTwoTone,
  PhoneTwoTone,
  Timelapse,
  VideocamTwoTone,
} from '@mui/icons-material';
import { Link as ExternalLink } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import React from 'react';
import { View } from 'react-big-calendar';
import { useMount } from 'react-use';

import { NestedPatientReadForCalendar } from '@headway/api/models/NestedPatientReadForCalendar';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserRead } from '@headway/api/models/UserRead';
import { CALENDAR_PATIENTS_PAGINATED_REFACTOR } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useShouldShowAnthemEAPExperience } from '@headway/shared/hooks/useShouldShowAnthemEAPExperience';
import { useUserPaymentMethods } from '@headway/shared/hooks/useUserPaymentMethod';
import { formatPatientName } from '@headway/shared/utils/patient';
import { Button } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { useAuthStore } from 'stores/AuthStore';

import { PatientsContext } from '../../../providers/PatientsProvider';
import { CalendarContext } from '../CalendarContext';
import {
  getDurationMinutes,
  hasTask,
  isAppointmentDateValidForPFEC,
  isAvailability,
  isCanceled,
  isDetailsConfirmed,
  isExternalEvent,
  isIntakeCall,
  isPaid,
  isPast,
  isSelfPayAppt,
  isUnavailability,
  isUnreadyReferralAppointment,
  paymentAttemptFailed,
  useWarningMessage,
} from '../events/util/events';
import { ProviderCalendarEvent } from '../utils/Calendar';

/**
 * @param renderOnSameLine render the appointment time and duration on the same line. Can be used to save space in the component
 * for when additional info needs to be displayed, such as UI designating the event as an EAP event.
 * */
const EventTimeAndDuration = ({
  event,
  renderOnSameLine,
  ...rest
}: {
  event: ProviderCalendarEvent;
  renderOnSameLine?: boolean;
}) => {
  if (!moment(event.startDate).isSame(moment(event.endDate), 'date')) {
    return null;
  }

  // when dragging `start` and `end` reflect the dragged time
  // otherwise `startDate` and `endDate` reflect the time
  const startDate = event.start || event.startDate;

  return (
    <React.Fragment>
      <div
        style={{
          display: renderOnSameLine ? 'flex' : 'block',
          alignItems: 'center',
          marginTop: theme.space.xs2,
        }}
      >
        {getDurationMinutes(event) >= 30 ? (
          <div
            css={{
              fontSize: theme.fontSize.xs,
              whiteSpace: 'nowrap',
            }}
            {...rest}
          >
            {moment(startDate).format('h:mm a')}
          </div>
        ) : null}
        {renderOnSameLine && getDurationMinutes(event) >= 45 ? (
          <div
            css={{
              margin: '0 4px',
              lineHeight: '1', // Center vertically
            }}
          >
            •
          </div>
        ) : null}
        {getDurationMinutes(event) >= 45 ? (
          <div
            css={{
              fontSize: theme.fontSize.xs,
              whiteSpace: 'nowrap',
              marginTop: renderOnSameLine ? 0 : theme.space.xs2,
            }}
          >
            {getDurationMinutes(event)} min
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

const TelehealthJoinButton = ({
  event,
  provider,
  view,
}: {
  event: ProviderCalendarEvent;
  provider: ProviderRead;
  view: View;
}) => {
  const dateNow = moment();
  const isJoinLinkInViewableTimeframe =
    // show join link between [now - 15, event.endDate]
    moment(event.startDate).subtract(15, 'minutes').isSameOrBefore(dateNow) &&
    dateNow.isSameOrBefore(moment(event.endDate));
  const shouldShowJoinLink =
    event.telehealth &&
    provider.videoUrlDefault &&
    isJoinLinkInViewableTimeframe &&
    getDurationMinutes(event) >= 45;

  return shouldShowJoinLink ? (
    <ExternalLink
      rel="nooopener noreferrer"
      target="_blank"
      href={provider.videoUrlDefault}
      css={{
        ':hover': {
          textDecoration: 'none',
        },
        alignSelf: 'flex-end',
      }}
    >
      <Button
        css={{
          color: theme.color.white,
          background: theme.color.primary,
          minWidth: 0,
          padding: `0 ${theme.space.xs}`,
          fontSize: theme.fontSize.xs,
          [theme.media.smallDown]: {
            display:
              view === 'work_week' || view === 'month' ? 'none' : 'inherit',
            padding: `0 ${theme.space.xs2}`,
          },
        }}
      >
        Join
      </Button>
    </ExternalLink>
  ) : null;
};

const HeaderWithAdornments = ({
  title,
  StartAdornment,
  MiddleAdornment,
  EndAdornment,
}: {
  title: string;
  StartAdornment?: React.ComponentType;
  MiddleAdornment?: React.ComponentType;
  EndAdornment?: React.ComponentType;
}) => (
  <div
    css={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      ' svg': {
        fontSize: theme.fontSize.base,
      },
    }}
  >
    <div
      css={{
        display: 'flex',
        minWidth: 0, // required to get ellipsis overflow working in doubly-nested flex container
      }}
    >
      {StartAdornment ? (
        <StartAdornment css={{ marginRight: '3px', fontSize: 'inherit' }} />
      ) : null}
      <span
        css={{
          display: 'block',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          fontSize: theme.fontSize.sm,
          fontWeight: theme.fontWeight.bold,
          paddingRight: MiddleAdornment ? '4px' : '0px',
        }}
      >
        {title}
      </span>
      {MiddleAdornment ? (
        <MiddleAdornment css={{ fontSize: 'inherit' }} />
      ) : null}
    </div>
    {EndAdornment ? <EndAdornment css={{ fontSize: 'inherit' }} /> : null}
  </div>
);

const Canceled = () => <CancelTwoTone css={{ color: theme.color.error }} />;
const Paid = () => <CheckCircleTwoTone />;
const ConfirmedPast = () => <CheckCircleOutline />;
const Warning = () => <ErrorTwoTone css={{ color: theme.color.warning }} />;
const Error = () => <ErrorTwoTone css={{ color: theme.color.error }} />;
const Task = () => <AssignmentTwoTone />;
const Referral = () => <Timelapse css={{ color: 'hsla(43.8, 200%, 24.7%)' }} />;

const AppointmentEvent = ({
  event,
  provider,
  view,
  freezeReasonsByUser,
  eventIdsToMatchingProviderFrontEndCarriers,
  isProviderFrontendCarriersLoading,
}: {
  event: ProviderCalendarEvent;
  provider: ProviderRead;
  view: View;
  freezeReasonsByUser: { [index: string]: UserFreezeReason[] };
  eventIdsToMatchingProviderFrontEndCarriers: Map<
    number,
    ProviderFrontEndCarrierRead | null
  >;
  isProviderFrontendCarriersLoading?: boolean;
}) => {
  const shouldGetPatientDataFromEvent = useFlag(
    CALENDAR_PATIENTS_PAGINATED_REFACTOR,
    false
  );
  const { patientsById } = React.useContext(PatientsContext);
  const patient: NestedPatientReadForCalendar | UserRead | undefined =
    event?.patientUserId
      ? shouldGetPatientDataFromEvent && !!event.patient
        ? event.patient
        : patientsById?.[event.patientUserId]
      : undefined;
  const matchingPFEC = event.id
    ? eventIdsToMatchingProviderFrontEndCarriers.get(event.id)
    : undefined;

  // If frontend carriers are still loading, prevent warning icon from showing
  const acceptsPatientInsurance =
    isProviderFrontendCarriersLoading ||
    (!!matchingPFEC &&
      isAppointmentDateValidForPFEC(matchingPFEC, event.startDate));

  const isMobileView = useMediaQuery(theme.media.smallDown);
  const warningMessage = useWarningMessage({
    event,
    patient,
    acceptsPatientInsurance,
    claimReadiness: undefined,
    freezeReasons: patient ? freezeReasonsByUser[patient.id] : undefined,
  });
  const { data: paymentMethods } = useUserPaymentMethods(patient?.id);
  const noVerifiedPaymentMethods = paymentMethods?.every(
    (paymentMethod) => !paymentMethod.isVerified
  );

  const {
    data: shouldShowAnthemEAPExperience,
    isLoading: isLoadingShouldShowAnthemEAPExperience,
  } = useShouldShowAnthemEAPExperience(
    event?.patientUserId,
    provider?.id,
    event?.providerAppointment?.billingType
  );

  const EndAdornment = isCanceled(event)
    ? Canceled
    : isSelfPayAppt(event) && paymentAttemptFailed(event)
    ? Error
    : isPaid(event)
    ? Paid
    : isPast(event) && isDetailsConfirmed(event)
    ? ConfirmedPast
    : isUnreadyReferralAppointment(event)
    ? Referral
    : !!warningMessage ||
      (noVerifiedPaymentMethods && !shouldShowAnthemEAPExperience)
    ? Warning
    : hasTask(event)
    ? Task
    : undefined;

  return (
    <div
      css={{
        textDecoration: isCanceled(event) ? 'line-through' : 'none',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      data-testid="appointmentEvent"
    >
      <HeaderWithAdornments
        title={
          isMobileView
            ? formatPatientName(patient, {
                firstInitial: true,
                lastInitial: true,
              })
            : formatPatientName(patient)
        }
        MiddleAdornment={event.telehealth ? VideocamTwoTone : undefined}
        EndAdornment={EndAdornment}
      />
      {view !== 'month' && (
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          {!isLoadingShouldShowAnthemEAPExperience && (
            <div
              css={{
                '& > * + *': {
                  marginTop: theme.space.xs2,
                },
              }}
            >
              <EventTimeAndDuration
                event={event}
                renderOnSameLine={!!shouldShowAnthemEAPExperience}
              />
              {shouldShowAnthemEAPExperience && (
                <div style={{ fontSize: theme.fontSize.xs }}>Anthem EAP</div>
              )}
            </div>
          )}
          <div
            css={{
              display: 'flex',
              marginBottom: theme.space.xs2,
            }}
          >
            <TelehealthJoinButton
              event={event}
              provider={provider}
              view={view}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const IntakeCallEvent = ({
  event,
  view,
}: {
  event: ProviderCalendarEvent;
  view: View;
}) => {
  const shouldGetPatientDataFromEvent = useFlag(
    CALENDAR_PATIENTS_PAGINATED_REFACTOR,
    false
  );
  const { patientsById } = React.useContext(PatientsContext);
  const patient: NestedPatientReadForCalendar | UserRead | undefined =
    event?.patientUserId
      ? shouldGetPatientDataFromEvent && !!event.patient
        ? event.patient
        : patientsById?.[event.patientUserId]
      : undefined;

  return (
    <div css={{ textDecoration: isCanceled(event) ? 'line-through' : 'none' }}>
      <HeaderWithAdornments
        title={formatPatientName(patient)}
        MiddleAdornment={PhoneTwoTone}
        EndAdornment={
          isCanceled(event)
            ? () => <CancelTwoTone css={{ color: theme.color.error }} />
            : isUnreadyReferralAppointment(event)
            ? () => <Warning />
            : undefined
        }
      />
      {view !== 'month' && <EventTimeAndDuration event={event} />}
    </div>
  );
};

const WorkingHoursBackgroundEvent = ({
  event,
  view,
  isConflictedOpening,
}: {
  event: ProviderCalendarEvent;
  view: View;
  isConflictedOpening?: boolean;
}) => {
  const ref = React.useRef<any>();

  // We want to scroll the viewport to the most appropriate place given
  // the current time of day.  If we're within the working hours we favor
  // showing the entire working hour block on the screen if possible, so
  // we scroll to the center of the block.  If we're not within a block we center
  // the current quarter-hour time slot in the scrollable area.
  useMount(() => {
    const now = moment();
    const start = moment(event.startDate);
    const end = moment(event.endDate);
    const isToday = moment(event.startDate).isSame(now, 'd');

    if (!isToday) {
      return;
    }

    const el = ref.current;
    if (now.isBetween(start, end)) {
      const eventBlock = el.closest('.rbc-event-working-hours');

      eventBlock.scrollIntoView({
        block: 'center',
        behavior: 'auto',
      });
    } else {
      // Get all quarter hour time slots
      const day = el.closest('.rbc-day-slot');
      const slots = day.querySelectorAll('.rbc-time-slot');

      // get the current quarter hour of the day. There are 96 (24 * 4) in a day.
      const currentQuarterHourOfDay =
        moment().hours() * 4 +
        ((Math.floor(moment().minutes() / 15) * 15) / 60) * 4;

      const slot = slots[currentQuarterHourOfDay];
      if (slot) {
        slot.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      }
    }
  });

  if (view === 'month') {
    return null;
  }

  return (
    <div
      ref={ref}
      css={{
        position: 'relative',
      }}
    >
      <div
        css={{
          transform: 'translateY(-100%)',
          fontSize: theme.fontSize.xs,
          padding: `${theme.space.xs} 0`,
          mixBlendMode: 'normal',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          width: 'fit-content',
          color: theme.color.textGray,
          marginTop: '22px',
        }}
      >
        <div>Available</div>
      </div>
    </div>
  );
};

const UnavailableCalendarEvent = ({
  event,
  view,
}: {
  event: ProviderCalendarEvent;
  view: View;
}) => {
  const title = event.title ? `Unavailable (${event.title})` : 'Unavailable';

  return (
    <div data-testid="unavailableEvent">
      <HeaderWithAdornments title={title} StartAdornment={Block} />
      {view !== 'month' && (
        <div
          css={{
            '& > * + *': {
              marginTop: theme.space.xs2,
            },
          }}
        >
          <EventTimeAndDuration event={event} />
        </div>
      )}
    </div>
  );
};

const ExternalCalendarEvent = ({
  event,
  view,
}: {
  event: ProviderCalendarEvent;
  view: View;
}) => {
  const title = event.title ? `Unavailable (${event.title})` : 'Unavailable';

  return (
    <div data-testid="externalEvent">
      <HeaderWithAdornments title={title} StartAdornment={Block} />
      {view !== 'month' && (
        <div
          css={{
            '& > * + *': {
              marginTop: theme.space.xs2,
            },
          }}
        >
          <EventTimeAndDuration event={event} />
        </div>
      )}
    </div>
  );
};

export const CalendarEvent = ({
  event,
  freezeReasonsByUser,
  eventIdsToMatchingProviderFrontEndCarriers,
  isProviderFrontendCarriersLoading,
}: {
  event: ProviderCalendarEvent;
  freezeReasonsByUser: { [index: string]: UserFreezeReason[] };
  eventIdsToMatchingProviderFrontEndCarriers: Map<
    number,
    ProviderFrontEndCarrierRead | null
  >;
  isProviderFrontendCarriersLoading?: boolean;
}) => {
  const auth = useAuthStore();
  const calendarContext = React.useContext(CalendarContext);

  if (isAvailability(event)) {
    return (
      <WorkingHoursBackgroundEvent event={event} view={calendarContext.view} />
    );
  }

  if (isIntakeCall(event)) {
    return <IntakeCallEvent event={event} view={calendarContext.view} />;
  }

  if (isExternalEvent(event)) {
    return <ExternalCalendarEvent event={event} view={calendarContext.view} />;
  }

  if (isUnavailability(event)) {
    return (
      <UnavailableCalendarEvent event={event} view={calendarContext.view} />
    );
  }

  return (
    <AppointmentEvent
      event={event}
      provider={auth.provider}
      view={calendarContext.view}
      freezeReasonsByUser={freezeReasonsByUser}
      eventIdsToMatchingProviderFrontEndCarriers={
        eventIdsToMatchingProviderFrontEndCarriers
      }
      isProviderFrontendCarriersLoading={isProviderFrontendCarriersLoading}
    />
  );
};
