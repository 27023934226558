import { Formik } from 'formik';
import { useProvider } from 'hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { ProviderAppointmentStatusNotes } from '@headway/api/models/ProviderAppointmentStatusNotes';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderRebookingPreference } from '@headway/api/models/ProviderRebookingPreference';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { NumberField } from '@headway/helix/NumberField';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { RichTextArea } from '@headway/helix/RichTextArea';
import { Item, Select } from '@headway/helix/Select';
import { Switch } from '@headway/helix/Switch';
import { TextField } from '@headway/helix/TextField';
import { RecurringUpdateApplyTo } from '@headway/shared/events/constants';
import { USE_PATIENT_REMATCHING_FOR_PROVIDER_CANCELLATIONS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useShouldShowAnthemEAPExperience } from '@headway/shared/hooks/useShouldShowAnthemEAPExperience';
import { useUser } from '@headway/shared/hooks/useUser';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { theme } from '@headway/ui/theme';

import { useSelectedEvent } from 'hooks/useSelectedEvent';
import { shouldBlockProviderWithIroncladAgreement } from 'utils/ironcladAgreement';

import {
  isAppointment,
  isCanceled,
  isIntakeCall,
  isPatientBooked,
} from '../events/util/events';
import { PopUpModalTitleType } from '../utils/constants';

const PATIENT_CANCELLATION_REASONS = [
  ProviderAppointmentStatusNotes.PATIENT_CANCELLATION,
  ProviderAppointmentStatusNotes.NO_SHOW,
];

export interface AppointmentCancelFormValues {
  recurringUpdateApplyTo: keyof typeof RecurringUpdateApplyTo;
  content: string;
  sendMessage: boolean;
  statusNotes: ProviderAppointmentStatusNotes | '';
  patientResponsibilityAmount?: number;
  cancellationReasonDescription?: string;
  providerRebookingPreference?: ProviderRebookingPreference;
}

const AppointmentCancelReasons = ({ event }: { event: ProviderEventRead }) => {
  return {
    [ProviderAppointmentStatusNotes.RESCHEDULING]: `Rebooking ${
      isIntakeCall(event) ? 'call' : 'session'
    } at another time`,
    [ProviderAppointmentStatusNotes.NOT_AWARE]: `Wasn't aware of the ${
      isIntakeCall(event) ? 'call' : 'session'
    }`,
    [ProviderAppointmentStatusNotes.NOT_AVAILABLE]: `Not available at this time`,
    [ProviderAppointmentStatusNotes.CLIENT_NOT_AVAILABLE]: `Client not available`,
    [ProviderAppointmentStatusNotes.SEEING_OUTSIDE_HEADWAY]: `Seeing client outside of Headway`,
    [ProviderAppointmentStatusNotes.STOPPING_CARE]: `Stopping care with client`,
    [ProviderAppointmentStatusNotes.NOT_RIGHT_FIT]: `Client not the right fit`,
    [ProviderAppointmentStatusNotes.PATIENT_CANCELLATION]: `Client canceled ${
      isIntakeCall(event) ? 'call' : 'session'
    }`,
    [ProviderAppointmentStatusNotes.NO_SHOW]: `Client did not ${
      isIntakeCall(event) ? 'pick up call' : 'show'
    }`,
    [ProviderAppointmentStatusNotes.NOT_TAKING_NEW_CLIENTS]: `Not currently taking new clients`,
    [ProviderAppointmentStatusNotes.OTHER]: `Other`,
  };
};

const appointmentCancelSchema = (maxPatientPaymentAmount?: number) =>
  Yup.object().shape({
    statusNotes: Yup.string().required('Cancellation reason is required.'),
    patientResponsibilityAmount: Yup.number().when('statusNotes', {
      is: (statusNotes) => PATIENT_CANCELLATION_REASONS.includes(statusNotes),
      then: (schema: any) =>
        schema
          .required('Amount is required.')
          .max(
            maxPatientPaymentAmount !== undefined
              ? maxPatientPaymentAmount
              : 200,
            maxPatientPaymentAmount !== undefined
              ? `You cannot increase the cancellation fee after you've charged your patient.`
              : 'Amount is greater than $200.'
          )
          .min(0, 'Cannot be a negative number'),
      otherwise: (schema: any) =>
        schema
          .max(
            maxPatientPaymentAmount !== undefined
              ? maxPatientPaymentAmount
              : 200,
            maxPatientPaymentAmount !== undefined
              ? `You cannot increase the cancellation fee after you've charged your patient.`
              : 'Amount is greater than $200.'
          )
          .min(0, 'Cannot be a negative number'),
    }),
    cancellationReasonDescription: Yup.string().when(
      'statusNotes',
      (statusNotes, schema) =>
        statusNotes === ProviderAppointmentStatusNotes.OTHER
          ? schema.max(200, 'Cannot exceed 200 characters.')
          : schema
    ),
  });

interface AppointmentCancelFormProps {
  onDismiss: () => void;
  onSubmit: (values: AppointmentCancelFormValues) => void;
}

export const AppointmentCancelFormHelix = ({
  onDismiss,
  onSubmit,
  ...rest
}: AppointmentCancelFormProps) => {
  const [trackedEventOnce, setTrackedEventOnce] = useState(false);

  const isIroncladBlockAppointmentConfirmationEnabled = useFlag(
    'ironcladBlockAppointmentConfirmation'
  );
  const isPatientRematchingForProviderCancellationsEnabled = useFlag(
    USE_PATIENT_REMATCHING_FOR_PROVIDER_CANCELLATIONS,
    false
  );
  const { event } = useSelectedEvent();
  const provider = useProvider();
  const { data: patient } = useUser(
    { userId: event?.patientUserId! },
    { enabled: !!event?.patientUserId }
  );

  useEffect(() => {
    if (trackedEventOnce || !event) {
      return;
    }

    setTrackedEventOnce(true);
    trackEvent({
      name: 'Pop Up Modal Viewed',
      properties: {
        popUpPageTitle: PopUpModalTitleType.CANCEL_SESSION,
        providerId: provider.id,
        patientUserId: event.patientUserId,
        providerAppointmentId:
          event.recurrence === undefined
            ? event.providerAppointment?.id
            : undefined,
      },
    });
  }, [trackedEventOnce, provider, event]);

  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const shouldBlockProviderFromConfirming =
    !!event?.startDate &&
    shouldBlockProviderWithIroncladAgreement(
      isIroncladBlockAppointmentConfirmationEnabled,
      new Date(event.startDate)
    ) &&
    isAppointment(event);

  const {
    data: shouldShowAnthemEAPExperience,
    isLoading: isLoadingShouldShowAnthemEAPExperience,
  } = useShouldShowAnthemEAPExperience(
    event?.patientUserId,
    provider?.id,
    event?.providerAppointment?.billingType
  );

  if (!event || !patient) {
    return null;
  }

  const initialValues: AppointmentCancelFormValues = {
    statusNotes: event.providerAppointment?.statusNotes || '',
    patientResponsibilityAmount: isCanceled(event)
      ? event.providerAppointment?.patientResponsibilityAmount
      : 0,
    recurringUpdateApplyTo: RecurringUpdateApplyTo.THIS_EVENT,
    content: '',
    sendMessage: true,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={appointmentCancelSchema(
        isCanceled(event)
          ? event.providerAppointment?.patientResponsibilityAmount
          : undefined
      )}
      enableReinitialize={true}
      onSubmit={onSubmit}
      {...rest}
    >
      {({ values, isSubmitting }) => {
        return (
          <SafeFormikForm className="grid gap-4">
            {shouldBlockProviderFromConfirming && (
              <div css={{ marginBottom: theme.space.base }}>
                <GuidanceCard variant="warning">
                  To ensure you are paid in a timely manner, we can only
                  facilitate cancellation fees for sessions scheduled within the
                  past 30 days
                </GuidanceCard>
              </div>
            )}
            <div>
              <FormControl
                component={Select}
                name="statusNotes"
                css={{ marginTop: theme.space.xs }}
                selectionMode="single"
                menuWidth="stretch"
                label="Why are you cancelling this session?"
                placeholder="Cancellation reason"
              >
                {Object.entries(AppointmentCancelReasons({ event })).map(
                  ([key, value]) => (
                    <Item key={key} textValue={key}>
                      {value}
                    </Item>
                  )
                )}
              </FormControl>
            </div>
            {values.statusNotes &&
              values.statusNotes === ProviderAppointmentStatusNotes.OTHER && (
                <FormControl
                  name="cancellationReasonDescription"
                  component={TextField}
                  label="Please share more details on this cancellation"
                  placeholder="What happened?"
                />
              )}

            {!provider.customBilling && // custom billing providers shouldn't charge cancellation fees
              !isIntakeCall(event) &&
              values.statusNotes &&
              !shouldShowAnthemEAPExperience &&
              !isLoadingShouldShowAnthemEAPExperience && (
                <div>
                  <FormControl
                    name="patientResponsibilityAmount"
                    component={NumberField}
                    label="How much should we bill the client? (optional)"
                    formatOptions={{ style: 'currency', currency: 'USD' }}
                  />
                  <ContentText variant="caption" color="foreground/secondary">
                    {isCanceled(event)
                      ? `You can reduce the amount you've already charged your client. We will refund the client the difference.`
                      : 'You can charge up to $200.'}
                  </ContentText>
                </div>
              )}
            {event.recurrence && (
              <div>
                <FormControl
                  component={RadioGroup}
                  name="recurringUpdateApplyTo"
                  label="Which sessions should this cancellation apply to?"
                >
                  <Radio value={RecurringUpdateApplyTo.THIS_EVENT}>
                    Only this session
                  </Radio>
                  <Radio value={RecurringUpdateApplyTo.FOLLOWING_EVENTS}>
                    This and all following sessions
                  </Radio>
                </FormControl>
              </div>
            )}

            {!isPatientBooked(event) ? (
              <div className="grid gap-4">
                <div className="flex justify-between">
                  <ContentText variant="body">
                    <strong>Send a note to {patientFirstName}</strong>
                  </ContentText>
                  <FormControl
                    name="sendMessage"
                    component={Switch}
                    labelPosition="left"
                  >
                    {''}
                  </FormControl>
                </div>
                <div>
                  {values.sendMessage ? (
                    event.recurrence &&
                    values.recurringUpdateApplyTo ===
                      RecurringUpdateApplyTo.FOLLOWING_EVENTS ? (
                      <ContentText variant="body">
                        We’ll let {patientFirstName} know that their session on
                        and following{' '}
                        <strong>
                          {moment(event.startDate).format('dddd, MMMM D')}
                        </strong>{' '}
                        was canceled. You can add a personal message to include
                        with the cancellation email.
                      </ContentText>
                    ) : (
                      <ContentText variant="body">
                        We’ll let {patientFirstName} know that their session on{' '}
                        <strong>
                          {moment(event.startDate).format('dddd, MMMM D')}
                        </strong>{' '}
                        was canceled. You can add a personal message to include
                        with the cancellation email.
                      </ContentText>
                    )
                  ) : null}
                </div>
              </div>
            ) : event.recurrence &&
              values.recurringUpdateApplyTo ===
                RecurringUpdateApplyTo.FOLLOWING_EVENTS ? (
              <>
                <ContentText variant="body">
                  We'll let <strong>{patientFirstName}</strong> know that their
                  sessions on and following{' '}
                  <strong>
                    {moment(event.startDate).format('dddd, MMMM Do')}
                  </strong>{' '}
                  were canceled. You can add a personal message to include with
                  the cancellation email.
                </ContentText>
              </>
            ) : (
              <ContentText variant="body">
                We'll let <strong>{patientFirstName}</strong> know that their
                session on{' '}
                <strong>
                  {moment(event.startDate).format('dddd, MMMM Do')}
                </strong>{' '}
                was canceled. You can add a personal message to include with the
                cancellation email.
              </ContentText>
            )}

            {values.sendMessage && (
              <FormControl
                name="content"
                component={RichTextArea}
                label="Add a custom note"
                optionalityText="Optional"
              />
            )}

            {isPatientRematchingForProviderCancellationsEnabled ? (
              <FormControl
                name="providerRebookingPreference"
                component={RadioGroup}
                label={
                  <ContentText variant="body">
                    <strong>
                      How would you like us to help to reschedule{' '}
                      {patientFirstName}?
                    </strong>{' '}
                    We share options in their cancellation email so they can
                    easily book another session.
                  </ContentText>
                }
              >
                <Radio
                  value={ProviderRebookingPreference.ALLOW_SHARE_AVAILABILITY}
                >
                  Share my availability, along with similar providers’
                  availability.
                </Radio>
                <Radio
                  value={ProviderRebookingPreference.DENY_SHARE_AVAILABILITY}
                >
                  Only share availability for similar providers.
                </Radio>
                <Radio
                  value={ProviderRebookingPreference.RESCHEDULING_INDEPENDENTLY}
                >
                  Neither, I’ll reschedule with them myself.
                </Radio>
              </FormControl>
            ) : null}
            <div className="mt-6 flex justify-end gap-2">
              <Button
                type="button"
                variant="secondary"
                onPress={onDismiss}
                data-testid="appointmentCancelCancelButton"
              >
                Back
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                data-testid="appointmentCancelSubmitButton"
              >
                {isCanceled(event) ? 'Modify' : 'Cancel session'}
              </Button>
            </div>
          </SafeFormikForm>
        );
      }}
    </Formik>
  );
};
