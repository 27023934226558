import { providerPaymentPause } from '~/legacy/hooks/useProviderPaymentPause';

import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderPaymentPauseRead } from '@headway/api/models/ProviderPaymentPauseRead';

import { Rule } from '../Rule';
import { PausedPaymentsRequirementBanner } from './PausedPaymentsProgressNoteBanner';

export class PausedPayments extends Rule<
  [ProviderPaymentPauseRead[], boolean]
> {
  addDataHooks() {
    return [
      async () =>
        providerPaymentPause(
          {
            providerId: this.provider.id,
            activeOnly: true,
          },
          Rule.queryClient
        ),
      async () => this.flags['pausedPayments'] || false,
    ];
  }

  isRuleActive(): boolean {
    const hookData = this.getHookData();

    if (!hookData || !hookData[0] || hookData[1] === false) {
      return false;
    }

    const activeProviderPaymentPauses = hookData[0];

    return activeProviderPaymentPauses.length > 0;
  }

  get disabledProgressNoteTypes(): ProgressNoteType[] {
    return [ProgressNoteType.NONE];
  }

  get banners() {
    return {
      progressNote: {
        header: <PausedPaymentsRequirementBanner />,
      },
    };
  }

  isDocumentationRequired(): boolean {
    return true;
  }
}
